import React, { useState, useEffect } from 'react'
import TitlePath from '../../components/TitlePath/TitlePath'
import { SymbolTextBox } from '../../components/Input/SymbolTextbox'
import { states } from './OverviewRecord';
import { CoustomTableHeaderProps } from '../../components/Table/CoustomTable';

interface Props {
    buttonChoice: string
    title: string
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    refreshOnClick: () => void
    state: string
}


export default function OverviewHeader(p: Props) {
    const [filterPlaceholder, ChangePlaceHolder] = useState("Filter Location Name");

    useEffect(() => {
        p.buttonChoice =="locationName" ? ChangePlaceHolder("Filter Location Name") : ChangePlaceHolder("Filter Project Name")
	}, [p.buttonChoice])

    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    }
    return (
        <div className=' flex justify-between flex-wrap '>
            <SymbolTextBox
                buttonChoice={p.buttonChoice}
                defaultValue={p.state}
                changePlaceholderValue={ChangePlaceHolder}
                options={p.options}
                IconLeft={filterProps.IconLeft}
                IconRight={filterProps.IconRight}
                placeholder={filterProps.placeholder}
                onChange={(e) => p.setState(e.target.value)}
                statesArray={p.statesArray}
                setButtonChoice={p.setButtonChoice} />	
        </div>
    )
}
