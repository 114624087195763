import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { ResetPasswordModel, UserFormValue } from "../../models/User";
import { ChangeEvent, useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from "react-router-dom";
import LoginIcons from "../../components/LoginElement/LoginIcons";
import { LoginInput } from "../../components/LoginElement/LoginInput";
import LoginButton from "../../components/LoginElement/LoginButton";
import LoadingComponent from "../../components/LoadingComponent";
import { AxiosResponse } from "axios";


export default observer(function ResetForm() {
    const { userStore } = useStore();

    const location = useLocation();
    const getQueryParams = (query: any) => {
        return new URLSearchParams(query);
    };
    const queryParams = getQueryParams(location.search);

    const code = queryParams.get('code');

    const email = queryParams.get('email');



    const [resetObj, setResetObj] = useState<ResetPasswordModel>({
        newPassword: '',
        confirmPassword: '',
        code: '',
        email: ''
    });
    const [ClientError, setClientError] = useState<Partial<ResetPasswordModel>>({});
    const [ServerError, setServerError] = useState("");
    const [requestSuccess, setRequestSuccess] = useState("");

    function handelSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var err = handleValidation(resetObj);

        if (Object.keys(err).length !== 0 || email === '' || code === '') {
            console.log("client side error")
            return;
        } else {

            console.log(code)

            //console.log({ ...resetObj, code: encodeCode ?? '', email: email ?? '' })
            userStore.reset({ ...resetObj, code: code ?? '', email: email ?? '' }).catch((error) => {
                console.log(error)
                if (error.response) {
                    const { data, status, config } = error.response as AxiosResponse;
                    setServerError(data);
                }
            })
        }
    }


    function handleInput(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        setResetObj({ ...resetObj, [name]: value })  //name='title'  >>> change activity.title
    }

    function handleValidation(userObj: ResetPasswordModel) {
        const errors: Partial<ResetPasswordModel> = {}

        if (!userObj.newPassword) {
            errors.newPassword = "The Input is empty"
        }
        if (!userObj.confirmPassword) {
            errors.confirmPassword = "The Input is empty"
        }
        if (userObj.newPassword != userObj.confirmPassword) {
            errors.confirmPassword = "The confirm Password is not the same."

        }
        setClientError(errors);
        return errors;
    }
    if (userStore.loadingInitial)
        return <LoadingComponent />;
    return (

        <div className="  h-full flex justify-center items-center "  >
            <div className='w-[350px]'>
                <form onSubmit={handelSubmit} autoComplete="off" >
                    <div className="flex flex-col gap-4">
                        <LoginIcons />

                        <div className="flex flex-col gap-3">
                            <LoginInput title={"New Password"} name={"newPassword"} value={resetObj.newPassword} record={resetObj} setRecord={setResetObj} isPassword={true} />
                            <LoginInput title={"Confirm Password"} name={"confirmPassword"} value={resetObj.confirmPassword} record={resetObj} setRecord={setResetObj} isPassword={true} />

                            {ServerError &&
                                <Message error>
                                    {ServerError}
                                </Message>}

                            {requestSuccess &&
                                <Message success>
                                    {requestSuccess}
                                </Message>}
                        </div>



                        <LoginButton isSubmit={true} title={"Submit"} />


                    </div>
                </form>
            </div>


        </div>
    )
})
