import React, { createContext, useContext } from 'react'
import UserStore from './UserStore';
import CommonStore from './CommonStore';
import DepartmentStore from './SummaryStore';
import SourceTypeStore from './SourceTypeStore';
import InventoryStore from './InventoryStore';
import SummaryStore from './SummaryStore';
import LoggerSetStore from './LoggerSetStore';
import ProjectStore from './ProjectStore';
import LocationStore from './LocationStore';
import AlarmStore from './AlarmStore';
import CustomerStore from './CustomerStore';
import AccountStore from './AccountStore';
import GraphStore from './GraphStore';
import DataStore from './DataStore';
import PerformanceStore from './PerformanceStore';
import LocationMapStore from './LocationMapStore';
import OverviewStore from './OverviewStore';


interface Store {
    accountStore : AccountStore,
    userStore: UserStore,
    commonStore: CommonStore,
    summaryStore: SummaryStore,
    sourceTypeStore: SourceTypeStore,
    inventoryStore: InventoryStore,
    loggerSetStore: LoggerSetStore
    locationStore: LocationStore,
    customerStore: CustomerStore
    projectStore: ProjectStore,
    alarmStore: AlarmStore,
    graphStore: GraphStore
    dataStore: DataStore
    performanceStore:PerformanceStore
    locationMapStore: LocationMapStore
    overviewStore: OverviewStore
}


export const store: Store = { // object can implement the interface
  
    accountStore : new AccountStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    summaryStore: new SummaryStore(),
    sourceTypeStore: new SourceTypeStore(),
    inventoryStore: new InventoryStore(),
    loggerSetStore: new LoggerSetStore(),
    projectStore:new ProjectStore(),
    locationStore: new LocationStore(),
    customerStore: new CustomerStore(),
    alarmStore: new AlarmStore(),
    graphStore: new GraphStore(),
    dataStore: new DataStore(),
    performanceStore: new PerformanceStore(),
    locationMapStore: new LocationMapStore(),
    overviewStore: new OverviewStore()



}

export const StoreContext = createContext(store); // to share an obj

export function useStore() {
    return useContext(StoreContext);
}