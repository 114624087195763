import React from 'react'

export interface InventoryStatusButtonProps {
  type: string| undefined,
  handleClick?: () => void
}

export default function InventoryStatusButton({ type, handleClick }: InventoryStatusButtonProps) {
  let bgColor = '', textColor = '', title = '';

  switch (type) {
    case 'blue':
      bgColor = 'rgb(213,241,255)';
      textColor = 'rgb(1,178,255)';
      title = 'In Use'
      break;
    case 'testingBlue':
      bgColor = 'rgb(213,241,255)';
      textColor = 'rgb(1,178,255)';
      title = 'In Use (Testing)'
      break;
    case 'green':
      bgColor = 'rgb(215, 235, 216)';
      textColor = 'rgb(42, 165, 43)';
      title = 'Available'
      break;
    case 'red':
      bgColor = 'rgb(255, 200, 200)';
      textColor = 'rgb(255, 0, 0)';
      title = 'Out of Service'
      break;
    case 'black':
      bgColor = 'rgb(175, 175, 175)';
      textColor = 'rgb(0, 0, 0)';
      title = 'Disposed'
      break;
  }
  return (

    <div className='hover:cursor-pointer' >
      {type === 'all'
        ? <span className='whitespace-nowrap '>All</span>
        : (
          <div className='inline-flex items-center  px-3  rounded-full ' style={{ backgroundColor: bgColor }} onClick={handleClick}  >
            <div className=" rounded-full w-3 h-3 " style={{ backgroundColor: textColor }} ></div>
            <span className='whitespace-nowrap pl-1  font-medium text-[14px] ' style={{ color: textColor }}>
              {title}
            </span>
          </div>
        )}
    </div>
  )
}
