import { useEffect, useRef, useState } from "react";
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../components/Table/CoustomTable";
import LoggerSetHeader from "./LoggerSetHeader"
import { ThemeProvider } from '@mui/material/styles';
import { SourceType, states } from "../SourceType/SourceTypeRecord";
import { ILoggerSetError, LoggerSetDTO, LoggerSetErrorValue, LoggerSetFormValue } from "../../models/LoggerSet";
import InventoryStatusButton from "../../components/Button/InventoryStatusButton";
import { Inventory, InventoryDto } from "../../models/Inventory";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import LoggerSetStore from "../../stores/LoggerSetStore";
import { LocationFormValueDto } from "../../models/Location";
import LoadingComponent from "../../components/LoadingComponent";
import { Page } from "../../components/TitlePath/TitlePath";
import { sortingAlgor } from "../../hook/useSorting";

interface props {
    isOpen?: boolean;
    setisOpen?: (x: boolean) => void;
    onSelectedIdsChange?: (arr: string[]) => void;
    record?: LocationFormValueDto;
    selectedProjectIds?: string[];
    selectedRow?: (number | null);
    setselectedRow?: React.Dispatch<React.SetStateAction<number | null>>

    selectedId?: string
    isInfo?: boolean
}

export default observer(function LoggerSetMaintenance({ isInfo, selectedId, setselectedRow, selectedRow, selectedProjectIds, isOpen, setisOpen, onSelectedIdsChange, record }: props) {

    const params = new URLSearchParams(window.location.search);
    const page_FromUrl = params.get('page');
    const LoggernameState_FromUrl = params.get('LoggernameState');
    const EquipmentnameState_FromUrl = params.get('EquipmentnameState');
    const statusState_FromUrl = params.get('statusState');
    const buttonChoice_FromUrl = params.get('buttonChoice');

    const activeId = useRef<string | null>(null);
    const direction = useRef<'asc' | 'desc'>('asc');

    const onSortClick = () => {
        setPage(0);
        sortingAlgor({ rows, setrows, direction, activeId });

    };

    const [page, setPage] = useState(Number(page_FromUrl) ?? 0);
    const [LoggersetState, setLoggersetState] = useState(EquipmentnameState_FromUrl ?? "");
    const [LoggernameState, setLoggernameState] = useState(LoggernameState_FromUrl ?? "");
    const [statusState, setStatusState] = useState(statusState_FromUrl ?? "all");
    const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "Loggersetname");
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
    const [Results, setResult] = useState<LoggerSetDTO[]>([])
    const [list, setlist] = useState<LoggerSetFormValue[]>();

    const final_setStatusState = (state: string) => { //cant use useEffect
        setStatusState(state)
        setPage(0)
    }

    const final_setButtonChoice = (state: string) => { //cant use useEffect
        setButtonChoice(state)
        setPage(0)
    }

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('page', String(page));
        params.set('statusState', statusState);
        params.set('buttonChoice', buttonChoice);

        if (buttonChoice === 'Loggersetname') {
            params.set('LoggersetState', LoggersetState);
        } else if (buttonChoice === 'Loggername') {
            params.set('LoggernameState', LoggernameState);
        }

        window.history.replaceState({}, '', `?${params.toString()}`);
    }, [page, statusState, buttonChoice, LoggersetState, LoggernameState]);

    const { loggerSetStore } = useStore();
    const { locationStore } = useStore();

    const { loadingInitial, submitting, LoadingLoggersets, loggerSets, getLoggerSetListByStatus, activeLoggerSets } = loggerSetStore

    const convertToRows = (list: Partial<InventoryDto>[]) => {
        return (
            <table>
                <tbody>
                    {list.length > 0 ? list.map((item, index) => (
                        <tr key={index}>
                            <td>{item.equipmentName}<span>{list.length > 1 && index < list.length - 1 && <>,</>}</span></td>
                        </tr>
                    )) : "--"}
                </tbody>
            </table>
        );
    };

    const filterList = (list: Partial<InventoryDto>[], state: string) => {
        for (const item of list) {
            if (item.equipmentName?.toLowerCase().includes(state.toLowerCase())) return true;
        }
        return false;
    }

    //item.loggerList[0].equipmentName?.toLowerCase().includes(EquipmentnameState.toLowerCase())


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isInfo) {
                    await locationStore.getLoggerSetsInLocation(selectedId).then(i => setlist(i));
                }
                else {
                    await LoadingLoggersets().then(i => setlist(i));
                }
            } catch (error) {
                console.error(error)
            }
        };
        if (loggerSetStore) {
            fetchData();
            //console.log(" RESULTS: "+loggerSets[0].statusId)
        }
    }, [LoggerSetStore])


    useEffect(() => {
        if (record && record.loggerSetId != undefined && setselectedRow != null) {
            const loggerSetId: string = record.loggerSetId.toString();

            for (var i = 0; i < rows.length; i++) {
                if (rows[i].Id === loggerSetId) {
                    console.log("changed to ,", i)
                    setselectedRow(i);
                }
            };

            console.log("inside");
        }
        else if (record?.loggerSetId == undefined && setselectedRow != null) {
            setselectedRow(null)
        }
    }, [record?.loggerSetId, rows]);

    useEffect(() => {
        const initialRows =
            Results.filter((item) => {
                return LoggernameState.toLowerCase() == "" ? item
                    : filterList(item.loggerList, LoggernameState);
            }).filter((item2) => {
                return LoggersetState.toLowerCase() == "" ? item2
                    : item2.name.toLowerCase().includes(LoggersetState.toLowerCase());
            }).filter((item3) => {
                return statusState.toLowerCase() === "all" || statusState.toLowerCase() === "" ? item3
                    : item3.status?.toLowerCase() === statusState.toLowerCase();
            }).map(
                (record: LoggerSetDTO) => ({

                    Id: String(record.id),
                    ischecked: false,
                    data: [
                        { id: "Loggersetname", data: record.name },
                        { id: "Loggername", data: convertToRows(record.loggerList) },
                        { id: "Sensorname", data: record.sensorList ? convertToRows(record.sensorList) : undefined },
                        { id: "OtherEquipmentName", data: record.otherEquipmentList ? convertToRows(record.otherEquipmentList) : undefined },
                        { id: "Loggerstatus", data: record.status, isJSXelement: true, JSXelement: <InventoryStatusButton type={record.status} /> },
                        { id: "Loggerlocation", data: record.Location },
                        { id: "Remark", data: record.remark ? record.remark : "No Remark" },
                    ]
                })
            )
        setrows(initialRows);
        //console.log(rows)
    }, [Results, LoggersetState, LoggernameState, statusState])

    useEffect(() => {
        if (list) {
            const temp: LoggerSetDTO[] = list.map(
                s => ({
                    id: s.id,
                    name: s.name,
                    loggerList: s.loggerList,
                    sensorList: s.sensorList,
                    otherEquipmentList: s.otherEquipmentList,
                    status: s.statusId == 1 ? "red" : s.statusId == 2 ? "green" : s.statusId === 3 ? "black" : s.statusId == 4 ? "blue" : "testingBlue",
                    Location: String(s.locationId),
                    remark: s.remark,
                    remarkDescription: s.remarkDescription
                })
            )
            setResult(temp);
        }
    }, [list])


    if (loadingInitial || submitting) {
        return <div></div>; //improve later
    }

    const statesArray: states[] = [
       
        {
            currentstate: LoggersetState,
            statemethod: setLoggersetState
        },
        {
            currentstate: LoggernameState,
            statemethod: setLoggernameState
        },
    ]

    const headers: Array<CoustomTableHeaderProps> = [
        { id: "Loggersetname", data: "Logger Set Name" },
        { id: "Loggername", data: "Logger Name" },
        { id: "Sensorname", data: "Sensor Name" },
        { id: "OtherEquipmentName", data: "Other Equipment Name" },
        { id: "Loggerstatus", data: "Status" },
        { id: "Loggerlocation", data: "(Location)" },
        { id: "Remark", data: "Remark" },

    ];


    //sorting



    //////////////////////
    const settingState = buttonChoice === "Loggername"
        ? setLoggernameState
        : setLoggersetState

    const state = buttonChoice === "Loggername"
        ? LoggernameState
        : LoggersetState
    /*
    => hard coded the first element of list to be displayed, needs to be corrected (need to find a way to display multiple values for the lists).
    => hard coded filter to filter out according to first element of loggerlist, needs to be corrected,
       Filter name needs to be changed from logger name to equipment name
    => **create/edit functionality for form** : first need to connect backend
    */
    const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]
    if (loadingInitial)
        return <LoadingComponent content="Loading Loggerset..." />;

    return (
        <div className="pageParentDiv">
            <LoggerSetHeader statusState={statusState} buttonChoice={buttonChoice} state={state} title="Logger Set Management" lastPages={lastPages} isInfoTableHeader={isInfo} setStatusState={final_setStatusState} setState={settingState} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "Loggername" || item.id == "Loggersetname")} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} />
            <ThemeProvider theme={customTableTheme}>

                {(!isInfo) &&
                    <CoustomTable onSortClick={onSortClick} direction={direction} page={page} setPage={setPage} subPath="LoggersetMaintenance" headers={headers} rows={rows} setRows={setrows} activeId={activeId} />
                }
                {
                    isInfo &&
                    <CoustomTable setselectedRow={setselectedRow} selectedRow={selectedRow} page={page} setPage={setPage} assignButton={() => { if (setisOpen) setisOpen(false) }} singleRowCheck={true} record={record} onSelectedIdsChange={onSelectedIdsChange} cancelButton={() => { if (setisOpen) setisOpen(false) }} headers={headers} rows={rows} setRows={setrows} activeId={activeId} tableMarginRight="10" />
                }

            </ThemeProvider>
        </div>
    )
})                   