import React, { useEffect, useRef, useState } from 'react'

import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import InventoryManagementHeader from '../../components/InventoryManagement/InventoryManagementHeader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InventoryStatusButton from '../../components/Button/InventoryStatusButton';
//import useSortableData from '../../components/Table/hooks/useSortableData';
import { states } from '../SourceType/SourceTypeRecord';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import LoadingComponent from '../../components/LoadingComponent';
import InventoryStore from '../../stores/InventoryStore';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AxiosResponse } from 'axios';
import ServerErrorMessage from '../../components/Message/Error/ServerErrorMessage';
import { sortingAlgor } from '../../hook/useSorting';

export interface InventoryType {
  equipmentId: string
  inventoryType: string,
  id: string,
  manufacturerName: string,
  serialNo: string,
  status?: "red" | "black" | "green" | "blue" | "all",
  locationName?: string,
  // purchaseDate?: string,
  remark?: string,
}

export const statusColorToRecord = (status: "red" | "black" | "green" | "blue" | "all" | undefined) => {
  let value: string = "";
  switch (status) {
    case 'blue':
      value = "In use"
      break;
    case 'green':
      value = "Available"
      break;
    case 'red':
      value = "Out of Service"
      break;
    case 'black':
      value = "Disposed"
      break;
    default:
      break;
  }
  return value;
}


export default observer(function InventoryManagement() {

  const params = new URLSearchParams(window.location.search);
  const page_FromUrl = params.get('page');
  const equipmentIdState_FromUrl = params.get('equipmentIdState');
  const manufacturerNameState_FromUrl = params.get('manufacturerNameState');
  const serialNoState_FromUrl = params.get('serialNoState');
  const statusState_FromUrl = params.get('statusState');
  const buttonChoice_FromUrl = params.get('buttonChoice');
  const typeState_FromUrl = params.get('typeState');

  const [page, setPage] = useState(Number(page_FromUrl) ?? 0);
  const [equipmentIdState, setequipmentIdState] = useState<string>(equipmentIdState_FromUrl ?? "");
  const [manufacturerNameState, setmanufacturerNameState] = useState<string>(manufacturerNameState_FromUrl ?? "");
  const [serialNoState, setserialNoState] = useState<string>(serialNoState_FromUrl ?? "");
 
  const [statusState, setStatusState] = useState<string>(statusState_FromUrl ?? 'all');
  const [buttonChoice, setButtonChoice] = useState<string>(buttonChoice_FromUrl ?? "equipmentId");
  const [typeState, setTypeState] = useState<string>(typeState_FromUrl ?? "all");

  const final_setStatusState =(state:string) =>{ //cant use useEffect
    setStatusState(state)
    setPage(0)
  }
  const final_setButtonChoice =(state:string) =>{ //cant use useEffect
    setButtonChoice(state)
    setPage(0)
  }
  const final_setTypeState =(state:string) =>{ //cant use useEffect
    setTypeState(state)
    setPage(0)
  }

  const [excelRecord, setexcelRecord] = useState<InventoryType[]>([]);
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
  const [Results, setResult] = useState<InventoryType[]>([])

  const { inventoryStore } = useStore();
  const { LoadingInventoryList, Inventories, loadingInitial, DeleteInventories, exportInventories } = inventoryStore
  const navigate = useNavigate();


  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', String(page));
    params.set('statusState', statusState);
    params.set('buttonChoice', buttonChoice);
    params.set('typeState', typeState);

    if (buttonChoice === 'equipmentId') {
      params.set('equipmentIdState', equipmentIdState);
    } else if (buttonChoice === 'manufacturerName') {
      params.set('manufacturerNameState', manufacturerNameState);
    } else if (buttonChoice === 'serialNo') {
      params.set('serialNoState', serialNoState);
    }
    window.history.replaceState({}, '', `?${params.toString()}`);
  }, [page, statusState, buttonChoice, equipmentIdState, manufacturerNameState, serialNoState, typeState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await LoadingInventoryList();
      } catch (error) {
        console.error(error)
      }
    };
    if (InventoryStore) {
      fetchData();
    }
  }, [InventoryStore])

  // useEffect(() => {
  //   setPage(0)
  // }, [buttonChoice, typeState, statusState])

  useEffect(() => {
    const initialRows: Array<CoustomTableRowProps> =
      Results.filter((item) => {
        return equipmentIdState.toLowerCase() == "" ? item
          : item.equipmentId.toLowerCase().includes(equipmentIdState.toLowerCase());
      }).filter((item) => {
        return manufacturerNameState.toLowerCase() == "" ? item
          : item.manufacturerName.toLowerCase().includes(manufacturerNameState.toLowerCase());
      }).filter((item) => {
        return serialNoState.toLowerCase() == "" ? item
          : item.serialNo.toLowerCase().includes(serialNoState.toLowerCase());
      }).filter((item) => {
        return statusState.toLowerCase() === "all" || statusState.toLowerCase() === "" ? item
          : item.status?.toLowerCase() === statusState.toLowerCase();
      })
        .filter((item) => {
          return typeState.toLowerCase() === "all" || typeState.toLowerCase() === "" ? item
            : item.inventoryType?.toLowerCase() === typeState.toLowerCase();
        }).map(
          (record: InventoryType) => ({

            Id: record.id,
            ischecked: false,

            data: [
              { id: "inventoryType", data: record.inventoryType, isJSXelement: false },
              { id: "equipmentId", data: record.equipmentId },
              { id: "manufacturerName", data: record.manufacturerName ? record.manufacturerName : "------", isJSXelement: false },
              { id: "serialNo", data: record.serialNo, isJSXelement: false },
              { id: "status", JSXelement: <InventoryStatusButton type={record.status} handleClick={() => { }} />, data: statusColorToRecord(record.status), isJSXelement: true },
              //<InventoryStatusButton type={record.status} handleClick={() => { }} />
              // <button disabled className={"w-36 rounded-full py-2 px-4 font-semibold " + (record.status === false ? "bg-teal-300" : "bg-rose-300")} onClick={(e) => {
              //   e.preventDefault();
              //   //handleClick(record.id, record.isEnableSync);
              // }}>{record.status === false ? "avaliable" : "out of service"}</button>,
              { id: "locationName", data: record.locationName ? record.locationName : "", isJSXelement: false },
              // { id: "purchaseDate", data: record.purchaseDate ? record.purchaseDate : "", isJSXelement: false },
              { id: "remark", data: record.remark ? record.remark : "No Remark", isJSXelement: false },

            ]
          })
        )
    setrows(initialRows)
    //console.log(initialRows)
  }, [Results, equipmentIdState, manufacturerNameState, serialNoState, statusState, typeState])


  useEffect(() => { //fetch and set , only once
    const Results2: InventoryType[] = Inventories.map(
      (inventory) => ({
        equipmentId: inventory.equipmentCode ? inventory.equipmentCode : 'N/A',
        inventoryType: String(inventory.inventoryTypeName),
        id: String(inventory.id),
        manufacturerName: inventory.manufacturerName,
        serialNo: inventory.serialNo,
        status: inventory.statusId === 1 ? 'red' : inventory.statusId === 2 ? 'green' : inventory.statusId === 3 ? 'black' : 'blue',
        locationName: inventory.locationName ?? "N/A",
        // purchaseDate: String(inventory.purchaseDate),
        remark: inventory.remark,

      })
    );
    setResult(Results2)
  }, [Inventories])


  const statesArray: states[] = [
    {
      currentstate: equipmentIdState,
      statemethod: setequipmentIdState
    },
    {
      currentstate: manufacturerNameState,
      statemethod: setmanufacturerNameState
    },
    {
      currentstate: serialNoState,
      statemethod: setserialNoState
    },

  ]


  const headers: Array<CoustomTableHeaderProps> = [
    { id: "inventoryType", data: "Inventory Type" },
    { id: "equipmentId", data: "Equipment ID" },
    { id: "manufacturerName", data: "Manufacturer Name" },
    { id: "serialNo", data: "Serial No." },
    { id: "status", data: "Status" }, //sortDisable:true },
    { id: "locationName", data: "(Location Name)" },
    //{ id: "purchaseDate", data: "Purchased By & Date" },
    { id: "remark", data: "Remark" },

  ];









  const stateSelection = buttonChoice === "equipmentId"
    ? setequipmentIdState
    : buttonChoice === "manufacturerName"
      ? setmanufacturerNameState
      : setserialNoState

 const state = buttonChoice === "equipmentId"
    ? equipmentIdState
    : buttonChoice === "manufacturerName"
      ? manufacturerNameState
      : serialNoState

  const handleDeleteFunction = async (ids: string[]) => {
    console.log(ids);

    await DeleteInventories(ids).then(
      (i) => {
        console.log("success")
        toast.success("success")
        window.location.reload()

      }
    ).catch(
      (error) => {
        console.error(error);
        const { data, status, config } = error.response as AxiosResponse;
        console.log(data)
        toast.error(
          <div>
            <ServerErrorMessage messageHeader={data[0]} messageList={data.slice(1)} />
          </div>
        );
      }
    )
  }

  const handleCopyFunction = async (ids: string[]) => {
    var id = ids[0];
    navigate('/inventory/create', { state: { copyId: id } });
  }

  //sorting

  const activeId = useRef<string | null>(null);
  const direction = useRef<'asc' | 'desc'>('asc');

  const onSortClick = () => {
    setPage(0);
    sortingAlgor({ rows, setrows, direction, activeId });

  };

  //////////////////////

  if (loadingInitial)
    return <LoadingComponent content="Loading activity..." />;

  return (



    <div className=" pageParentDiv " >
      <InventoryManagementHeader statusState={statusState} buttonChoice={buttonChoice} typeState={typeState} setTypeState={final_setTypeState} setStatusState={final_setStatusState} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} state={state} setState={stateSelection} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "equipmentId" || item.id == "manufacturerName" || item.id == "serialNo")} />
      <ThemeProvider theme={customTableTheme}>
        <CoustomTable onSortClick={onSortClick} activeId={activeId} direction={direction} overrideExcelExport={exportInventories} copyFunction={handleCopyFunction} deleteFunction={handleDeleteFunction} page={page} setPage={setPage} excelRecord={excelRecord} setexcelRecord={setexcelRecord} headers={headers} rows={rows} setRows={setrows} subPath='inventory' />
      </ThemeProvider>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </div>


  )
})
