
import { ThemeProvider } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/store";
import SourceTypeStore from "../../../stores/SourceTypeStore";
import { states } from "../../../pages/SourceType/SourceTypeRecord";
import { InfoTableHeader } from "../../InfoFIleHeader/InfoTableHeader";
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../Table/CoustomTable";

interface sourceTypeInfo {
  onSelectedIdsChange?: (arr: string[]) => void;
  id: string;
  sourceType: string;
  valueUnit: string;

}

interface props {
  onSelectedIdsChange: (arr: string[]) => void;
  selectedIds?: string[]
  isOpen: boolean;
  setisOpen: (x: boolean) => void;
}

// const MODAL_STYLES = {
//   postion: "fixed",
//   top: "50%",
//   left: "50%",
//   transform: "translate(15%,-50%)",
//   backgroundColor: "#FFF",
//   margin: "10%",
//   height:"900px",
//   width:"1000px",
//   borderRadius:"20px",
//   zIndex: 1000,

// }

// const OVERLAY_STYLES = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: "rgba(0,0,0,.7)",
//   zIndex: 1000,
//   overflow:"auto"

// };


const MODAL_STYLES = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

}

const OVERLAY_STYLES = {
  zIndex:999,
  width: '100vw',
  height: '100vh',
  backgroundColor: ' rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(2px)',
};

const CONTENT_STYLES = {

  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  zIndex: 1000,
  padding: '32px',
  boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  maxHeight: '100vh',
  overflowY: 'auto',
};


export const InventoryInfoTable = observer(({ selectedIds, onSelectedIdsChange, isOpen, setisOpen }: props) => {


  const [page, setPage] = useState(0);
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
  const [SourcetypeState, setSourcetypeState] = useState("");
  const [LoggerequipmentIdState, setLoggerequipmentIdState] = useState("");
  const [LoggerserialNoState, setLoggerserialNoState] = useState("");
  const [buttonChoice, setButtonChoice] = useState("SourceType");
  const { sourceTypeStore } = useStore();
  const { LoadingSourceType, SourceTypes } = sourceTypeStore
  const activeId = useRef<string | null>(null);
  const [results, setResult] = useState<sourceTypeInfo[]>([])

const handleCancel =()=>{ 
  setisOpen(false)
  const updatedRows = results
  .filter((item) => (
    item.sourceType.toLowerCase().includes(SourcetypeState.toLowerCase())
  ))
  .map((record: sourceTypeInfo) => ({
    data: [
      { id: "SourceType", data: record.sourceType },
      { id: "ValueUnit", data: record.valueUnit },

    ],
    Id: record.id,

    ischecked: selectedIds ? selectedIds.includes(record.id) : false
  }));

setrows(updatedRows);
}


  useEffect(() => {
    const fetchData = async () => {
     // console.log("SourceTypes appended")

      try {
        await LoadingSourceType(true);
        //setisLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (SourceTypeStore) {
      fetchData();

    }
  }, [SourceTypeStore]);



  useEffect(() => {
    const updatedRows = results
      .filter((item) => (
        item.sourceType.toLowerCase().includes(SourcetypeState.toLowerCase())
      ))
      .map((record: sourceTypeInfo) => ({
        data: [
          { id: "SourceType", data: record.sourceType },
          { id: "ValueUnit", data: record.valueUnit },

        ],
        Id: record.id,

        ischecked: selectedIds ? selectedIds.includes(record.id) : false
      }));

    setrows(updatedRows);
    //console.log(updatedRows);
   // console.log(selectedIds);

  }, [results, SourcetypeState, selectedIds]);


  useEffect(() => {

    const temp: sourceTypeInfo[] = SourceTypes.map(
      (sourceType) => ({
        sourceType: String(sourceType.sourceType),
        id: String(sourceType.id),
        valueUnit: sourceType.unit ?  sourceType.unit : "N/A",

      })
    )
    setResult(temp)

  }, [SourceTypes, SourceTypeStore])


  const statesArray: states[] = [
    {
      currentstate: SourcetypeState,
      statemethod: setSourcetypeState
    }
  ]
  const headers: Array<CoustomTableHeaderProps> = [

    { id: "SourceType", data: "SourceType" },
    { id: "ValueUnit", data: "Value Unit" },

  ];

  const settingState = buttonChoice === "SourceType"
    ? setSourcetypeState
    : buttonChoice === "id"
      ? setLoggerequipmentIdState
      : setLoggerserialNoState


  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // Reset the filter state when the modal is closed.
      setSourcetypeState("");
      setLoggerequipmentIdState("");
      setLoggerserialNoState("");
      document.body.style.removeProperty('overflow');
    }
  }, [isOpen]);
  if (isOpen == false) return null;




  return ReactDOM.createPortal(

    <div className="content" style={MODAL_STYLES as React.CSSProperties}>
      <div style={OVERLAY_STYLES as React.CSSProperties} />
      <div style={CONTENT_STYLES as React.CSSProperties}>
        <div className="pageParentDiv " style={{ borderRadius: '20px' }}>
          <InfoTableHeader buttonChoice={buttonChoice} title="Search Source Type" placeholder="Filter Source Type"
            statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))}
            setState={settingState}
            setButtonChoice={setButtonChoice}
          />

          <ThemeProvider theme={customTableTheme}>
            <CoustomTable page={page} setPage={setPage}  maxHeight={400} onSelectedIdsChange={onSelectedIdsChange} cancelButton={handleCancel} assignButton={() => setisOpen(false)} headers={headers} setRows={setrows} rows={rows} activeId={activeId} tableMarginRight="450" />
          </ThemeProvider>


        </div>
      </div>
    </div>,
    document.getElementById('portal') as HTMLElement



  )
})