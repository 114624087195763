
import React from 'react'

interface Prop {
    title: string;
    path?: string;
    handleClick?: () => void
    isSubmit?: boolean
}
export default function LoginButton({ isSubmit, title, path, handleClick }: Prop) {
    return (
        <button type={`${isSubmit ? "submit":"button"}`} className="cursor-pointer rounded-lg w-full h-[30px] bg-[#0081AB] flex items-center justify-center">
            <p className="text-[18px] text-white whitespace-nowrap ">
                {title}
            </p>
        </button>
    )
}
