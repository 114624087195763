import React from 'react'
import GeneralButton from '../Button/GeneralButton'
import { DropdownList } from '../Select/DropdownList'
import { CustomCalendar } from '../Input/CustomCalendar'
import { SymbolTextBox } from '../Input/SymbolTextbox'
import { IButton } from '../Button/Button'
import InventoryStatusButton, { InventoryStatusButtonProps } from '../Button/InventoryStatusButton'
import InventoryTypeHeader from './InventoryTypeHeader/InventoryTypeHeader'
import StatusSwitch from '../Button/StatusSwitch'
import { CoustomTableHeaderProps } from '../Table/CoustomTable'
import { states } from '../../pages/SourceType/SourceTypeRecord'
import { Link } from 'react-router-dom'
import { useStore } from '../../stores/store'
import { stat } from 'fs/promises'


interface Props {
  statusState:string

  buttonChoice:string
  typeState:string
  setState: (str: string) => void;
  setButtonChoice: (str: string) => void;
  options: Array<CoustomTableHeaderProps>;
  statesArray: states[];
  setStatusState: (x: string) => void;
  setTypeState: (x: string) => void
  state?:string
}



export default function InventoryManagementHeader({state, statusState, buttonChoice, typeState, setTypeState, setStatusState, setState, setButtonChoice, options, statesArray }: Props) {
  const excelButton = { title: 'Import Excel to Register', imgSrc: '/assets/button/excel.svg', Click: function () { } }
  const regButton = { title: 'Register New Inventory', imgSrc: '/assets/button/recAdd.png', Click: function () { } }

  const { inventoryStore } = useStore();
  const { filterPlaceholder, ChangePlaceHolder } = inventoryStore;

  const filterProps = {
    placeholder: filterPlaceholder,
    IconLeft: "/assets/button/filterProp/filterLeft.png",
    IconRight: "/assets/button/filterProp/filterRight.png",

  }

  return (
    <div className='flex flex-col gap-2 md:gap-4' >


      <div>
        <span className="text-[20px] text-bold">Inventory Management</span>
      </div>

      <div className=' flex flex-col xl:grid gap-1   ' style={{ gridTemplateColumns: '3fr 1fr' }}>

        <div style={{ maxWidth: "850px" }} className=''>
          <InventoryTypeHeader typeState={typeState} setTypeState={setTypeState} />
        </div>

        <div className='flex flex-col md:flex-row gap-1 '>
          <Link to={"/inventory/import"}>
            <GeneralButton title={excelButton.title} imgSrc={excelButton.imgSrc} />
          </Link>
          <Link to={"/inventory/Create"}>
            <GeneralButton title={regButton.title} imgSrc={regButton.imgSrc} />
          </Link>
        </div>
        
      </div>

      <div className='flex flex-col gap-2 md:flex-row justify-between md:items-center' >
        <div className="flex">
          <SymbolTextBox buttonChoice={buttonChoice} changePlaceholderValue={ChangePlaceHolder} options={options} statesArray={statesArray} onChange={(e) => setState(e.target.value)} placeholder={filterProps.placeholder} IconLeft={filterProps.IconLeft} IconRight={filterProps.IconRight} setButtonChoice={setButtonChoice} defaultValue={state} />
        </div>
        <div className="">
          <StatusSwitch setStatusState={setStatusState} statusState={statusState}/>
        </div>


      </div>




    </div>
  )
}
