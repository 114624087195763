export interface Inventory {
    id?: number;
    inventoryTypeId: number;
    equipmentCode?: string;
    sourceTypeId: string[];
    departmentId?: number;
    statusId: number;
    surType?: string;
    equipmentName: string;
    label?: string;
    manufacturerName: string;
    supplierName?: string;
    serialNo: string;
    modelNo: string;
    warrantyStartDate?: string;
    warrantyEndDate?: string;
    simCardNo?: string;
    equipment?: File;
    purchaseDate?: string;
    purchaseCost?: string;
    recipt?: File;
    withDrawlDate?: string;
    lastMaintenance?: string;
    nextMaintenance?: string;
    remark?: string;
    remarkDescription?: string;
    disposedDate: string;

    reciptFileUrl?: string;
    equipmentFileUrl?: string;
}

export interface InventoryDto {
    id?: number;
    inventoryTypeId: number;
    equipmentCode?: string;
    sourceTypeId: string[];
    departmentId?: number;
    statusId: number;
    surType?: string;
    equipmentName: string;
    label?: string;
    manufacturerName: string;
    supplierName?: string;
    serialNo: string;
    modelNo: string;
    warrantyStartDate?: string;
    warrantyEndDate?: string;
    simCardNo?: string;
    equipment?: string; // url
    purchaseDate?: string;
    purchaseCost?: string;
    recipt?: string; // url 
    withDrawlDate?: string;
    lastMaintenance?: string;
    nextMaintenance?: string;
    remark?: string;
    remarkDescription?: string;
    disposedDate: string;

    locationName?: string;
    inventoryTypeName: string // new field
}

export interface IInventoryError {
    purchaseDate: string;
    purchaseCost: string;
    equipmentCode: string;

    inventoryTypeId: string
    sourceTypeId: string
    equipmentName: string
    manufacturerName: string
    serialNo: string
    modelNo: string
    statusId: string
    withDrawlDate: string
    lastMaintenance: string
    nextMaintenance: string
}

export class InventoryErrorValue implements IInventoryError {
    purchaseDate: string = "";
    purchaseCost: string = "";
    equipmentCode: string = "";


    inventoryTypeId: string = "";
    sourceTypeId: string = "";
    equipmentName: string = "";
    manufacturerName: string = "";
    serialNo: string = "";
    modelNo: string = "";
    statusId: string = "";
    withDrawlDate: string = "";
    lastMaintenance: string = "";
    nextMaintenance: string = "";
}


export interface InventoryForm extends Partial<Inventory> {
}

//Logger Name,Equipment ID, SerialNo,  ModelNo, Manufactuer name, suplier name
export class LoggerFormInventoryValue implements InventoryForm {
    id?: number | undefined;
    equipmentName?: string | undefined = "";
    equipmentCode?: string | undefined = "";
    serialNo?: string | undefined = "";
    modelNo?: string | undefined = "";
    manufacturerName?: string | undefined = "";
    supplierName?: string | undefined = "";

    constructor(init?: InventoryForm) {
        Object.assign(this, init);
    }
}

export class InventoryFormValue implements InventoryForm {

    sourceTypeId: string[] = [];
    departmentId?: number;
    equipmentCode: string = "";
    statusId?: number;
    surType: string = "";
    equipmentName: string = "";
    label: string = "";
    manufacturerName: string = "";
    supplierName: string = "";
    serialNo: string = "";
    modelNo: string = "";
    warrantyStartDate?: string;
    warrantyEndDate?: string;
    simCardNo: string = "";
    equipmentFile?: File = undefined;
    purchaseDate?: string;
    purchaseCost: string = "";
    reciptFile?: File = undefined;
    disposedDate?: string;

    withDrawlDate?: string;
    lastMaintenance?: string;
    nextMaintenance?: string;
    remark: string = "";
    remarkDescription: string = "";

    constructor(init?: InventoryForm) {
        // if (init && init.date) {
        //   init.time = init.date;
        // }
        Object.assign(this, init);
    }
}

export interface InventoryType {
    id: number;
    name: string;
}

export interface InventoryCounts {
    all: number;
    logger: number;
    sensor: number;
    other: number;

}

export interface TableHistory {
    registeredBy: string;
    registeredOn: string;
    updateBy: string;
    updateOn: string;
}

