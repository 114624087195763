import React from 'react'

interface Prop {

}
export default function AlarmTable() {

  const Data = undefined
  if (Data === undefined)
    return <div className="h-[600px] flex items-center justify-center"><span className="text-[#CC311B]">No record</span></div>
  return (
    <div>

    </div>
  )
}
