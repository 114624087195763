import React, { useEffect, useState } from 'react'
import { CustomCalendar } from '../../components/Input/CustomCalendar'
import { SelectElement } from '../../components/FormElement/SelectElement'
import { InputElement } from '../../components/FormElement/InputElement'
import CheckboxInfo from '../../components/form/Project/NewAlarmControlTable/InfoTable/Checkbox/CheckboxInfo'
import { DataFormVal, IDataForm, DataOptions, DataError, DataRow } from '../../models/Pharse2/Data'
import { useStore } from '../../stores/store'
import GeneralButton from '../../components/Button/GeneralButton'
import { setOptions } from 'react-chartjs-2/dist/utils'
import { observer } from 'mobx-react-lite'
import { Dict, SelectListOption } from '../../models/Pharse2/Graph'
import MultiLevelSelectElement, { OptionContainer } from '../../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement'


interface Prop {
    record: IDataForm
    setRecord: (arr: IDataForm) => void
}
const DataFormValidation = (form: IDataForm) => {
    const error: DataError = new DataError()

    if (form.projectId === undefined || form.projectId === -1) {
        error.projectId = true
    }
    if (form.locationId === undefined || form.locationId === -1) {
        error.locationId = true
    }
    if (form.sourceTypeId === undefined || form.sourceTypeId === -1) {
        error.sourceTypeId = true
    }

    return error;


}
export default observer(function DataForm({ record, setRecord }: Prop) {

    const [currentScript, setCurrentScript] = useState<Dict>()
    const [original_Datas, setOriginal_Datas] = useState<DataRow[]>()


    const [locationTableOpen, setLocationTable] = useState(false);

    const [projectOptions, setProjectOptions] = useState<SelectListOption[]>([])
    const [locationOptions, setLocationOptions] = useState<SelectListOption[]>([])
    const [sourceTypeOptions, setSourceTypeOptions] = useState<OptionContainer[]>([])

    const { graphStore, dataStore } = useStore();
    const [errors, setErrors] = useState<DataError>(new DataError());

    const handleMultiOnChange = (unit:string, parentValue: number, subValue: boolean, script:any) => {
        setRecord({ ...record, sourceTypeId: parentValue, isScripted:subValue  })
        setCurrentScript(script)
    }

    useEffect(() => { //load projectListFirst
        graphStore.getprojectList().then(i => {
            console.log(i)
            if (i) setProjectOptions(i)
        })
    }, [])

    useEffect(() => { //load projectListFirst
        graphStore.getlocationList(record.projectId).then(i => {
            if (i) {
                setLocationOptions(i)
                setSourceTypeOptions([]);
            }
            setRecord({ ...record, locationId: -1, sourceTypeId: -1 })
        })

    }, [record.projectId])

    useEffect(() => {
        if (record.locationId > 0) {
            dataStore.getsourceTypeList(record.locationId).then(
                (i) => {
                    setRecord({ ...record, sourceTypeId: -1 })
                    if (i) setSourceTypeOptions(i)
                }
            )
        }
    }, [record.locationId])

    const setLocationIds = (Id: number) => {
        setRecord({ ...record, locationId: Id })
    }

    const handleSearch = () => {
        var newError = DataFormValidation(record);
        setErrors(newError);
        if (Object.values(newError).some((item) => item === true)) {
            return;
        }
        if (original_Datas!=undefined&& dataStore.Data != undefined && dataStore.Data.fromDate == record.fromDate
            && dataStore.Data.toDate == record.toDate && dataStore.Data.locationId == record.locationId
            && dataStore.Data.sourceTypeId == record.sourceTypeId) 
        {
            console.log("Enter Handle Script ")

            dataStore.clientSetData({...dataStore.Data, datas:dataStore.postProcessingScript(record.locationId,original_Datas ,currentScript)})
            return
        }
        dataStore.getData(record,setOriginal_Datas,currentScript);
    }
    return (

        <div className="border-b-[4px] border-gray-400">
            <form className="w-full flex flex-col gap-3   h-full mt-2" >

                <div className="w-full flex flex-row gap-3 items-center flex-wrap">
                    <div style={{ width: 'auto' }} className=" grow">
                        <CustomCalendar name={"fromDate"} setRecord={setRecord} record={record} title="From Date" />
                    </div>
                    <div style={{ width: 'auto' }} className=" grow">
                        <CustomCalendar name={"toDate"} setRecord={setRecord} record={record} title="To Date" />
                    </div>
                </div>

                <div className="  flex lg:flex-row flex-col gap-3 justify-between items-center flex-wrap pb-2">

                    <div className="w-full lg:w-auto grow">
                        <SelectElement defValue={record.projectId} isWithApi={true} isRequired={errors.projectId} options={projectOptions} title={"Project"} name={"projectId"} setRecord={setRecord} record={record} />
                    </div>
                    <div className="w-full lg:w-auto grow">
                        <SelectElement defValue={record.locationId} isWithApi={true} isRequired={errors.locationId} options={locationOptions} title={"Location"} name={"locationId"} setRecord={setRecord} record={record} onlyRead={record.projectId <= 0} />

                    </div>
                    <div className="w-full lg:w-auto grow">
                        <MultiLevelSelectElement defIsScripted={record.isScripted} onChange={handleMultiOnChange} readOnly={record.locationId <= 0} defValue={record.sourceTypeId} title={"Source Type"} options={sourceTypeOptions} isRequired={errors.sourceTypeId} />

                        {/* <SelectElement defValue={record.sourceTypeId} isWithApi={true} isRequired={errors.sourceTypeId} options={sourceTypeOptions} title={"Source Type"} name={"sourceTypeId"} setRecord={setRecord} record={record} onlyRead={record.locationId <= 0} /> */}
                    </div>
                    <div  className='w-full lg:w-auto grow-0'><GeneralButton title={"Search"} handleClick={handleSearch} /></div>
                </div>
            </form>


        </div>

    )
})
