export interface ProjectDistinct{
    id:number,
    name:string,
    locationAlert?:boolean
}

export interface LocationMarker{
    locationId:number,
    projectId:number;
    loggersetName:string
    name:string
    latitude:number,
    longtitude:number,
    batteryValue:number,
    url:string,
    locationAlert: number
}

export interface LoggersetDetailsDto{
    loggersetId:number;
    locationName:string;
    loggersetName:string
    status:string;
    remark?:string;
    remarkDescription?:string;
    loggerList:string[]
    sensorList:string[]
    equipmentList:string[]
}

export function isLoggersetDetailsDto(object: any): object is LoggersetDetailsDto {
    const hasBasicProps = 
    //   typeof object.loggersetId === 'number' &&
    //   typeof object.locationCode === 'string' &&
    //   typeof object.loggersetName === 'string' &&
    //   typeof object.status === 'string' &&
      Array.isArray(object.loggerList) &&
      Array.isArray(object.sensorList) &&
      Array.isArray(object.equipmentList);
  
    // const hasOptionalProps = 
    //   (typeof object.remark === 'undefined' || typeof object.remark === 'string') &&
    //   (typeof object.remarkDescription === 'undefined' || typeof object.remarkDescription === 'string');
  
      console.log(hasBasicProps)
    return  hasBasicProps;
  }