import React, { useEffect, useState } from 'react'
import InventoryTypeHeaderItems from './InventoryTypeHeaderItems';
import { useStore } from '../../../stores/store';
import { InventoryCounts } from '../../../models/Inventory';
import { observer } from 'mobx-react-lite';
interface Prop {
  setTypeState: (x: string) => void
  typeState: string | undefined
}

export default observer(function InventoryTypeHeader({ typeState, setTypeState }: Prop) {
  const { inventoryStore } = useStore();
  const { getCount, InventoryCount } = inventoryStore;
  const InventoryTypeHeaderAllItems = [
    { setName: "all", imgSrc: "/assets/InventoryTypeHeader/all.png", clickedImgSrc: "/assets/InventoryTypeHeader/orange/all.png", isSelected: false, title: "All Inventory", number: InventoryCount.all },
    { setName: "logger", imgSrc: "/assets/InventoryTypeHeader/loggers.png", clickedImgSrc: "/assets/InventoryTypeHeader/orange/loggers.png", isSelected: false, title: "Loggers", number: InventoryCount.logger },
    { setName: "sensor", imgSrc: "/assets/InventoryTypeHeader/sensors.png", clickedImgSrc: "/assets/InventoryTypeHeader/orange/sensors.png", isSelected: false, title: "Sensors", number: InventoryCount.sensor },
    { setName: "other", imgSrc: "/assets/InventoryTypeHeader/others.png", clickedImgSrc: "/assets/InventoryTypeHeader/orange/others.png", isSelected: false, title: "Others", number: InventoryCount.other },

  ]
   const initClickedId = (typeState:string|undefined)=>{
    if(typeState == undefined){
      return 0;
    }
    let i = 0;
    for(var item of InventoryTypeHeaderAllItems){

      if(typeState == item.setName){
        return i
      }
      i++
    }
  
    return 0 
  }
 // console.log(typeState, initClickedId(typeState))
  const [clickedId, setClickedId] = useState<number>(initClickedId(typeState)) // default: all Inventory
  const handleClick = (index: number) => {
    setClickedId(index);
  }



  useEffect(() => {
    getCount();

  }, [])

 
  return (
    <div className='relative w-full ' >
      <div className='flex  justify-between ' >
        {InventoryTypeHeaderAllItems.map((items, index) => (
          <InventoryTypeHeaderItems key={index} imgSrc={items.imgSrc}
            clickedImgSrc={items.clickedImgSrc} handleClick={() => { handleClick(index); setTypeState(items.setName) }}
            isSelected={clickedId === index} title={items.title} number={items.number} id={index} />
        ))}
      </div>
      <div className="w-full h-5px absolute  bottom-0 rounded-full" style={{ backgroundColor: "rgb(172, 172, 172)" }}></div>
    </div>
  )
})
