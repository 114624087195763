import { CoustomTableRowProps } from "../components/Table/CoustomTable"

interface Prop {
    rows: CoustomTableRowProps[]
    setrows: (arr: CoustomTableRowProps[]) => void
    direction: React.MutableRefObject<"asc" | "desc">
    activeId: React.MutableRefObject<string | null>
}

export const sortingAlgor = (p: Prop) => {
    const compareRow = (a: React.ReactNode, b: React.ReactNode) => {
         a = typeof a === 'string' ? a.toLowerCase() : a;
         b = typeof b === 'string' ? b.toLowerCase() : b;

        if (a === undefined) {
            return p.direction.current === 'asc' ? -1 : 1;
        }
        if (b === undefined) {
            return p.direction.current === 'asc' ? 1 : -1;
        }
        if (a!= undefined && b!=undefined && a>b) {
            return p.direction.current === 'asc' ? 1 : -1;
        }
        else if((a!= undefined && b!=undefined && a<b)){
            return p.direction.current === 'asc' ? -1 : 1;
        }
        return 0;
    }

    const rowsCopy = [...p.rows];
   const sortedRow = rowsCopy.sort((a, b) => {
        const aValue = a.data.find(x=>x.id == p.activeId.current)?.data
        const bValue = b.data.find(x=>x.id == p.activeId.current)?.data

        return compareRow(aValue,bValue )
       
    }
   )
    p.setrows(sortedRow);
    
}