import React, { useEffect, useRef, useState } from 'react'
import AccountRecordHeader from '../../components/AccountRecord/AccountRecordHeader'
import { ThemeProvider } from '@emotion/react'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import LoadingComponent from '../../components/LoadingComponent';
import { useStore } from '../../stores/store';
import { states } from '../SourceType/SourceTypeRecord';
import { observer } from 'mobx-react-lite';
import { sortingAlgor } from '../../hook/useSorting';

export interface AccountType {
    id: string,
    name: string,
    phoneNumber: string,
    email: string,
    companyName: string
    role: string
}

export default observer(function AccountRecord() {
    const params = new URLSearchParams(window.location.search);

    const page_FromUrl = params.get('page');
    const nameState_FromUrl = params.get('nameState');
    const companynameState_FromUrl = params.get('companynameState');
    const phoneNoState_FromUrl = params.get('phoneNoState');
    const emailState_FromUrl = params.get('emailState');
    const roleState_FromUrl = params.get('roleState');
    const buttonChoice_FromUrl = params.get('buttonChoice');



    const [page, setPage] = useState(Number(page_FromUrl) ?? 0);

    const [nameState, setNameState] = useState(nameState_FromUrl ?? "");
    const [companynameState, setCompanynameState] = useState(companynameState_FromUrl ?? "");
    const [phoneNoState, setPhoneNoState] = useState<string>(phoneNoState_FromUrl ?? "");
    const [emailState, setEmailState] = useState<string>(emailState_FromUrl ?? "");
    const [roleState, setRoleState] = useState<string>(roleState_FromUrl ?? "");

    const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "name");

    const final_setButtonChoice = (state: string) => { //cant use useEffect
        setButtonChoice(state)
        setPage(0)
    }

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('page', String(page));
        params.set('buttonChoice', buttonChoice);

        if (buttonChoice === 'name') {
            params.set('nameState', nameState);
        } else if (buttonChoice === 'companyName') {
            params.set('companynameState', companynameState);
        } else if (buttonChoice === 'phoneNumber') {
            params.set('phoneNoState', phoneNoState);
        } else if (buttonChoice === 'email') {
            params.set('emailState', emailState);
        } else if (buttonChoice === 'role') {
            params.set('roleState', roleState);
        }
        window.history.replaceState({}, '', `?${params.toString()}`);
    }, [page, buttonChoice, nameState, companynameState, phoneNoState, emailState, roleState]);


    const [excelRecord, setexcelRecord] = useState([]);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const [Results, setResult] = useState<AccountType[]>([])


    const { accountStore } = useStore();
    const { loadingInitial, LoadingAccountList, Accounts } = accountStore;

    useEffect(() => {
        LoadingAccountList()
    }, [])
    const statesArray: states[] = [
        {
            currentstate: nameState,
            statemethod: setNameState
        },
        {
            currentstate: companynameState,
            statemethod: setCompanynameState
        },
        {
            currentstate: phoneNoState,
            statemethod: setPhoneNoState
        },
        {
            currentstate: emailState,
            statemethod: setEmailState
        }, {
            currentstate: roleState,
            statemethod: setRoleState
        }
    ]


    useEffect(() => {
        console.log(buttonChoice, nameState, companynameState, phoneNoState, emailState)

        const updatedRows = Results.filter((item) => {
            return nameState.toLowerCase() === "" ? item
                : item.name.toLowerCase().includes(nameState.toLowerCase());
        }).filter((item2) => {
            return companynameState.toLowerCase() === "" ? item2
                : item2.companyName.toLowerCase().includes(companynameState.toLowerCase());
        }).filter((item3) => {
            return phoneNoState.toLowerCase() === "" ? item3
                : item3.phoneNumber.toLowerCase().includes(phoneNoState.toLowerCase());
        }).filter((item) => {
            return emailState.toLowerCase() === "" ? item
                : item.email.toLowerCase().includes(emailState.toLowerCase());
        }).filter((item) => {
            return roleState.toLowerCase() === "" ? item
                : item.role.toLowerCase().includes(roleState.toLowerCase());
        }).map((record: AccountType) => ({
            data: [
                { id: "name", data: record.name },
                { id: "companyName", data: record.companyName },
                { id: "phoneNumber", data: record.phoneNumber },
                { id: "email", data: record.email },
                { id: "role", data: record.role },

            ],
            hiddenData: [],
            Id: record.id,
            ischecked: false
        }));

        setrows(updatedRows);
    }, [Results, nameState, companynameState, phoneNoState, emailState, roleState]);

    useEffect(() => {

        const temp: AccountType[] = Accounts.map(
            (s) => ({
                id: s.id,
                name: s.name ? s.name : "N/A",
                companyName: s.companyName ? s.companyName : "N/A",
                phoneNumber: s.phoneNumber ? s.phoneNumber : "N/A",
                email: s.email ? s.email : "N/A",
                role: s.role ? s.role : "N/A"
            })
        )
        setResult(temp)

    }, [Accounts, accountStore])



    const headers: Array<CoustomTableHeaderProps> = [
        { id: "name", data: "Name" },
        { id: "companyName", data: "Company Name" },
        { id: "phoneNumber", data: "Tel No." },
        { id: "email", data: "Email Address" },
        { id: "role", data: "Role" },

    ];

    const stateSelection = buttonChoice === "name"
        ? setNameState
        : buttonChoice === "companyName"
            ? setCompanynameState
            : buttonChoice === "phoneNumber"
                ? setPhoneNoState
                : buttonChoice === "role"
                    ? setRoleState
                    : setEmailState


    const state = buttonChoice === "name"
        ? nameState
        : buttonChoice === "companyName"
            ? companynameState
            : buttonChoice === "phoneNumber"
                ? phoneNoState
                : buttonChoice === "role"
                    ? roleState
                    : emailState

    //sorting

    const activeId = useRef<string | null>(null);
    const direction = useRef<'asc' | 'desc'>('asc');

    const onSortClick = () => {
        setPage(0);
        sortingAlgor({ rows, setrows, direction, activeId });

    };

    //////////////////////
    if (loadingInitial)
        return <LoadingComponent content="Loading activity..." />;

    return (
        <div className=" pageParentDiv ">
            <AccountRecordHeader buttonChoice={buttonChoice} state={state} title="Search Account Record" statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "name" || item.id == "companyName" || item.id == "phoneNumber" || item.id == "email" || item.id == "role")} />
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable onSortClick={onSortClick} direction={direction} page={page} setPage={setPage} activeId={activeId} excelRecord={excelRecord} setexcelRecord={setexcelRecord} headers={headers} rows={rows} setRows={setrows} subPath='account' />
            </ThemeProvider>
        </div>
    )
})
