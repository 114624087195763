import React from 'react'
import { Message } from 'semantic-ui-react'

interface Prop {

    messageHeader?: string
    messageList?: string[]

}
export default function ServerErrorMessage({ messageHeader, messageList }: Prop) {


    if (messageHeader === undefined || messageList === undefined)
        return null
    
    return (
        <div>

            {messageList !== undefined &&
                <Message error>
                    <Message.Header>{messageHeader} </Message.Header>
                    {messageList.map((msg, i) => (
                        <p key={i}>{msg}</p>
                    ))}
                </Message>}
        </div>

    )
}
