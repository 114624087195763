import React from 'react'
import { useStore } from '../../stores/store';


export default function UserIcon() {
    const {userStore} = useStore();
    const {user} = userStore
    return (
        <div className=" cursor-pointer rounded-full h-full w-full flex items-center justify-center  border-1 border-black">
           <span className='text-base'> {user!=undefined?`${user?.username.slice(0, 2).toUpperCase()}`:''}</span>
        </div>
    )
}
