import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/Api/agent";
import { LoggerSetFormValue } from "../models/LoggerSet";
import { InventoryDto } from "../models/Inventory";

export default class LoggerSetStore {

    loggerSets: LoggerSetFormValue[] = [];
    activeLoggerSets: LoggerSetFormValue[] = [];
    selectedLoggerSet: LoggerSetFormValue | undefined = undefined;

    loadingInitial = false;
    submitting = false;

    filterPlaceholder = "Filter Equipment Name";

    constructor() {
        makeAutoObservable(this);
    }

    ChangePlaceHolder = (p: string) => {
        this.filterPlaceholder = p;
    }



    LoadingLoggersets = async () => {
        try {
            this.loadingInitial = true

            const loggerSets = await agent.Loggersets.list();
            this.loadingInitial = false;
            // runInAction(() => {
            //     this.loggerSets = loggerSets

            // })

            return loggerSets;


        }
        catch (error) {
            this.loadingInitial = false;
            throw error;
        }




    }

    LoadSelectedLoggerset = async (id: string) => {
        //const Id: number= Number(id);

        this.loadingInitial = true

        try {
            const newLoggerset = await agent.Loggersets.details(id);
            runInAction(() => this.selectedLoggerSet = newLoggerset)
            this.loadingInitial = false;
            return newLoggerset;
        } catch (error) {
            this.loadingInitial = false;
            throw error;
        }

    }

    CreateLoggerset = async (record: LoggerSetFormValue) => {
        this.submitting = true;
        try {
            await agent.Loggersets.create(record);
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            throw error;
        }

    }

    clearSelectedLoggerset = () => {
        this.selectedLoggerSet = undefined;
    }

    getLoggersetHistory = async (id?: string) => {
        try {
            if (id == null) return;
            return await agent.Loggersets.getHistory(id);
        } catch (error) {
            throw error;
        }
    }

    editLoggerSet = async (record: LoggerSetFormValue) => {
        this.submitting = true;

        try {
            await agent.Loggersets.update(record);

        } catch (error) {
            throw error;
        }
        this.submitting = false;

    }

    getInfoTableInventories = async (selectedIds: string[]) => {
        try {

            var jsonIds = JSON.stringify(selectedIds)
            var temp = await agent.Loggersets.getInfoInventories(jsonIds)
            const InventoryList: InventoryDto[] = temp ? temp : [];

            return InventoryList;
        }
        catch (error) {
            throw error
        }

    }

    getLoggerSetByInventoryId = async (inventoryId: string) => {
        try {
            const loggerset = await agent.Loggersets.getExistLoggerset(inventoryId);
            return loggerset;
        }
        catch (error) {
            throw error
        }
    }

    LoadLoggerLocation = async (loggersetId: string) => {
        try {
            const location = await agent.Loggersets.getLoggersetLocation(loggersetId);
            return location;
        }
        catch (error) {
            throw error;
        }
    }

    getLoggerSetListByStatus = async (status: number) => {
        this.loadingInitial = true
        try {
            var temp = await agent.Loggersets.list();
            const loggerSets = (temp ? temp : []).filter(x => x.statusId == status);
            this.loadingInitial = false;
            // runInAction(()=>{
            //     this.activeLoggerSets=loggerSets;
            // })
            return loggerSets;


        }
        catch (error) {
            this.loadingInitial = false;
            throw error;
        }


    }

}