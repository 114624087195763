export interface AlarmLevel {
  level: number;
  conditionId: string;
  value: number;
  alarmId?: string;
  // graphTypeId?:string
  displayUnit?: string
  msgToolId?: string[]
  usersId?: string[]
}

export interface Alarm {
  alarmId?: string;
  projectId?: string;
  sourceTypeId: number;
  alarmLevels: AlarmLevel[];
  inventoryId?: string;
}

export interface IAlarmError {

  alarmLevels?: IAlarmLevelError[];
}

export interface IAlarmLevelError {

  conditionId?: string;
  value?: string;
}

export interface AlarmLevelsMsgToolId {
  alarmIndex: number;
  level: number;
  msgToolId?: string[];
}

export interface AlarmLevelsUsersId {
  alarmIndex: number;
  level: number;
  usersId?: string[];
}

export interface CustomInventoryAlarm {
  inventoryId: string;
  alarm: Alarm[];
  /*

  public List<AlarmLevelsMsgToolId> AlarmLevelsMsgToolId { get; set; }

  public List<AlarmLevelsUsersId> AlarmLevelsUsersId { get; set; }

   */
}

export interface UpdatedCustomInventory extends CustomInventoryAlarm {
  alarmLevelsMsgToolId: AlarmLevelsMsgToolId[];
  alarmLevelsUsersId: AlarmLevelsUsersId[]
}

/**
 *  public class AlarmLevelsMsgToolId
    {
        [Required]
        public int AlarmIndex { get; set; }
        [Required]
        public int Level { get; set; }
        public List<string>? MsgToolId { get; set; }
    }

    public class AlarmLevelsUsersId
    {
        [Required]
        public int AlarmIndex { get; set; }
        [Required]
        public int Level { get; set; }
        public List<string>? UsersId { get; set; }
    }
 */

export interface LocationFormAlarmVal extends Partial<CustomInventoryAlarm> { }

export class CustomInventoryAlarmValue implements LocationFormAlarmVal {
  inventoryId: string | undefined;
  alarm: Alarm[] = [];


  constructor(init?: LocationFormAlarmVal) {
    Object.assign(this, init);
  }
}

