import * as React from 'react';
import { styled } from '@mui/system';
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export const CustomTablePagination = styled(TablePagination)(
    ({ theme, tableBlur }) => `
    
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
        display: flex;
        width: 100%;
        align-items: center;
        
        gap: 10px;
       
      }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
   
  
    & .${classes.displayedRows} {
      
      color: #888;
      font-style: normal;
      font-weight: 400;   
      white-space: nowrap;
      text-transform: uppercase;

      margin: 0;
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 0;
      
      display: flex;
      justify-content: space-between;
      
    }
  
    & .${classes.actions} > button {
      filter: ${ tableBlur ? 'blur(2px)' :'none'};
      cursor: pointer;
      border-radius: 5px;
      font-size: 0;
      width: 34px;
      height: 34px;
      background-image: url('/assets/table/pagination/btnRight.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 100;
      
    }

    & .${classes.actions} > button:last-child {
        background-image: url('/assets/table/pagination/btnLeft.svg');
        margin-left: 20px
        
      }

    & .${classes.actions} > button > span{
        display: none;
    }
    `,
);

// margin: 0 8px;
//       border: 2px solid orange;
//       border-radius: 2px;
//       background-color: transparent;
        
//       
  
//       &:focus {
//         outline: 1px solid blue;
//       }