import { ThemeProvider } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../stores/store";
import { ProjectDto } from "../../../../../models/Project";
import { states } from "../../../../../pages/SourceType/SourceTypeRecord";
import ReactDOM from "react-dom";
import { InfoTableHeader } from "../../../../InfoFIleHeader/InfoTableHeader";
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../../../Table/CoustomTable";




interface ProjectInfo {
    onSelectedIdsChange?: (arr: string[]) => void;
    id: string;
    name: string;
    companyName: string;
    projectPeriod?: string;
}


interface props {
    handlePresave: (newIds: string[]) => void
    id?: string;
    onSelectedIdsChange: (arr: string[]) => void;
    selectedIds: string[]
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
    ProjectRecord: ProjectDto[]
}

const MODAL_STYLES = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,


}


const OVERLAY_STYLES = {
    width: '100vw',
    height: '100vh',
    backgroundColor: ' rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
    zIndex: 1000,
};


const CONTENT_STYLES = {


    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    zIndex: 1001,
    padding: '32px',
    boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    maxHeight: '100vh',
    overflowY: 'auto',
};




export const AssignProjectInfoTable = observer(({ ProjectRecord, handlePresave, id, selectedIds, onSelectedIdsChange, isOpen, setisOpen }: props) => {
    const [page, setPage] = useState(0);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const [projNameState, setProjNameState] = useState("");
    const [compNameState, setCompNameState] = useState("");
    const [buttonChoice, setButtonChoice] = useState("name");


    const activeId = useRef<string | null>(null);

    const [results, setResult] = useState<ProjectInfo[]>([])

    const { projectStore } = useStore()
    const { getProjectHistory, LoadSrcTypeId } = projectStore;




    const handleCancel = () => {
        const updatedRows = results
            .filter((item) => (
                projNameState.toLowerCase() === "" ||
                item.name.toLowerCase().includes(projNameState.toLowerCase())
            )).filter((item3) => (
                compNameState.toLowerCase() === "" ||
                item3.companyName.toLowerCase().includes(compNameState.toLowerCase())
            ))
            .map((record: ProjectInfo) => ({
                data: [
                    { id: "name", data: record.name },
                    { id: "companyName", data: record.companyName },
                    { id: "projectPeriod", data: record.projectPeriod },
                ],
                Id: record.id,
                ischecked: selectedIds.includes(record.id)
            }));


        setrows(updatedRows);
        setisOpen(false);
    }



    const handleAssign = (newIds: string[]) => {
        handlePresave(newIds)
        setisOpen(false);
    }





    useEffect(() => {
        // console.log(sourceTypeIds)
        const updatedRows = results
            .filter((item) => (
                projNameState.toLowerCase() === "" ||
                item.name.toLowerCase().includes(projNameState.toLowerCase())
            ))
            .filter((item3) => (
                compNameState.toLowerCase() === "" ||
                item3.companyName.toLowerCase().includes(compNameState.toLowerCase())
            ))
            .map((record: ProjectInfo) => ({
                data: [
                    { id: "name", data: record.name },
                    { id: "companyName", data: record.companyName },
                    { id: "projectPeriod", data: record.projectPeriod },
                ],
                Id: record.id,
                ischecked: selectedIds.includes(record.id)
            }));


        setrows(updatedRows);
    }, [results, projNameState, compNameState, selectedIds]);

    useEffect(() => {
        const temp: ProjectInfo[] = ProjectRecord.map(
            (s) => ({
                id: String(s.projectId),
                name: s.name,
                companyName: s.companyName,
                projectPeriod: s.projectPeriod,
            })
        )
        setResult(temp)
    }, [ProjectRecord])


    const statesArray: states[] = [
        {
            currentstate: projNameState,
            statemethod: setProjNameState
        },
       
        {
            currentstate: compNameState,
            statemethod: setCompNameState
        },
    ]



    const headers: Array<CoustomTableHeaderProps> = [
        { id: "name", data: "Project Name" },
        { id: "companyName", data: "Company Name" },
        { id: "projectPeriod", data: "Project Period" },

    ];


    const stateSelection = buttonChoice === "name"
    ? setProjNameState
      : setCompNameState

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            // Reset the filter state when the modal is closed.
            setProjNameState("");
            setCompNameState("");
            document.body.style.removeProperty('overflow');
        }
    }, [isOpen]);
    if (isOpen == false) return null;








    return ReactDOM.createPortal(


        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} />
            <div style={CONTENT_STYLES as React.CSSProperties}>
                <div className="pageParentDiv " style={{ borderRadius: '20px' }}>

                    <InfoTableHeader buttonChoice={buttonChoice} title="Search Project" placeholder="Filter Project Name"
                        statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))}
                        setState={stateSelection}
                        setButtonChoice={setButtonChoice}
                        options={headers.filter((item) => item.id == "name" || item.id == "projectCode" || item.id == "companyName")}
                    />

                    <ThemeProvider theme={customTableTheme}>
                        <CoustomTable page={page} setPage={setPage} maxHeight={400} onSelectedIdsChange={onSelectedIdsChange} tableMarginRight="200" cancelButton={handleCancel} assignButton={handleAssign} headers={headers} setRows={setrows} rows={rows} activeId={activeId} />
                    </ThemeProvider>

                </div>
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement






    )
})
