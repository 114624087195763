import React, { FormEvent, useEffect, useState } from 'react'
import { AccountEditForm } from '../../../../models/Account'
import { InputElement } from '../../../FormElement/InputElement'
import { IButton } from '../../../Button/Button'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GeneralButton from '../../../Button/GeneralButton'
import { useStore } from '../../../../stores/store'
import { observer } from 'mobx-react-lite'
import { AssignProjectInfoTable } from './AssignProjectTable/AssignProjectInfoTable'
import { ProjectDto } from '../../../../models/Project'
import AssignedProjectTable from './ProjectTable/AssignedProjectTable'

interface Prop {
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void

    setRecord: (arr: AccountEditForm) => void
    record: AccountEditForm
}
export default observer(function AccountEditProject(p: Prop) {
    const navigate = useNavigate()
    const { id } = useParams();

    const { summaryStore, accountStore } = useStore();
    const { companys } = summaryStore;
    const companyName = companys != undefined ? companys.find(c => c.id === Number(p.record.companyId))?.name : " "
    const [isOpen, setisOpen] = useState<boolean>(false);
    const [ProjectRecord, setProject] = useState<ProjectDto[]>([]);

    const [selectedIds, setSelectedIds] = useState<string[]>(p.record.projectIds)

    useEffect(() => {
        if (id) {
            accountStore.LoadUserProjectDto(id).then(
                (proj) => {
                    console.log(proj)
                    if(proj!=undefined)setProject(proj)
                }
            )
        }

    }, [id])

    const handlePresave = (newIds: string[]) => {

        p.setRecord({ ...p.record, projectIds: newIds })
    }

    const onshow = () => {
        console.log(p.record)
        console.log(selectedIds)
    }
    return (
        <div>
            <form className="flex flex-col gap-4 mt-4" onSubmit={p.handleSubmit} autoComplete="off">

                <AssignProjectInfoTable onSelectedIdsChange={setSelectedIds} handlePresave={(ids: string[]) => handlePresave(ids)} isOpen={isOpen} setisOpen={setisOpen} ProjectRecord={ProjectRecord} selectedIds={selectedIds} />
                <div className="flex flex-col gap-4 mb-5">
                    <span className="text-[20px] font-medium text-decoration-line: underline">
                        Account Details
                    </span>
                    <div className="flex flex-col gap-3" onClick={onshow}>
                        <InputElement readonly={true} title={"Name"} name={"companyName"} isRequired={false} setRecord={p.setRecord} record={p.record} value={p.record.firstName + " " + p.record.lastName} />
                        <InputElement readonly={true} title={"Company Name"} name={"companyName"} isRequired={false} setRecord={p.setRecord} record={p.record} value={companyName} />
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <span className="text-[20px] font-medium text-decoration-line: underline ">
                        Assign Project
                    </span>
                    <AssignedProjectTable selectedIds={selectedIds} setisOpen={setisOpen} />
                </div>


                <div className="flex justify-center gap-5 mt-5 mb-4 flex-col">
                    <IButton type="submit" text={"Edit"} />
                        <IButton onClick={()=>{navigate(-1)}} type="button" text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" />

                </div>
            </form>
        </div >
    )
})
