import React, { ChangeEvent } from 'react'
import { ProjectForm } from '../../../../models/Project';

const errorMsg = {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '-2px'
}

interface Props{
    alarmIndex: number
    level:number
    title: string;
    name: string;
    isRequired: boolean;
    setRecord:(arr: {}) => void;
    record: ProjectForm;
    error?: string;
    value?: string | number | readonly string[] | undefined
    handleClick?: () => void
    width?: string;
    disabled?: boolean
}

export default function AlarmInput({disabled, alarmIndex,  level, width, handleClick, value, name, title, isRequired, setRecord, record, error}:Props ){

    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        //console.log("changed input")
        setRecord((prev: ProjectForm) => ({
          ...prev,
          alarm: prev.alarm?.map((alarmItem, idx) => 
            idx === alarmIndex
              ? {
                ...alarmItem,
                alarmLevels: alarmItem.alarmLevels.map((levelItem, levelIdx) => 
                  levelIdx === level - 1 
                    ? { ...levelItem, [name]: value } 
                    : levelItem
                )
              } 
              : alarmItem
          )
        }));
    };

    return (
        <div className="flex flex-col">
            <div className="mb-2">
                <label>
                    <p className={`whitespace-nowrap ${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>

                </label>
            </div>
            <div>
                <input disabled={disabled}  className={ ` ${width? "w-" + width:"w-full"}  h-[30px] border-2 rounded-lg border-[#ACACAC]`} value={value} name={name} onChange={handleChange} />
                {error && <p style={errorMsg}>{error}</p>}
            </div>


        </div>
    )
}
