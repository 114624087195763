import React from 'react'
import SourceTypeStore from '../../../stores/SourceTypeStore'
import { useStore } from '../../../stores/store';
interface Prop{
    id:number
}
export default function TextAreaItems({id}:Prop) {
    const {sourceTypeStore} = useStore();
    const {getSourceNameWithId, getUnitWithId} = sourceTypeStore;
    const Title = getSourceNameWithId(id) +" (" + getUnitWithId(id) +")"
  return (
    <div className="flex items-center gap-2">
        <img src="/assets/table/thickTicked.png"/>
        <span>
            {Title}
        </span>
    </div>
  )
}
