
import { makeAutoObservable, runInAction } from 'mobx';
import { ResetPasswordModel, User, UserDto, UserFormValue } from '../models/User';
import agent from '../services/Api/agent';
import { store } from './store';
import { router } from '../router/Routes';

export default class UserStore {
    user: User | null = null;
    usersDto: UserDto[] = []
    loadingInitial = false;

    /**
     *
     */
    constructor() {
        makeAutoObservable(this);
    }

    login = async (creds: UserFormValue) => {

        try {
            //console.log("login success")
            this.loadingInitial = true
            const user = await agent.Account.login(creds)
            if(user!=undefined)store.commonStore.setToken(user.token);
            if(user!=undefined)this.user = user;
            this.loadingInitial = false

            router.navigate('/dashboard')
        } catch (error) {
            console.log(error)
            this.loadingInitial = false
            throw error
        }

    }

    logout = async () => {
        console.log("logout success")
        store.commonStore.setToken(null);
        // localStorage.removeItem('jwt');
        this.user = null;

    }


    register = async (regObj: UserFormValue) => {
        try {
            const user = await agent.Account.register(regObj)
            console.log(user)

            if(user!=undefined)store.commonStore.setToken(user.token);
            if(user!=undefined)this.user = user;
            router.navigate('/dashboard')
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    forget = async (forgetObj: UserFormValue) => {
        try {
            this.loadingInitial = true;
            await agent.Account.forget(forgetObj)
            console.log('forget success')
            this.loadingInitial = false;
            return;
        } catch (error) {
            throw error;
        }
    }
    reset = async(resetObj: ResetPasswordModel) =>{
        try{
            this.loadingInitial = true
            var user = await agent.Account.reset(resetObj)
            if(user!=undefined)store.commonStore.setToken(user.token);
            if(user!=undefined)this.user = user;
            this.loadingInitial = false
            router.navigate('/dashboard')
        }
        catch(error){
            this.loadingInitial = false

            throw error
        }
    }
    getUser = async () => {
        try {
            const user = await agent.Account.current();
            if(user!=undefined)this.user = user;
        } catch (error) {
            throw error;
        }

    }

    LoadingUsers = async () => {
        this.loadingInitial = true;
        try {
            const users = await agent.Account.list();
            console.log("fetching users...", users)

            runInAction(() => {
                this.usersDto = users?users:[]

            })
            console.log(this.usersDto)
            this.loadingInitial = false;
            return users;
        } catch (error) {
            this.loadingInitial = false;
            throw error;
        }

    }

    CheckIfCastcoAdmin = ()=>{
        console.log(this.user?.role )
        return this.user?.role === "Castco_Admin"
    }

    CheckIfHkiaEmail = ()=>{
        if(this.user == null)
            return false
        return this.user?.email.trim().split("@")[1] ==="hkairport.com" 
    }

}
