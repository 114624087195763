import React from 'react'
interface Prop{
    value?: string | number | readonly string[] | undefined
    width?:string;
    title: string;
    isRequired: boolean;

}

export default  function DisableField({isRequired, title, value, width}:Prop) {

  return (
    <div className="flex flex-col" >
        <div className="mb-2">
                <label>
                    <p className={`whitespace-nowrap ${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>

                </label>
            </div>

         <div>
         <input  disabled className={ ` ${width? "w-" + width:"w-full"}  h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D94D]`} value={value}/>
         </div>
    </div>
  )
}
