import React, { useEffect } from 'react'
import { LoggersetDetailsDto } from '../../../../../models/Pharse2/LocationMap'
import { TextAreaElement } from '../../../../../components/FormElement/TextAreaElement'
import { AALocationDetailsDto } from '../../../../../models/Pharse2/Overview';
import { InputElement } from '../../../../../components/FormElement/InputElement';
import { Input } from 'semantic-ui-react';

interface Prop {
    JsonObj?: any
}
export default function CustomDetails({ JsonObj }: Prop) {
    const result = Object.keys(JsonObj).map((key, idx) => {
        return <InputElement value={JsonObj[key]} isRequired={false} title={key} name={""} record={{}} setRecord={() => { }} readonly={true} />
    })


    return (
        <div className="flex flex-col w-full gap-4">
            {result}
        </div>
    )
}
