import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import GraphPage from '../../../Graph/GraphPage';
import { GraphData, GraphForm } from '../../../../models/Pharse2/Graph';
import { LoggersetDetailsDto, isLoggersetDetailsDto } from '../../../../models/Pharse2/LocationMap';
import LoggersetDetails from './LoggersetDetails/LoggersetDetails';
import LoadingComponent from '../../../../components/LoadingComponent';
import { useStore } from '../../../../stores/store';
import { observer } from 'mobx-react-lite';
import { AALocationDetailsDto } from '../../../../models/Pharse2/Overview';
import CustomDetails from './CustomDetails/CustomDetails';

const MODAL_STYLES = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

}

const OVERLAY_STYLES = {
	zIndex: 999,
	width: '100vw',
	height: '100vh',
	backgroundColor: ' rgba(0, 0, 0, 0.5)',
	position: 'fixed',
	top: 0,
	left: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backdropFilter: 'blur(2px)',

};

const CONTENT_STYLES = {

	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: 'white',
	zIndex: 1000,
	boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: '20px',
	maxHeight: '100vh',
	maxWidth: '100vw',
	overflowY: 'auto',
};

interface prop {
	detailsInfo: any
	isOpen: boolean
	setOpen: (bool: boolean) => void
	form: GraphForm
}
export default observer(function MapDetails({ isOpen, setOpen, form, detailsInfo }: prop) {
	const { graphStore } = useStore();
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {

			document.body.style.removeProperty('overflow');
		}
	}, [isOpen]);

	if (isOpen == false) return null;
	return ReactDOM.createPortal(

		<div className="content" style={MODAL_STYLES as React.CSSProperties}>

			<div style={OVERLAY_STYLES as React.CSSProperties} onClick={() => setOpen(false)} />
			<div style={CONTENT_STYLES as React.CSSProperties} className="w-11/12 h-5/6 hide-scrollbar">
				<img className='cursor-pointer w-[20px] hidden lg:block' onClick={() => { setOpen(false) }} src='/assets/button/cross/greyCross.png' style={{ position: "sticky", top: "15px", left:"98%", zIndex:1000 }} />
				<div className="flex flex-col md:flex-row items-center md:items-start justify-around " style={{ borderRadius: '20px', width: '100%', position: "relative" }}>
					{isLoggersetDetailsDto(detailsInfo) ?
						<div className="w-[100%] md:w-[35%]">
							<div className="pageParentDiv">
							<LoggersetDetails loggersetDetails={detailsInfo as LoggersetDetailsDto} />
							</div>
						</div>
						: <div className="w-[100%] md:w-[35%]">
							<div className="pageParentDiv">
							<CustomDetails JsonObj={detailsInfo} />
							</div>
						</div>
					}
					<div className="w-[100%] md:w-[60%]">
						<GraphPage form={form} />
					</div>

				</div>

			</div>
		</div>,
		document.getElementById('portal') as HTMLElement



	)
})
