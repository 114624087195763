import { FormEvent, useEffect, useState } from "react";
import { NewToggleSwitch } from "../FormElement/ToggleButton";
import { InputElement } from "../FormElement/InputElement";
import { SelectElement } from "../FormElement/SelectElement";
import { ISourceTypeError, SourceType, SourceTypeErrorValue, SourceTypeFormValue } from "../../models/SourceType";
import { IButton } from "../Button/Button";
import { useStore } from "../../stores/store";
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import LoadingComponent from "../LoadingComponent";
import SourceTypeFormValidation from "./validations/SourceTypeFormValidation";
import { Message } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import ServerErrorMessage from "../Message/Error/ServerErrorMessage";
import { ApiError } from "../../models/Error";
import * as yup from 'yup';

const textStyle = {
    color: "#CC311B",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
};

interface props {
    selectedId?: string;
}



export default observer(function SourceTypeCreateForm({ selectedId }: props) {


    const [serverError, setServerError] = useState<ApiError | undefined>();
    const { sourceTypeStore } = useStore();
    const { submitting, loadingInitial, LoadingSourceType, CreateSourceType, EditSourceType, LoadSelectedSourceType, selectedSourceType, clearSelectedSourceType } = sourceTypeStore




    useEffect(() => {
        if (selectedId) {
            LoadSelectedSourceType(selectedId).then(i => setRecord(new SourceTypeFormValue(i)))
        }
    }, [selectedId])


    let text: string;
    if (selectedId) {
        text = "Edit"
    } else {

        text = "Create"
    }

    const navigate = useNavigate();


    const YesNoOption = [
        { value: 1, name: "Yes" },
        { value: 0, name: "No" }
    ];


    const [record, setRecord] = useState(new SourceTypeFormValue());



    const [errors, setErrors] = useState<Partial<ISourceTypeError>>(new SourceTypeErrorValue());

    const validationSchema = yup.object({
        status: yup.boolean().required('Status is required'),
        sourceType: yup.string().required('Source type name is required').trim().min(1, "Source Type can't be empty"),
        access: yup.boolean().required('Access is required'),
    });

   

    const handleValidationChange = (field: string, value: any)=>{
        validationSchema.validateAt(field, {
            [field]: value
        })
            .then(() => {
                setErrors(prev => ({ ...prev, [field]: '' }));
            })
            .catch(err => {
                console.log(err)
                setErrors(prev => ({ ...prev, [field]: err.message }));
            });
    }

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {


        event.preventDefault();

        // var error = SourceTypeFormValidation(record)

        // if (Object.keys(error).length !== 0) {
        //     console.log("error")
        //     setErrors(error);
        //     return;
        // }
        try {
            await validationSchema.validate(record, { abortEarly: false });
            setErrors({})
            console.log("submit form successfully")
        }
        catch (err) {
            if (err instanceof yup.ValidationError) {
                const newErrors: Record<string, string> = {}; // Define the shape of the errors object
                err.inner.forEach((error) => {
                    if (error.path) { // Check if path is defined
                        newErrors[error.path] = error.message;
                    }
                });
                setErrors(newErrors);
                console.log(newErrors)
            }
            return;
        }
        
        let newRecord: SourceType = {
            ...record,
            access: record.access !== undefined ? record.access : false
        };



        //console.log(record);

        if (selectedId) {
            EditSourceType(newRecord).then(() => navigate("/sourceType")).catch((error) => {
                console.log(error);
                const { data, status, config } = error.response as AxiosResponse;
                var newError: ApiError = {
                    messageHeader: data[0],
                    messageList: data.slice(1)
                }

                setServerError(newError);
            });
        }
        else {
            CreateSourceType(newRecord).then(() => navigate("/sourceType"))

        }

    }

    const showRecord = () => {
        console.log(record)
        console.log(Number(record.access))
    }



    const handleCancel = () => {
        if (selectedSourceType) clearSelectedSourceType();
        navigate(-1);
    }

    if (loadingInitial || submitting)
        return <LoadingComponent content="Loading activity..." />;

    return (

        <div className="w-full  flex   h-[750px] mt-2">


            <form onSubmit={handleSubmit} autoComplete="off" className="w-full " >
                <div className="w-full flex flex-col gap-4">
                    <span className="text-[20px] font-medium text-decoration-line: underline" onClick={showRecord}>General Information</span>
                    <NewToggleSwitch  value={record.status} record={record} setRecord={setRecord} name={"status"} isRequired={true} title="Status" />

                    <InputElement handleValidation={handleValidationChange} value={record.sourceType} title={"Sources Type Name"} error={errors.sourceType} name={"sourceType"} isRequired={true} record={record} setRecord={setRecord} />


                    <InputElement handleValidation={handleValidationChange} error={errors.unit}  value={record.unit} title={"Unit"} name={"unit"} isRequired={true} record={record} setRecord={setRecord} />


                    <SelectElement handleValidation={handleValidationChange} record={record} setRecord={setRecord} error={errors.access} name={"access"} title={"External User Accessible"} options={YesNoOption} defValue={record.access != undefined ? Number(record.access) : undefined} isRequired={true} />


                    <InputElement value={record.remark} title={"Remark"} name={"remark"} isRequired={false} record={record} setRecord={setRecord} />

                    <div className="flex flex-col min-[540px]:flex-row justify-center gap-1 min-[540px]:gap-5 mt-5">
                        <IButton type="submit" text={text} />
                        <IButton type="button" onClick={handleCancel} text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" />
                    </div>

                    <ServerErrorMessage messageHeader={serverError?.messageHeader} messageList={serverError?.messageList} />
                </div>
            </form>



        </div>

    )
}
)
function useForm(): { register: any; handleSubmit: any; setValue: any; formState: { errors: any; }; } {
    throw new Error("Function not implemented.");
}

