import React from 'react'
import CreateFormHeader from '../../components/form/CreateFormHeader/CreateFormHeader'
import { Page } from '../../components/TitlePath/TitlePath'
import InventoryImportTable from './ImportElement/InventoryImportForm'

export default function InventoryImportPage() {
  const lastPages: Page[] = [{ title: "Inventory Management", path: "/inventory" }]
    
    return (
        <div className='pageParentDiv flex flex-col px-5'>
            <CreateFormHeader
                registeredBy={""}
                registeredOn={""}
                updateBy={ "" }
                updateOn={""}
                titleText={"Import Inventories"}
                lastPages={lastPages}
                formType={"inventory record"} />

            <InventoryImportTable /> 
        </div>
    )
}
