import React from 'react'


interface Props {
    registeredBy?: string;
    registeredOn?: string;
    updateBy?: string;
    updateOn?: string;

}
export default function HistoryHeader({ registeredBy, registeredOn, updateBy, updateOn }: Props) {

    const historyProp = [
        { Props: "Registered By: ", value:  registeredBy  },
        { Props: "Registered On: ", value:  registeredOn  },
        { Props: "Update By: ", value: updateBy  },
        { Props: "Update On: ", value:  updateOn  },

    ]
    return (
        <div className='w-full h-full px-4 bg-[#15304B] flex items-center justify-between flex-wrap'>
            {historyProp.map((item, index) => (
                <div key={index}  className="flex-1">
                    <span className="text-white">{item.Props} <span className="text-white whitespace-wrap">{item.value}</span></span>
                </div>
            ))}
            

        </div>
    )
}
