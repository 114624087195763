import React from 'react'
import { InputElement } from '../../../FormElement/InputElement'
import { TextAreaElement } from '../../../FormElement/TextAreaElement'
import { Location, LocationProject } from '../../../../models/Location'
import LoggersetLocationMap from './Map/LoggersetLocationMap'

interface Prop {
  record: LocationProject
}

export default function LoggersetLocationPage({ record }: Prop) {

  return (
    <div className=" mt-[40px]">
      <p className=" font-medium underline text-[20px]   " onClick={() => console.log(record)}>Responsible Project</p>
      <div className="flex mt-3">
        <div className="w-[50%] flex flex-col gap-4 " style={{ paddingRight: "30px" }}>
          <div className="flex gap-4">
            <div className="w-[70%]">
              <InputElement readonly={true} title={"Project Name"} name={"name"} value={record.project.name} isRequired={false} setRecord={() => { }} record={record.project} />
            </div>

          </div>
          
          <TextAreaElement readonly={true} title={"Project Address"} name={"Projectaddress"} value={record.location.address} isRequired={false} setRecord={() => { }} record={record.location} />
          <InputElement readonly={true} title={"Company"} name={"companyId"} value={record.project.companyId} isRequired={false} setRecord={() => { }} record={record.project} />


          <div className="mt-8">
            <p className=" font-medium underline text-[20px] ">
              Responsible Location Information
            </p>
            <div className="w-[30%]">
              <InputElement readonly={true} title={"Location Name"} name={"name"} value={record.location.name} isRequired={false} setRecord={() => { }} record={record.location} />
            </div>
            <div className="flex gap-4">
              <div className="w-[30%]"><InputElement readonly={true} title={"Date Start"} name={"dateStart"} value={record.location.dateStart} isRequired={false} setRecord={() => { }} record={record.location} /></div>
              <div className="w-[60%]"><InputElement readonly={true} title={"Date End"} name={"dateEnd"} value={record.location.dateEnd} isRequired={false} setRecord={() => { }} record={record.location} /></div>
            </div>
            <TextAreaElement readonly={true} title={"Location Address"} name={"address"} value={record.location.address} isRequired={false} setRecord={() => { }} record={record.location} />
          </div>
        </div>
        <div className="w-[50%]">
          <LoggersetLocationMap southLatitude={record.location.longtitude} northLatitude={record.location.latitude} />
        </div>
      </div>
    </div>
  )
}
