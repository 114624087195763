import React, { useState } from 'react'
interface prop {
    isChecked: boolean
    title: string
    index:number
    handleClick:(index: number)=>void
}


    
export default function SingleCheckboxOption({handleClick, index, isChecked, title }: prop) {
    const checkboxSrc = "/assets/table/thick.png"
    const tickedSrc = "/assets/table/thickTicked.png"
    
   
    return (
        <div className="flex items-center gap-2" >
            <img src={isChecked ? tickedSrc : checkboxSrc} 
            onClick={
                ()=>handleClick(index)}/>
            <span>{title}</span>
        </div>
    )
}
