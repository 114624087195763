import React, { ChangeEvent, useEffect, useState } from 'react'
import { ProjectForm } from '../../../../models/Project';
const errorMsg = {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '-2px'
}

interface Props {
    alarmIndex: number
    level: number

    borderColor?: string;
    className?: string;
    width?: string;
    isRequired: boolean;
    defValue?: any;

    options: string[] | { value: number, name: string }[] | { id:  number, name: string}[];
    title: string;
    name: string;
    setRecord: (arr: {}) => void;
    record: ProjectForm;
    error?: any;
    disabled?:boolean;

}


export default function AlarmSelect({disabled, defValue, alarmIndex, level, className, borderColor, width, isRequired, options, title, record, setRecord, name, error }: Props) {

    const [selectedOption, setSelectedOption] = useState(defValue === "" ? "placeholder" : defValue);
    useEffect(() => {
        setSelectedOption(defValue === "" ? "placeholder" : defValue);
    }, [defValue]);
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        let newValue: string = event.target.value;
        setSelectedOption(newValue);
    console.log("changed select")
        setRecord((prev: ProjectForm) => ({
            ...prev,
            alarm: prev.alarm?.map((alarmItem, idx) =>
                idx === alarmIndex
                    ? {
                        ...alarmItem,
                        alarmLevels: alarmItem.alarmLevels.map((levelItem, levelIdx) =>
                            levelIdx === level - 1
                                ? { ...levelItem, [name]: newValue }
                                : levelItem
                        )
                    }
                    : alarmItem
            )
        }));
    };

    return (
        <div className="flex flex-col ">
            <div className="mb-2">
                <label>
                    <p className={`${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>
                </label>
            </div>
            <div className="">
                {/* <DropdownList width={width} options={options} value={selectedOption} onChange={handleChange} placeholder={defValue} /> */}
                <select
                disabled={disabled}
                    value={selectedOption}
                    onChange={(e)=>handleChange(e)}
                    className={`h-full border-2 rounded-xl font-bold text-black ${borderColor ? "border-" + borderColor : "border-[#ACACAC]"}  ${width ? "w-" + width : "w-full"} ${className ?? ""}`}
                >
                    <option value="placeholder" disabled hidden >- Select Type -</option>



                    {(options as { id: number, name: string }[]).map((set) => (
                        <option
                            key={set.id}
                            value={set.id}
                            className="font-bold "
                        >
                            {set.name}
                        </option>
                    ))}


                </select>
                {error && <p style={errorMsg}>{error}</p>}
            </div>
        </div>
    )
}
