import React, { useState, MouseEventHandler, useEffect } from 'react';
import { CoustomTableHeaderProps } from '../Table/CoustomTable';
import { states } from '../../pages/SourceType/SourceTypeRecord';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';



interface TextBoxProps {
    buttonChoice: string
    placeholder?: string;
    IconLeft?: string;
    IconRight?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    options?: Array<CoustomTableHeaderProps>;
    setButtonChoice: (str: string) => void;
    statesArray: states[];
    setactiveState?: (x: string) => void;
    changePlaceholderValue: (x: string) => void;
    defaultValue?: string

}

interface buttonProp {
    item: CoustomTableHeaderProps;
    setButtonChoice: (str: string) => void;
    statesArray: states[];
    inputfieldFocus: boolean;
    changePlaceholderValue: (x: string) => void;

    buttonFocus: boolean;


}

export const TButton = observer(({ buttonFocus, changePlaceholderValue, item, setButtonChoice, statesArray, inputfieldFocus }: buttonProp) => {


    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setButtonChoice(item.id);
        if (item.data) changePlaceholderValue("Filter " + String(item.data));
        statesArray.map((item: states) => (item.statemethod("")));
    }


    return (
        <>
            <button
                className={`border-2 bg-white  h-[37px] rounded-full px-2 py-1 ${buttonFocus ? 'border-[#CC311B]' : 'border-[#ACACAC]'}`}

                onClick={onClick}
            >
                <p className={`whitespace-nowrap  ${buttonFocus ? 'text-[#CC311B]' : 'text-[#ACACAC]'}`}>{item.data}</p>
            </button>
        </>
    )

})

export const SymbolTextBox: React.FC<TextBoxProps> = ({ buttonChoice, defaultValue, changePlaceholderValue, setactiveState, placeholder, IconLeft, IconRight, onChange, options, setButtonChoice, statesArray }) => {

    const buttonChoiceToIdx = () => {
        if (!buttonChoice || !options) return 0

        for (let i = 0; i < options.length; i++) {
            if (buttonChoice == options[i].id) {
                return i
            }
        }
    }

    const initButtonChoice = () => {
        if (!buttonChoice ||!options || options.length<1) return 0

        for (let i = 0; i < options.length; i++) {
            if (buttonChoice == options[i].id) {
                return i
            }
        }
    }
 
    var [isFocused, setIsFocused] = useState(false);
    const [openOption, setOpenOption] = useState(initButtonChoice() !== 0);
    const onSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setOpenOption(!openOption);
    }

    const onclick = () => {

        // if(setactiveState){
        //     setactiveState("");
        // }
    }
    //useeffect
    // ${isFocused ? 'border-[#CC311B]' :
    return (
        <div className="flex flex-col lg:flex-row gap-2">
            <div
                className={`border-2 w-auto bg-white   max-w-[366px] sm:w-[366px] h-[37px] rounded-full flex items-center flex-shrink-0  border-[#ACACAC]`}
            >

                <img
                    className="border-none w-6 h-6 mx-2"
                    src={IconLeft}
                    alt="Icon Left"
                />
                <input

                    className=" text-black grow bg-[]"
                    type="text"
                    style={{
                        background: 'transparent',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                    }}
                    placeholder={placeholder}
                    onChange={onChange}
                    // onFocus={() => setIsFocused(true)}
                    // onBlur={() => setIsFocused(false)}
                    onClick={onclick}
                    value={defaultValue}
                />

                <button
                    className='w-12 h-7 rounded-full bg-[#F3F3F3] flex justify-center items-center'
                    onClick={(e) => onSelect(e)}>
                    <img
                        className='w-full'
                        src={IconRight}
                        alt="Icon Right"
                    />
                </button>

            </div>

            {openOption &&
                <div className='flex flex-col lg:flex-row gap-2'>
                    {options?.map((item: CoustomTableHeaderProps, index) => (
                        <TButton buttonFocus={index === buttonChoiceToIdx()} changePlaceholderValue={changePlaceholderValue} inputfieldFocus={isFocused} item={item} key={index} setButtonChoice={setButtonChoice} statesArray={statesArray} />
                    ))}
                </div>}


        </div>

    );
};