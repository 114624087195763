import React, { FormEvent, useEffect } from 'react'
import { InputElement } from '../../../FormElement/InputElement'
import { SelectElement } from '../../../FormElement/SelectElement'
import { IButton } from '../../../Button/Button'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { AccountCreateForm, IAccountCreateError } from '../../../../models/Account'
import { LoginInput } from '../../../LoginElement/LoginInput'
import AccountStore from './../../../../stores/AccountStore';
import { useStore } from '../../../../stores/store'
import { AxiosResponse } from 'axios'
import * as yup from 'yup';

interface Prop {
  record: AccountCreateForm;
  setRecord: (arr: AccountCreateForm) => void
  setPage: () => void

  errors: Partial<IAccountCreateError>
  setErrors: (arr: {}) => void
}


//.matches(/^[A-Za-z0-9%^*+]+$/, "Password only includes A-Za-z0-9%^*+")
const validationSchema = yup.object({
  email: yup.string().required("Email can't be empty").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Fill in a correct email"),
  password: yup.string().required("Password can't be empty").min(6, "Password must be at least 6 characters.").matches(/\d/, "Password must be at least 1 digit").matches(/[A-Z]/, "Password must be at least 1 Capital english letter"),
  confirmPassword: yup.string().required("Confirm Password can't be empty")   //.oneOf([yup.ref("password")], "Passwords does not match"
});


export default function AccountCreateLogin({ errors, setErrors, setPage, record, setRecord }: Prop) {
  useEffect(() => {
    console.log(record)
  }, [record])
  const handleValidationChange = (field: string, value: any) => {
    validationSchema.validateAt(field, {
      [field]: value
    })
      .then(() => {
        setErrors({ ...errors, [field]: '' });
      })
      .catch(err => {
        console.log(err)
        setErrors({ ...errors, [field]: err.message });
      });
  }

  const navigate = useNavigate();
  const { accountStore } = useStore();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await validationSchema.validate(record, { abortEarly: false });
      setErrors({})
      console.log("submit form successfully")
    }
    catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors: Record<string, string> = {}; // Define the shape of the errors object
        err.inner.forEach((error) => {
          if (error.path) { // Check if path is defined
            newErrors[error.path] = error.message;
          }
        });
        setErrors(newErrors);
        console.log(newErrors)
      }
      return;
    }

    accountStore.CreateAccount(record).then(() => navigate("/account"))
      .catch(error => {
        console.log(error)
        let apiErrors = error.response.data.errors;
        if (apiErrors != undefined) {
          var newError: Partial<IAccountCreateError> = {}
          for (let key in apiErrors) {
            if (apiErrors.hasOwnProperty(key)) {
              console.log(key, error.response.data.errors[key])
              newError = { ...newError, [key]: error.response.data.errors[key] }

            }
          }
          setErrors(newError)
        }
        return;

      }

      )
  }

  return (

    <form onSubmit={handleSubmit} autoComplete="off" className="mt-4 mb-[10px] flex flex-col gap-4" >
      <span className="text-[20px] font-medium text-decoration-line: underline">Login Information</span>
      <InputElement handleValidation={handleValidationChange} title={"Email Address"} name={"email"} isRequired={true} setRecord={setRecord} record={record} value={record.email} error={errors.email} />
      <LoginInput handleValidation={handleValidationChange} title={"Password"} name={"password"} value={record.password} record={record} setRecord={setRecord} isPassword={true} error={errors.password} />
      <LoginInput handleValidation={handleValidationChange} title={"Confirm Password"} name={"confirmPassword"} value={record.confirmPassword} record={record} setRecord={setRecord} isPassword={true} error={errors.confirmPassword} />

      <div className="flex justify-center gap-5 mt-5">
        <IButton type="submit" text={"Submit"} />
        <IButton type="button" text="Previous" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" onClick={setPage} />

      </div>
    </form>
  )

}