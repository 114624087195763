import { LoggerSetFormValue } from "../../models/LoggerSet"

interface props{
    loggerset?: LoggerSetFormValue;
}

export default function LoggerCountInfoDisplay({loggerset}:props){


    const historyProp = [
        { Props: "Logger Quantity: ", value:  loggerset?.loggerList.length  },
        { Props: "Sensor Quantity: ", value:  loggerset?.sensorList.length  },
        { Props: "Other Equipment Quantity: ", value: loggerset?.otherEquipmentList.length },
        
    ]

    return (
        <div className='w-full h-full px-4 bg-[#CC311B]  flex items-center justify-between gap-[89px] flex-wrap'>
            {historyProp.map((item, index) => (
                <div key={index}  className="flex-1">
                    <span className="text-white">{item.Props} <span className="text-white whitespace-nowrap">{item.value}</span></span>
                </div>
            ))}
        </div>
    )
}
