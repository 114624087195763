export interface SourceType {

    id?:number;
    sourceType: string;
    unit?: string;
    access: boolean;
    remark: string;
    status?: boolean;
}

export interface ISourceTypeError{
    sourceType: string;
    unit: string;
    status:string
    access: string;

    remark: string;

}
export class SourceTypeErrorValue implements ISourceTypeError {
    status:string ="";
    sourceType: string ="";
    unit: string = "";
    access: string = "";
    remark: string ="";
}

export interface SourceTypeForm extends Partial<SourceType>{
    
}

export class SourceTypeFormValue implements SourceTypeForm {
   
    id?: number | undefined;
    sourceType: string = "";
    unit: string = "";
    access?: boolean = undefined;
    remark: string = "";
    status?: boolean = false;

    constructor(init?: SourceTypeForm){
        Object.assign(this,init);
    }
}

