import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";

const LoadingComponent: React.FC<{ inverted?: boolean; content?: string }> = ({
  inverted = true,
  content
}) => {
  return (
    <Dimmer active inverted={inverted} style={{
      position: 'fixed',   /* Positioned relative to the viewport */
      top: 0,              /* Align to the top edge of the viewport */
      left: 0,             /* Align to the left edge of the viewport */
      width: '100vw',      /* Full viewport width */
      height: '100vh',     /* Full viewport height */
      zIndex: 1000,        /* Place on top of other elements */
    }}>
      <Loader content={content} />
    </Dimmer>
  );
};

export default LoadingComponent;