// NavItem.tsx
import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import CommonStore from './../../stores/CommonStore';
import { useStore } from '../../stores/store';

interface Props {
  title: string;
  imgSrc: string;  // If you're using image icons, change this to string
  path: string;
  isSelected: boolean;
}

function NavItem({ title, imgSrc, path, isSelected }: Props) {
  const navigate = useNavigate();
  const bgColor = isSelected ? 'white' : 'transparent';
  const textColor = isSelected ? 'black' : 'white';
  const imgColor = isSelected ? 'Black' : 'White';

  const {commonStore} = useStore();

  return (

    <div className="md:px-4 flex  items-center justify-center rounded-md  h-[50px] md:h-14 w-[70px] md:w-[80%] cursor-pointer " style={{ backgroundColor: bgColor }} onClick={() => { navigate(path);commonStore.checkAndCloseNavBar() }} >

      <div className="flex flex-col md:flex-row  gap-1 md:gap-2 items-center md:mr-7">

        <img className="w-6 h-6" src={`/assets/nav/${imgSrc}${imgColor}.png`} />  {/* If you're using image icons, replace this with <img src={icon} alt={title} /> */}

        <div className="flex md:w-24 justify-center md:justify-start md:ml-3">
          <span className=" whitespace-nowrap p-0 text-[12px] md:text-base " style={{ color: textColor }}>{title}</span>

        </div>

      </div>
    </div>
  )
};

export default NavItem;
