import { useParams } from "react-router-dom";
import { useStore } from "../../stores/store";
import { useEffect } from "react";
import CreateFormHeader from "../../components/form/CreateFormHeader/CreateFormHeader";
import CustomerViewForm from "../../components/form/Customer/CustomerViewForm";
import { Page } from "../../components/TitlePath/TitlePath";

export default function CustomerViewPage() {
    const { id } = useParams();
    const {inventoryStore} = useStore()
    const {getInventoryHistory} = inventoryStore;
   
  
  
    const lastPages:Page[] =[{title:"Project Management", path:"/projectManagementMenu"}, {title:"Customer Record", path:"/customer"}]
  
    return (
      <div className="pageParentDiv flex flex-col px-5">
          <CreateFormHeader 
          lastPages={lastPages}
          titleText={"View Customers Record"} 
          formType={"inventory record"} /> 
         
         <CustomerViewForm selectedId={id} />
          
         
      </div>
    )
  }