import { ILoggerSetError, LoggerSetFormValue } from "../../../models/LoggerSet";

export default function LoggerSetFormValidation(record:LoggerSetFormValue){
    const errors: Partial<ILoggerSetError>={};
    console.log(record);
    if(record.name==""){
        errors.name="Input field is required"
    }
    if(record.statusId==undefined || record.statusId==0){
        errors.status="Input field is required"
    }
    // if(record.loggerList.length==0){
    //     errors.loggerList="Input field is required"
    // }
    // if(record.sensorList.length==0){
    //     errors.sensorList="Input field is required"
    // }
    //Add validation for project segment later
    return errors;
}