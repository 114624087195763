import React, { ChangeEvent, useEffect, useState } from 'react'

import { DropdownList } from '../Select/DropdownList'
import { CustomCalendar } from '../Input/CustomCalendar'
import { Button, ToggleButton } from '@mui/material'


import { SymbolTextBox } from '../Input/SymbolTextbox'
import GeneralButton from '../Button/GeneralButton'
import { CoustomTableHeaderProps } from '../Table/CoustomTable'
import { states } from '../../pages/SourceType/SourceTypeRecord'
import { useNavigate } from 'react-router-dom';
import { NewToggleSwitch } from '../FormElement/ToggleButton'
import { SourceType, SourceTypeForm } from '../../models/SourceType';
import { useStore } from '../../stores/store'
import TitlePath, { Page } from '../TitlePath/TitlePath'

export const compLabel = {
    width: '35px', padding: '1.5%', marginRight: '10%'
}

export const components = {
    height: '35px',
    paddingTop: '8px',
    paddingRight: '16px',
    margin: '10px'

}

interface Props {
    buttonChoice: string
    state: string
    activeState: string

    lastPages?: Page[]
    title: string
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    setactiveState?: (x: string) => void;
    isInfoTableHeader?: boolean;
}

export default function ProjectHeader({ buttonChoice, state, activeState, title, lastPages, setState, setButtonChoice, options, statesArray, setactiveState, isInfoTableHeader }: Props) {

    const navigate = useNavigate();

    const [filterPlaceholder, ChangePlaceHolder] = useState("Filter Project Name");

    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    }

    const createProject = () => {
        navigate('/project/create');
    }

    const createButton = { title: 'Create New Project', imgSrc: '/assets/button/recAdd.png', Click: createProject }


    return (
        <div className=' flex flex-col gap-4'>
            <TitlePath currentTitle={title} lastPages={lastPages} />

            <div className='flex flex-col  lg:flex-row justify-between gap-3'>
                <div className='flex' >
                    <SymbolTextBox
                        buttonChoice={buttonChoice}
                        defaultValue={state}
                        changePlaceholderValue={ChangePlaceHolder}
                        setactiveState={setactiveState}
                        options={options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={filterProps.placeholder}
                        onChange={(e) => setState(e.target.value)}
                        statesArray={statesArray}
                        setButtonChoice={setButtonChoice} />
                </div>
                <div className='flex gap-4'>
                    {
                        (isInfoTableHeader == undefined || isInfoTableHeader == false) &&
                        <div className="flex flex-col md:flex-row gap-3">
                            <div className=' flex  items-center'  >
                                <span >
                                    Status:
                                </span>
                                <NewToggleSwitch value={activeState === "Active" ? true : undefined} setActiveState={setactiveState} name={"activeStatus"} />
                            </div>
                            <div className='w-full' >
                                <GeneralButton title={createButton.title} imgSrc={createButton.imgSrc} handleClick={createProject} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


