import React, { useState, useEffect } from 'react'
import MapDetails from '../home/LoggersetMap/Info/MapDetails'
import MapParentContainer from '../home/LoggersetMap/MapParentContainer'
import { LoggersetDetailsDto } from '../../models/Pharse2/LocationMap';
import { GraphForm, GraphFormVal } from '../../models/Pharse2/Graph';
import OverviewRecord from '../Overview/OverviewRecord';
import { AALocationDetailsDto } from './../../models/Pharse2/Overview';
import { store } from '../../stores/store';
import LoadingComponent from '../../components/LoadingComponent';
import { observer } from 'mobx-react-lite';
import FilterHeader from '../../components/Graph/FilterHeader/FilterHeader';
import GeneralButton from '../../components/Button/GeneralButton';
import { ProjectDistinct } from "../../models/Pharse2/LocationMap";
import ReactDOM from 'react-dom';
export default observer(function DashboardPage() {
	const [detailsInfo, setDetailsInfo] = useState<any>();
	const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
	const [form, setForm] = useState<GraphForm>(new GraphFormVal())
	const [horizontalView, setHorizontalView] = useState<boolean>(window.innerWidth > 1024 ? true : false);
	const [projectoptions, setprojectoptions] = useState<string>();

	function setprojectoptionsnamevoid(data: string) {
		setprojectoptions(data)
	}
	const [useOldMap, setUseOldMap] = useState<boolean>(false);
	const [domReady, setDomReady] = React.useState(false)

	React.useEffect(() => {
	  setDomReady(true)
	}, [])
	return (

		<div className="pageParentDiv">
				{domReady&&ReactDOM.createPortal(<button onClick={() => setHorizontalView(!horizontalView)} >
					<img src="/assets/button/rotate.png" className="w-8" />
				</button>, document.getElementById('navportal') as HTMLElement)}
			<MapDetails detailsInfo={detailsInfo} isOpen={isInfoOpen} setOpen={setInfoOpen} form={form} />
			<div className={`${horizontalView ? "horizontal-container" : "vertical-container"} `}>
				{horizontalView && <div className={'w-1/2 z-0'}>
					<MapParentContainer form={form} setForm={setForm} setInfoOpen={setInfoOpen} setDetailsInfo={setDetailsInfo} useOldMap={useOldMap} setprojectoptionsname={setprojectoptionsnamevoid} />
				</div>}
				{!horizontalView && <div className='w-full z-0'>
					<MapParentContainer form={form} setForm={setForm} setInfoOpen={setInfoOpen} setDetailsInfo={setDetailsInfo} useOldMap={useOldMap} setprojectoptionsname={setprojectoptionsnamevoid} />
				</div>}
				<div className={`${horizontalView ? 'w-1/2' : 'w-full'}`}>

					<OverviewRecord setForm={setForm} setInfoOpen={setInfoOpen} setDetailsInfo={setDetailsInfo} projectoptionsname={projectoptions} />
				</div>
			</div>
		</div>
	)
})
