import React from 'react'
import { NavItem } from '../NavBar';
import NavItems from '../NavItem';
import {useLocation} from 'react-router-dom';


interface Prop {
}
export default function AdminNav() {
  const adminNavItems: NavItem[] = [
    { title: 'Inventory', src: 'inventory', path: '/inventory' },
    { title: 'Project', src: 'project', path: '/projectManagementMenu' },
    { title: 'Account', src: 'account', path: '/account' },
  ];
  
  const location = useLocation();
  const { pathname } = location;
  return (
	<>
      <div className="hidden md:block my-4 ml-7 self-end md:self-start">
        <span className="text-white text-[12px] md:text-base " >
          Admin <br /> Management
        </span>
      </div>

      {adminNavItems.map((item, index) => (
        <NavItems key={index} title={item.title} imgSrc={item.src}
          path={item.path} isSelected={pathname === item.path} />
      ))}
    </>
  )
}
