import React, { FormEvent, useEffect, useState } from 'react'
import { InputElement } from '../../FormElement/InputElement'
import { SelectElement } from '../../FormElement/SelectElement'
import { TextAreaElement } from '../../FormElement/TextAreaElement'
import GeneralButton from '../../Button/GeneralButton'
import { NewToggleSwitch } from '../../FormElement/ToggleButton'
import { CustomCalendar } from '../../Input/CustomCalendar'
import { ProjectInfoTable } from './ProjectInfo/ProjectInfoTable'
import { AlarmInfoForm } from './AlarmInfo/AlarmInfoForm'
import { IProjectError, ProjectErrorValue, ProjectForm, ProjectFormValue } from '../../../models/Project'
import { useStore } from '../../../stores/store'
import { Alarm, IAlarmError, IAlarmLevelError, LocationFormAlarmVal } from '../../../models/Alarm'
import { IButton } from '../../Button/Button'
import { Link, useNavigate } from 'react-router-dom'
import LoadingComponent from '../../LoadingComponent'
import { ProjectFormValidation } from './Validation/ProjectValidation'
import ServerErrorMessage from '../../Message/Error/ServerErrorMessage'
import { ApiError } from '../../../models/Error'


interface Prop {
  changePage: (arr: number) => void;
  record: ProjectForm
  setRecord: React.Dispatch<React.SetStateAction<ProjectForm>>;
  selectedId?: string;
  recordIsLoaded: boolean;
  setRecordLoaded: (arr: boolean) => void
  selectedIds: string[];
  setSelectedIds: (arr: string[]) => void;
  errors: Partial<IProjectError>
  setErrors: (arr: {}) => void


}



export default function ProjectCreateForm({ errors, setErrors, changePage, recordIsLoaded, setRecordLoaded, selectedId, record, setRecord, selectedIds, setSelectedIds }: Prop) {


  useEffect(() => {
    console.log("selecteedid changed,", selectedIds)
  }, [selectedIds])



  function handlePresave(newIds: string[], selectedId?: string) {

    setSelectedIds(newIds);
    console.log("handle presave, Ids: ", newIds)
    if (selectedId) { //edi tmode
      setRecord((prevState: ProjectForm) => {
        prevState.alarm = prevState.alarm || [];

        let newAlarm: Alarm[] = [...prevState.alarm]; // Start with the existing alarms
        if (newIds.length > 0) {
          newIds.forEach((item: string) => {
            // Only add a new alarm if it doesn't already exist
            if (!prevState.alarm!.some(a => a.sourceTypeId === Number(item))) {
              newAlarm.push({
                sourceTypeId: Number(item),
                alarmLevels: [{ level: 1, conditionId: "", value: 0 }]
              });
            }
          });
        }

        // Filter out any alarms that are not in the selectedIds
        console.log("before filter", newIds, record)
        newAlarm = newAlarm.filter(a => newIds.includes(a.sourceTypeId.toString()));
        console.log("after filter", record)
        return {
          ...prevState,
          alarm: newAlarm
        };
      });

    }
    else { // create

      setRecord((prevState: ProjectForm) => {

        prevState.alarm = prevState.alarm || [];

        let newAlarm: Alarm[] = [];
        if (newIds.length > 0) {
          newAlarm = newIds.map((item: string) => {
            // Find the existing alarm for this id
            const existingAlarm = prevState.alarm!.find((a: Alarm) => a.sourceTypeId === Number(item));
            return {
              sourceTypeId: Number(item),
              // If an existing alarm was found, use its alarmLevels, otherwise use a default value
              alarmLevels: existingAlarm ? existingAlarm.alarmLevels : [{ level: 1, conditionId: "", value: 0 }]
            };
          });
        }
        return {
          ...prevState,
          alarm: newAlarm
        };
      });


    }

  }



  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    //validation
    setErrors({});
    var error = ProjectFormValidation(record);
    if (Object.keys(error).length !== 0) {
      console.log(error)
      setErrors(error);
      return;
    }
    changePage(1);
  }

  const navigate = useNavigate();

  const [loadingConditions, setLoadingConditions] = useState<boolean>(false)

  useEffect(() => {
    console.log("record is changed,", record)
  }, [record])


  const [projectInfoisOpen, setProjectInfoisOpen] = useState(false);

  const handleProjectInfo = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setProjectInfoisOpen(true)

  }

  const { projectStore, sourceTypeStore, summaryStore, inventoryStore } = useStore();
  const { departments, LoadingDepartment, LoadingCompany, companys } = summaryStore
  const { CreateProject, EditProject } = projectStore
  const { LoadingProject } = projectStore

  const { getSourceNameWithId } = sourceTypeStore

  useEffect(() => {
    LoadingDepartment();
    LoadingCompany();
  }, [])


  useEffect(() => {

    if (selectedId && !recordIsLoaded) {
      console.log("Loading project")
      LoadingProject(selectedId).then(p => setRecord(new ProjectFormValue(p)))
      setRecordLoaded(true)
    }

  }, [selectedId])

  const { LoadingAlarmCondi, conditions } = summaryStore

  useEffect(() => {
    const fetchData = async () => {
      setLoadingConditions(true);
      try {
        await LoadingAlarmCondi();
      } catch (error) {
        console.error(error)
      }
      setLoadingConditions(false)
    };
    if (summaryStore) {
      fetchData();
      //console.log(" RESULTS: "+loggerSets[0].statusId)
    }
  }, [summaryStore])

  //test
  const showError = () => {
    console.log(errors)
  }

  if (loadingConditions)
    return <LoadingComponent content="Loading activity..." />;

  return (

    <div>
      <form onSubmit={handleSubmit} autoComplete="off">

        <span className="text-[20px] font-medium text-decoration-line: underline" onClick={showError} >General Information</span>
        <div className="mt-[10px] mb-[10px] flex flex-col gap-4 flex-wrap">

          <NewToggleSwitch record={record} setRecord={setRecord} name={"status"} title="Status" isRequired={true} value={record.status} />
          <InputElement title={"Project Name"} name={"name"} isRequired={true} setRecord={setRecord} record={record} error={errors.name} value={record.name} />
          <div className="w-full">
            <SelectElement defValue={record.departmentId} isWithApi={true} isRequired={false} record={record} setRecord={setRecord} name={"departmentId"} title={"Department"} options={departments} />
          </div>
          <SelectElement defValue={record.companyId} isWithApi={true} isRequired={true} record={record} setRecord={setRecord} name={"companyId"} title={"Company"} options={companys} error={errors.companyId} />

          <div className="flex w-full flex-wrap">

            <CustomCalendar width='w-full' isRequired={true} title="Project Period" name="startDate" record={record} setRecord={setRecord} error={errors.startDate} value={record.startDate} />
            <CustomCalendar width='w-full' isRequired={true} name="endDate" record={record} setRecord={setRecord} value={record.endDate} />


          </div>

          <CustomCalendar title="Maintenance End Date" width='w-full' name="maintenanceEndDate" record={record} setRecord={setRecord} value={record.maintenanceEndDate} />

          <TextAreaElement title={"Remark"} name={"remark"} isRequired={false} setRecord={setRecord} record={record} value={record.remark} />


        </div>

        <div className="flex flex-col gap-3">
          <span className="text-[20px] font-medium text-decoration-line: underline">
            Default Alarm Control Setting
          </span>


          <GeneralButton px="px-5" title={"Default Sources Type Alarm Setting"} handleClick={(e) => handleProjectInfo(e)} />

          <ProjectInfoTable handlePresave={(ids) => handlePresave(ids, selectedId)} id={selectedId} selectedIds={selectedIds} onSelectedIdsChange={setSelectedIds} isOpen={projectInfoisOpen} setisOpen={setProjectInfoisOpen} />

          {record.alarm?.map((alarm, index) => (
            <AlarmInfoForm
              options={conditions}
              sourceTypeId={Number(alarm.sourceTypeId)}
              key={index}
              //title={`Alarm  ${index + 1}`}
              record={record} setRecord={setRecord} type={''}
              alarmIndex={index}
              validationFunction={errors.alarm && errors.alarm[index]}
            />
          ))}


        </div>

        <div className="flex justify-center gap-5 mt-5 mb-3 flex-wrap">
          <IButton type="submit" text="Next" className='w-full'/>
            <IButton onClick={()=>{navigate(-1)}} type='button' text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" className='w-full'/>

        </div>

      </form>
    </div>
  )
}
