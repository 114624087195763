import React, { useEffect, useState } from 'react'
import { IButton } from '../../../../../Button/Button'
import ReactDOM from 'react-dom'
import SingleCheckboxOption from './SingleCheckboxOption';
import MultiCheckboxOption from './MultiCheckboxOption';
import GeneralButton from '../../../../../Button/GeneralButton';


export interface Prop {
    options: { id: number, name: string }[];
    title: string

    isOpen: boolean;
    setisOpen: (x: boolean) => void;
    singleSelection?: number
    setSingleSelection?: (arr: number) => void;

    multiSelection?: number[]
    setMultiSelection?: (arr: number[]) => void;

    isinfotable?:boolean;

}
const MODAL_STYLES = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

}

const OVERLAY_STYLES = {
    width: '100vw',
    zIndex: 999,

    height: '100vh',
    backgroundColor: ' rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
};

const CONTENT_STYLES = {

    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    zIndex: 1000,
    boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    maxHeight: '100vh',
    overflowY: 'auto',
};
export default function CheckboxInfo({isinfotable, multiSelection, setMultiSelection, singleSelection, setSingleSelection, isOpen, setisOpen, options, title }: Prop) {

    const crossImg = "/assets/button/cross/squareCross.png"
    const [clickedId, setClickedId] = useState<number>(singleSelection ? singleSelection : 0)
    const [clickedIds, setClickedIds] = useState<number[]>(multiSelection ? multiSelection : [])

    useEffect(() => {
        if (singleSelection)
            setClickedId(singleSelection)
    }, [singleSelection]);

    useEffect(() => {
        if (multiSelection)
            setClickedIds(multiSelection)
    }, [multiSelection]);


    const handleSingleClick = (index: number) => {
        if (clickedId === index) {
            setClickedId(0);

        }
        else {
            setClickedId(index);
        }
    }

    const handleMultiClick = (index: number) => {
        if (clickedIds.includes(index)) {
            setClickedIds(clickedIds.filter(idx => idx !== index));
        }
        else {
           {setClickedIds([...clickedIds, index])};
        }
    }

    // const handleMultipleClick = (index: number) => {
    //     setClickedId(index);
    // }

    function handlePreSave() {
        if (setSingleSelection) {
            setSingleSelection(clickedId)
        }
        if (setMultiSelection) {
            setMultiSelection(clickedIds)
        }
        setisOpen(false)
    }
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [isOpen]);
    if (isOpen == false) return null;

    return ReactDOM.createPortal(
        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} onClick={handlePreSave}/>
            <div style={CONTENT_STYLES as React.CSSProperties} >
                <div className="w-[300px] md:w-[600px]" style={{ borderRadius: '20px',position:"relative"}}>
					<div className='cursor-pointer' style={{position:"absolute",right:"15px",top:"15px"}}>
					<img onClick={handlePreSave} src='/assets/button/cross/greyCross.png' className="w-[20px]"/>
					</div>
                    <div className="flex flex-col gap-2 py-5 px-4">

                        {title&&<div className="flex mb-3 justify-center items-center" >
                            <div><p className="text-[20px] font-medium">{title}</p></div>
                        </div>}

                        {setSingleSelection && singleSelection != null && (options as { id: number, name: string }[]).map((option, index) => (
                            <SingleCheckboxOption index={index + 1} handleClick={handleSingleClick} key={index + 1} isChecked={clickedId === index + 1} title={option.name} />
                        ))}


                        {setMultiSelection && multiSelection && (options as { id: number, name: string }[]).map((option, index) => (
                            <MultiCheckboxOption index={isinfotable? option.id:index + 1} handleClick={handleMultiClick} key={index} isChecked={clickedIds.includes(isinfotable? option.id:index + 1)} title={option.name} />
                        ))}
                    </div>


                </div>
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    )
}


