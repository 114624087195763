import React from 'react'
import LoginPoppedUp from './LoginMenu/LoginPoppedUp'
import { useStore } from '../stores/store';



export default function AdminManagement() {
  const { commonStore } = useStore();
  const { setNavBarOpen, navBarOpened } = commonStore;
 
  return (
    <div className={`z-40 fixed top-0 left-0  ${!navBarOpened? 'pl-0':'pl-[75px] md:pl-227'} w-full h-60 flex  items-center  bg-white justify-between overflow-visible`}>
      <div className="flex gap-2 cursor-pointer">
        <div onClick={() => setNavBarOpen()}>
          <img src={'/assets/nav/menuButton.png'} className=" w-[30px] ml-6" />

        </div>
		<div id="navportal" className="flex items-center gap-2"></div>

      </div>


      
        <LoginPoppedUp />
      


    </div>
  )
}
