import React, { useEffect, useRef, useState } from 'react'

import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { ThemeProvider } from '@mui/material/styles';
import SourceTypeHeader from '../../components/SourceTypeRecord/SourceTypeHeader';
import { useStore } from '../../stores/store';
import SourceTypeStore from '../../stores/SourceTypeStore';
import { observer } from 'mobx-react-lite';
import { Project, ProjectDto } from './../../models/Project';
import ProjectHeader from '../../components/ProjectRecord/ProjectHeader';
import { LocationFormValueDto } from '../../models/Location';
import LoadingComponent from '../../components/LoadingComponent';
import { Page } from '../../components/TitlePath/TitlePath';
import { sortingAlgor } from '../../hook/useSorting';


export interface states {
  currentstate: string;
  statemethod: React.Dispatch<React.SetStateAction<string>>;
}

export interface ProjectType {
  id: string,
  name: string,
  departmentName: string | null
  companyName: string
  projectPeriod: string,
  maintenanceEndDate: string | null,
  status: string,

  //pathId: string
}


interface props {
  selectedId?: string // replace isInfoTable
  isOpen?: boolean;
  setisOpen?: (x: boolean) => void;
  onSelectedIdsChange?: (arr: string[]) => void;
  record?: LocationFormValueDto;
  selectedProjectIds?: string[];
  selectedRow?: (number | null);
  setselectedRow?: React.Dispatch<React.SetStateAction<number | null>>
  handleLocationAssign?: (ids: string[]) => void
  isInfo?: boolean
}


export default observer(function ProjectRecord({ isInfo, handleLocationAssign, selectedRow, setselectedRow, selectedId, isOpen, setisOpen, onSelectedIdsChange, record, selectedProjectIds }: props) {

  const params = new URLSearchParams(window.location.search);
  const page_FromUrl = params.get('page');
  const projNameState_FromUrl = params.get('projNameState');
  const projCodeState_FromUrl = params.get('projCodeState');
  const compNameState_FromUrl = params.get('compNameState');
  const activeState_FromUrl = params.get('activeState');
  const buttonChoice_FromUrl = params.get('buttonChoice');

  const [page, setPage] = useState(Number(page_FromUrl) ?? 0);

  const [projNameState, setProjNameState] = useState(projNameState_FromUrl ?? "");
  const [compNameState, setCompNameState] = useState(compNameState_FromUrl ?? "");

  const [activeState, setactiveState] = useState<string>(activeState_FromUrl ?? "");

  const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "name");//what is this
  const [excelRecord, setexcelRecord] = useState<ProjectType[]>([]);
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

  const { projectStore } = useStore();
  const { LoadingProjectList, loadingInitial, submitting } = projectStore;
  const [Results, setResult] = useState<ProjectType[]>([])
  const [list, setlist] = useState<ProjectDto[]>();

  const final_setButtonChoice = (state: string) => { //cant use useEffect
    setButtonChoice(state)
    setPage(0)
  }
  const final_setactiveState = (state: string) => { //cant use useEffect
    setactiveState(state)
    setPage(0)
  }

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', String(page));
    params.set('buttonChoice', buttonChoice);
    params.set('activeState', activeState);

    if (buttonChoice === 'name') {
      params.set('projNameState', projNameState);
    } else if (buttonChoice === 'companyName') {
      params.set('compNameState', compNameState);
    }
    window.history.replaceState({}, '', `?${params.toString()}`);
  }, [page, buttonChoice, projNameState, compNameState, activeState]);



  useEffect(() => {
    const fetchData = async () => {
      try {

        await LoadingProjectList(selectedId).then(i => setlist(i));

      } catch (error) {
        console.error(error)
      }
    };
    if (projectStore) {
      fetchData();
    }
  }, [projectStore])

  useEffect(() => {
    if (selectedProjectIds != undefined && setselectedRow != null) {
      const projectId: string = selectedProjectIds[0];

      for (var i = 0; i < rows.length; i++) {
        if (rows[i].Id === projectId) {
          console.log("changed to ,", i)
          setselectedRow(i);
        }
      };

      console.log("inside");
    }
  }, [selectedProjectIds, rows]);


  useEffect(() => {
    const updatedRows = Results.filter((item) => {
      return projNameState.toLowerCase() === "" ? item
        : item.name.toLowerCase().includes(projNameState.toLowerCase());
    }).filter((item3) => {
      return compNameState.toLowerCase() === "" ? item3
        : item3.companyName.toLowerCase().includes(compNameState.toLowerCase());
    }).filter((item4) => {
      return activeState.toLowerCase() === "" ? item4
        : item4.status.toLowerCase() === activeState.toLowerCase();
    }).map((record: ProjectType) => ({

      data: [
        { id: "name", data: record.name },
        { id: "departmentName", data: record.departmentName },
        { id: "companyName", data: record.companyName },
        { id: "projectPeriod", data: record.projectPeriod },
        { id: "maintenanceEndDate", data: record.maintenanceEndDate },
        { id: "status", data: record.status },

      ],
      hiddenData: [],
      Id: record.id,
      ischecked: false

    }));

    setrows(updatedRows);

  }, [Results, projNameState, compNameState, activeState]);


  const handleCancel = () => {

    if (setisOpen) {
      setisOpen(false)

    }
    //refresh set rowId again
  }
  useEffect(() => {

    if (list) {
      const temp: ProjectType[] = list.map(
        (s) => ({
          id: String(s.projectId),
          name: s.name,
          departmentName: s.departmentName ? s.departmentName : "N/A",
          companyName: s.companyName,
          projectPeriod: s.projectPeriod,
          maintenanceEndDate: s.formattedMaintanance ? s.formattedMaintanance : "N/A",
          status: s.status ? "Active" : "Inactive"
        })
      )

      setResult(temp)
    }

  }, [list])




  const statesArray: states[] = [
    {
      currentstate: projNameState,
      statemethod: setProjNameState
    },
    {
      currentstate: compNameState,
      statemethod: setCompNameState
    },
    {
      currentstate: activeState,
      statemethod: setactiveState
    }
  ]


  const headers: Array<CoustomTableHeaderProps> = [

    { id: "name", data: "Project Name" },
    { id: "departmentName", data: "Department" },
    { id: "companyName", data: "Company Name" },
    { id: "projectPeriod", data: "Project Period" },
    { id: "maintenanceEndDate", data: "Maintenance End Date" },
    { id: "status", data: "Status" },

  ];


  const stateSelection = buttonChoice === "name"
    ? setProjNameState
    : setCompNameState

  const state = buttonChoice === "name"
    ? projNameState
    : compNameState

  const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]

  const onshow = () => {
    console.log(selectedRow)
  }

  const handleInfoAssign = (ids: string[]) => {
    if (handleLocationAssign) {
      handleLocationAssign(ids)
    }
    if (setisOpen) {
      setisOpen(false)
    }
  }



  //sorting

  const activeId = useRef<string | null>(null);
  const direction = useRef<'asc' | 'desc'>('asc');

  const onSortClick = () => {
    setPage(0);
    sortingAlgor({ rows, setrows, direction, activeId });

  };

  //////////////////////

  if (loadingInitial || submitting) {
    return (<LoadingComponent content="Loading..." />)
  }

  return (

    <>

      <div className="pageParentDiv">
        {/* <button onClick={onshow}>123123123</button> */}

        <ProjectHeader state={state} buttonChoice={buttonChoice} activeState={activeState} title={"Search Project Record"} lastPages={lastPages} isInfoTableHeader={isInfo} setactiveState={final_setactiveState} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "name" || item.id == "projectCode" || item.id == "companyName")} />
        <ThemeProvider theme={customTableTheme}>
          {(isInfo == undefined || isInfo == false) &&
            <CoustomTable onSortClick={onSortClick} activeId={activeId} direction={direction} page={page} setPage={setPage} tableMarginRight="200" subPath="project" headers={headers} setRows={setrows} rows={rows} setexcelRecord={setexcelRecord} excelRecord={excelRecord} />
          }

          {
            isInfo != undefined && isInfo && handleLocationAssign != undefined &&
            <div>
              <CoustomTable setselectedRow={setselectedRow} selectedRow={selectedRow} page={page} setPage={setPage} assignButton={handleInfoAssign} singleRowCheck={true} record={record} onSelectedIdsChange={onSelectedIdsChange} cancelButton={handleCancel} headers={headers} rows={rows} setRows={setrows} activeId={activeId} tableMarginRight="150" />
            </div>

          }
        </ThemeProvider>
      </div>

    </>

  )
}
)
