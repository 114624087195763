
import { action, makeAutoObservable, runInAction } from 'mobx';
import { User, UserFormValue } from '../models/User';
import agent from '../services/Api/agent';
import { store } from './store';
import { router } from '../router/Routes';
import { Department } from '../models/Department';
import { Status } from '../models/Status';
import { Condition } from '../models/Condition';
import { Company } from '../models/Company';
import { GraphType } from './../models/GraphType';
import { MessegeTool } from '../models/MessegeTool';
import { IntervalType, SummaryType } from '../models/Pharse2/Performance';

export default class SummaryStore {
    loadingInitial = false;

    departments: Department[] = []
    statuses: Status[] = []
    conditions: Condition[] = []
    companys: Company[] = []
    graphTypes: GraphType[] = []
    messegeTools: MessegeTool[] = []
    summaryTypes: SummaryType[] = []
    intervalTypes:IntervalType[] = []
    constructor() {
        makeAutoObservable(this);
    }


    LoadingDepartment = async () => {
        try {
            const departments = await agent.Summary.loadDepartments();
            runInAction(() => {
                this.departments = departments? departments:[]

            })
        }
        catch (error) {
            throw error
        }
    }

    LoadingInvStatus = async (selectedId?:string) => {
        try {
            const statusList = await agent.Summary.loadInvStatus(selectedId);
            
            runInAction(() => {
                this.statuses = statusList?statusList:[]
            })

        }
        catch (error) {
            throw error
        }
    }
    
    LoadingLogStatus = async (id?:string) => {
     
        try {
          
            const statusList = await agent.Summary.loadLogStatus(id);
            runInAction(() => {
                this.statuses = statusList?statusList:[]
            })
        }
        catch (error) {
            throw error
        }
    }

    LoadingAlarmCondi = async () => {
        console.log(this.loadingInitial)
        try {
            const condiList = await agent.Summary.loadCondi();

            runInAction(() => {
                this.conditions = condiList?condiList:[]
                this.loadingInitial = false

            })
            console.log(this.loadingInitial)
            return;
        }
        catch (error) {
            throw error

        }
    }

    LoadingCompany = async () => {
        try {
            const comp = await agent.Summary.loadCompany();
            runInAction(() => {
                this.companys = comp?comp:[]

            })
        }
        catch (error) {
            throw error
        }
    }

    LoadingGraphType = async () => {
        try {
            const graph = await agent.Summary.loadGraphTypes();
            console.log(graph)
            runInAction(() => {
                this.graphTypes = graph?graph:[]

            })
        }
        catch (error) {
            throw error
        }
    }

    LoadingMessegeTool = async () => {
        try {
            //if have, then leave
            const msgTools = await agent.Summary.loadMessegeTools();
            console.log(msgTools)
            runInAction(() => {
                this.messegeTools = msgTools?msgTools:[]

            })
        }
        catch (error) {
            throw error
        }
    }

    LoadingSummarys = async () => {
        try {
            //if have, then leave
            const sum = await agent.Summary.loadSummarys();
            runInAction(() => {
                this.summaryTypes = sum?sum:[]

            })
        }
        catch (error) {
            throw error
        }
    }
    
    LoadingIntervals = async (filterInt?:number[]) => {
        try {
            //if have, then leave
            const intv = await agent.Summary.loadIntervals(filterInt);
            runInAction(() => {
                this.intervalTypes = intv?intv:[]
                

            })
        }
        catch (error) {
            throw error
        }
    }

    getGraphTypeName = async (id: number) => {
        console.log("graphType is exist? ", this.graphTypes)
        if (this.graphTypes.length === 0) {
            await this.LoadingGraphType()
        }

        var name = this.graphTypes.find((element) => element.id === id)?.name;
        if (name) {
            return name
        }
        else {
            return ""
        }

    }

    getGraphTypeNameNoAsync = (id: number) => {
        if (this.graphTypes === null) {
            return ""
        }
        else {
            var name = this.graphTypes.find((element) => element.id === id)?.name;
            if (name) {
                return name
            }
            else {
                return ""
            }
        }

    }

    getMsgtool = (ids: number[]) => {


        var names: string[] = [];
        for (let i = 0; i < ids.length; i++) {
            var name = this.messegeTools.find(x => x.id === ids[i])?.name
            if (name != undefined) {
                names.push(name)
            }
        }
        return names

    }

    getMsgToolWithId = async (id: number) => {
        console.log(this.messegeTools.length === 0);
        if (this.messegeTools.length === 0) {
            await this.LoadingMessegeTool()
        }
        console.log(this.messegeTools)
        var name = this.messegeTools.find((element) => element.id === id)?.name;
        console.log("name are, ", name)
        if (name) {
            return name
        }
        else {
            return ""
        }
    }

    areArraysEqual(array1: number[], array2: number[]): boolean {
        if (array1.length !== array2.length) {
            return false;
        }
    
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
    
        return true;
    }
}
