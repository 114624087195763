import React, { useEffect, useState } from 'react'
import FilterHeader from '../../components/Graph/FilterHeader/FilterHeader'
import ProjectInfo from '../../components/form/Location/PopUpInfo/ProjectInfo'
import { GraphData, GraphDataVal, GraphForm, GraphFormVal } from '../../models/Pharse2/Graph'
import { SelectElement } from '../../components/FormElement/SelectElement'
import { PlotType } from 'plotly.js'
import GraphVisualization from './GraphVisualization'
import { observer } from 'mobx-react-lite'
import GForm from './GraphForm'
import { store } from '../../stores/store'
import LoadingComponent from '../../components/LoadingComponent'
import moment from 'moment'

interface Prop {
    form?: GraphForm
}
export default observer(function GraphPage({ form }: Prop) {

    const [record, setRecord] = useState<GraphData | undefined>(undefined);
    const [localForm, setlocalForm] = useState<GraphForm>(form ? form : new GraphFormVal());

    return (
        <div className="pageParentDiv" onClick={()=>console.log(localForm)}>
            <GForm isMap={form != undefined} record={record} setRecord={setRecord} localForm={localForm} setlocalForm={setlocalForm}/>
            <GraphVisualization record={record} localForm={localForm}  isMap={form != undefined}/>
        </div>
    )
})

