import React from 'react'
import { GraphForm } from '../../../models/Pharse2/Graph';
import { DataOptions } from '../../../models/Pharse2/Data';

interface Prop {
	onlyRead?: boolean
	isRequired?: boolean;
	title: string;
	onClick: () => void
	content: () => string
	localForm?: GraphForm
	dataOptions?: DataOptions
}

export default function CheckBoxInfoElement(p: Prop) {

	// const generateLocationList = (localForm : GraphForm, dataOptions : DataOptions) => {
	//     var result: string = "";

	//     for (let i = 0; i <localForm.locationIds.length; i++) {
	//         var name = dataOptions.locationList.find(x => x.id == localForm.locationIds[i])?.name
	//         if (name != undefined)
	//             result += name
	//         if (i < localForm.locationIds.length - 2)
	//             result += ', '
	//     }
	//     if(localForm.locationIds.length>2)result+="..click to see more"  
	//     return result;
	// }

	return (
		<div className="flex flex-col" style={{"maxWidth":"100%"}}>
			<div className="mb-2 w-full"><label><p className=" whitespace-nowrap">{p.title}</p></label></div>
			<div className="w-full">
				<select
					onMouseDown={(e) => {e.preventDefault();p.onClick(); }}
					disabled={p.onlyRead}
					className={`  w-full h-full border-2 rounded-xl font-bold text-black border-[#ACACAC] ${p.onlyRead ? "bg-[#D9D9D94D]" : ""} `}
				>
					<option value="placeholder" >{p.content()===""?`- Select Location -`:p.content()}</option>
				</select>
			</div>
		</div>
	)
}
