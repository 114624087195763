import React, { useEffect, useRef, useState } from 'react'
import './MultiLevelSelectElement.css'

export interface OptionContainer {
    id: number
    name: string
    subOptions: any[]
}

interface Prop {
    title: string
    isRequired?: boolean
    options: OptionContainer[]
    defValue: any
    defIsScripted?:boolean
    readOnly?: boolean
    onChange: (unit:string, parentValue: number, subValue: boolean, script:any) =>void //id, bool
}

export default function MultiLevelSelectElement({ defIsScripted, onChange, readOnly, options, defValue, title, isRequired }: Prop) {

    const [showOption, setShowOption] = useState<boolean>(false)
    const [selectedOption, setSelectedOption] = useState<number | undefined>() //for view only
    const [selectedChildOption, setSelectedChildOption] = useState<number>() //for view only
    const [placeholder, setPlaceholder] = useState<string>("- Select Type -");
    const wrapperRef = useRef<HTMLDivElement>(null);


    //handle default value
    useEffect(()=>{
        if(defValue>0 && defIsScripted!=undefined){
            for(let i =0;i<options.length;i++){
                if(options[i].id == defValue){
                    var childIdx = Number(defIsScripted)
                    setSelectedOption(i)
                   
                    setSelectedChildOption(childIdx)
                    //onChange(options[i].id , options[i].subOptions[options[i].subOptions.length-1].value)
                    setPlaceholder(options[i].name +" - "+options[i].subOptions[childIdx].name + ` (${options[i].subOptions[childIdx].unit})`)

                    break;
                }

            }  
        }
        else{
            setPlaceholder("- Select Type -")
        }


    }, [defValue, defIsScripted])

    const changeSelectedOption = (newNum: number) => {
        if (selectedOption != newNum) {
            setSelectedChildOption(undefined)
        }
        setSelectedOption(newNum)
    }
    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
            setShowOption(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionOnChange = (parent: OptionContainer, child: any, isScripted:boolean) => {
        onChange(child.unit, parent.id,isScripted, child.value)
        setShowOption(false)
    }
    return (
        <div ref={wrapperRef} className="relative flex flex-col w-full h-full">
            {title && <div className="mb-2 w-full">
                <label>
                    <p className={`${isRequired ? "text-[#CC311B]" : ""} whitespace-nowrap`}>
                        {isRequired && "*"}
                        {title}
                    </p>
                </label>
            </div>
            }
			<select
					onMouseDown={(e) => {e.preventDefault();setShowOption(!showOption); }}
					disabled={readOnly}
					className={`  w-full h-full border-2 rounded-xl font-bold text-black border-[#ACACAC] ${readOnly ? "bg-[#D9D9D94D]" : ""} `}
				>
					<option value="placeholder" >{placeholder}</option>
				</select>
            {showOption &&
                <div className="absolute flex-col flex-wrap w-full border-[0.5px] border-[#000000] bg-[#FFF]  top-full ">
                    {
                        options.map((option, i) => {
                            return (
                                <div key={i} className={`flex-col flex-wrap font-extrabold text-[13px] ${i === selectedOption ? 'bg-[#1C64F2] text-[#FFF]' : ''} `}
                                    onMouseEnter={() => changeSelectedOption(i)} onClick={() => changeSelectedOption(i)} >
                                    {/* onMouseLeave={()=>setSelectedOption(undefined)} */}
                                    <div className="flex justify-between">
                                    {option.name}
                                    <img className=" h-4 w-4" src="/assets/form/rightArrow.png" />
                                    </div>
                                    {option.subOptions && i === selectedOption &&
                                        <div className="flex-col flex-wrap pl-3 min-w-[150px]  border-[0.5px] border-[#000000] bg-[#FFFF] text-[#000] text-[13px]" >
                                            {option.subOptions.map((subOption: any, j: number) => {
                                                return (
                                                    <div onMouseEnter={() => setSelectedChildOption(j)} key={j} className={`w-full z-10 ${j == selectedChildOption ? "bg-[#1C64F2] text-[#FFF]" : ""}`} onClick={() => { setSelectedChildOption(j); handleOptionOnChange(option, subOption, j===1) }}>
                                                        {subOption.name} - ({subOption.unit})
                                                    </div>
                                                )
                                            })}
                                        </div>}

                                </div>

                            )
                        })
                    }
                </div>
            }
        </div>
    )
}
