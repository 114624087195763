import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { ThemeProvider } from '@mui/material/styles';
import SourceTypeHeader from '../../components/SourceTypeRecord/SourceTypeHeader';
import { store, useStore } from '../../stores/store';
import SourceTypeStore from '../../stores/SourceTypeStore';
import { observer } from 'mobx-react-lite';
import { Project, ProjectDto } from './../../models/Project';
import ProjectHeader from '../../components/ProjectRecord/ProjectHeader';
import { LocationFormValueDto } from '../../models/Location';
import LoadingComponent from '../../components/LoadingComponent';
import { Page } from '../../components/TitlePath/TitlePath';
import { sortingAlgor } from '../../hook/useSorting';
import OverviewHeader from './OverviewHeader';
import { AALocationDetailsDto, KeyValue } from '../../models/Pharse2/Overview';
import { LoggersetDetailsDto } from '../../models/Pharse2/LocationMap';
import { GraphForm } from '../../models/Pharse2/Graph';
import ScriptRemark from '../../components/Other/ScriptRemark';
import RefreshButton from '../../components/Button/RefreshButton';
import ReactDOM from 'react-dom';


export interface states {
    currentstate: string;
    statemethod: React.Dispatch<React.SetStateAction<string>>;
}

export interface OverviewType {
    id: string, //locationId
    locationId: number
    projectId: number
    locationName: string,

    projectName: string,
    alarmValue: number,
    sourceTypeValues: KeyValue[]

}


interface props {
    setInfoOpen: (x: boolean) => void
    setDetailsInfo: Dispatch<SetStateAction<LoggersetDetailsDto | string | undefined>>;
    setForm: React.Dispatch<SetStateAction<GraphForm>>
    selectedId?: string // replace isInfoTable
    isOpen?: boolean;
    setisOpen?: (x: boolean) => void;
    onSelectedIdsChange?: (arr: string[]) => void;
    record?: LocationFormValueDto;
    selectedProjectIds?: string[];
    selectedRow?: (number | null);
    setselectedRow?: React.Dispatch<React.SetStateAction<number | null>>
    handleLocationAssign?: (ids: string[]) => void
    isInfo?: boolean
    projectoptionsname?: string
}


export default observer(function OverviewRecord({ setForm, setInfoOpen, setDetailsInfo, isInfo, handleLocationAssign, selectedRow, setselectedRow, selectedId, isOpen, setisOpen, onSelectedIdsChange, record, selectedProjectIds, projectoptionsname }: props) {


    const handleRowClicked = (projectId: number, locationId: number) => {

        setForm((prev: GraphForm) => {
            return { ...prev, projectId: projectId, locationIds: [locationId], intervalId: 5, summaryId: 1 }
        })

        const Data = store.locationMapStore.getDetailsInfo(locationId).then((i) => {
            setInfoOpen(true)
            setDetailsInfo(i);
        })
    }

    const covertAlarmLevel = (val: number) => {
        switch (val) {
            case 0:
                return "Normal";
            case 1:
                return "High Alert";
            case 2:
                return "High High Alert"
            default:
                return "Level " + String(val)

        }
    }
    const [page, setPage] = useState(0);
    const [excelRecord, setexcelRecord] = useState<OverviewType[]>([]);

    const [projNameState, setProjNameState] = useState("");
    const [locNameState, setLocNameState] = useState("");


    const [buttonChoice, setButtonChoice] = useState("locationName");
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const { overviewStore } = useStore();
    const { loadingInitial, getOverviewDtoList } = overviewStore
    const [Results, setResult] = useState<OverviewType[]>([])

    const [headers, setHeaders] = useState<CoustomTableHeaderProps[]>([]);
    const [soucesHeaders, setSoucesHeaders] = useState<CoustomTableHeaderProps[]>([]);
    useEffect(() => {
        setPage(0)
    }, [buttonChoice])

    const [activateRefresh, setActivateRefresh] = useState<boolean>(false)

    const handleRefresh = () => {
        setActivateRefresh(!activateRefresh)
        setPage(0)
        setButtonChoice("locationName")
        setProjNameState("")
        setLocNameState("")
    }

    useEffect(() => {
        const fetch = async () => {
            await getOverviewDtoList().then((dtos) => {
                let list: OverviewType[] = [];
                let resourcesSet = new Set<number>()
                let sourcesHeader: Array<{ "id": string, "data": string }> = []
                dtos?.forEach((item, idx) => {
                    list.push({
                        locationId: item.locationId,
                        projectId: item.projectId,
                        id: String(item.locationId),
                        locationName: item.locationName,
                        projectName: item.projectName,
                        alarmValue: item.alarmValue,
                        sourceTypeValues: item.sourceTypeValues
                    } as OverviewType)
                    item.sourceTypeValues.forEach((r) => {
                        if (resourcesSet.has(r.resourceTypeId) === false) {
                            sourcesHeader.push({ "id": `${r.resourceTypeId}`, "data": `${r.resourceTypeName} ` }) //(${r.unit})
                        }
                        resourcesSet.add(r.resourceTypeId)
                    })
                })
                setResult(list)
                let baseHeader = [
                    {
                        "id": "locationName",
                        "data": "Location Name"
                    },

                    {
                        "id": "projectName",
                        "data": "Project Name"
                    },
                    // {
                    //     "id": "alarmValue",
                    //     "data": "Alarm Level"
                    // },
                    {
                        "id": "lastUpdated",
                        "data": "Last Updated"
                    },
                ]
                setHeaders([...baseHeader, ...sourcesHeader])
                setSoucesHeaders(sourcesHeader)
            })
            //TODO: handleheaders
        }
        fetch();
    }, [overviewStore, activateRefresh])

    useEffect(() => {
        if (projectoptionsname == "All"){
            setButtonChoice("locationName")
            setProjNameState("")
            //setLocNameState(projectoptionsname)
        }else if (projectoptionsname !== undefined){
            setButtonChoice("projectName")
            setProjNameState(projectoptionsname!)
        }
	}, [projectoptionsname])

    useEffect(() => {
        if (Results.length > 0) {
            const updatedRows = Results.filter((item) => {
                return projNameState.toLowerCase() === "" ? item
                    : item.projectName.toLowerCase().includes(projNameState.toLowerCase());
            }).filter((item) => {
                return locNameState.toLowerCase() === "" ? item
                    : item.locationName.toLowerCase().includes(locNameState.toLowerCase());
            }).map((record: OverviewType) => {

                var scripted_Ids: Set<string> = new Set();
                const baseData = [
                    { id: "locationName", data: record.locationName },


                    { id: "projectName", data: record.projectName },

                    // { id: "alarmValue", data: covertAlarmLevel(record.alarmValue) },
                    { id: "lastUpdated", data: record.sourceTypeValues.length > 0 ? record.sourceTypeValues[0].dateTime : "N/A" },


                ]
                soucesHeaders.forEach((item, idx) => {
                    console.log(record)
                    const sourceValue = record.sourceTypeValues.find(x => x.resourceTypeId === Number(item.id))
                    if (sourceValue && sourceValue.value != null) {
                        var newData = sourceValue.isScripted == true ? "" : "";
                        if (sourceValue.isScripted) {
                            scripted_Ids.add(item.id)
                        }
                        newData += String(sourceValue.value) + ` (${sourceValue.unit})`;
                        baseData.push({ id: item.id, data: newData })
                    }
                    else {
                        baseData.push({ id: item.id, data: "N/A" })
                    }
                })
                return {
                    highlight_Ids: scripted_Ids,
                    data: baseData,
                    hiddenData: [],
                    Id: record.id,
                    ischecked: false,
                    rowClickFunc: () => { handleRowClicked(record.projectId, record.locationId) }
                };
            })
            console.log(updatedRows)
            setrows(updatedRows);
        }


    }, [Results, projNameState, locNameState]);


    const handleCancel = () => {

        if (setisOpen) {
            setisOpen(false)

        }
        //refresh set rowId again
    }





    const statesArray: states[] = [
        {
            currentstate: locNameState,
            statemethod: setLocNameState
        },
        {
            currentstate: projNameState,
            statemethod: setProjNameState
        },

    ]





    const stateSelection = buttonChoice === "projectName"
        ? setProjNameState
        : setLocNameState

    const state = buttonChoice === "projectName"
        ? projNameState
        : locNameState


    const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]

    const onshow = () => {
        console.log(selectedRow)
    }



    const activeId = useRef<string | null>(null);
    const direction = useRef<'asc' | 'desc'>('asc');

    const onSortClick = () => {
        setPage(0);
        sortingAlgor({ rows, setrows, direction, activeId });


    };

	const [domReady, setDomReady] = React.useState(false)

	React.useEffect(() => {
	  setDomReady(true)
	}, [])

    return (
        <>
            <div className="">
                <OverviewHeader buttonChoice={buttonChoice} state={state} refreshOnClick={handleRefresh} title={"Location Overview"} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={setButtonChoice} options={headers.filter((item) => item.id == "projectName" || item.id == "locationName")} />
            	{domReady&& ReactDOM.createPortal(<RefreshButton onClick={handleRefresh} className="hidden lg:flex lg:items-center cursor-pointer" />,document.getElementById("navportal") as HTMLElement) }
			    <ThemeProvider theme={customTableTheme}>
                    <CoustomTable remark={<ScriptRemark/>} setexcelRecord={setexcelRecord} excelRecord={excelRecord} onSortClick={onSortClick} direction={direction} page={page} setPage={setPage} headers={headers} rows={rows} setRows={setrows} activeId={activeId} tableMarginRight='10' />
                </ThemeProvider>
            </div>

        </>

    )
}
)
