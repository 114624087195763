import { useParams } from "react-router-dom";
import CreateFormHeader from "../../components/form/CreateFormHeader/CreateFormHeader";
import LoggerSetCreateForm from "../../components/form/LoggerSet/LoggerSetCreateForm";
import { useStore } from "../../stores/store";
import { useEffect, useState } from "react";
import { TableHistory } from "../../models/Inventory";
import { observer } from "mobx-react-lite";
import { Page } from "../../components/TitlePath/TitlePath";

export default observer(function LoggerSetCreatePage() {
    const { id } = useParams();
    const { loggerSetStore } = useStore();
    const { getLoggersetHistory } = loggerSetStore;
    const [history, setHistory] = useState<TableHistory | undefined>(undefined);

    useEffect(() => {
        const fetchHistory = async () => {
            const data = await getLoggersetHistory(id);
            setHistory(data);
        }
        fetchHistory();
    }, [])

    const lastPages:Page[] =[{title:"Project Management", path:"/projectManagementMenu"}, {title:"Logger Set Maintenance", path:"/LoggersetMaintenance"}]

    return (
        <div className="pageParentDiv flex flex-col px-5">
            <CreateFormHeader
            lastPages={lastPages}
                registeredBy={history?.registeredBy}
                registeredOn={history?.registeredOn}
                updateBy={history?.updateBy}
                updateOn={history?.updateOn}
                titleText={id?"Edit Logger Set":"Create Logger Set"} formType={"Logger set"} />
            <LoggerSetCreateForm selectedId={id} />
        </div>
    )
})