import { getElementAtEvent } from "react-chartjs-2/dist/utils"
import { GraphData, GraphForm } from "../../models/Pharse2/Graph"
import { useState } from "react"
import { useStore } from "../../stores/store"

interface Props{
    localForm: GraphForm
    record: GraphData
    dummyadd:() => void
}

export default function LocationNameConfig({localForm, record, dummyadd}: Props){
    var locationCount = 100;
    var defaultTitle = ""
    const { userStore, commonStore } = useStore();
	const { user, CheckIfHkiaEmail } = userStore;
    record.locationName.map((name, index)=>{defaultTitle = defaultTitle + name; if(index != record.locationName.length-1){defaultTitle = defaultTitle+", "}})
    const [titleText, setTitle] = useState(`${defaultTitle}            ${record.fromDate? record.fromDate.split("T")[0] :""}${record.toDate? " to " + record.toDate.split("T")[0]:""}`)
    const handleNameChange=(newName: string, id: number)=>{
        var index = id - 100
        record.locationName[index] = newName
        dummyadd()
    }
    {
        return <>
            <div className="flex mt-[25px]">
                {record.locationName.map((location)=>{ return <>
                <div>
                    <input value={location} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm w-[100%] rounded-lg focus:ring-blue-500 focus:border-blue-500 block flex p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={"Enter location name"} id={(locationCount++).toString()} onChange={(e)=>{handleNameChange(e.target.value, parseInt(e.currentTarget.id))}}>
                    </input>
                </div>
                </>
                })}
            </div>
            <h2 style={{textAlign: "center",marginTop:"50px"}}><input style={{textAlign: "center", width:"100%"}} value={titleText} onChange={(e)=>{setTitle(e.target.value)}}></input></h2>
        </>
    }
}