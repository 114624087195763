import React from 'react'
interface Prop {
    onClick: () => void
	any:any
}
export default function RefreshButton(props: any) {

    return (
        <div className={`flex items-center justify-center cursor-pointer`}{...props}>
            <img src="/assets/button/refresh.png" className="w-7 h-7" onClick={props.onClick} />
        </div>
    )
}
