import { Inventory, InventoryDto, LoggerFormInventoryValue } from "./Inventory";

export interface LoggerSetDTO {

    id?:string;
    name:string;
    loggerList: Partial<InventoryDto>[]
    sensorList? : Partial<InventoryDto>[]
    otherEquipmentList?:Partial<InventoryDto>[]
    status: "red" | "black" | "green" | "blue" | "all"|"testingBlue",
    Location: string,
    remark?: string;
    remarkDescription?: string;
    
}

// export interface LoggerSetDto{
//     id: number;
//     name: string;
//     loggerList: Partial<Inventory>[];
//     sensorList: Partial<Inventory>[];
//     otherEquipmentList: Partial<Inventory>[];
//     locationId: number;  //Location: Location Later
//     statusId: number;  //string? 
//     remark?: string;
//     remarkDescription?: string;
//     ProjectId?: number;
// }





export interface LoggerSetForm extends Partial<LoggerSetDTO>{

}

export class LoggerSetFormValue implements LoggerSetForm{
    id?:string;
    name: string="";
    loggerList: Partial<InventoryDto>[]=[];
    sensorList: Partial<InventoryDto>[]=[];
    otherEquipmentList: Partial<InventoryDto>[]=[];
    statusId: number=0;
    locationId?: number| undefined;
    remark?: string | undefined;
    remarkDescription?: string|undefined;
    ProjectId?: number;

    constructor(init?: LoggerSetForm){
        Object.assign(this,init);
    }
}

// export interface LoggerSet{
//     id?:number;
//     name:string;

// }   

export interface ILoggerSetError{
    name:string;
    loggerList: string
    sensorList : string
    otherEquipmentList:string
    status:string,
    Location: string,
    remark: string;
    remarkDescription: string;
}

export class LoggerSetErrorValue implements ILoggerSetError{
    name: string= "";
    loggerList: string="";
    sensorList: string="";
    otherEquipmentList: string="";
    status: string="";
    Location: string="";
    remark: string="";
    remarkDescription: string="";
}
