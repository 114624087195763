import React, { useEffect, useState } from 'react'
import { Inventory } from '../../../models/Inventory';
import { LoggerSetDTO, LoggerSetFormValue } from './../../../models/LoggerSet';
import { useStore } from '../../../stores/store';



interface Prop {
    selectedId?: string
}

export default function EquipmentLoggerSet({ selectedId }: Prop) {

    const onDisplay = () => {
        console.log(Number(selectedId));
    }
    const [loggerset, setLoggerset] = useState<LoggerSetFormValue | undefined>(undefined);
    const { loggerSetStore } = useStore();

    useEffect(() => {
        const loadInventories = async (id?: string) => {
            if (id) {
                await loggerSetStore.getLoggerSetByInventoryId(id).then((i) => {
                    setLoggerset(i);
                })
            }
        }
        loadInventories(selectedId)
    }, [selectedId])

    return (
        <div className="flex flex-col w-full gap-4">
            <span className="text-[20px] font-medium text-decoration-line: underline">
                Equipment Component Set
            </span>
            <div>
                <p>{selectedId ? "Component " : "Logger Set "}Name</p>
                <input disabled value={loggerset?.name ? loggerset.name : ''} className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
            </div>

            <div className="flex flex-col gap-2">
                <p>{selectedId ? "Existing " : ""}Logger (Equipment ID)</p>
                {loggerset?.loggerList ? loggerset.loggerList.map((logger) => (
                    <input disabled value={logger ? logger.equipmentCode  : ''} className={` ${Number(selectedId) === logger.id ? "text-[#CC311B]" : "text-[#111111]"} bg-[#D9D9D9] w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-opacity-30`} />
                )) :
                    <>
                        <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                    </>

                }
            </div>
            <div className="flex flex-col gap-2" >
                <p>{selectedId ? "Existing " : ""}Sensor (Equipment ID)</p>
                {loggerset?.sensorList ? loggerset.sensorList.map((sensor) => (
                    <input disabled value={sensor ? sensor.equipmentCode  : ''} className={` ${Number(selectedId) === sensor.id ? "text-[#CC311B]" : "text-[#111111]"} w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30`} />
                )) :
                    <>
                        <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                    </>

                }
            </div>

            <div className="flex flex-col gap-2">
                <p>{selectedId ? "Existing " : ""}Other Equipment (Equipment ID)</p>
                {loggerset?.otherEquipmentList ? loggerset.otherEquipmentList.map((otherEquipment) => (
                    <input disabled value={otherEquipment ? otherEquipment.equipmentCode  : ''} className={` ${Number(selectedId) === otherEquipment.id ? "text-[#CC311B]" : "text-[#111111]"}  w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30 `}/>
                )) :
                    <div className="flex flex-col gap-3">
                        <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                        <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />

                    </div>

                }
            </div>
        </div>
    )
}
