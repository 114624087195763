import { useEffect, useRef, useState } from 'react';
import { Inventory } from '../../../models/Inventory';
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../../components/Table/CoustomTable';
import { ThemeProvider } from '@emotion/react';
interface Prop {
    selectedInventories: Inventory[]
}

interface InventoryInfo {
    id: string;
    inventoryTypeId: number;
    equipmentCode?: string;
    sourceTypeId: string[];
    departmentId?: number;
    statusId: number;
    surType?: string;
    equipmentName: string;
    label?: string;
    manufacturerName: string;
    supplierName?: string;
    serialNo: string;
    modelNo: string;
    warrantyStartDate?: string;
    warrantyEndDate?: string;

    simCardNo?: string;
    purchaseDate?: string;
    purchaseCost?: string;
    withDrawlDate?: string;
    lastMaintenance?: string;
    nextMaintenance?: string;
    remark?: string;
    remarkDescription?: string;
    disposedDate: string;


}

export default function InventoryImportTable({ selectedInventories }: Prop) {
    const [page, setPage] = useState(0);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const activeId = useRef<string | null>(null);

    const [results, setResult] = useState<InventoryInfo[]>([])


    useEffect(() => {
        // console.log(sourceTypeIds)
        const updatedRows = results
            .map((record: InventoryInfo) => ({
                data: [
                    { id: "name", data: record.equipmentCode },
                    { id: "manufacturerName", data: record.manufacturerName },
                    { id: "modelNo", data: record.modelNo },
                    { id: "serialNo", data: record.serialNo },
                    { id: "supplierName", data: record.supplierName },
                    { id: "surType", data: record.surType },
                    { id: "inventoryTypeId", data: record.inventoryTypeId },
                    { id: "sourceTypeId", data: record.sourceTypeId },
                    { id: "departmentId", data: record.departmentId },
                    
                    { id: "statusId", data: record.statusId },
                    { id: "remark", data: record.remark },
                    { id: "remarkDescription", data: record.serialNo },
                    { id: "disposedDate", data: record.serialNo },
                    { id: "lastMaintenance", data: record.lastMaintenance },
                    { id: "nextMaintenance", data: record.nextMaintenance },
                    { id: "purchaseDate", data: record.purchaseDate },
                    { id: "purchaseCost", data: record.purchaseCost },
                   
                    { id: "warrantyStartDate", data: record.warrantyStartDate },
                    { id: "warrantyEndDate", data: record.warrantyEndDate },
                ],
                Id: record.id

            }));


        setrows(updatedRows);
    }, [results]);

    useEffect(() => {
        const temp: InventoryInfo[] = selectedInventories.map(
            (s) => ({
                id: String(s.id),
                equipmentCode: s.equipmentCode,
                sourceTypeId: s.sourceTypeId,
                departmentId: s.departmentId,
                inventoryTypeId: s.inventoryTypeId, 
                statusId: s.statusId,
                surType: s.surType,
                equipmentName: s.equipmentName,
                label: s.label,
                manufacturerName: s.manufacturerName, 
                supplierName: s. supplierName, 
                serialNo: s.serialNo,
                modelNo: s.modelNo,
                warrantyStartDate: s.warrantyStartDate, 
                warrantyEndDate: s.warrantyEndDate, 
                simCardNo: s.simCardNo,
                purchaseDate: s.purchaseDate,
                purchaseCost: s.purchaseCost,
                withDrawlDate: s.withDrawlDate,
                lastMaintenance: s.lastMaintenance,
                nextMaintenance: s.nextMaintenance,
                remark:s.remark ,
                remarkDescription: s.remarkDescription,
                disposedDate: s.disposedDate,
            })
        )
        setResult(temp)
    }, [selectedInventories])

    const headers: Array<CoustomTableHeaderProps> = [

        { id: "equipmentCode", data: "Equipment Code" },
        { id: "manufacturerName", data: "Manufacturer Name" },
        { id: "serialNo", data: "Serial No." },
        { id: "modelNo", data: "Model No." },
        { id: "supplierName", data: "Supplier Name" },
        { id: "surType", data: "Sur. Type" },

        { id: "inventoryTypeId", data: "Inventory Type Id" },
        { id: "sourceTypeId", data: "SourceType Id" },
        { id: "departmentId", data: "Department Id" },
        { id: "statusId", data: "Status Id" }, //sortDisable:true },

        // { id: "locationCode", data: "(Location Code)" },
        { id: "remark", data: "Remark" },
        { id: "remarkDescription", data: "Remark Description" },
        { id: "disposedDate", data: "Disposed Date" },
        { id: "lastMaintenance", data: "Last Maintenance" },
        { id: "nextMaintenance", data: "Next Maintenance" },
        { id: "purchaseDate", data: "Purchased Date" },
        { id: "purchaseCost", data: "Purchased Cost" },
        { id: "warrantyStartDate", data: "Warranty StartDate" },
        { id: "warrantyEndDate", data: "Warranty EndDate" },


    ];

    return (
        <div>
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable page={page} setPage={setPage}    headers={headers} setRows={setrows} rows={rows} activeId={activeId} />
            </ThemeProvider>
        </div>
    )
}
