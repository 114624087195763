import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { UserFormValue } from "../../models/User";
import { ChangeEvent, useState } from "react";
import { Message } from "semantic-ui-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import LoginIcons from "../../components/LoginElement/LoginIcons";
import { LoginInput } from "../../components/LoginElement/LoginInput";
import LoginButton from "../../components/LoginElement/LoginButton";
import LoadingComponent from "../../components/LoadingComponent";


export default observer(function ForgetForm() {
    const { userStore } = useStore();
    const [forgetObj, setForgetObj] = useState<UserFormValue>({
        email: '',
    });
    const [ClientError, setClientError] = useState<Partial<UserFormValue>>({});
    const [ServerError, setServerError] = useState("");
    const [requestSuccess, setRequestSuccess] = useState("");
    function handelSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var err = handleValidation(forgetObj);

        if (Object.keys(err).length !== 0) {
            console.log("client side error")
            return;
        } else {
            userStore.forget(forgetObj).then((i) => {
                setRequestSuccess("The email is sent. Please check it. ");
            }).catch((error) => {
                setServerError("Invalid email");
            });
        }
    }


    function handleInput(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        setForgetObj({ ...forgetObj, [name]: value })  //name='title'  >>> change activity.title
    }

    function handleValidation(userObj: UserFormValue) {
        const errors: Partial<UserFormValue> = {}
        if (!userObj.email) {
            errors.email = "The Email is empty"
        }
        setClientError(errors);
        return errors;
    }
    if (userStore.loadingInitial)
        return <LoadingComponent />;
    return (

        <div className="  h-full flex justify-center items-center "  >
            <div className='w-[350px]'>
                <form onSubmit={handelSubmit} autoComplete="off" >
                    <div className="flex flex-col gap-4">
                        <LoginIcons />
                        <div>
                            <span className="text-[16px] font-medium">
                                Forgot your password?
                            </span><br />
                            <p className="text-[13px]">
                                Let's fix that. Provide us with the email address you use to log in to Castco IoT, and we'll send you a link to reset your password
                            </p>
                        </div>
                        <div>
                            <LoginInput title={"Email Address"} name={"email"} value={forgetObj.email} record={forgetObj} setRecord={setForgetObj} />
                            {ServerError &&
                                <Message error>
                                    {ServerError}
                                </Message>}

                            {requestSuccess &&
                                <Message success>
                                    {requestSuccess}
                                </Message>}
                        </div>


                        {/* <div className='mb-3'>
                            <label className='form-label ' >Email:</label>
                            <input className='form-control' placeholder='Email' value={forgetObj?.email} onChange={handleInput} name='email' />
                            {ClientError.email && <div>{ClientError.email}</div>}
                        </div> */}

                        <LoginButton isSubmit={true} title={"Send"} />


                    </div>
                </form>
            </div>


        </div>
    )
})
