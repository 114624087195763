import React, { useEffect, useState } from 'react'
import { SelectElement } from '../../../FormElement/SelectElement';
import { InputElement } from '../../../FormElement/InputElement';
import AlarmInput from '../Input/AlarmInput';
import { ProjectForm } from '../../../../models/Project';
import AlarmSelect from '../Input/AlarmSelect';
import { useStore } from '../../../../stores/store';
import { getUnit } from '@mui/material/styles/cssUtils';
import { IAlarmLevelError } from '../../../../models/Alarm';
import LoadingComponent from '../../../LoadingComponent';

interface Prop {
    level: number;
    alarmIndex: number;
    setRecord:(arr: {}) => void;
    record: ProjectForm;
    showDelete: boolean;
    onDelete: (event?: React.MouseEvent<HTMLButtonElement>) => void

    leftValue?: any;
    leftOptions: string[] | { value: number, name: string }[] | { id: number, name: string }[];
    leftName: string;
    leftError?: string;
 
    rightValue?: any;
    rightName: string
    rightError?: string;
    disabled?: boolean
    errors?:IAlarmLevelError

}
export default function AlarmLevelComponent({errors,alarmIndex,disabled, leftOptions, showDelete, onDelete, level, record, setRecord, leftError, rightError, leftName, rightName}: Prop){
    const [unit,setUnit] = useState("");
    const srcTypeId = record?.alarm?.[alarmIndex]?.sourceTypeId;

    const {sourceTypeStore, summaryStore} = useStore();
    const {getUnitWithId} = sourceTypeStore;
     useEffect(()=>{
        setUnit(getUnitWithId(Number(srcTypeId)));
     }, [srcTypeId])
     
     
   
    return (
        <div className='w-full h-[128px] flex flex-col gap-2'>
            <div className="flex items-center gap-3">
                <div>
                    <p className="text-[20px] font-medium text-decoration-line: underline">
                        Alarm Level {level}- ({unit})
                    </p>
                </div>

                {showDelete &&
                    <button onClick={onDelete}>
                        <img src="/assets/button/crossButton.png" />
                    </button>}

            </div>
            <div className="flex w-full gap-5">
                <div className='w-[45%]'>
                    <AlarmSelect disabled={disabled} defValue={record?.alarm?.[alarmIndex]?.alarmLevels[level - 1]?.conditionId} title={"Triggering Condition"} isRequired={true} record={record} setRecord={setRecord} name={leftName} options={leftOptions} error={errors?.conditionId} alarmIndex={alarmIndex} level={level} />
                </div>
                <div className='w-[35%]'>
                    <AlarmInput disabled={disabled} value={record?.alarm?.[alarmIndex]?.alarmLevels[level - 1]?.value} alarmIndex={alarmIndex} level={level} title={"Value"} name={rightName} isRequired={true} record={record} setRecord={setRecord} error={errors?.value} />
                </div>
            </div>
        </div>
    )
}
