import { useParams } from "react-router-dom";
import FormHeader from "../../components/FormElement/FormHeader/FormHeader";
import LocationCreateForm from "../../components/form/Location/LocationCreateForm";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { TableHistory } from "../../models/Inventory";
import CreateFormHeader from "../../components/form/CreateFormHeader/CreateFormHeader";
import { Page } from "../../components/TitlePath/TitlePath";




export default function LocationCreatePage() {
    const { id } = useParams();
    const { locationStore } = useStore();
    const [history, setHistory] = useState<TableHistory | undefined>(undefined);

    useEffect(() => {
        const fetchHistory = async () => {
            const data = await locationStore.getLocationHistory(id)
            setHistory(data);
        }
        fetchHistory();
        // console.log(history)
    }, [])
    const lastPages:Page[] =[{title:"Project Management", path:"/projectManagementMenu"}, {title:"Location Record", path:"/location"}]

    return (
        <div className="pageParentDiv flex flex-col px-5">
            <CreateFormHeader
                registeredBy={history?.registeredBy}
                registeredOn={history?.registeredOn}
                updateBy={history?.updateBy}
                updateOn={history?.updateOn}
                lastPages={lastPages}
                titleText={id ? "Edit Source Type Record" : "Create Source Type Record"} formType={"Logger Set record"} />
            <LocationCreateForm selectedId={id} />
        </div>
    )
}