import React, { useEffect, useState } from 'react'
import FormHeader from '../../components/FormElement/FormHeader/FormHeader'
import SourceTypeCreateForm from '../../components/form/SourceTypeCreateForm'
import { useParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import { TableHistory } from '../../models/Inventory';
import CreateFormHeader from '../../components/form/CreateFormHeader/CreateFormHeader';
import { Page } from '../../components/TitlePath/TitlePath';

export default function SourceTypeCreatePage() {
  const { id } = useParams();
  const { sourceTypeStore } = useStore();
  const { getSourceTypeHistory } = sourceTypeStore;
  const [history, setHistory] = useState<TableHistory | undefined>(undefined);

  useEffect(() => {
    const fetchHistory = async () => {
      const data = await getSourceTypeHistory(id);
      setHistory(data);
    }
    fetchHistory();
    // console.log(history)
  }, [])
  const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }, { title: "Source Type Record", path: "/sourceType" }]

  return (
    <div className="pageParentDiv flex flex-col px-5">
      <CreateFormHeader
        registeredBy={history?.registeredBy}
        registeredOn={history?.registeredOn}
        updateBy={history?.updateBy}
        updateOn={history?.updateOn}
        lastPages={lastPages}
        titleText={id ? "Edit Source Type Record" : "Create Source Type Record"} formType={"Logger Set record"} />

      <SourceTypeCreateForm selectedId={id} />
    </div>
  )
}
