export interface IAlarmForm {
    projectId: number;
    locationId: number;
    fromDate?: string;
    toDate?: string
}

export class AlarmFormValue implements IAlarmForm {
    
    projectId: number = -1;
    locationId: number = -1;
    fromDate?: string;
    toDate?: string;
    constructor(init?: AlarmFormValue) {
        Object.assign(this, init);
    }
}