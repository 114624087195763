import { makeAutoObservable, reaction } from "mobx";
import { Navigate, useNavigate } from 'react-router-dom';

export default class CommonStore{
    token: string | null = localStorage.getItem('jwt')
    appLoaded: boolean= false;
    navBarOpened: boolean=true;
    /**
     *
     */
    constructor() {
        makeAutoObservable(this);
        
        reaction( //initialise wont activate
        ()=> this.token,// side effect, when token is updated will run this code
        token => {
            if(token){// to update the local storage
                localStorage.setItem('jwt', token)
            }else{
                localStorage.removeItem('jwt');
               
                const Navigate = useNavigate();
                Navigate('/')
                
            }
        }
    )
    }

    setToken=(_token:string|null)=>{
       
        this.token = _token;
    } 

    setAppLoaded = () =>{
        this.appLoaded =true;
        this.checkAndCloseNavBar()
    }
    
    setNavBarOpen = ()=>{
        this.navBarOpened = !this.navBarOpened; 
    }

    closeNavBar = ()=>{
        this.navBarOpened = false;
    }

    checkAndCloseNavBar = () => {
        const mdBreakpoint = 768;
        const smBreakpoint = 640;
        if (window.innerWidth < mdBreakpoint) {
          this.closeNavBar();
        }
      };

  
}