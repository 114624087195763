import { makeAutoObservable } from "mobx";
import agent from "../services/Api/agent";
import DataForm from "../pages/Data/DataForm";
import { IPerformanceForm, PerformTableDto } from "../models/Pharse2/Performance";

export default class PerformanceStore {

    loadingInitial: boolean = false
    constructor() {
        makeAutoObservable(this)
    }

    

    getData = async (dataForm: IPerformanceForm, isForm: boolean) => {
        console.log(dataForm)

        try {

             this.loadingInitial = true
            const data = await agent.Performance.getData(dataForm)
            this.loadingInitial = false

            return data;

        } catch (error) {
             this.loadingInitial = false

            console.log(error)
        }
    }


}