import React, { useState } from 'react'
import EditLevelInfoTable from '../InfoTable/EditLevelInfoTable';
import { ProjectForm } from '../../../../../models/Project';

interface Prop {
  setTableBlur: (arr:boolean) => void
  record: ProjectForm
  setRecord: (arr: {}) => void
  alarmIndex: number;
  level: number;
  handleClick: () => void
  title: string;
  px?: string
  py?: string
}
export default function EditButton({setTableBlur, record, setRecord, alarmIndex, level, handleClick, title, px, py }: Prop) {
  const [editInfoisOpen, setEditInfoisOpen] = useState(false);

  function handleEditInfo(arr:boolean){
    setEditInfoisOpen(arr);
    setTableBlur(arr);
  }
  return (
    <div>
      <button type='button' onClick={() => handleEditInfo(true)} className={` ${py ? py : "py-2"} ${px ? px : "px-[25px]"}  cursor-pointer  m-1 rounded-lg bg-theme-orange   flex items-center justify-center`}>
        <span className='whitespace-nowrap text-white'>
          {title}
        </span>
      </button>

      <EditLevelInfoTable
        alarmIndex={alarmIndex}
        record={record}
        setRecord={setRecord}
        level={level}
        isOpen={editInfoisOpen}
        setisOpen={handleEditInfo} />

    </div>
  )
}
