import { observer } from "mobx-react-lite";
import GeneralButton from "../../components/Button/GeneralButton";
import InventoryStatusButton from "../../components/Button/InventoryStatusButton";
import StatusSwitch from "../../components/Button/StatusSwitch";
import { NewToggleSwitch } from "../../components/FormElement/ToggleButton";
import { SymbolTextBox } from "../../components/Input/SymbolTextbox";
import { compLabel, components } from "../../components/SourceTypeRecord/SourceTypeHeader";
import { CoustomTableHeaderProps } from "../../components/Table/CoustomTable";
import { states } from "../SourceType/SourceTypeRecord";
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../stores/store";
import { useState } from "react";
import TitlePath, { Page } from "../../components/TitlePath/TitlePath";



interface Props {
  buttonChoice:string
  statusState:string

    lastPages?:Page[]
    title:string
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    setStatusState: (x: string) => void;
    isInfoTableHeader?: boolean;
    state:string
}

export default observer(function LoggerSetHeader({statusState, buttonChoice, state, title,lastPages, isInfoTableHeader, setStatusState, setState, setButtonChoice, options, statesArray }: Props) {

    const navigate = useNavigate();
    const { loggerSetStore } = useStore();

    const [filterPlaceholder,ChangePlaceHolder] = useState("Filter Equipment Name"); 

    const createLoggerset = () => {
        navigate('/createLoggerset');
    }

    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    }

    const createButton = { title: 'Create New Logger Set', imgSrc: '/assets/button/recAdd.png', Click: createLoggerset }


    return (
        <div className=' flex flex-col gap-4   '>
            <TitlePath currentTitle={title} lastPages={lastPages} />
            <div className='flex  gap-3 lg:flex-row flex-col justify-between '>
                <div className='flex' >
                    <SymbolTextBox buttonChoice={buttonChoice} changePlaceholderValue={ChangePlaceHolder} options={options} IconLeft={filterProps.IconLeft} IconRight={filterProps.IconRight} placeholder={filterProps.placeholder} onChange={(e) => setState(e.target.value)} statesArray={statesArray} setButtonChoice={setButtonChoice} defaultValue={state}/></div>

                    {(isInfoTableHeader == undefined || isInfoTableHeader == false) &&
                        <div className="flex  gap-3 md:flex-row flex-col">
                            <StatusSwitch isLoggerset={true} setStatusState={setStatusState} statusState={statusState}/>
                             <GeneralButton title={createButton.title} imgSrc={createButton.imgSrc} handleClick={createButton.Click} />
                        </div>
                    }

            </div>
        </div>
    )
})
