import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { UserFormValue } from "../../models/User";
import { ChangeEvent, useState } from "react";
import { Message } from "semantic-ui-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { LoginInput } from "../../components/LoginElement/LoginInput";
import LoginIcons from "../../components/LoginElement/LoginIcons";
import LoginButton from "../../components/LoginElement/LoginButton";
import LoginCheckbox from "../../components/LoginElement/LoginCheckbox";
import LoadingComponent from "../../components/LoadingComponent";


export default observer(function LoginForm() {
    const { userStore } = useStore();
    const [loginObj, setLoginObj] = useState<UserFormValue>({
        email: '',
        password: '',

    });
    const [ClientError, setClientError] = useState<Partial<UserFormValue>>({});
    const [ServerError, setServerError] = useState("");

    function handelSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var err = handleValidation(loginObj);

        if (Object.keys(err).length !== 0) {
            console.log("client side error")
            return;
        } else {
            userStore.login(loginObj).catch((error) => {
                setServerError("Invalid email or password");
            });
        }
    }

    function handleInput(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        setLoginObj({ ...loginObj, [name]: value })  //name='title'  >>> change activity.title
    }

    function handleValidation(userObj: UserFormValue) {
        const errors: Partial<UserFormValue> = {}
        if (!userObj.email) {
            errors.email = "The Email is empty"
        }
        if (!userObj.password) {
            errors.password = "The Password is empty"
        }
        setClientError(errors);
        return errors;
    }

    if(userStore.loadingInitial){
        return <LoadingComponent /> 
    }
    return (

        <div className=" h-full flex justify-center items-center "  >
            <div className='w-[350px]'>
                <form onSubmit={handelSubmit} autoComplete="off" >
                    <div className="flex flex-col gap-4">
                        <LoginIcons />
                        <span className="font-medium">
                            Welcome back, good to see you again
                        </span>

                        <LoginInput title={"Email Address"} name={"email"} value={loginObj.email} record={loginObj} setRecord={setLoginObj} />
                        <LoginInput title={"Password"} name={"password"} value={loginObj.password} record={loginObj} setRecord={setLoginObj} isPassword={true} />

                        <div className="flex justify-between items-center">
                            <LoginCheckbox title={"Remember me"} />

                            <Link to={"/loginPage/forget-password"}>
                                <span className="text-[13px] text-[#116DAA]">
                                    Forgot your password?
                                </span>
                            </Link>
                        </div>
                        <LoginButton isSubmit={true} title={"Log In"} />

                        <div>
                            <span className="text-[13px] text-trans-gray ">
                                Are you interested in learning more about Castco IoT?
                            </span><br />
                            <Link to={"https://www.castco.com.hk/eng/contact-us"}>
                                <span className="cursor-pointor text-[13px] text-[#116DAA]">Contact Us</span>

                            </Link>
                        </div>
                        {/* <Link to={"/loginPage/register"}>
                            <button className="btn btn-success w-full " >Register</button>
                        </Link>
                        

                        <Link to={"/loginPage/reset-password"}>
                            <button className="btn btn-secondary w-1/2 " >Reset</button>
                        </Link> */}

                    </div>
                </form>
                {ServerError &&
                    <Message error>
                        {ServerError}
                    </Message>}

            </div>



        </div>
    )
})
