import ReactDOM from "react-dom";
import { CONTENT_STYLES, MODAL_STYLES, OVERLAY_STYLES } from "../../../LoggerSet/LoggerInfo/LoggerInfoTable";
import { AlarmInfoForm } from "../../../Project/AlarmInfo/AlarmInfoForm";
import { Alarm, CustomInventoryAlarm, CustomInventoryAlarmValue, IAlarmError, IAlarmLevelError, LocationFormAlarmVal } from "../../../../../models/Alarm";
import { RoundButton } from "../../../../Button/RoundButton";
import { useEffect, useState } from "react";
import { DefaultTickButton } from "../../../../Button/DefaultTickButton";
import SummaryStore from '../../../../../stores/SummaryStore';
import { useStore } from "../../../../../stores/store";

interface ComponentSetProps {
    //in place of this there could be an inventory (which will then be used to retrieve a list of sourceTypeIds)
    type: string;
    cancelOption?: boolean;
    //count: number;
    onCancel?: (x: number) => void;
    handleClick?: () => void;
    alarmlist: Alarm[];
    DefaultRecord: Alarm[];

    localRecord:LocationFormAlarmVal
    setlocalRecord:(x:LocationFormAlarmVal) =>void

    errors:Partial<IAlarmError>[]
}

export default function InventoryAlarmInfoLevel(p: ComponentSetProps) {

    const { summaryStore } = useStore();
    const { LoadingAlarmCondi, conditions } = summaryStore

    useEffect(() => {
        LoadingAlarmCondi();
    }, [])
    


    return (

        <div>

            {p.alarmlist.length < 1 &&
                <div className="h-[600px] flex items-center justify-center"><span className="text-[#CC311B]">No Default Source Type Alarms for this Inventory</span></div>
            }
            <div className="">
                {p.alarmlist.map((x, i) =>
<div className="mb-2">
                    <AlarmInfoForm
                        options={conditions}
                        key={i}
                        sourceTypeId={x.sourceTypeId}
                        record={p.localRecord}
                        alarmIndex={i}
                        setRecord={p.setlocalRecord} type={p.type}
                        defaultRecord={p.DefaultRecord}
                        defaultCheckbox={true}
                        validationFunction={p.errors[i]}
                    />
</div>
                )}
            </div>
            <div className="w-[944px] mt-[20px]"><span className="text-[#CC311B]">**Please note that:</span> <span>The alarm record of the source type will be saved after the Location Record is created or edited.</span></div>
           
        </div>
    )

}
