import { useEffect, useState } from "react";
import { LocationMarker } from "../../../../models/Pharse2/LocationMap";
import {
	MapContainer,
	TileLayer,
	useMap,
	Marker,
	Popup,
	Tooltip
} from 'react-leaflet'
import './leaflet.css'
import { LatLngExpression, LatLngTuple } from "leaflet";
interface Prop {
	locationMarkerList: LocationMarker[]
	handleMarkersClick: (id: number, id1: number) => void
}

export default function NewMap({ locationMarkerList, handleMarkersClick }: Prop) {

	const [satelliteOpt, setSatelliteOpt] = useState<{ satellite: any, map: any }>();
	const [satelliteMode, setSatelliteMode] = useState(true);
	const L = require("leaflet");
	const findInitialCenter = (markers: LocationMarker[]) => {
		if (markers.length === 0) {
			return [22.31163421362307, 113.91598196464943]
		}
		let i = 1
		for (let marker of markers) {
			if (marker.latitude === undefined || marker.longtitude === undefined) continue
			if (marker.latitude > 5 && marker.longtitude > 5 && i !== markers.length) {
				return [marker.latitude, marker.longtitude]
			}
			if (i === markers.length) {
				return [marker.latitude, marker.longtitude]
			}
			i++;
		}
		return [22.31163421362307, 113.91598196464943];
	}
	useEffect(() => {


		delete L.Icon.Default.prototype._getIconUrl;

		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require("./images/marker-icon-2x.png"),
			iconUrl: require("./images/marker-icon.png"),
			shadowUrl: require("./images/marker-shadow.png")
		});
	}, []);
	function ChangeView({ center }: { center: number[] }) {
		const map = useMap();
		map.setView(center as LatLngExpression, 13);
		return null;
	}
	return (
		<MapContainer scrollWheelZoom={true} style={{ width: "100%", height: "800px" }}>
			<ChangeView center={findInitialCenter(locationMarkerList)} />
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{locationMarkerList.map((i) => {
				return (
					<Marker position={[i.latitude, i.longtitude]} key={i.locationId} icon={L.icon({ iconUrl: '/assets/markers/' + i.url, iconSize: [50, 42], iconAnchor: [25, 41] })} eventHandlers={{
						click: (e) => {
							console.log('marker clicked', e);
							handleMarkersClick(i.locationId, i.projectId)
						},
					}} >
						<Tooltip>
							{i.name}
						</Tooltip>
					</Marker>
				)
			})}
		</MapContainer>
	)
}