import { makeAutoObservable } from "mobx";
import { Dict, GraphForm, SelectListOption } from "../models/Pharse2/Graph";
import agent from "../services/Api/agent";
import { OptionContainer } from "../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";


export default class GraphStore {

    projectList: SelectListOption[] | undefined = undefined;
    loadingInitial: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    isGraphBadResult = (yData: number[][]) => {
        if (yData.length === 0) {
            return true
        }

        let isAllLength0 = true //check for each array, is it empty
        for (var array of yData) {
            if (array.length !== 0) {
                isAllLength0 = false
                break;
            }

        }
        if (isAllLength0) return true


        for (var array of yData) { // check all data inside each array
            for (var data of array) {
                if (data != null) {
                    return false
                }
            }
        }
        return true
    }
    getprojectList = async () => {
        this.loadingInitial = true

        try {

            const projects = await agent.Graph.projectList();
            this.loadingInitial = false
            return projects

        } catch (error) {
            this.loadingInitial = false
            throw error
        }
    }

    getlocationList = async (projectId: number) => {

        try {
            const locations = await agent.Graph.locationList(projectId);

            return locations;

        } catch (error) {

            throw error

        }
    }

    getsourceTypeList = async (locationId: number[]) => {
        var sourceTypes
        try {
            if (locationId.length === 1) {
                sourceTypes = await agent.Graph.sourceTypeList(locationId[0]);
            }
            else if (locationId.length > 1) {
                sourceTypes = await agent.Graph.sourceTypeList_InCommon(locationId);
            }
            return sourceTypes;
        } catch (error) {
            throw error
        }
    }


    getgraphData = async (graphForm: GraphForm) => {
        this.loadingInitial = true

        try {
            const graphDatas = await agent.Graph.getData2(graphForm);
            this.loadingInitial = false
            return graphDatas;
        } catch (error) {
            this.loadingInitial = false

            throw error
        }
    }

    postProcessingScript = (locationIds: number[], data: number[][], dict?: Dict):number[][] => {

        var idx = 0
        var newData: number[][] = []

        if (this.isGraphBadResult(data) || !dict) {
            return data
        }
        console.log(dict)
        for (var locId of locationIds) {
            if (dict[locId]) {
                let body = dict[locId].substring(dict[locId].indexOf("{") + 1, dict[locId].lastIndexOf("}"));
                let params = dict[locId].substring(dict[locId].indexOf("(") + 1, dict[locId].indexOf(")"));
                let convert = new Function(params, body);

                console.log(convert, dict[locId])

                var arr = []
                for (var d of data[idx]) {
                   // console.log(convert(d))
                    arr.push(parseFloat(convert(d)))
                }
                newData[idx] = arr
            }
            else {
                newData[idx] = data[idx]
            }
            idx++;
        }
        return newData;
    }

    handleGraphMapScript = (options: OptionContainer[], srcId: number) => {
        var option = options.find(x => x.id == srcId);
        if (!option) return undefined
        for (var subOption of option.subOptions) {
            if (subOption.value != null) {
                console.log({script:subOption.value, unit:subOption.unit})
                return {script:subOption.value, unit:subOption.unit}
            }
        }
        return undefined
    }

    handleOptionIsScriptedIdx = (options: OptionContainer[], srcId: number) => { // pick Scripted if can
        var option = options.find(x => x.id == srcId);
        if (!option) return undefined
        let idx = 0
        for (var subOption of option.subOptions) {
            if (subOption.value != null) {
                return Boolean(idx)
            }
            idx++
        }
        return false
    }

    calMax = (numbers: number[]) => {
        let maxYData = -Infinity;
            for (let num of numbers) {
                if (num > maxYData) maxYData = num;
                
            }
            return maxYData
    }
}