import ReactDOM from "react-dom";

import { AlarmInfoForm } from "../../../Project/AlarmInfo/AlarmInfoForm";
import { Alarm, CustomInventoryAlarm, CustomInventoryAlarmValue, IAlarmError, IAlarmLevelError, LocationFormAlarmVal } from "../../../../../models/Alarm";
import { RoundButton } from "../../../../Button/RoundButton";
import { useEffect, useState } from "react";
import { DefaultTickButton } from "../../../../Button/DefaultTickButton";
import SummaryStore from '../../../../../stores/SummaryStore';
import { useStore } from "../../../../../stores/store";
import InventoryAlarmInfoLevel from "./InventoryAlarmInfoLevel";
import InventoryAlarmInfoAudience from "./InventoryAlarmInfoAudience";
import ProjectTypeHeader, { TypeHeaderItem } from "../../../../ProjectRecord/ProjectTypeHeader/ProjectTypeHeader";
import { MODAL_STYLES } from "../../../LoggerSet/LoggerInfo/LoggerInfoTable";

interface ComponentSetProps {

    record: LocationFormAlarmVal;
    setRecord: (arr: {}) => void;
    type: string;
    cancelOption?: boolean;
    //count: number;
    onCancel?: (x: number) => void;
    handleClick?: () => void;
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
    alarmlist: Alarm[];
    DefaultRecord: Alarm[];
}

export default function InventoryAlarmInfo(p: ComponentSetProps) {
    const [blur, setBlur] = useState<boolean>(false);

    const OVERLAY_STYLES = {
    zIndex: 999,

        width: '100vw',
        height: '100vh',
        backgroundColor: ' rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
    };

    const CONTENT_STYLES = {
        zIndex: 1000,

        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '32px',
        boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        maxHeight: '100vh',
        maxWidth: '100vw',
        overflowY: 'auto',
        filter: blur ? 'blur(2px)' : 'none'
    };
    const [state, setState] = useState<number>(0);
    const [localRecord, setlocalRecord] = useState<LocationFormAlarmVal>(new CustomInventoryAlarmValue());

    useEffect(() => {
        console.log(" local record...")
        console.log(localRecord)
    }, [localRecord])

    const handlePresave = () => {
        p.setRecord(localRecord);
        p.setisOpen(false);
        setState(0);

    }
    const handleHeaderStateChange = (page: number) => {
        if (page === 1) {
            seterrors([]);
            var newError = InventoryAlarmValidation(localRecord);
            if (newError.length > 0) {
                for (var obj of newError) {
                    if (obj.alarmLevels) {
                        for (var obj2 of obj.alarmLevels) {
                            if (Object.keys(obj2).length !== 0) {
                                console.log(newError);
                                seterrors(newError);
                                return false;
                            }
                        }
                    }

                }
            }
            setState(1);
            return true;
        } else if (page === 0) {
            setState(0)
            return true;
        }
        else { return false }
    }
    const InventoryAlarmValidation = (record: LocationFormAlarmVal) => {

        var alarmErrorList: IAlarmError[] = []
        const errorString = "Input Field is Required!"

        if (record.alarm) {
            for (var recAlarm of record.alarm) {
                var alarmError: IAlarmError = {
                    alarmLevels: []
                };

                var newAlarm: Alarm = recAlarm;
                for (var alarmlvl of newAlarm.alarmLevels) {
                    var alarmLevelError: IAlarmLevelError = {}
                    if (alarmlvl.conditionId == undefined || alarmlvl.conditionId == "") {
                        alarmLevelError.conditionId = errorString
                    }
                    if (alarmlvl.value == undefined) {
                        alarmLevelError.value = errorString
                    }
                    alarmError.alarmLevels?.push(alarmLevelError)
                }
                alarmErrorList.push(alarmError)
            }
        }
        return alarmErrorList;
    }
    const [errors, seterrors] = useState<Partial<IAlarmError>[]>([]);


    const handleNext = () => {
        seterrors([])
        var newError = InventoryAlarmValidation(localRecord);
        if (newError.length > 0) {
            for (var obj of newError) {
                if (obj.alarmLevels) {
                    for (var obj2 of obj.alarmLevels) {
                        if (Object.keys(obj2).length !== 0) {
                            console.log(newError);
                            seterrors(newError);
                            return;
                        }
                    }
                }

            }
        }
        setState(1);

    }
    const handleCancel = () => {
        p.setisOpen(false);
        setState(0);
    }

    const handleBack = () => {
        setState(0);
    }
    const InventoryTypeHeaderAllItems: TypeHeaderItem[] = [
        { imgSrc: "/assets/ProjectTypeHeader/profile.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/profile.png", isSelected: false, title: "Alarm Setting" },
        { imgSrc: "/assets/ProjectTypeHeader/audiences.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/audiences.png", isSelected: false, title: "Audience Table" },
    ]

    useEffect(() => {
        setlocalRecord(p.record);

    }, [p.record])
    useEffect(() => {
        console.log(`useEffect called with isOpen: ${p.isOpen} and state: ${state}`);
        if (p.isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [p.isOpen]); //div className="pageParentDiv flex-col flex " //style={{ borderRadius: '20px', width: '1500px' }}

    if (p.isOpen == false) return null;
    return ReactDOM.createPortal(
        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} />
            <div style={CONTENT_STYLES as React.CSSProperties}>
                <div className="pageParentDiv " style={{ borderRadius: '20px', height: '800px' }}>
                    <div className="w-[550px] mb-4">
                        <ProjectTypeHeader page={state} changePage={handleHeaderStateChange} InventoryTypeHeaderAllItems={InventoryTypeHeaderAllItems} />
                    </div>
                    <div style={{ borderRadius: '20px', width: '1200px' }}  >
                        {state === 0 && <InventoryAlarmInfoLevel errors={errors} DefaultRecord={p.DefaultRecord} alarmlist={p.alarmlist} type={''} localRecord={localRecord} setlocalRecord={setlocalRecord} />
                        }
                        {state === 1 && <InventoryAlarmInfoAudience localRecord={localRecord} setlocalRecord={setlocalRecord} alarmlist={p.alarmlist} tableBlur={blur} setTableBlur={setBlur} />}

                    </div>
                    <div className="flex gap-16 items-center justify-center h-[191px] w-full">

                        <RoundButton type="button" handleClick={state === 0 ? handleNext : handlePresave} title={state === 0 ? "Next" : "Pre-save"} backgroundColor={"#15304B"} border={"#15304B"} textColor={"#FFF"} />
                        <RoundButton type={"button"} handleClick={handleCancel} title={"Cancel"} backgroundColor={"#FFF"} border={"#15304B"} textColor={"#15304B"} />
                    </div>
                </div>

            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    )
}
