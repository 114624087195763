import React, { ChangeEvent, useState } from 'react'
import GeneralButton from '../Button/GeneralButton'
interface Props<T extends { [key: string]: any }> {
  btnName?: string;
  title?: string;
  name: string;
  isRequired?: boolean;
  setRecord: (input: T) => void;
  record: T;
  error?: string;
  url?: string;
}


export const UploadPhotoElement = <T extends { [key: string]: any }>({ url, btnName, name, title, isRequired, setRecord, record, error }: Props<T>) => {

  let filename;
  if (url) {
    const Url = new URL(url);
    const pathname = Url.pathname;
    filename = pathname.split("/").pop();
    if (filename) {
      filename = decodeURIComponent(filename);

    }
  }

  // const [file, setFile] = useState<File | null>(null);
  function handleFile(event: ChangeEvent<HTMLInputElement>) {

    if (event.target.files) {
      const { name, value } = event.target;

      const file = event.target.files[0]
      setRecord({ ...record, [name]: file })
    }


  }
  function handleDownload(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const file = record[name] as File;
    if (file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(url);
    }
  }

  function handleUrlDownload(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (url) {
      const link = document.createElement('a');
      link.href = url;

      const Url = new URL(url);
      const pathname = Url.pathname;
      const filename = pathname.split("/").pop() || "default";
      link.download = filename;
      link.click();

    }
  }


  function handleRemove() {
    setRecord({ ...record, [name]: null });
  }
  function handleUrlRemove(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    var newName: string = name + "Url"
    setRecord({ ...record, [newName]: undefined });
  }

  return (
    <div className='flex flex-col'>
      <div className='mb-2'>
        {title &&
          <label className="w-full">
            <p className={`${isRequired ? "text-[#CC311B]" : ""}`}>
              {isRequired && "*"}
              {title}
            </p>
          </label>}
      </div>
      <div className="flex justify-between items-center">
        <label className='w-[156px] py-2 px-[25px] cursor-pointer  rounded-lg bg-theme-orange flex items-center justify-center'>
          <input name={name} type='file' style={{ display: 'none' }} onChange={handleFile} />
          <span className='whitespace-nowrap text-white'>
            {btnName ? btnName : "Upload Photo"}
          </span>
        </label>

        {record[name] &&
          <div className="flex gap-3">
            <div>
              <p>{(record[name] as File).name}</p>
            </div>
            <button className="hover:underline hover:text-[#CC311B]" type="button"
              onClick={handleDownload}>
              <p className="hover:text-[#CC311B]">Download</p>
            </button>
            <button className="hover:underline hover:text-[#CC311B]" onClick={handleRemove}>
              <p className="hover:text-[#CC311B]">Remove</p>
            </button>
          </div>
        }

        {!record[name] && url &&
          <div className="flex gap-3">
            <div>
              <p>{filename}</p>
            </div>
            <button className="hover:underline hover:text-[#CC311B]"
              onClick={handleUrlDownload}>
              <p className="hover:text-[#CC311B]">Download</p>
            </button>
            <button className="hover:underline hover:text-[#CC311B]" onClick={handleUrlRemove}>
              <p className="hover:text-[#CC311B]">Remove</p>
            </button>
          </div>
        }

      </div>
    </div>
  )
}
