import React, { useState, useEffect } from 'react'
import { GraphData, GraphForm} from '../../models/Pharse2/Graph'
import ProjectTypeHeader, { TypeHeaderItem } from '../../components/ProjectRecord/ProjectTypeHeader/ProjectTypeHeader'
import { useStore } from '../../stores/store'
import LoadingComponent from '../../components/LoadingComponent'
import { observer } from 'mobx-react-lite'
import { LineGraph } from './Plots2/LineGraph'
import { BarGraph } from './Plots2/BarGraph'
import GraphBadResult from './GraphBadResult'
import { HexColorPicker } from "react-colorful";
import AlertLineConfig from './AlertLineConfig'
import TableVisualization from './TableVisualization'
import LocationNameConfig from './LocationNameConfig'
import TimeIntervalConfig from './TimeIntervalConfig'

interface Prop {
	record?: GraphData
	isMap?: boolean
	localForm: GraphForm
}

export default observer(function GraphVisualization({ isMap, record, localForm}: Prop) {
	const [page, setPage] = useState(0);
	const { graphStore } = useStore();
	const [customMaxY,setCustomMaxY] = useState<number|undefined>(undefined);
	const [showStyle, setShow] = useState("hidden")
	/*Alert Line color*/
	const [colorvalue, setColorValue] = useState("")
	const [stateColor, setColor] = useState(['#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FF0000'])
	const colorList = stateColor
	/*Graph Line type*/
	const [stateType, setType] = useState(["solid", "solid", "solid", "solid", "solid", "solid", "solid"]);
	const typeList = stateType
	/*Graph Line color*/
	const [graphColorValue, setGraphColorValue] = useState("")
	const [stateGraphColor, setGraphColor] = useState(['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'])
	const graphColorList = stateGraphColor
	const [updateDummy, setDummy] = useState(0); /*If anything to update the graph, call this*/
	const [showDisplay, setDisplay] = useState(false)
	const [displayImgSrc, setImgSrc] = useState("/assets/button/Password/visible.png")
	const [showTable, setTable] = useState<boolean|undefined>(undefined)	/*Project 70 only*/
	/*For getting generated element ID*/ 
	var locationCount = 200
	var alertCount = 0
	/*Tool default status*/ 
	var enableAlertConfig:Boolean = false
	var enableTimeConfig:Boolean = false
	var enableNameConfig:Boolean = false

	const { userStore, commonStore } = useStore();
	const { user, CheckIfHkiaEmail } = userStore;

	const toolConditioning = () =>{
		if(user?.role === 'Project70Admin'){
			enableAlertConfig = true
			enableTimeConfig = true
			enableNameConfig = true
			if (record !== undefined && (record.alarmLevels === undefined || record.alarmLevels.length === 0)){
				record.alarmLevels = [{alarmId: NaN, value: NaN, name:"", level: NaN, conditionId:NaN}]
			}
		}
		if(user?.role === 'Project70User'){
			enableTimeConfig = true
		}
	}

 	useEffect(() => {
		try {
			let temp = JSON.parse(localStorage.getItem(`graphPref`)!)[localForm.projectId]
			if (temp !== undefined) {
				setPage(temp)
			}
			else { setPage(0) }
		}
		catch {
		}
	}, [localForm.projectId])

	const changeShow= () =>{
		if (showStyle !== "hidden")setShow("hidden")	
		else setShow("display absolute mt-[7%]")
	}
	const dummyadd = () =>{
		setDummy(updateDummy+1)
	}

	const changeDisplayValue = () =>{
		if (showDisplay !== false){setDisplay(false);setImgSrc("/assets/button/Password/visible.png")}
		else {setDisplay(true);setImgSrc("/assets/button/Password/invisible.png")}
	}

	const changePage = (newPage: number) => {
		console.log(`changePage`)
		setPage(newPage);
		let currentPrefString = localStorage.getItem("graphPref");
		let currentPref: any = {}
		if (currentPrefString !== null) {
			currentPref = JSON.parse(currentPrefString)
		}
		currentPref[localForm.projectId] = newPage
		localStorage.setItem("graphPref", JSON.stringify(currentPref))
		return true
	}
	const InventoryTypeHeaderAllItems: TypeHeaderItem[] = [
		{ isSelected: false, title: "Line Graph", imgSrc: "/assets/ProjectTypeHeader/line.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/line.png" },
		{ isSelected: false, title: "Bar Chart", imgSrc: "/assets/ProjectTypeHeader/bar.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/bar.png" },

	]

	toolConditioning()
	if (graphStore.loadingInitial && !isMap)
		return <LoadingComponent content="Loading activity..." />;
	if (record === undefined)
		return <GraphBadResult content={"No record"} />
	if (record && graphStore.isGraphBadResult(record.yData))
		return <GraphBadResult content={"No data logged in this period"} />
	return (
		<div className="flex flex-col mt-8">
			<div className="min-[1000px]:ml-8 w-full min-[860px]:w-[550px] flex flex-row">
				<ProjectTypeHeader page={page} changePage={changePage} InventoryTypeHeaderAllItems={InventoryTypeHeaderAllItems} />
				<div className="min-[1000px]:ml-8 w-full min-[860px]:w-[550px] flex flex-row">
					<input value={customMaxY} onChange={(e)=>{setCustomMaxY(parseInt(e.target.value));}} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full flex flex-row p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter max y" />	
					<img src="/assets/button/dropper.png" alt="" className="h-[40px] w-[40px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block flex flex-row p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					onClick={changeShow}/><img src={displayImgSrc} alt="" className="h-[40px] w-[40px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block flex flex-row p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					onClick={changeDisplayValue}></img>
				</div>
			</div>
			{(enableTimeConfig === true)&&
			<TimeIntervalConfig dummyadd={dummyadd} setTable={setTable} record={record} localForm={localForm}/>
			}
			{(enableNameConfig === true)&&
			<LocationNameConfig record={record} dummyadd={dummyadd} localForm={localForm}/> 
			}{/*Project 70*/}
			<div className=' w-full min-h-[700px] flex justify-center pt-[60px]' >

				{(page === 0) &&
					<LineGraph setDummy={setDummy} localForm={localForm} record={record} customMaxY={customMaxY} stateGraphColor={stateGraphColor} stateColor={stateColor} stateType = {stateType} updateDummy = {updateDummy} showDisplay = {showDisplay}/>
				}
				{
					page === 1 &&
					<BarGraph setDummy={setDummy} localForm={localForm} record={record} customMaxY={customMaxY} stateGraphColor={stateGraphColor} stateColor={stateColor} stateType = {stateType} updateDummy = {updateDummy} showDisplay = {showDisplay}/>
				}	
			</div>
			<div className={showStyle}>  {/*Line colors modification*/ }
				<div className="float w-[100%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 w-full dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
					{record.locationName?.map((location)=>{return <>
					<div>
						<span><b>{location + ":"}</b></span>
						<HexColorPicker id={(locationCount++).toString()} onChange={setGraphColorValue} onClick={e=>
							{graphColorList[Math.floor(parseInt(e.currentTarget.id)-200)] = graphColorValue ; setGraphColor(graphColorList);dummyadd();}}></HexColorPicker>						
					</div>
					</>})}
				</div>
				<div>
					{record.alarmLevels?.map((alarmlevel)=>{return <>
						<div className="float-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
							<span>{record.alarmUnit? alarmlevel.name + ` (${record.alarmUnit})`: alarmlevel.name}:</span>
							<HexColorPicker id={(alertCount++).toString()} onChange={setColorValue} onClick={e=>
								{colorList[Math.floor(parseInt(e.currentTarget.id)/3)] = colorvalue ; setColor(colorList);dummyadd();}}></HexColorPicker>						
							<select id={(alertCount++).toString()} onChange={e=>{typeList[Math.floor(parseInt(e.currentTarget.id)/3)] = e.target.value; setType(typeList);dummyadd()}}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
								<option value="solid">Solid</option>	
								<option value="dotted">Dotted</option>
								<option value="dashed">Dashed</option>
							</select>
							{(enableAlertConfig === true) &&
							<AlertLineConfig dummyadd={dummyadd} record={record} id={alertCount++} projectId={localForm.projectId}/> 
							}{/*Project 70*/}
						</div>
					</>})}
				</div>
			</div>
			{(showTable === true) &&
			<TableVisualization updateDummy={updateDummy} stateColor={stateColor} stateGraphColor={stateGraphColor} localForm={localForm} record={record}/>
			}
		</div>
		
	)
})
