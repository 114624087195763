import { FormEvent, useCallback, useEffect, useState } from "react";
import { InputElement } from "../../FormElement/InputElement";
import { SelectElement } from "../../FormElement/SelectElement";
import { TextAreaElement } from "../../FormElement/TextAreaElement";
import GeneralButton from "../../Button/GeneralButton";
import { LoggerInfoTable } from "./LoggerInfo/LoggerInfoTable";
import { RoundButton } from "../../Button/RoundButton";
import { useNavigate } from "react-router-dom";
import { ILoggerSetError, LoggerSetErrorValue, LoggerSetFormValue } from "../../../models/LoggerSet";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { Inventory, InventoryDto, InventoryForm, LoggerFormInventoryValue } from "../../../models/Inventory";
import LoggerSetFormValidation from "../validations/LoggerSetFormValidation";
import { runInAction } from "mobx";
import SummaryStore from './../../../stores/SummaryStore';
import PhotoElement from "../../PhotoElement/PhotoElement";



export interface ComponentSetProps {
    type:string
    inventory: Partial<InventoryDto>
    event?: FormEvent<HTMLFormElement>;
}

export const ComponentSet = observer(({ type, inventory, event }: ComponentSetProps) => {


    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const { inventoryStore, loggerSetStore } = useStore();
    const { LoadingInventory, InventoryTypes, LoadingInventoryType } = inventoryStore


    return (
        <>

            <div>
                <div className="w-full h-[320px] flex  gap-4 mb-5 mt-3">
                    <div className=" h-full w-[30%]">
                        <InputElement  clickonly={true} value={inventory.equipmentName}  title={`${type + " Name"}`} name={"equipmentName"} isRequired={true} setRecord={(()=>{})} record={""} />
                        <PhotoElement src={inventory.equipment} showPhoto={inventory.equipment!=undefined} />
                    </div>

                    <div className=" h-full w-[70%] border-[12px] border-gray-400 rounded-xl  " style={{ borderRadius: '20px' }}>
                        <div className="w-full h-full flex  gap-4 items-center justify-center ">
                            <div className="w-[50%]  flex flex-col gap-4 ">
                                <InputElement readonly={true} value={inventory.equipmentName} title={`${type + " Name"}`} name={"equipmentName"} isRequired={false} setRecord={()=>{}} record={""} />
                                <InputElement readonly={true} value={inventory.serialNo} title={"Serial No."} name={"serialNo"} isRequired={false} setRecord={()=>{}} record={""} />
                                <InputElement readonly={true} value={inventory.manufacturerName} title={"Manufacturer Name"} name={"manufacturerName"} isRequired={false} setRecord={()=>{}} record={""} />
                            </div>
                            <div className="w-[50%]  flex flex-col gap-4 ">
                                <InputElement readonly={true} value={inventory.equipmentCode} title={"Equipment ID"} name={"equipmentCode"} isRequired={false} setRecord={()=>{}} record={""} />
                                <InputElement readonly={true} value={inventory.modelNo} title={"Model No. "} name={"modelNo"} isRequired={false} setRecord={()=>{}} record={""} />
                                <InputElement readonly={true} value={inventory.supplierName} title={"Supplier Name"} name={"supplierName"} isRequired={false} setRecord={()=>{}} record={""} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
})