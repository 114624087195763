import { useEffect, useState } from "react";
import AMapLoader from '@amap/amap-jsapi-loader';
import { LocationMarker } from "../../../../models/Pharse2/LocationMap";
import { wgs84togcj02 } from "../../../../services/Map/gcj02wgs84";
import './LoggersetMap.css';
interface Prop {
    locationMarkerList: LocationMarker[]
    handleMarkersClick: (id: number, id1: number) => void
}

const findCenter = (markers: LocationMarker[]) => {
    if (markers.length === 0) {
        return [116, 24]
    }
    for (var marker of markers) {
        if (marker.latitude > 5 && marker.longtitude > 5) {
            return [marker.longtitude, marker.latitude]
        }
    }
    return [markers[0].longtitude, markers[0].latitude];

}
export default function LoggersetMap({ locationMarkerList, handleMarkersClick }: Prop) {

    const [satelliteOpt, setSatelliteOpt] = useState<{ satellite: any, map: any }>();
    const [satelliteMode, setSatelliteMode] = useState(true);

    useEffect(() => {
        if (true) {

            (window as any)._AMapSecurityConfig = {
                securityJsCode: '878aafec7318cd1bf101535c50a05380',
            };

            AMapLoader.load({
                key: '9bc7d341c9456f4cf26eb06555f1c427',
                version: '2.0',
                plugins: ['AMap.Driving', 'AMap.Marker', 'AMap.Polyline', 'AMap.GraspRoad'],
            }).then((AMap) => {

                const map = new AMap.Map('container', {
                    zoom: 14,
                    center: findCenter(locationMarkerList),
                    features: ['bg', 'road', 'building', 'point']
                });
                var satellite = new AMap.TileLayer.Satellite({

                    map: map,
                })
                //satellite.setMap(null);
                setSatelliteOpt({ satellite: satellite, map: map })

                locationMarkerList.map((i) => {
                    const marker = new AMap.Marker({
                        map: map,
                        position: wgs84togcj02(i.longtitude, i.latitude),
                        icon: '/assets/markers/' + i.url,
						anchor: 'bottom-center'
                    });

                    // Create a label as a custom overlay.
                    const labelContent = `<div class="marker-label">${i.loggersetName} <br/>(${i.name})</div>`;
                    const label = new AMap.Marker({
                        content: labelContent,
                        offset: new AMap.Pixel(45, -10),
                        position: marker.getPosition(),
                        map: map,
                        bubble: true
                    });

                    map.on('zoomchange', function () {
                        var zoomLevel = map.getZoom();
                        var markerSize = zoomLevel > 10 ? new AMap.Size(40, 40) : new AMap.Size(30, 30);
                        marker.setOffset(markerSize);
                    });

                    // Initially, set the label to be invisible.
                    label.hide();

                    // Show the label on marker hover.
                    marker.on('mouseover', () => {
                        label.show();
                    });

                    // Hide the label when no longer hovering over the marker.
                    marker.on('mouseout', () => {
                        label.hide();
                    });

                    marker.on('click', () => handleMarkersClick(i.locationId, i.projectId));
                    return marker;
                });

            });
        }
        //     locationMarkerList.map((i) => {
        //         const marker = new AMap.Marker({
        //         map: map,
        //         position: [i.southLatitude, i.northLatitude],
        //         icon: '/assets/markers/' + i.url


        //                         })
        //                         marker.setLabel(
        //                             {
        //                                 offset: new AMap.Pixel(-15, 20),
        //                                 content: `<div>${i.loggersetName } (${i.locationCode})</div>`,
        //                                 direction: 'right'
        //                             }
        //                         )

        //                         marker.on('click',()=>handleMarkersClick(i.locationId, i.projectId) );
        //                         return marker;
        //                     })
    }, [locationMarkerList]);

    const showSatellite = () => {
        if (satelliteMode) {
            satelliteOpt?.satellite.setMap(null);
            setSatelliteMode(false)
        } else {
            satelliteOpt?.satellite.setMap(satelliteOpt.map);
            setSatelliteMode(true)
        }
    }


    return (
        <div className="relative " style={{ width: "100%", height: '800px' }}>
            <div id="container" className="w-full h-full "></div>
            <div className="absolute bottom-4 right-4 bg-white p-4 rounded-md shadow-lg z-10">
                <button className="text-lg font-bold w-full h-full" onClick={showSatellite}>{satelliteMode ? <span>Satellite View</span> : <span>General View</span>}</button>
            </div>
        </div>


    )
}