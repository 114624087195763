import React, { useEffect, useRef, useState } from 'react'

import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { ThemeProvider } from '@mui/material/styles';
import SourceTypeHeader from '../../components/SourceTypeRecord/SourceTypeHeader';
import { useStore } from '../../stores/store';
import SourceTypeStore from '../../stores/SourceTypeStore';
import { observer } from 'mobx-react-lite';
import LoadingComponent from '../../components/LoadingComponent';
import { Page } from '../../components/TitlePath/TitlePath';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ServerErrorMessage from '../../components/Message/Error/ServerErrorMessage';
import { sortingAlgor } from '../../hook/useSorting';


export interface states {
  currentstate: string;
  statemethod: (state: string) => void;
}

export interface SourceType {
  id: string;
  sourceType: string;
  unit: string;
  access: string;
  remark: string;
  status: string;
}




export default observer(function SourceTypeRecord() {
  const params = new URLSearchParams(window.location.search);
  const page_FromUrl = params.get('page');
  const sourceState_FromUrl = params.get('sourceState');
  const unitState_FromUrl = params.get('unitState');
  const buttonChoice_FromUrl = params.get('buttonChoice');
  const activeState_FromUrl = params.get('activeState');

  const [page, setPage] = useState(Number(page_FromUrl) ?? 0);

  const [sourceState, setSourceState] = useState(sourceState_FromUrl ?? "");
  const [unitState, setUnitState] = useState(unitState_FromUrl ?? "");
  const [activeState, setactiveState] = useState<string>(activeState_FromUrl??"");
  const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "sourceType");
  const [excelRecord, setexcelRecord] = useState<SourceType[]>([]);
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

  const { sourceTypeStore } = useStore();
  const { LoadingSourceType, SourceTypes, loadingInitial, DeleteSourceTypes } = sourceTypeStore;
  const [Results, setResult] = useState<SourceType[]>([])
 
  const final_setactiveState=(state:string)=>{
    setactiveState(state)
    setPage(0)
  }
  const final_setButtonChoice =(state:string) =>{ //cant use useEffect
    setButtonChoice(state)
    setPage(0)
  }


  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', String(page));
    params.set('sourceState', sourceState);
    params.set('unitState', unitState);
    params.set('buttonChoice', buttonChoice);
    params.set('activeState', activeState);

    if (buttonChoice === 'sourceType') {
      params.set('sourceState', sourceState);
    } else if (buttonChoice === 'unitState') {
      params.set('unitState', unitState);
    }
    window.history.replaceState({}, '', `?${params.toString()}`);
  }, [page, sourceState, buttonChoice, unitState, activeState]);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("SourceTypes appended")

      try {
        await LoadingSourceType(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (SourceTypeStore) {
      fetchData();

    }
  }, [SourceTypeStore]);

 

  useEffect(() => {
    const updatedRows = Results.filter((item) => {
      return sourceState.toLowerCase() === "" ? item
        : item.sourceType.toLowerCase().includes(sourceState.toLowerCase());
    }).filter((item2) => {
      return unitState.toLowerCase() === "" ? item2
        : item2.unit.toLowerCase().includes(unitState.toLowerCase());
    }).filter((item3) => {
      return activeState.toLowerCase() === "" ? item3
        : item3.status.toLowerCase() === activeState.toLowerCase();
    }).map((record: SourceType) => ({

      data: [
        { id: "sourceType", data: record.sourceType },
        { id: "unit", data: record.unit },
        { id: "externalUserAccess", data: record.access },
        { id: "activeStatus", data: record.status },
      ],
      hiddenData: [],
      Id: record.id,
      ischecked: false
    }));

    setrows(updatedRows);
  }, [Results, sourceState, unitState, activeState]);

  useEffect(() => {

    const temp: SourceType[] = SourceTypes.map(
      (s) => ({
        id: String(s.id),
        sourceType: s.sourceType,
        unit: s.unit ? s.unit : "N/A",
        access: s.access ? "Yes" : "No",
        remark: s.remark,
        status: s.status ? "Active" : "Inactive"
      })
    )
    setResult(temp)

  }, [SourceTypes, SourceTypeStore])




  const statesArray: states[] = [
    {
      currentstate: sourceState,
      statemethod: setSourceState
    },
    {
      currentstate: unitState,
      statemethod: setUnitState
    },
    {
      currentstate: activeState,
      statemethod: setactiveState
    }
  ]


  const headers: Array<CoustomTableHeaderProps> = [
    { id: "sourceType", data: "Source Type" },
    { id: "unit", data: "Unit" },
    { id: "externalUserAccess", data: "External User Access" },
    { id: "activeStatus", data: "Status" }

  ];
  const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]

  const handleDeleteFunction = async (ids: string[]) => {
    console.log(ids);

    await DeleteSourceTypes(ids).then(
      (i) => {
        console.log("success")
        toast.success("success")
        window.location.reload()

      }
    ).catch(
      (error) => {
        console.error(error);
        const { data, status, config } = error.response as AxiosResponse;
        console.log(data)
        toast.error(
          <div>
            <ServerErrorMessage messageHeader={data[0]} messageList={data.slice(1)} />
          </div>
        );
      }
    )
  }

  //sorting

  const activeId = useRef<string | null>(null);
  const direction = useRef<'asc' | 'desc'>('asc');

  const onSortClick = () => {
    console.log(rows);
    setPage(0);
    sortingAlgor({ rows, setrows, direction, activeId });

  };

  //////////////////////



  if (loadingInitial)
    return <LoadingComponent content="Loading activity..." />;

  return (



    <div className="pageParentDiv">
      <SourceTypeHeader activeState={activeState} buttonChoice={buttonChoice} title="Search Sources Type Record" lastPages={lastPages} setactiveState={final_setactiveState} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} state={buttonChoice == "sourceType" ? sourceState : unitState} setState={buttonChoice == "sourceType" ? setSourceState : setUnitState} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "sourceType" || item.id == "unit")} />
      <ThemeProvider theme={customTableTheme}>

        <CoustomTable onSortClick={onSortClick} direction={direction} deleteFunction={handleDeleteFunction} page={page} setPage={setPage} subPath="sourceType" headers={headers} setRows={setrows} rows={rows} activeId={activeId} setexcelRecord={setexcelRecord} excelRecord={excelRecord} />

      </ThemeProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>


  )
}
)
