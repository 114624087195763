import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TitlePath, { Page } from '../TitlePath/TitlePath';
import { SymbolTextBox } from '../Input/SymbolTextbox';
import { CoustomTableHeaderProps } from '../Table/CoustomTable';
import { states } from '../../pages/SourceType/SourceTypeRecord';
import GeneralButton from '../Button/GeneralButton';


interface Props {
    state:string
    buttonChoice:string

    lastPages?:Page[]
    title:string
    
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];

}


export default function AccountRecordHeader({ state, buttonChoice, title,lastPages,setState, setButtonChoice, options, statesArray }: Props) {
   
    const navigate = useNavigate();

    const [filterPlaceholder,ChangePlaceHolder]=useState("Filter Name");

    const filterProps = {
        placeholder: filterPlaceholder,//
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",

    }
   
    const createAccount = () => {
        navigate('/account/create');
    }

    const createButton = { title: 'Create New Account', imgSrc: '/assets/button/recAdd.png', Click: createAccount }



    return (
        <div className='  flex pl-3 my-4 flex-col gap-4  '>
            <TitlePath currentTitle={title} lastPages={lastPages} />
           
            <div className='flex flex-col md:flex-row justify-between'>
                <div className='flex' >
                    <SymbolTextBox
                    changePlaceholderValue={ChangePlaceHolder}
                        buttonChoice={buttonChoice}
                        defaultValue={state}
                        options={options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={filterProps.placeholder}
                        onChange={(e) => setState(e.target.value)}
                        statesArray={statesArray}
                        setButtonChoice={setButtonChoice} />
                </div>
                <div className='flex'>
                <GeneralButton title={createButton.title} imgSrc={createButton.imgSrc} handleClick={createAccount} />
                   
                </div>
            </div>
        </div>
    )
}
