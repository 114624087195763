import { useEffect, useState } from "react";
import GeneralButton from "../../../Button/GeneralButton";
import { InputElement } from "../../../FormElement/InputElement";
import { useStore } from "../../../../stores/store";
import { observer } from "mobx-react-lite";
import { ProjectForm } from "../../../../models/Project";
import AlarmLevelComponent from "./AlarmLevelComponent";
import { Alarm, IAlarmError } from "../../../../models/Alarm";
import { DefaultTickButton } from "../../../Button/DefaultTickButton";
import LoadingComponent from "../../../LoadingComponent";

interface ComponentSetProps<T> {
    sourceTypeId: number
    record: T;
    setRecord: (arr: {}) => void;

    type: string;
    cancelOption?: boolean;
    //count: number;
    onCancel?: (x: number) => void;
    handleClick?: () => void;
    alarmIndex: number

    defaultCheckbox?: boolean;
    defaultRecord?: Alarm[];
    validationFunction?: IAlarmError;

    options: string[] | { value: number, name: string }[] | { id: number, name: string }[];
}


interface withAlarm {
    alarm?: Alarm[];
}


export const AlarmInfoForm = <T extends withAlarm>({options, validationFunction, defaultCheckbox, defaultRecord, sourceTypeId, alarmIndex, record, setRecord, type, cancelOption, onCancel, handleClick }: ComponentSetProps<T>) => {

    // const [components, setComponents] = useState([{}]);
    const { sourceTypeStore, summaryStore } = useStore();

    const components = record.alarm && record.alarm[alarmIndex] ? record.alarm[alarmIndex].alarmLevels : [{}];
    const { getSourceNameWithId } = sourceTypeStore
    const [Title, setTitle] = useState<string>("");
    const [isChecked, setisChecked] = useState(false);
    const [disabled, setdisabled] = useState(false);

    useEffect(() => {
        if (isChecked == true) {
            setRecord({ ...record, alarm: defaultRecord }) 
            setdisabled(true);
        } else {
            setdisabled(false);
        }
    }, [isChecked])



    useEffect(() => {
        var sourceName = getSourceNameWithId(sourceTypeId)
        setTitle(sourceName)
    }, [sourceTypeId])

    
   


    // useEffect(() => {
    //     setComponents(record.alarm && record.alarm[alarmIndex] ? record.alarm[alarmIndex].alarmLevels : [{}]);
    // }, [record])

    const addComponent = (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        if (record.alarm && record.alarm[alarmIndex].alarmLevels.length < 8) {
            console.log('Adding component', record);
            setRecord((prevRecord: ProjectForm) => {
                const updatedRecord = {
                    ...prevRecord,
                    alarm: prevRecord.alarm?.map((item, index) =>
                        index === alarmIndex ? {
                            ...item,
                            alarmLevels: [...item.alarmLevels, { level: item.alarmLevels.length + 1, conditionId: '', value: 0 }]
                        } : item
                    )
                };
                return updatedRecord;
            });
        }
    };



    const deleteComponent = (index: number, event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        console.log('Deleting component', record);
        setRecord((prevRecord: ProjectForm) => {
            const updatedRecord = {
                ...prevRecord,
                alarm: prevRecord.alarm?.map((item, idx) =>
                    idx === alarmIndex ? {
                        ...item,
                        alarmLevels: item.alarmLevels.filter((_, i) => i !== index),
                    } : item
                )
            };
            return updatedRecord;
        });
    };

    const firstColumn = components.slice(0, 4).map((_, index) => (
        //   <AlarmLevelComponent
        //     key={index}
        //     number={index+1}
        //     onDelete={() => deleteComponent(index)}
        //     showDelete={index !== 0}
        //   />
        <AlarmLevelComponent
            errors={validationFunction && validationFunction.alarmLevels ? validationFunction?.alarmLevels[index] : undefined}
            alarmIndex={alarmIndex}
            key={index}
            level={index + 1}
            setRecord={setRecord}
            record={record}
            leftOptions={options}
            leftName="conditionId"
            rightName="value"
            onDelete={(e) => deleteComponent(index, e)}
            disabled={disabled}
            showDelete={index !== 0} />


    ));

    const secondColumn = components.slice(4).map((_, index) => (

        <AlarmLevelComponent
            errors={validationFunction && validationFunction.alarmLevels ? validationFunction?.alarmLevels[index+4] : undefined}
            disabled={disabled}
            alarmIndex={alarmIndex}
            key={index + 4}
            level={index + 5}
            setRecord={setRecord}
            record={record}
            leftOptions={options}
            leftName="conditionId"
            rightName="value"
            onDelete={(e) => deleteComponent(index + 4, e)}
            showDelete={index + 4 !== 0} />
    ));

    
     

    return (


        <>
            <div className="h-[650px] w-full flex flex-col gap-4 border-[12px] border-gray-400 rounded-xl px-5 py-4">
                <div className="flex justify-between items-center">
                    <span className="text-[20px] font-medium text-decoration-line: underline">
                        {Title + " Alarm Setting"}
                    </span>

                    <div className="flex gap-2 items-center">
                        {defaultCheckbox &&
                            <div><DefaultTickButton isChecked={isChecked} setisChecked={setisChecked} /></div>
                        }
                        <GeneralButton title={"Add Alarm Level"} imgSrc={"/assets/button/circleAdd.png"} handleClick={(e) => addComponent(e)} />

                    </div>
                </div>

                <div className="flex">
                    <div className="w-[50%]">
                        {firstColumn}
                    </div>
                    <div className="w-[50%]">
                        {secondColumn}
                    </div>
                </div>

            </div>

        </>

    )
}

//may need editting for multiple sourceTypes-