import ReactDOM from "react-dom";
import { LocationFormValueDto } from "../../../../models/Location";
import LoggerSetMaintenance from "../../../../pages/LoggerSetMaintenance/LoggerSetMaintenance";
import { CONTENT_STYLES, MODAL_STYLES, OVERLAY_STYLES } from "../../LoggerSet/LoggerInfo/LoggerInfoTable";
import { useEffect, useState } from "react";

interface props {
    record:LocationFormValueDto;
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
    onSelectedIdsChange: (arr: string[]) => void;
    selectedProjectIds?: string[];
    selectedId?:string
  }
  

export default function LoggerSetInfo({selectedId, record,isOpen,setisOpen,onSelectedIdsChange,selectedProjectIds}:props) {
    const [selectedRow, setselectedRow] = useState<null | number>(null)
    
    useEffect(() => {
        if (isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          // Reset the filter state when the modal is closed.
        
          document.body.style.removeProperty('overflow');
        }
      }, [isOpen]);
      if (isOpen == false) return null;
    return ReactDOM.createPortal(
        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} />
            <div style={CONTENT_STYLES as React.CSSProperties}>
                <div style={{ borderRadius: '20px' }}>
                    <LoggerSetMaintenance isInfo={true} selectedId={selectedId} selectedRow={selectedRow} setselectedRow={setselectedRow} selectedProjectIds={selectedProjectIds} record={record} isOpen={isOpen} setisOpen={setisOpen} onSelectedIdsChange={onSelectedIdsChange} />
                </div>
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    )
}