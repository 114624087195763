import React, { useEffect, useRef, useState } from 'react';
import { load } from '@amap/amap-jsapi-loader';
import { components } from '../SourceTypeRecord/SourceTypeHeader';
import GeneralButton from '../Button/GeneralButton';

interface props {
  inputIDstring?: string;
  record?: {longtitude:number,latitude:number,address:string};
  setRecord?: (x:{longtitude:number,latitude:number,address:string})=> void;
  setSearchClick?: (x:boolean) => void;
  searchClick?: boolean;
  selectedId?: string;
}

// const Autocomplete = ({onSelect : (e: AMap.Event<"select">)=>void}) 
export default function MapComponent ({selectedId,inputIDstring,record,setRecord,setSearchClick,searchClick}:props){

  const [selectedLocation, setSelectedLocation] = useState<{location:string,longitude:number,latitude:number} | undefined>(undefined);
  const [selectedMarkerState, setselectedMarker] = useState<any>();
  var count=0;
 
  // var autoOptions = {
  //   input: inputIDstring,
  // }
  
  const mapRef = useRef(null);

  useEffect(() => {

    (window as any)._AMapSecurityConfig = {
      securityJsCode: '878aafec7318cd1bf101535c50a05380',
    };

    load({
      key: '9bc7d341c9456f4cf26eb06555f1c427',
      version: '2.0',
      AMapUI: {           
        version: '1.1',  
        plugins:['overlay/SimpleMarker'],   
            
      },
      Loca:{                
        version: '2.0'  
      },
      
      plugins: ['AMap.Geocoder','AMap.AutoComplete'] //,'AMap.AutoComplete'
    }).then((AMap) => {
      
      
      const map = new AMap.Map(mapRef.current, {
        lang: "en",
        zoom: 12,
        dragEnable: true,
        zoomEnable: true,
        center: [116.397428, 39.90923],
        resizeEnable: true,
        
        
      });
      
   
    var selectedMarker : any|null=null;
    
      // AMap.plugin('AMap.Geocoder', function() {//Suzhou Street, Haidian District, Beijing
        var geocoder = new AMap.Geocoder({ city: 'nationwide' }); //北京市朝阳区阜荣街10号 , 北京市东城区景山前街4号, 
        
      
        if(!searchClick){
          const handleClick = (e:any) => {
            console.log(e);
            const {lng,lat} = e.lnglat;
            geocoder.getAddress(e.lnglat,(status:string,result:any)=>{
              console.log(" inside click...")
              console.log(e.lnglat);
              console.log(new AMap.LngLat(lng,lat))
              console.log(lng,lat)
              if (status === 'complete' && result.info === 'OK') {
                // Retrieve the formatted address
                const address = result.regeocode.formattedAddress;
  
                setSelectedLocation({latitude:lat,longitude:lng,location:address})
                //map.setCenter([lng,lat]);
  
                // if(selectedMarker!=null){
                //   map.remove(selectedMarker)
                // }
  
                selectedMarker = new AMap.Marker({
                  position: new AMap.LngLat(lng, lat),
                  icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                  anchor: 'bottom-center',
                });
                //map.add(selectedMarker);
                setselectedMarker(selectedMarker)
                
              }
            })
          }
  
          map.on('click',handleClick);
        }

      // });
     
      // AMap.plugin('AMap.Geocoder',function(){
        //var geocoder = new AMap.Geocoder({ city: 'nationwide' }); 
        if(searchClick==true){
          
            if(record){
              const lnglat = new AMap.LngLat(record.longtitude,record.latitude);
              console.log(" inside search click...")
              console.log(lnglat)
              geocoder.getAddress(lnglat,(status:string,result:any)=>{
                if (status === 'complete' && result.info === 'OK') {
                  // Retrieve the formatted address
                  console.log(" inside search clickk..")
                  const address = result.regeocode.formattedAddress;
     console.log(address, [record.longtitude,record.latitude])
                  
                  if(selectedId && count==0){
                    setSelectedLocation({latitude:record?.latitude,longitude:record.longtitude,location:record.address})
                    count++;
                  }else{
                    setSelectedLocation({latitude:record?.latitude,longitude:record.longtitude,location:address})
                  }
                  // map.setCenter([record.lng,record.lat]);
                  console.log("working", map)
     
                  // if(selectedMarker!=null){
                  //   map.remove(selectedMarker) 
                  // }
     
              console.log("working")
              selectedMarker = new AMap.Marker({
                    position: new AMap.LngLat(record?.longtitude,record?.latitude),
                    icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                    anchor: 'bottom-center',
                  });
                  console.log("selectedMarker ",selectedMarker)
                  //map.add(selectedMarker);
                 setselectedMarker(selectedMarker)
                  
                }
              console.log("working")
              })
            }
          

        
         if(setSearchClick)setSearchClick(false)
       }
  
      // })

    var autoOptions = {
        input: inputIDstring
    };
    var auto = new AMap.Autocomplete(autoOptions);
    
    auto.on("select", function (event:any) {
      // Handle the selected record
      const selectedRecord = event.poi;
      if(setRecord){
        //setRecord({lng:selectedRecord.location.lng,lat:selectedRecord.location.lat,address:selectedRecord.name})
        setSelectedLocation({latitude:selectedRecord.location.lat,longitude:selectedRecord.location.lng,location:selectedRecord.name})
        // if(selectedMarker!=null){
        //   map.remove(selectedMarker) 
        // }

        selectedMarker = new AMap.Marker({
          position: new AMap.LngLat(selectedRecord.location.lng,selectedRecord.location.lat),
          icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
          anchor: 'bottom-center',
        });
        //map.add(selectedMarker);
        console.log("selectedMarket:Auto ",selectedMarker)
        setselectedMarker(selectedMarker)
        //map.setCenter([selectedRecord.location.lng,selectedRecord.location.lat] );
      }
    });

    if(selectedMarkerState){
      map.on("complete",function(){
        map.add(selectedMarkerState);
        map.setCenter([selectedLocation?.longitude,selectedLocation?.latitude] );
      });
    }
      
    });
  }, [searchClick,selectedMarkerState]);

  useEffect(()=>{
    console.log(" record inside map...")
    console.log(record)
  },[record])

  useEffect(()=>{
    console.log(" selectedLocation Changes ")
    console.log(selectedLocation);
    console.log(record)
  },[selectedLocation,record])


  useEffect(()=>{
    if(setRecord && selectedLocation!=undefined ){
      setRecord({longtitude:selectedLocation.longitude,latitude:selectedLocation.latitude,address:selectedLocation.location})
    }
  },[selectedLocation])

  


  return (
    <div className='flex'>
      <div id="map-container" ref={mapRef} style={{ height: '450px',width:'1009px' }}></div>
      
    </div>
  );
};

