import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import LoginForm from '../../Features/userForm/LoginForm';
import { useNavigate } from "react-router-dom";
import { bool } from 'yup';
export default function Login() {
	const location = useLocation();
	const navigate = useNavigate();
	let shoudlRedirect = localStorage.getItem("rememberMe") === "true" && localStorage.getItem("jwt") !== null;
	if (shoudlRedirect === true) { window.location.replace("/dashboard"); }
	return <>{shoudlRedirect === true ? <div></div> : 
	<div className="w-full h-screen flex m-0 p-0">

		<div className="w-full  bg-[#344B75]">

		</div>

		<div className="w-full min-[400px]:w-[500px] bg-white px-8">
			{location.pathname === '/' &&
				<>
					{<LoginForm />}
				</>
			}
			{location.pathname !== '/' &&

				<Outlet />

			}
		</div>


	</div>}</>


}
