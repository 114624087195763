import { useState } from "react";
import { states } from "../../pages/SourceType/SourceTypeRecord";
import { SymbolTextBox } from "../Input/SymbolTextbox";
import { CoustomTableHeaderProps } from "../Table/CoustomTable";

interface Props {
    buttonChoice:string

    title?: string;
    setState?: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options?: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    addMethod?: () => void
    placeholder: string;

}



const addImg = "/assets/button/orangeAdd.png"
export const InfoTableHeader = ({ buttonChoice, addMethod, placeholder, title, setState, setButtonChoice, options, statesArray }: Props) => {

    const [filterPlaceholder,setfilterPlaceholder] = useState("Filter Equipment ID");

    const filterProps = {
        placeholder:filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    
    }
    
    return (
        <div className=' w-full mx-3 my-3 flex flex-col gap-2'>
            {title && <p className="  font-md text-[20px]">
                {title}
            </p>}
            <div className='flex justify-between'>
                <div className='flex ' >
                    <SymbolTextBox
                    buttonChoice={buttonChoice}
                    changePlaceholderValue={setfilterPlaceholder}
                        options={options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={placeholder}
                        onChange={(e) => setState && setState(e.target.value)}
                        statesArray={statesArray}
                        setButtonChoice={setButtonChoice} />
                </div>
                
                {addMethod &&
                    <div className="cursor-pointer mr-[100px] flex flex-col justify-end" onClick={addMethod}>
                        <img src={addImg} />
                    </div>}

            </div>
        </div>
    )
}