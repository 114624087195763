import React, { useEffect, useRef, useState } from 'react'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../../../Table/CoustomTable';
import { ProjectType, states } from '../../../../../pages/Project/ProjectRecord';
import { useStore } from '../../../../../stores/store';
import { ProjectDto } from '../../../../../models/Project';
import ProjectHeader from '../../../../ProjectRecord/ProjectHeader';
import { ThemeProvider } from '@emotion/react';
import { Page } from '../../../../TitlePath/TitlePath';
import AssignTableHeader from './header/AssignTableHeader';

interface props {
    selectedIds: string[]
    setisOpen:(bool:boolean)=>void

}

export default function AssignedProjectTable({setisOpen, selectedIds }: props) {
    const [page, setPage] = useState(0);

    const [projNameState, setProjNameState] = useState("");
    const [compNameState, setCompNameState] = useState("");
    const [buttonChoice, setButtonChoice] = useState("name");//what is this
    const [activeState, setactiveState] = useState<string>("");

    const activeId = useRef<string | null>(null);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
    const [Results, setResult] = useState<ProjectType[]>([])

    const { projectStore } = useStore();

    const [list, setlist] = useState<ProjectDto[]>();

    useEffect(() => {
        setPage(0)
    }, [buttonChoice])

    useEffect(() => {
        const fetchData = async () => {
            try {

                await projectStore.loadProjectDtosByIds(selectedIds).then(i => setlist(i));

            } catch (error) {
                console.error(error)
            }
        };
        if (projectStore) {
            fetchData();
        }
    }, [projectStore, selectedIds])

    useEffect(() => {
        const updatedRows = Results.filter((item) => {
            return projNameState.toLowerCase() === "" ? item
                : item.name.toLowerCase().includes(projNameState.toLowerCase());
        }).filter((item3) => {
            return compNameState.toLowerCase() === "" ? item3
                : item3.companyName.toLowerCase().includes(compNameState.toLowerCase());
        }).map((record: ProjectType) => ({

            data: [
                { id: "name", data: record.name },
                { id: "departmentName", data: record.departmentName },
                { id: "companyName", data: record.companyName },
                { id: "projectPeriod", data: record.projectPeriod },
                { id: "maintenanceEndDate", data: record.maintenanceEndDate },
                { id: "status", data: record.status },

            ],
            hiddenData: [],
            Id: record.id,
            ischecked: false

        }));

        setrows(updatedRows);

    }, [Results, projNameState, compNameState]);

    useEffect(() => {

        if (list) {
            const temp: ProjectType[] = list.map(
                (s) => ({
                    id: String(s.projectId),
                    name: s.name,
                    departmentName: s.departmentName ? s.departmentName : "--",
                    companyName: s.companyName,
                    projectPeriod: s.projectPeriod,
                    maintenanceEndDate: s.formattedMaintanance ? s.formattedMaintanance : "--",
                    status: s.status ? "Active" : "Inactive"
                })
            )

            setResult(temp)
            console.log(temp)
        }

    }, [list])

    const statesArray: states[] = [
        {
            currentstate: projNameState,
            statemethod: setProjNameState
        },
        {
            currentstate: compNameState,
            statemethod: setCompNameState
        }
    ]


    const headers: Array<CoustomTableHeaderProps> = [

        { id: "name", data: "Project Name" },
        { id: "departmentName", data: "Department" },
        { id: "companyName", data: "Company Name" },
        { id: "projectPeriod", data: "Project Period" },
        { id: "maintenanceEndDate", data: "Maintenance End Date" },
        { id: "status", data: "Status" },

    ];

    const stateSelection = buttonChoice === "name"
        ? setProjNameState
        : setCompNameState


            const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]


    return (
        <div className="pageParentDiv">
            
            <AssignTableHeader buttonChoice={buttonChoice} setactiveState={setactiveState} setisOpen={setisOpen} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={setButtonChoice} options={headers.filter((item) => item.id == "name" || item.id == "projectCode" || item.id == "companyName")}/>
            <ThemeProvider theme={customTableTheme}>

                <CoustomTable disableCheck={true} rowsPerPage={10} page={page} setPage={setPage} tableMarginRight="200"  headers={headers} setRows={setrows} rows={rows} activeId={activeId}  />



            </ThemeProvider>
        </div>
    )
}
