import React, { useEffect, useState } from 'react'

import InventoryCreateForm from '../../components/form/Inventory/InventoryCreateForm'
import FormHeader from '../../components/FormElement/FormHeader/FormHeader'
import { useLocation, useParams } from 'react-router-dom';
import CreateFormHeader from '../../components/form/CreateFormHeader/CreateFormHeader'
import { Inventory, InventoryForm, InventoryFormValue, TableHistory } from './../../models/Inventory';
import { useStore } from '../../stores/store';
import LoadingComponent from '../../components/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { Page } from '../../components/TitlePath/TitlePath';
import { ApiError } from '../../models/Error';


export default observer(function InventoryCreatePage() {
  const location = useLocation();
  const { copyId } = location.state || {};
  const { id } = useParams();
  const [record, setRecord] = useState<InventoryForm>(new InventoryFormValue());

  const { inventoryStore } = useStore()
  const { getInventoryHistory, submitting } = inventoryStore;
  const [history, setHistory] = useState<TableHistory | undefined>(undefined);
 
  useEffect(() => {
    const fetchHistory = async () => {
      const data = await getInventoryHistory(id);
      setHistory(data);
    }
    fetchHistory();
    // console.log(history)
  }, [])

  const [page, setPage] = useState('first');
  const lastPages: Page[] = [{ title: "Inventory Management", path: "/inventory" }]
  if (submitting)
    return <LoadingComponent content="Loading activity..." />;

  return (
    <div className="pageParentDiv flex flex-col px-3 md:px-5">
      <CreateFormHeader
        inventoryType={record.inventoryTypeId === 1 ? "Logger" : record.inventoryTypeId === 2 ? "Sensor" : record.inventoryTypeId === 3 ? "Other" : "None"}
        registeredBy={history?.registeredBy}
        registeredOn={history?.registeredOn}
        updateBy={history?.updateBy}
        updateOn={history?.updateOn}
        titleText={id ? "Edit Inventory Record" : "Register New Inventory"}
        lastPages={lastPages}
        formType={"inventory record"} />

      <InventoryCreateForm copyId={copyId} selectedId={id} record={record} setRecord={setRecord} />


    </div>
  )
})
