import internal from "stream";
import { Alarm, AlarmLevelsMsgToolId, AlarmLevelsUsersId, IAlarmError } from "./Alarm";

export interface Project {
    projectId?: number
    name: string;
    departmentId?: number
    companyId?: string
    startDate?: string
    endDate?: string
    maintenanceEndDate?: string
    status: boolean
    remark?: string
    alarm?: Alarm[]
}

export interface ProjectDto extends Project {
    departmentName: string;
    companyName: string;
    projectPeriod: string;
    formattedMaintanance? :string;
}

export interface ProjectForm extends Partial<Project> {
}

export class ProjectFormValue implements ProjectForm {
    name: string = "";
    departmentId?: number = undefined
    companyId: string = ""
    startDate: string = ""
    endDate: string = ""
    maintenanceEndDate?: string = undefined
    status?: boolean = undefined
    remark: string = ""
    alarm?: Alarm[]


    constructor(init?: ProjectForm) {
        Object.assign(this, init);
    }
}


export interface IProjectError {
    name: string;
    companyId: string;
    startDate: string; // displaying start and end date error
    status: string;

    alarm?: IAlarmError[];
}

export class ProjectErrorValue implements IProjectError {
    name: string = "";
    companyId: string = ""
    startDate: string = "" // displaying start and end date error
    status: string = ""
}

export interface ProjectResponse extends Project {
    alarmLevelsUsersId: AlarmLevelsUsersId[]
    alarmLevelsMsgToolId: AlarmLevelsMsgToolId[];
}

export interface ProjectRequest extends ProjectForm {
    alarmLevelsMsgToolId: AlarmLevelsMsgToolId[];
    alarmLevelsUsersId: AlarmLevelsUsersId[]
}