import React, { ChangeEvent, useState } from 'react'

import { DropdownList } from '../Select/DropdownList'
import { CustomCalendar } from '../Input/CustomCalendar'
import { Button, ToggleButton } from '@mui/material'

import './icons/filter.png';
import { SymbolTextBox } from '../Input/SymbolTextbox'
import GeneralButton from '../Button/GeneralButton'
import { CoustomTableHeaderProps } from '../Table/CoustomTable'
import { states } from '../../pages/SourceType/SourceTypeRecord'
import { useNavigate } from 'react-router-dom';
import { NewToggleSwitch } from '../FormElement/ToggleButton'
import { SourceType, SourceTypeForm } from '../../models/SourceType';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import TitlePath, { Page } from '../TitlePath/TitlePath';

export const compLabel = {
    width: '35px', padding: '1.5%', marginRight: '10%'
}

export const components = {
    height: '35px',
    paddingTop: '8px',
    paddingRight: '16px',
    margin: '10px'

}

interface Props {
    buttonChoice: string
    activeState:string
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    setactiveState: (x: string) => void;
    lastPages?: Page[]
    title: string
    state?: string

}

export default observer(function SourceTypeHeader({activeState,  state, buttonChoice, title, lastPages, setState, setButtonChoice, options, statesArray, setactiveState }: Props) {

    const navigate = useNavigate();

    const { sourceTypeStore } = useStore();
    const { filterPlaceholder, ChangePlaceHolder } = sourceTypeStore;

    const [record, setRecord] = useState<SourceTypeForm>({ //just for now fix it later
        sourceType: '',
        unit: '',
        access: undefined,
        remark: '',
        status: false
    });

    const filterProps = {
        placeholder: filterPlaceholder,//
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",

    }


    const createSourceType = () => {
        navigate('/sourceType/create');
    }

    const createButton = { title: 'Create New Source Type', imgSrc: '/assets/button/recAdd.png', Click: createSourceType }//


    return (
        <div className=' flex flex-col gap-4 '>
            <TitlePath currentTitle={title} lastPages={lastPages} />

            <div className='flex flex-col  lg:flex-row justify-between gap-3'>
                <div className='flex' >
                    <SymbolTextBox
                        defaultValue={state}
                        buttonChoice={buttonChoice}
                        changePlaceholderValue={ChangePlaceHolder}
                        setactiveState={setactiveState}
                        options={options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={filterProps.placeholder}
                        onChange={(e) => setState(e.target.value)}
                        statesArray={statesArray}
                        setButtonChoice={setButtonChoice} />
                </div>
                <div className='flex flex-col md:flex-row gap-3'>
                    <div className=' w-full flex items-center'  >
                        <span>Status:</span>
                        <NewToggleSwitch value={activeState === "Active"?true:undefined} setActiveState={setactiveState} record={record} setRecord={setRecord} name={"activeStatus"} />
                    </div>
                    <div className='w-full' >
                        <GeneralButton title={createButton.title} imgSrc={createButton.imgSrc} handleClick={createSourceType} />
                    </div>
                </div>
            </div>
        </div>
    )
}
)

