import React, { FormEvent } from 'react'
import DisableField from '../../FormElement/DisableField'
import { ProjectForm } from '../../../models/Project'
import AudienceTableSetting from './NewAlarmControlTable/BigAudienceTable/AudienceTableSetting'
import { IButton } from '../../Button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { ApiError } from '../../../models/Error'
import ServerErrorMessage from '../../Message/Error/ServerErrorMessage'
interface Prop {
    record: ProjectForm
    setRecord: (arr: {}) => void;

    handleSubmit: (event: FormEvent<HTMLFormElement>) => void

    tableBlur:boolean
    setTableBlur:(arr:boolean) => void;
    serverError:ApiError | undefined
}


export default function ProjectAudienceForm({serverError, tableBlur,setTableBlur,  setRecord, handleSubmit, record }: Prop) {
    const navigate = useNavigate()
    function showRecord() {
        console.log(record)
    }

    return (
        
        <div>
            <form className="flex flex-col gap-3 mt-4" onSubmit={handleSubmit} autoComplete="off">
                <div className="mt-[10px] mb-[10px] flex flex-col gap-4" onClick={showRecord} >
                    <DisableField value={record.name} title={"Project Name"} isRequired={true} />
                </div>
                <AudienceTableSetting tableBlur={tableBlur} setTableBlur={setTableBlur} record={record} setRecord={setRecord} />
                
                { (record.alarm === undefined  || (record.alarm!= undefined && record.alarm.length<1)) &&
                <div className="h-[600px] flex items-center justify-center"><span className="text-[#CC311B]">No Default Source Type Alarms for this Project</span></div>
            }
                <div className="flex justify-center gap-5 mt-5 mb-3 flex-wrap">
                    <IButton type="submit" text="Save" className='w-full'/>
                        <IButton onClick={()=>{navigate(-1)}} type='button' text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" className='w-full'/>

                </div>

                <ServerErrorMessage messageHeader={serverError?.messageHeader} messageList={serverError?.messageList} />

            </form>
        </div>

    )
}
