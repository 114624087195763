import React from 'react'
import { Dropdown } from 'semantic-ui-react';
import { DropdownList } from '../Select/DropdownList';
interface Props {
  title: string;
  imgSrc?: string;
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  px?: string
  py?: string
  disabled?:boolean
  type?:'button' | 'submit'
}

export default function GeneralButton({type, disabled, py, px, title, imgSrc, handleClick }: Props) {
  return (

    <div>
      <button type={type??'button'} onClick={handleClick} disabled={disabled} className={` ${disabled ? 'bg-[#808080] cursor-not-allowed ' : 'cursor-pointer bg-theme-orange'} ${py ? py : "py-2.5"} ${px ? px : "px-[10px] md:px-[25px]"} rounded-lg flex items-center justify-center h-full w-full`}>
        {imgSrc && <img src={imgSrc} className='w-5 h-5 mr-2' />}
        <span className='whitespace-nowrap text-[#e8e6e6] text-wrap'>
          {title}
        </span>
      </button>
    </div>
  )
}
