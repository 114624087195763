import { useStore } from "../../stores/store";
import { CategoryType, MenuTemplate, menuOptions } from "./MenuTemplate"



export const ProjectManagementMenu = () => {
    const { userStore, commonStore } = useStore();
    const { user, CheckIfHkiaEmail } = userStore;

    const images = {
        company: "assets/button/Menu/projectmanagementmenu/company.png",
        customer: "assets/button/Menu/projectmanagementmenu/customer.png",
        loggerSetmaintenance: "assets/button/Menu/projectmanagementmenu/loggerSetmaintenance.png",
        sourceType: "assets/button/Menu/projectmanagementmenu/sourceType.png",
        projectMaintenance: "assets/button/Menu/projectmanagementmenu/project-diagram.png",
        location: "assets/button/Menu/projectmanagementmenu/mdi_location.png",
    }


    const companyAndcustomer: menuOptions[] = [
        
        {
            imageInfo: { imageWidth: "100px", imageHeight: "100px", imageSource: images.customer },
            path: "/customer",
            backgroundColor:"#F2F4FF",
            imageLabel:"Customer"
        }
    ]

    const projectAndlocation: menuOptions[] = [
        {
            imageInfo:
                { imageWidth: "100px", imageHeight: "100px", imageSource: images.projectMaintenance },
            path: "/project",
            backgroundColor:"#F5F3FF",
            imageLabel:"Project Maintenance"
        },
        {
            imageInfo: { imageWidth: "100px", imageHeight: "100px", imageSource: images.location },
            path: "/location",
            backgroundColor:"#F5F3FF",
            imageLabel:"Location Maintenance"
        }
    ]

    const componentSet: menuOptions[] = [
        {
            imageInfo:
                { imageWidth: "100px", imageHeight: "100px", imageSource: images.loggerSetmaintenance },
            path: "/LoggersetMaintenance",
            backgroundColor:"#F1EFF3",
            imageLabel:"Logger Set Maintenance"
        },
        {
            imageInfo: { imageWidth: "100px", imageHeight: "100px", imageSource: images.sourceType },
            path: "/sourceType",
            backgroundColor:"#FFF7EF",
            imageLabel:"Source Type"
        }
    ]


    const projectManagement: CategoryType[] = (user?.role === 'HKL_Admin' || user?.role === 'Castco_Admin')?[     
        { categoryName: "Company & Customers Data Base", options: companyAndcustomer },
        { categoryName: "Project & Location & Logger Set Data Base", options: projectAndlocation },
        { categoryName: "Component Set Data Base", options: componentSet }
    ]:[{ categoryName: "Project & Location & Logger Set Data Base", options: projectAndlocation }]


    return (
        <>
            <MenuTemplate mainTitle={"Project Management Menu "} categoryList={projectManagement} />
        </>
    )
}

//fix background later