import { useNavigate } from "react-router-dom";

export interface menuOptions {
    imageInfo: imageInfo;
    path: string
    backgroundColor: string;
    imageLabel: string;
}

export interface CategoryType {
    categoryName: string;
    options: menuOptions[];
}

export interface imageInfo {
    imageWidth: string;
    imageHeight: string;
    imageSource: string;
}

interface MenuProps {
    mainTitle: string;
    categoryList: CategoryType[];
}

interface CategoryProps {
    categoryName: string;
    options: menuOptions[];
}

interface optionimageProps {
    imageWidth: string;
    imageHeight: string;
    imageSource: string;
}


export const Categories = (p: CategoryProps) => {
    const navigate = useNavigate();
    return (
        <div className="w-full ">
            <p className="text-[20px]  underline mb-[30px]">{p.categoryName}</p>
            <div className="flex gap-1 md:gap-[40px] flex-col md:flex-row">
                {p.options.map((option: menuOptions) => (
                    <div className="flex flex-col md:items-center">
                        
                        <div onClick={()=>{navigate(option.path)}} style={{ backgroundColor: option.backgroundColor, borderRadius: '20px' }}
                            className={`md:min-w-[252px] h-[131px]   flex justify-center items-center hover:cursor-pointer hover:shadow-lg hover:transition duration-300"`}>
                            <OptionImage imageWidth={option.imageInfo.imageWidth} imageHeight={option.imageInfo.imageHeight} imageSource={option.imageInfo.imageSource} />
                        </div>
                        <p className="mt-3  font-medium">{option.imageLabel}</p>
                    </div>

                ))}

            </div>
        </div>
    )
}

export const OptionImage = (p: optionimageProps) => {
    return (
        <img className={`${"w-[" + p.imageWidth + "]"} ${"h-[" + p.imageHeight + "]"}`} src={p.imageSource} />

    )
}

export const MenuTemplate = (p: MenuProps) => {
    
    return (
        <div className="pageParentDiv">
            <div className="px-[40px] py-[20px]  overflow-y-scrollable flex flex-col gap-5">
                <p className=" text-[20px]  font-semibold  ">{p.mainTitle}</p>
                {p.categoryList.map((category: CategoryType) => (
                    <Categories categoryName={category.categoryName} options={category.options} />
                ))}
            </div>

        </div>
    )
}