import React from 'react'
import HistoryHeader from '../../components/historyHeader'
import TitlePath from '../../components/TitlePath/TitlePath'

export default function ChangePwHeader() {
  return (
    <div className="flex mt-4 ">
           

            <div className='flex flex-col gap-3 w-full'>
                <TitlePath currentTitle={"Change Password"} />
                
              

                <span>
                    Please fill in the information below to change the user Password . Please note that the field highlighted in
                    <span className="text-[#CC311B]"> orange</span> is
                    <span className="font-bold"> Mandatory.</span>
                </span>
                <div >
                    <HistoryHeader registeredBy={""} registeredOn={""} updateBy={""} updateOn={""} />
                </div>
            </div>

</div>
  )
}
