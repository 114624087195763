import React, { useCallback, useEffect, useRef, useState } from 'react'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { ThemeProvider } from '@emotion/react';
import { states } from '../SourceType/SourceTypeRecord';
import CustomerHeader from '../../components/CustomerRecord/CustomerHeader';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import debounce from 'lodash.debounce';
import LoadingComponent from '../../components/LoadingComponent';
import { Page } from '../../components/TitlePath/TitlePath';

export interface CustomerType {
    id: string,
    name: string,
    companyName: string,
    telNo: string,
    mobileNo: string,
    email: string,
}

export default observer(function CustomerRecord() {
    const params = new URLSearchParams(window.location.search);

    const page_FromUrl = params.get('page');
    const nameState_FromUrl = params.get('nameState');
    const companynameState_FromUrl = params.get('companynameState');
    const telnoState_FromUrl = params.get('telnoState');
    const mobilenoState_FromUrl = params.get('mobilenoState');
    const emailState_FromUrl = params.get('emailState');
    const buttonChoice_FromUrl = params.get('buttonChoice');


    const [page, setPage] = useState(0); //fake
    const [apiPage, setApiPage] = useState<number>(Number(page_FromUrl) ?? 0);

    const [nameState, setNameState] = useState(nameState_FromUrl ?? "");
    const [companynameState, setCompanynameState] = useState(companynameState_FromUrl ?? "");
    const [telnoState, setTelnoState] = useState<string>(telnoState_FromUrl ?? "");
    const [mobilenoState, setMobilenoState] = useState<string>(mobilenoState_FromUrl ?? "");
    const [emailState, setEmailState] = useState<string>(emailState_FromUrl ?? "");

    const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "name");
    const [excelRecord, setexcelRecord] = useState([]);
    const activeId = useRef<string | null>(null);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const [Results, setResult] = useState<CustomerType[]>([])

    const final_setButtonChoice = (state: string) => { //cant use useEffect
        setButtonChoice(state)
        setPage(0)
    }

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('page', String(apiPage));
        params.set('buttonChoice', buttonChoice);

        if (buttonChoice === 'name') {
            params.set('nameState', nameState);
        } else if (buttonChoice === 'companyName') {
            params.set('companynameState', companynameState);
        } else if (buttonChoice === 'telNo') {
            params.set('telnoState', telnoState);
        } else if (buttonChoice === 'mobileNo') {
            params.set('mobilenoState', mobilenoState);
        } else if (buttonChoice === 'email') {
            params.set('emailState', emailState);
        }
        window.history.replaceState({}, '', `?${params.toString()}`);
    }, [apiPage, buttonChoice, nameState, companynameState, telnoState, mobilenoState, emailState]);


    const { customerStore } = useStore();
    const { loadingInitial, LoadingCustomerList, Customers, LoadingCount, count } = customerStore;

    const debouncedFetch = useCallback(
        debounce((customerState, companynameState, telnoState, mobilenoState, emailState, page) =>
            LoadingCustomerList(customerState, companynameState, telnoState, mobilenoState, emailState, page), 500),
        [] // dependencies
    );



    const statesArray: states[] = [
        {
            currentstate: nameState,
            statemethod: setNameState
        },
        {
            currentstate: companynameState,
            statemethod: setCompanynameState
        },
        {
            currentstate: telnoState,
            statemethod: setTelnoState
        },
        {
            currentstate: mobilenoState,
            statemethod: setMobilenoState
        },
        {
            currentstate: emailState,
            statemethod: setEmailState
        }
    ]

    useEffect(() => {
        debouncedFetch(nameState, companynameState, telnoState, mobilenoState, emailState, apiPage)
        LoadingCount(nameState, companynameState, telnoState, mobilenoState, emailState);

    }, [nameState, companynameState, telnoState, mobilenoState, emailState, apiPage])

    useEffect(() => {
        console.log(buttonChoice, nameState, companynameState, telnoState, mobilenoState, emailState)

        const updatedRows = Results.map((record: CustomerType) => ({
            data: [
                { id: "name", data: record.name },
                { id: "companyName", data: record.companyName },
                { id: "telNo", data: record.telNo },
                { id: "mobileNo", data: record.mobileNo },
                { id: "email", data: record.email },
            ],
            hiddenData: [],
            Id: record.id,
            ischecked: false
        }));

        setrows(updatedRows);
    }, [Results]);

    useEffect(() => {

        const temp: CustomerType[] = Customers.map(
            (s) => ({
                id: s.id,
                name: s.name ? s.name : "--",
                companyName: s.companyName ? s.companyName : "--",
                telNo: s.telNo ? s.telNo : "--",
                mobileNo: s.mobileNo ? s.mobileNo : "--",
                email: s.email ? s.email : "--",
            })
        )
        setResult(temp)

    }, [Customers, customerStore])



    const headers: Array<CoustomTableHeaderProps> = [
        { id: "name", data: "Name" },
        { id: "companyName", data: "Company Name" },
        { id: "telNo", data: "Tel No." },
        { id: "mobileNo", data: "Mobile No." },
        { id: "email", data: "Email Address" },
    ];

    const stateSelection = buttonChoice === "name"
        ? setNameState
        : buttonChoice === "companyName"
            ? setCompanynameState
            : buttonChoice === "telNo"
                ? setTelnoState
                : buttonChoice === "mobileNo"
                    ? setMobilenoState
                    : setEmailState

    const state = buttonChoice === "name"
        ? nameState
        : buttonChoice === "companyName"
            ? companynameState
            : buttonChoice === "telNo"
                ? telnoState
                : buttonChoice === "mobileNo"
                    ? mobilenoState
                    : emailState

    const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]

    if (loadingInitial)
        return <LoadingComponent content="Loading activity..." />;

    return (
        <div className="pageParentDiv">
            <CustomerHeader buttonChoice={buttonChoice} state={state} lastPages={lastPages} title="Search Customers Record [LIMS]" statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={final_setButtonChoice} options={headers.filter((item) => item.id == "name" || item.id == "companyName" || item.id == "telNo" || item.id == "mobileNo" || item.id == "email")} />
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable page={page} setPage={setPage} subPath="customer" apiCount={count} apiPage={apiPage} setApiPage={setApiPage} tableMarginRight='150' headers={headers} setRows={setrows} rows={rows} activeId={activeId} setexcelRecord={setexcelRecord} excelRecord={excelRecord} />
            </ThemeProvider>
        </div>
    )
})
