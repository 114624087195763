import { makeAutoObservable, runInAction } from "mobx";
import { LocationDto, LocationFormValueDto, LocationRequest, LocationRequestVal } from "../models/Location";
import agent from "../services/Api/agent";
import { Alarm, AlarmLevel, CustomInventoryAlarm, UpdatedCustomInventory } from "../models/Alarm";

export default class LocationStore {
    locationsList: LocationDto[] = []; //LocationRequestVal[] | LocationFormValueDto[]
    private selectedLocation: LocationRequestVal | undefined = undefined;
    selectedLocationFormVAL: LocationFormValueDto | undefined = undefined;
    loading = false;
    filterPlaceholder = "Filter Logger SN";


    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (x: boolean) => {
        this.loading = x;
    }

    LoadingLocationsList = async () => {
        try {
            this.setLoading(true);

            const locations = await agent.Locations.list();

            runInAction(() => {
                this.locationsList = locations ? locations : []
            })


            this.setLoading(false)
        } catch (error) {
            this.setLoading(false);
            throw error;
        }
    }

    ChangePlaceHolder = (p: string) => {
        this.filterPlaceholder = p;
    }

    LoadSelectedLocation = async (id: string) => {
        this.setLoading(true)
        try {
            const newLocation = await agent.Locations.details(Number(id));
            console.log("selected lcoation : store")
            console.log(newLocation)
            this.setLoading(false);
            this.selectedLocation = newLocation;
            //return newLocation;
        } catch (error) {
            this.setLoading(false);
            throw error;
        }

        //this.selectedLocationFormVAL = new LocationFormValueDto(this.selectedLocation)
        var x = new LocationFormValueDto(this.selectedLocation)

        var newCustInvList: CustomInventoryAlarm[] = [];

        if (this.selectedLocation && this.selectedLocation.customInventoryAlarm) {
            for (let i = 0; i < this.selectedLocation.customInventoryAlarm.length; i++) {

                const custInv = this.selectedLocation.customInventoryAlarm[i];
                var edittedAlarms: Alarm[] = custInv.alarm;
                //j is the alarmIndex
                //k is the alarm level
                //when, alarmIndex and level match, alarmLvl.msgToolId = blabla
                for (let j = 0; j < custInv.alarm.length; j++) {
                    for (let k = 0; k < custInv.alarm[j].alarmLevels.length; k++) {
                        var msgTool = custInv.alarmLevelsMsgToolId.find(x => x.alarmIndex == j && x.level == k + 1);
                        edittedAlarms[j].alarmLevels[k].msgToolId = (msgTool?.msgToolId ? msgTool.msgToolId : []
                        )
                        var usersId = custInv.alarmLevelsUsersId.find(x => x.alarmIndex == j && x.level == k + 1);
                        edittedAlarms[j].alarmLevels[k].usersId = (usersId?.usersId ? usersId.usersId : [])
                    }
                }
                var newCustInv: CustomInventoryAlarm = {
                    inventoryId: custInv.inventoryId,
                    alarm: edittedAlarms
                }
                newCustInvList.push(newCustInv);
            }
        }
        //this.selectedLocationFormVAL.customInventoryAlarm = newCustInvList;
        x.customInventoryAlarm = newCustInvList;
        this.selectedLocationFormVAL = x;

        console.log("check", this.selectedLocationFormVAL);
        return this.selectedLocationFormVAL;

    }

    CreateLocation2 = async (record: LocationFormValueDto) => {
        var finalCustomerInv: UpdatedCustomInventory[] = [];
        for (let i = 0; i < record.customInventoryAlarm.length; i++) {
            var CustomerInv: UpdatedCustomInventory;
            var rec = record.customInventoryAlarm[i]
            const alarmLevelsMsgToolId = rec.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    msgToolId: alarmLevel.msgToolId ? alarmLevel.msgToolId : [],
                }))
            ) || [];

            const alarmLevelsUsersId = rec.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    usersId: alarmLevel.usersId ? alarmLevel.usersId : [],
                }))
            ) || [];

            CustomerInv = { ...record.customInventoryAlarm[i], alarmLevelsMsgToolId: alarmLevelsMsgToolId, alarmLevelsUsersId: alarmLevelsUsersId };
            finalCustomerInv.push(CustomerInv);

        }

        var UpdatedRecord: LocationRequestVal = { ...record, customInventoryAlarm: finalCustomerInv }

        this.setLoading(true);
        try {
            await agent.Locations.create(UpdatedRecord);
            //const newArr = [...this.locationsList, UpdatedRecord]
            runInAction(() => {
                //this.locationsList = newArr as LocationRequestVal[]
                this.setLoading(false);
                //console.log(newArr)
            })

        } catch (error) {
            console.log("error");
            this.setLoading(false);
            throw error;
        }

        console.log(UpdatedRecord);
    }

    EditLocation = async (record: LocationFormValueDto) => {
        var finalCustomerInv: UpdatedCustomInventory[] = [];
        for (let i = 0; i < record.customInventoryAlarm.length; i++) {
            var CustomerInv: UpdatedCustomInventory;
            var rec = record.customInventoryAlarm[i]
            const alarmLevelsMsgToolId = rec.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    msgToolId: alarmLevel.msgToolId ? alarmLevel.msgToolId : [],
                }))
            ) || [];

            const alarmLevelsUsersId = rec.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    usersId: alarmLevel.usersId ? alarmLevel.usersId : [],
                }))
            ) || [];

            CustomerInv = { ...record.customInventoryAlarm[i], alarmLevelsMsgToolId: alarmLevelsMsgToolId, alarmLevelsUsersId: alarmLevelsUsersId };
            finalCustomerInv.push(CustomerInv);

        }

        var UpdatedRecord: LocationRequestVal = { ...record, customInventoryAlarm: finalCustomerInv }

        this.setLoading(true);
        try {
            await agent.Locations.update(UpdatedRecord);
            // const newArr = [...(this.locationsList as LocationRequestVal[]).filter((x: LocationRequestVal | LocationFormValueDto) => x.id != UpdatedRecord.id), UpdatedRecord];

            runInAction(() => {
                // newArr.sort();
                //  this.locationsList = newArr as LocationRequestVal[]
                this.setLoading(false);
                //console.log(newArr)
            })

        } catch (error) {
            console.log("error");
            this.setLoading(false);
            throw error;
        }

        console.log(UpdatedRecord);
        //  console.log("location's list", this.locationsList)
    }


    getLocationHistory = async (id?: string) => {
        try {
            if (id == null) return
            return await await agent.Locations.getHistory(id);
        } catch (error) {
            console.log(error)
            return undefined
        }
    }

    getLoggerSetsInLocation = async (selectedId?: string) => {
        this.loading = true
        try {
            const loggerSets = await agent.Locations.getLogSets(selectedId)
            this.loading = false;
            // runInAction(()=>{
            //     this.activeLoggerSets=loggerSets;
            // })
            return loggerSets;


        }
        catch (error) {
            this.loading = false;
            throw error;
        }


    }
}