import path from 'path'
import React from 'react'
import { Link } from 'react-router-dom'
export interface Page {
    title: string
    path: string
}
interface Props {
    currentTitle: string
    lastPages?: Page[]
}
export default function TitlePath({ currentTitle, lastPages }: Props) {
    return (
        <div className="flex flex-col md:flex-row">
            {lastPages && lastPages.map((page, i) => (
                <Link to={page.path}>
                    <span className="text-[20px] font-medium no-underline whitespace-nowrap" key={i} >
                        {page.title + " > "}
                    </span>
                </Link>
            ))}
            <span className="text-[20px] font-medium  whitespace-nowrap" >{currentTitle}</span>
        </div>
    )
}
