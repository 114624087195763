import React from 'react'
import ChangePwHeader from './ChangePwHeader'
import ChangePwForm from './ChangePwForm'

export default function ChangePwPage() {
    return (
            <div className="pageParentDiv flex flex-col px-5">
            <ChangePwHeader />
            <ChangePwForm />
            </div>
    )
}
