import React from 'react'
import AudienceAlarmTable from '../../../Project/NewAlarmControlTable/AudienceAlarmTable'
import { Alarm, LocationFormAlarmVal } from '../../../../../models/Alarm'

interface Prop {
  localRecord: LocationFormAlarmVal
  setlocalRecord: (x: LocationFormAlarmVal) => void
  alarmlist: Alarm[];
  tableBlur:boolean
  setTableBlur:(arr:boolean) => void;

}

export default function InventoryAlarmInfoAudience(p: Prop) {
 
  return (
    <div>
      {p.alarmlist.map((alarm, i) => (
        <div className="mb-2">
          <AudienceAlarmTable // usemap tmr // possible more than one source type for one inventory
            tableBlur={p.tableBlur}
            setTableBlur={p.setTableBlur}
            record={p.localRecord}
            key={i}
            sourceTypeId={alarm.sourceTypeId}
            alarmIndex={i}
            setRecord={p.setlocalRecord}
            
          />
        </div>
      ))}

    </div>
  )
}
// p.localRecord.alarm?[0].sourceTypeId