import React, { useState } from 'react'
import AlarmForm from './AlarmForm';
import AlarmTable from './AlarmTable';

export default function AlarmPage() {

    //const [alarmData, setAlarmData] = useState<>();
    return (
        <div className="pageParentDiv">
            <AlarmForm />
            <AlarmTable />
        </div>
    )
}
