import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/Api/agent";
import { CoustomTableHeaderProps } from "../components/Table/CoustomTable";
import { OverviewDto } from "../models/Pharse2/Overview";

export default class OverviewStore {
    loadingInitial: boolean = false;
    temp: {
        baseHeader: CoustomTableHeaderProps[];
        sourceHeader: CoustomTableHeaderProps[];
        overviewDtos: OverviewDto[];
    } | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    private setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state
    }
    // getOverviewHeader = async () => {
    //     try {
    //         const res = await agent.Overview.getOverviewHeader();
    //         return res

    //     }
    //     catch (error) {
    //         this.setLoadingInitial(false)
    //         throw (error)
    //     }
    // }
    getOverviewDtoList = async () => {

        try {
            this.setLoadingInitial(true);
            const res = await agent.Overview.getOverviewDtoList();
            this.setLoadingInitial(false)
            return res
        }
        catch (error) {
            this.setLoadingInitial(false)
            throw (error)
        }
    }

    

    // getHeader = async () => {
    //     try {
    //         this.setLoadingInitial(true);
    //         const res = await agent.Overview.getHeader();
    //         this.setLoadingInitial(false);
    //         return res
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     return [];
    // }

    // getOverview = async () => {
    //     try {
    //         this.setLoadingInitial(true);
    //         const res = await agent.Overview.getOverview();
    //         this.setLoadingInitial(false);
    //         console.log(res);
    //         return res;
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     return [];
    // }
}