import React from 'react'
interface Prop {
    onClick: () => void 
    iconSrc: string
}
export default function TableDeleteButton({ onClick, iconSrc }: Prop) {
    return (
        <div onClick={onClick} className="cursor-pointer border-2 bg-[#D0D6DB] w-[135px] h-[32px] rounded-full flex justify-center items-center">
            <div className="flex gap-1 items-center">
                <img src={iconSrc} className="w-[21.875px] h-[20.313px]" />

                <p className=" text-base whitespace-nowrap p-0">
                    Delete
                </p>
                
            </div>
        </div>
    )
}
