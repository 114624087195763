import React, { ChangeEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { MODAL_STYLES } from '../../LoggerSet/LoggerInfo/LoggerInfoTable';
import { RoundButton } from '../../../Button/RoundButton';
import { TextAreaElement } from '../../../FormElement/TextAreaElement';
import { title } from 'process';
import { LocationFormValueDto, PostProcessingScript } from '../../../../models/Location';
import { store } from '../../../../stores/store';
import { IdName } from '../../../../models/IdName';

interface Prop {
    record: LocationFormValueDto
    setRecord: (x: LocationFormValueDto) => void;
    setIsOpen: (b: boolean) => void
    isOpen: boolean
    sourceTypeIds: String[]
    locationId?: Number
}

export default function ScriptInfo({ record, setRecord, locationId, sourceTypeIds, setIsOpen, isOpen }: Prop) {


    const [sourceTypeList, setSourceTypeList] = useState<IdName[]>([])

    const handleTextAreaChangeUnit = (i: IdName, event: ChangeEvent<HTMLInputElement>) => {
        i.unitsScript = event.target.value
        setSourceTypeList(
            (prev) => {
                prev = prev.filter(x => x.id !== i.id)
                var res = [...prev, i].sort((a, b) => {
                    return a.id - b.id
                })
                return (res)
            }
        )

    }

    const handleTextAreaChange = (i: IdName, event: ChangeEvent<HTMLTextAreaElement>) => {
        i.value = event.target.value
        setSourceTypeList(
            (prev) => {
                prev = prev.filter(x => x.id !== i.id)
                var res = [...prev, i].sort((a, b) => {
                    return a.id - b.id
                })
                return (res)
            }
        )

    }
    useEffect(() => {
        console.log(locationId)
        if (sourceTypeIds.length > 0) {
            store.sourceTypeStore.GetAllSourceType(sourceTypeIds, locationId).then((res) => {
                setSourceTypeList(res)
            })
        }
    }, [sourceTypeIds])

    const OVERLAY_STYLES = {
        zIndex: 999,
        width: '100vw',
        height: '100vh',
        backgroundColor: ' rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
    };

    const CONTENT_STYLES = {
        zIndex: 1000,

        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '32px',
        boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        maxHeight: '100vh',
        maxWidth: '100vw',
        overflowY: 'auto',
    };

    useEffect(() => {
        console.log(sourceTypeList)
    }, [sourceTypeList])


    const handleSave = () => {
        setIsOpen(false)
        setRecord({ ...record, scripts: sourceTypeList })
    }

    if (isOpen == false) return null;

    return ReactDOM.createPortal(
        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} onClick={handleSave} />
            <div style={CONTENT_STYLES as React.CSSProperties}>

                <div className="pageParentDiv flex flex-col gap-2" style={{ borderRadius: '20px', }}>
                    <span className="text-[20px] text-bold whitespace-nowrap underline">
                        Source Type Script Setting (Javascript)
                    </span>

                    {sourceTypeList == undefined || (sourceTypeList!=undefined && sourceTypeList.length ==0)? <div className="w-[500px] h-[500px] flex justify-center items-center">
                        <span className="text-red-700">
                            No Resource Type for current project.
                        </span>
                    </div>
                        : sourceTypeList.map((srcType, i) => {
                            return (
                                <TextAreaElement overrideChangeUnit={(e) => handleTextAreaChangeUnit(srcType, e)} overrideChange={(e) => handleTextAreaChange(srcType, e)} title={srcType.name} record={{}} setRecord={() => { }} isRequired={false} name="" value={srcType.value} unitsscript={srcType.unitsScript} />
                            )
                        })}



                </div>

            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    )

}
