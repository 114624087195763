
import React from 'react'
interface Prop{
    content:string
}
export default function GraphBadResult({content}:Prop) {
    return (
        <div className="h-[700px] flex items-center justify-center">
            <span className="text-[#CC311B]">
               {content}
            </span>
        </div>
    )
}
