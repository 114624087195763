import React from 'react'
import InventoryManagement from '../../../pages/Inventory/InventoryManagement';
import InventoryManagementHeader from '../../InventoryManagement/InventoryManagementHeader';
import HistoryHeader from '../../historyHeader';
import ProjectTypeHeader, { TypeHeaderItem } from '../../ProjectRecord/ProjectTypeHeader/ProjectTypeHeader';
import TitlePath, { Page } from '../../TitlePath/TitlePath';

interface props {
    lastPages?: Page[]
    titleText: string;
    formType: string;
    registeredBy?: string;
    registeredOn?: string;
    updateBy?: string;
    updateOn?: string;
    changePage?: (arr: number) => boolean | Promise<boolean> ;
    inventoryType?: string;
    page?: number
    isCreate?: boolean
    InventoryTypeHeaderAllItems?: TypeHeaderItem[]
}

export default function CreateFormHeader(p: props) {
    return (
        <div className="flex mt-4 flex-wrap">


            <div className='flex flex-col gap-3 w-full'>
                <TitlePath currentTitle={p.titleText} lastPages={p.lastPages} />

                {p.InventoryTypeHeaderAllItems && p.changePage && p.page != undefined && p.isCreate != undefined &&
                    <div className="w-full min-[860px]:w-[550px]">
                        <ProjectTypeHeader page={p.page} changePage={p.changePage} InventoryTypeHeaderAllItems={p.InventoryTypeHeaderAllItems} />
                    </div>}

                <span>
                    Please fill in the information below to register a new {p.formType} . Please note that the field highlighted in
                    <span className="text-[#CC311B]"> orange</span> is
                    <span className="font-bold"> Mandatory.</span>
                </span>
                <div className='h-full'>
                    <HistoryHeader registeredBy={p.registeredBy} registeredOn={p.registeredOn} updateBy={p.updateBy} updateOn={p.updateOn} />
                </div>
            </div>
            {p.inventoryType && <div className="mr-10 w-[100px]">
                <span >
                    {p.inventoryType}
                </span>
            </div>}
        </div>

    )
}
