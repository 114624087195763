import React, { useEffect, useRef, useState } from 'react'
import { ProjectForm } from '../../../../../models/Project'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../../../Table/CoustomTable';
import { ProjectInfoTable } from '../../ProjectInfo/ProjectInfoTable';
import { ThemeProvider } from "@mui/material/styles"
import { InfoTableHeader } from '../../../../InfoFIleHeader/InfoTableHeader';
import { states } from '../../../../../pages/SourceType/SourceTypeRecord';
import AlarmControlInfo from '../../DefaultAlarmControl/InfoTable/AlarmControlInfo';
import AudienceAlarmTable from '../AudienceAlarmTable';


interface Prop {
  record: ProjectForm
  setRecord: (arr: {}) => void;
  tableBlur:boolean
  setTableBlur:(arr:boolean) => void;
}

interface audiencesInfo {
  id: string;
  userName: string;
  sourceType: string;
  alarmLevel: string[]
}

export default function AudienceTableSetting({tableBlur,setTableBlur, record, setRecord }: Prop) {
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
  const [results, setResult] = useState<audiencesInfo[]>([]);
  const [userNameState, setUserNameState] = useState("");
  const activeId = useRef<string | null>(null);
  const [buttonChoice, setButtonChoice] = useState("Username");
  const [alarmControlInfoisOpen, setAlarmControlInfoisOpen] = useState(false);

  const handleAlarmControlInfo = () => {
    setAlarmControlInfoisOpen(true);
    console.log(alarmControlInfoisOpen)
  }


  // useEffect(() => {
  //   const updatedRows = results
  //     .filter((item) => (
  //       item.userName.toLowerCase().includes(userNameState.toLowerCase())
  //     ))
  //     .map((record: audiencesInfo) => ({
  //       data: [
  //         { id: "userName", data: record.userName },
  //         { id: "sourceType", data: record.sourceType },

  //       ],
  //       Id: record.id,
  //       ischecked: false
  //     }));
  // }, [userNameState])

  // const statesArray: states[] = [
  //   {
  //     currentstate: userNameState,
  //     statemethod: setUserNameState
  //   }
  // ]
  // const headers: Array<CoustomTableHeaderProps> = [

  //   { id: "UserName", data: "User Name" },
  //   { id: "ValueUnit", data: "Access Sources Type" },
  //   { id: "alarmLevel", data: "Alarm Level" },
  //   { id: "messageTool", data: "Message Tool" },
  //   { id: "messageTool", data: "Update by/ Update on" },
  //   { id: "button", data: " " },



  // ];



  return (
    <div className="flex flex-col gap-3">
      <span className="text-[20px] font-medium text-decoration-line: underline">
        Default Alarm Control Setting
      </span>

      <div className="flex flex-col gap-3 " >

        {/* <InfoTableHeader placeholder="Filter User Name"
          statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))}
          setButtonChoice={setButtonChoice}
          addMethod={handleAlarmControlInfo} /> */}
        {/* <AlarmControlInfo isOpen={alarmControlInfoisOpen} setisOpen={setAlarmControlInfoisOpen} />
        
        <ThemeProvider theme={customTableTheme}>
          <CoustomTable rowsPerPage={10} headers={headers} setRows={setrows} rows={rows} activeId={activeId} />
        </ThemeProvider> */}

        {record.alarm?.map((alarm, index) => (

          <AudienceAlarmTable
            tableBlur={tableBlur}
            setTableBlur={setTableBlur}
            record={record}
            key={index}
            sourceTypeId={Number(alarm.sourceTypeId)}
            alarmIndex={index}
            setRecord={setRecord}
          />

        ))}

      </div>


    </div>
  )
}
