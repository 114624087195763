import React, { useEffect, useRef, useState } from 'react'
import { ProjectForm } from '../../../../models/Project';
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../../Table/CoustomTable';
import { ThemeProvider } from '@emotion/react';
import AddUserButton from './Button/AddUserButton';
import EditButton from './Button/EditButton';
import { GraphType } from './../../../../models/GraphType';
import SummaryStore from './../../../../stores/SummaryStore';
import { useStore } from '../../../../stores/store';

interface Prop {
  record: ProjectForm
  alarmIndex: number
  setRecord: (arr: {}) => void
  tableBlur: boolean
  setTableBlur: (arr: boolean) => void

}

interface audiencesInfo {
  id: string;
  level: number;
  graph: string;
  messageTool: string;
}


export default function AudienceLevelsTable({ tableBlur, setTableBlur, setRecord, alarmIndex, record }: Prop) {
  const [page, setPage] = useState(0);
  
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
  const [results, setResult] = useState<audiencesInfo[]>([]);
  const activeId = useRef<string | null>(null);
  const [alarmControlInfoisOpen, setAlarmControlInfoisOpen] = useState(false);
  const components = record.alarm && record.alarm[alarmIndex] ? record.alarm[alarmIndex].alarmLevels : [{}];


  const { summaryStore } = useStore();
  const { getGraphTypeName, getMsgtool, getMsgToolWithId,  LoadingMessegeTool, LoadingGraphType } = summaryStore;

  
 
  const handleAlarmControlInfo = () => {
    setAlarmControlInfoisOpen(true);
    console.log(alarmControlInfoisOpen)
  }

  async function toFormatString(ids: string[]) {
    console.log("trying to get the msg id string[], ",ids )
    var result: string = ""
    for (let i = 0; i < ids.length; i++) {
      result += await getMsgToolWithId(Number(ids[i]));
      if (i !== ids.length - 1) {
        result += ", "
      }
    }
    return result;
  
  }

  useEffect(() => {
    const fetchData = async () => {
        const Results: audiencesInfo[] = await Promise.all(
            components.map(async (component: any) => {
                const messageTool = (component.msgToolId && component.msgToolId.length > 0)
                    ? await toFormatString(component.msgToolId)
                    : "--";

                const graph = component.graphTypeId !== undefined 
                    ? (await getGraphTypeName(Number(component.graphTypeId)) === "" ? "--" : await getGraphTypeName(Number(component.graphTypeId)))
                    : "--";

                return {
                    id: component.level,
                    level: component.level,
                    graph,
                    messageTool,
                };
            })
        );

        setResult(Results);
        console.log("results", Results);
    };

    fetchData();
}, [record]);

  useEffect(() => {
    const updatedRows: Array<CoustomTableRowProps> =
      results.map((rec: audiencesInfo) => ({

        Id: rec.id,

        data: [
          { id: "level", data: "Level " + rec.level, isJSXelement: false },
          { id: "messageTool", data: rec.messageTool, isJSXelement: false },
          {
            id: "add", data: "add", isJSXelement: true
            , JSXelement: <AddUserButton level={rec.level}  alarmIndex={alarmIndex}  setTableBlur={setTableBlur} handleClick={() => { } } record={record} setRecord={setRecord}  />
          },
          {
            id: "edit", data: "edit", isJSXelement: true
            , JSXelement: <EditButton setTableBlur={setTableBlur} alarmIndex={alarmIndex} record={record} setRecord={setRecord} level={rec.level} handleClick={() => { }} title="Edit Row" />
          },

        ],

      }));
    setrows(updatedRows);
  }, [results])


  const headers: Array<CoustomTableHeaderProps> = [

    { id: "level", data: "Level " },
    { id: "messageTool", data: "Message Tool" },
    { id: "adduser", data: "Add User" },
    { id: "edit", data: "" },

  ];



  return (
    <div className="pageParentDiv">



      <ThemeProvider theme={customTableTheme}>
        <CoustomTable disableCheck={true} page={page} setPage={setPage} tableBlur={tableBlur} maxHeight={350} tableMarginRight="50" rowsPerPage={8} headers={headers} setRows={setrows} rows={rows} activeId={activeId} />
      </ThemeProvider>



    </div>
  )
}
