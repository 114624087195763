import React, { FormEvent, useEffect, useState } from 'react'
import { AccountCreateErrorValue, AccountCreateForm, IAccountCreateError } from '../../../../models/Account'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores/store'
import { Link, useNavigate } from 'react-router-dom'
import { InputElement } from '../../../FormElement/InputElement'
import { SelectElement } from '../../../FormElement/SelectElement'
import { IButton } from '../../../Button/Button'
import * as yup from 'yup';

interface Prop {
  record: AccountCreateForm
  setRecord: (arr: AccountCreateForm) => void
  setPage: () => void
  errors: Partial<IAccountCreateError>
  setErrors: (arr: {}) => void
}
// function GenInfoValidation(record: AccountCreateForm) {
//   const errors: Partial<IAccountCreateError> = {};

//   if (record.firstName === "") {
//     errors.firstName = "Input field is required!"
//   }
//   if (record.lastName === "") {
//     errors.lastName = "Input field is required!"
//   }
//   if (record.role === "") {
//     errors.role = "Input field is required!"
//   }
//   if (record.companyId === "") {
//     errors.companyId = "Input field is required!"
//   }

//   return errors;
// }

const validationSchema = yup.object({
  firstName: yup.string().trim().min(1, "First name can't be empty").matches(/^[A-Za-z ]+$/, "First name must contain only English letters"),
  lastName: yup.string().trim().min(1, "Last name can't be empty").matches(/^[A-Za-z ]+$/, "First name must contain only English letters"),
  role: yup.string().required('Role is required').trim().min(1, "Role can't be empty"),
  companyId: yup.number().required('Company is required'),
  phoneNumber: yup.string()
    .nullable() // empty or must contain 8
    .trim().matches(/^(|[0-9]{8})$/, "Phone Number must be empty or contain exactly 8 digits")

});

export default observer(function AccountCreateGeneralInfo({ errors, setErrors, record, setRecord, setPage }: Prop) {
  useEffect(()=>{
    console.log(record)
  },[record])

  const handleValidationChange = (field: string, value: any) => {
    validationSchema.validateAt(field, {
      [field]: value
    })
      .then(() => {
        setErrors({ ...errors, [field]: '' });
      })
      .catch(err => {
        console.log(err)
        setErrors({ ...errors, [field]: err.message });
      });
  }

  const { accountStore } = useStore()
  const { roles, EditAccount, LoadingRoles, LoadingAccountEditForm, selectedAccount, loadingInitial } = accountStore
  const { summaryStore } = useStore();
  const { LoadingCompany, companys } = summaryStore

  const navigate = useNavigate();
  useEffect(() => {
    LoadingRoles()
    LoadingCompany();

  }, [])



  async function handleNext() {

    try {
     await validationSchema.validate(record, { abortEarly: false });
      setErrors({})
      console.log("handle next successfully")
      setPage();

    }
    catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors: Record<string, string> = {}; // Define the shape of the errors object
        err.inner.forEach((error) => {
          if (error.path) { // Check if path is defined
            newErrors[error.path] = error.message;
          }
        });
        setErrors(newErrors);
        console.log(newErrors)
      }
      return;
    }

  }
  return (

    <div className="mt-4 mb-[10px] flex flex-col gap-4" >

      <span className="text-[20px] font-medium text-decoration-line: underline">General Information</span>
      <div className="flex gap-5">
        <InputElement handleValidation={handleValidationChange} error={errors.firstName} width="444px" title={"First Name"} name={"firstName"} isRequired setRecord={setRecord} record={record} value={record.firstName} />
        <InputElement handleValidation={handleValidationChange} error={errors.lastName} width="444px" title={"Last Name"} name={"lastName"} isRequired setRecord={setRecord} record={record} value={record.lastName} />

      </div>
      <SelectElement handleValidation={handleValidationChange} title={"Role"} name={"role"} isRequired={true} record={record} setRecord={setRecord} defValue={record.role} options={roles} error={errors.role} />
      <SelectElement handleValidation={handleValidationChange} defValue={record.companyId} isWithApi={true} isRequired={true} record={record} setRecord={setRecord} name={"companyId"} title={"Company"} options={companys} error={errors.companyId} />

      <InputElement error={errors.phoneNumber} handleValidation={handleValidationChange} title={"Mobile Number"} name={"phoneNumber"} isRequired={false} setRecord={setRecord} record={record} value={record.phoneNumber} />




      <div className="flex justify-center gap-5 mt-5">
        <IButton type="button" text={"Next"} onClick={handleNext} />
        <IButton onClick={() => { navigate(-1) }} type="button" text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" />

      </div>
    </div>

  )
})
