import React from 'react'

interface Prop{
    leftIcon?:string;
    rightIcon?:string

}
const iconProps = {
    castcoIcon:"/assets/login/icon/castco.png",
    iotIcon:"/assets/login/icon/iot.png",
    verLine: "/assets/login/icon/verLine.png",

}
export default function LoginIcons({leftIcon, rightIcon}:Prop) {
  return (
    <div className='h-full flex gap-3 items-center'>
        <img src={iconProps.castcoIcon} />
        <img src={iconProps.verLine} />
        <img src={iconProps.iotIcon} />

    </div>
  )
}
