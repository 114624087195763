import { makeAutoObservable, runInAction } from "mobx";
import { CustomerDto, CustomerForm } from "../models/Customer";
import agent from "../services/Api/agent";
import { AccountCreateForm, AccountDto, AccountEditForm, ChangePasswordFormVal } from "../models/Account";

export default class AccountStore {

    loadingInitial = false;
    submitting = false;

    roles: string[] = [];
    Accounts: AccountDto[] = [];
    selectedAccount: AccountEditForm | undefined = undefined;
    count: number = 0;


    constructor() {
        makeAutoObservable(this);
    }

    LoadingAccountList = async () => {
        this.loadingInitial = true

        try {
            const accountList = await agent.AccountManagement.list();

            runInAction(() => {
                this.Accounts = accountList ? accountList : []
                this.loadingInitial = false
            })
            console.log(accountList);

        }
        catch (error) {
            this.loadingInitial = false
            throw error
        }

    }

    LoadingAccountEditForm = async (id: string) => {
        if (id === undefined) return undefined;
        this.loadingInitial = true;
        var account: AccountEditForm;
        try {
            await agent.AccountManagement.details(id).then(
                response => {
                    if (response) {
                        account = { ...response };

                        Object.keys(account).forEach((key: string) => {
                            const value = (account as any)[key];
                            if (key === 'projectIds' && typeof value === 'string') {
                                (account as any)[key] = JSON.parse(value);
                            } else {
                                (account as any)[key] = value !== null
                                    ? value : undefined;
                            }
                        });
                    }
                }
            )
            runInAction(() => {
                this.selectedAccount = account;
            })
            this.loadingInitial = false
            return this.selectedAccount;
        }
        catch (error) {
            this.loadingInitial = false
            throw error
        }

    }
    EditAccount = async (record: AccountEditForm) => {
        this.submitting = true;
        try {
            const formData = new FormData();
            for (const key in record) {
                const value = (record as Record<string, any>)[key];

                if (value !== undefined) {
                    if (value instanceof Array) { //handling projectId array
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            }
            await agent.AccountManagement.update(formData);
            this.submitting = false;
        }
        catch (error) {
            this.submitting = false;
            throw error;


        }
    }

    CreateAccount = async (record: AccountCreateForm) => {
        this.submitting = true;
        try {
            await agent.AccountManagement.create(record);
            this.submitting = false;
        }
        catch (error) {
            this.submitting = false;
            throw error;
        }
    }

    LoadingRoles = async () => {
        this.loadingInitial = true;
        try {

            const roles = await agent.AccountManagement.loadingRoles();
            runInAction(() => {
                this.roles = roles ? roles : []
            })
            this.loadingInitial = false

        }
        catch (error) {
            this.loadingInitial = false
            throw error
        }
    }

    getUserHistory = async (id?: string) => {
        try {
            if (id == null) return;
            return await agent.AccountManagement.getHistory(id);
        }
        catch (error) {
            throw error
        }
    }

    LoadUserProjectDto = async (userId: string) => {
        this.loadingInitial = true;
        try {
            const ProjList = await agent.AccountManagement.LoadUserProjectDto(userId);
            this.loadingInitial = false;
            return ProjList
        }
        catch (error) {
            this.loadingInitial = false;
            throw error
        }
    }

    ChangePassword = async (form: ChangePasswordFormVal) => {
        this.submitting = true;
        try {
            await agent.Account.changePw(form);
            this.submitting = false;
        }
        catch (error) {
            this.submitting = false;
            throw error;
        }
    }

    
}