import { makeAutoObservable, runInAction } from "mobx";
import { Project, ProjectDto, ProjectForm, ProjectRequest, ProjectResponse } from "../models/Project";
import agent from "../services/Api/agent";
import { AlarmLevel, AlarmLevelsMsgToolId, AlarmLevelsUsersId } from "../models/Alarm";


const handleDates = (object: any) => {
    Object.keys(object).forEach((key: string) => {
        const value = object[key];
        object[key] = value !== null && key.includes('Date')
            ? value.split('T')[0]
            : value;
    });
};


const handleMsgTools = (project: Project, msgTools: AlarmLevelsMsgToolId[]) => {


    if (project.alarm) {
        for (let i = 0; i < project.alarm.length; i++) {
            for (let j = 1; j <= project.alarm[i].alarmLevels.length; j++) {
                const msgToolArray = msgTools.find(x => x.alarmIndex === i && x.level === j)?.msgToolId
                if (msgToolArray != undefined) {
                    project.alarm[i].alarmLevels[j - 1].msgToolId = msgToolArray; // may have bug
                }
            }
        }
    }
}

const handleUserIds = (project: Project, userIds: AlarmLevelsUsersId[]) => {

    if (project.alarm) {
        for (let i = 0; i < project.alarm.length; i++) {
            for (let j = 1; j <= project.alarm[i].alarmLevels.length; j++) {
                const userIdsArray = userIds.find(x => x.alarmIndex === i && x.level === j)?.usersId
                if (userIdsArray != undefined) {
                    project.alarm[i].alarmLevels[j - 1].usersId = userIdsArray; // may have bug
                }
            }
        }
    }

}

export default class ProjectStore {

    loadingInitial = false;
    submitting = false;

    //Projects: ProjectDto[] = [];
    selectedProject: Project | undefined = undefined
    filterPlaceholder = "Filter Project Name";



    constructor() {
        makeAutoObservable(this);
    }

    ChangePlaceHolder = (p: string) => {
        this.filterPlaceholder = p;
    }

    LoadingProjectList = async (selectedId?: string) => {
        this.loadingInitial = true;

        try {
            let ProjList: ProjectDto[] = [];
            var temp = await agent.Projects.list()
            ProjList = temp ? temp : [];

            var temp2 = await agent.Projects.list(selectedId);
            ProjList = temp2 ? temp2 : [];

            this.loadingInitial = false;
            //console.log(ProjList)
            return ProjList;

        }
        catch (error) {
            this.loadingInitial = false;
            throw error
        }


    }

    CreateProject = async (record: ProjectForm) => {
        this.submitting = true;

        try {
            console.log(record)
            const alarmLevelsMsgToolId = record.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    msgToolId: alarmLevel.msgToolId,
                }))
            ) || [];

            const alarmLevelsUsersId = record.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    usersId: alarmLevel.usersId,
                }))
            ) || [];
            var request: ProjectRequest = { ...record, alarmLevelsMsgToolId: alarmLevelsMsgToolId, alarmLevelsUsersId: alarmLevelsUsersId };
            console.log(request)

            await agent.Projects.create(record, alarmLevelsMsgToolId, alarmLevelsUsersId);
            this.submitting = false;
        }
        catch (error) {
            this.submitting = false;
            throw error
        }
        this.submitting = false;

    }

    EditProject = async (record: ProjectForm) => {
        this.submitting = true;

        try {
            console.log(record)
            const alarmLevelsMsgToolId = record.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    msgToolId: alarmLevel.msgToolId,
                }))
            ) || [];
            const alarmLevelsUsersId = record.alarm?.flatMap((alarm, index) =>
                alarm.alarmLevels.map(alarmLevel => ({
                    alarmIndex: index,
                    level: alarmLevel.level,
                    usersId: alarmLevel.usersId,
                }))
            ) || [];

            var projectRequest: ProjectRequest = { ...record, alarmLevelsMsgToolId: alarmLevelsMsgToolId, alarmLevelsUsersId: alarmLevelsUsersId };
            await agent.Projects.update(projectRequest);
            this.submitting = false;
        }
        catch (error) {
            this.submitting = false;
            throw error
        }


    }

    LoadingProject = async (id: string) => {
        if (id === undefined) return undefined;
        this.loadingInitial = true;

        var responseProj: Project | undefined;
        var responseMsgtool: AlarmLevelsMsgToolId[];
        var responseUsers: AlarmLevelsUsersId[];

        try {
            console.log("loading ... project");

            await agent.Projects.details(id).then(
                response => {

                    if (response) {
                        const { alarmLevelsUsersId, alarmLevelsMsgToolId, ...project } = response;
                        responseProj = { ...project };
                        responseMsgtool = alarmLevelsMsgToolId ? [...alarmLevelsMsgToolId] : [];
                        responseUsers = alarmLevelsUsersId ? [...alarmLevelsUsersId] : [];
                        handleDates(responseProj)
                        console.log(responseMsgtool);
                        if (responseMsgtool.length > 0) {
                            handleMsgTools(responseProj, responseMsgtool);
                        }
                        if (responseUsers.length > 0) {
                            handleUserIds(responseProj, responseUsers);
                        }
                    }
                }
            );
            runInAction(() => {
                this.selectedProject = responseProj;
                console.log("final msgt, ", responseMsgtool, responseProj)
            });
            this.loadingInitial = false;
            console.log(" returning project", responseProj)
            return responseProj;
        } catch (error) {
            this.loadingInitial = false;

            throw error;
        }
    }

    getProjectHistory = async (id?: string) => {
        try {
            if (id == null)
                return
            return await agent.Projects.getHistory(id);
        }
        catch (error) {
            throw error
        }
    }

    LoadSrcTypeId = async (id: string) => {
        try {
            var temp = await agent.Projects.loadAlarmSrcTypeId(id);
            const ids: string[] = temp ? temp : [];
            console.log(ids)
            return ids;

        }
        catch (error) {
            throw error
        }
    }

    // LoadProjectListbyStatus = async (status:boolean) => {
    //     this.loadingInitial = true;

    //     try {
    //         const ProjList = (await agent.Projects.list()).filter(x=>x.status==status);
    //         this.loadingInitial = false;
    //        return ProjList
    //     }
    //     catch (error) {
    //         this.loadingInitial = false;
    //         throw error
    //     }
    // }

    loadProjectDtosByIds = async (ids: string[]) => {
        try {
            this.loadingInitial = true
            const projs = await agent.Projects.loadProjectDtosByIds(ids);
            this.loadingInitial = false
            return projs
        }
        catch (error) {
            this.loadingInitial = false
            throw error;
        }
    }


}