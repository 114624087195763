import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { InventoryForm } from '../../../models/Inventory';
import { InputElement } from '../../FormElement/InputElement';
import { Link, useNavigate } from 'react-router-dom';
import { IButton } from '../../Button/Button';
import { useStore } from '../../../stores/store';
import { CustomerForm } from '../../../models/Customer';
interface Prop {
  selectedId?: string;
}

export default observer(function CustomerViewForm({ selectedId }: Prop) {
const navigate = useNavigate()
  const { customerStore } = useStore()
  const { LoadingCustomer, selectedCustomer, loadingInitial } = customerStore
  useEffect(() => {
    if (selectedId) {
      LoadingCustomer(selectedId)
    }

  }, [selectedId])



  return (
    <div className="mt-4 mb-[10px] flex flex-col gap-4 ">
      <span className="text-[20px] font-medium text-decoration-line: underline">General Information</span>
      <InputElement readonly={true} title={"Name"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.name ? selectedCustomer?.name :"--" } />
      <InputElement readonly={true} title={"Company Name (Alias)"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.companyName ? selectedCustomer?.companyName :"--"} />
      <InputElement readonly={true} title={"Company Address (Street Adress)"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.companyAddress? selectedCustomer?.companyAddress :"--"} />

      <span className="text-[20px] font-medium text-decoration-line: underline">Contact Information</span>
      <div className="flex gap-5 flex-col">
        <InputElement readonly={true} title={"Tel No. (Phone)"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.telNo ? selectedCustomer?.telNo :"--"} />
        <InputElement readonly={true} title={"Mobile No. (Cell Number)"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.mobileNo  ? selectedCustomer?.mobileNo :"--"} />

      </div>
      <InputElement readonly={true} title={"Fax No."} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.faxNo ? selectedCustomer?.faxNo :"--"} />
      <InputElement readonly={true} title={"Email Address"} name={"name"} isRequired={false} setRecord={() => { }} record={""} value={selectedCustomer?.email ? selectedCustomer?.email :"--"} />

      <div className="flex justify-center mt-4 mb-5">
          <IButton onClick={()=>{navigate(-1)}} type="button" text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" />
      </div>
    </div >
  )
})
