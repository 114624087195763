import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import CheckboxInfo from './Checkbox/CheckboxInfo';
import AudienceField from '../Field/AudienceField';
import { IButton } from '../../../../Button/Button';
import { useStore } from '../../../../../stores/store';
import { ProjectForm } from '../../../../../models/Project';

interface props {
    record: ProjectForm
    setRecord: (arr: {}) => void
    level: number
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
}

const MODAL_STYLES = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

}

const OVERLAY_STYLES = {
    zIndex: 999,

    width: '100vw',
    height: '100vh',
    backgroundColor: ' rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
};

interface props {
    record: ProjectForm
    setRecord: (arr: {}) => void
    alarmIndex: number
    level: number
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
}

interface fieldProp {
    title: string | string[]
    set: (arr: boolean) => void
    textColor: 'Black' | 'Grey'
}

export default function EditLevelInfoTable({ record, setRecord, alarmIndex, level, isOpen, setisOpen }: props) {
    const [blur, setBlur] = useState(false);
    const CONTENT_STYLES = {


        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        zIndex: 1000,
        padding: '32px',
        boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        maxHeight: '100vh',
        overflowY: 'auto',
        filter: blur ? 'blur(2px)' : 'none',
    };


    const [isChildOpen, setChildOpen] = useState(false);
    const [graphInfoisOpen, setGraphInfoisOpen] = useState(false);
    const [msgInfoisOpen, setMsgInfoisOpen] = useState(false);

    const { summaryStore } = useStore();
    const { LoadingGraphType, graphTypes, LoadingMessegeTool, messegeTools, getGraphTypeName, getMsgtool , getGraphTypeNameNoAsync} = summaryStore;

    useEffect(() => {
        LoadingGraphType();
        LoadingMessegeTool();
    }, [])

    function handlePreSave() {

        setRecord((prev: ProjectForm) => ({
            ...prev,
            alarm: prev.alarm?.map((alarmItem, idx) =>
                idx === alarmIndex
                    ? {
                        ...alarmItem,
                        alarmLevels: alarmItem.alarmLevels.map((levelItem, levelIdx) =>
                            levelIdx === level - 1
                                ? { ...levelItem, msgToolId: msgtoolIds.length === 0 ? undefined : msgtoolIds.map(String) }
                                : levelItem
                        )
                    }
                    : alarmItem
            )
        }))
        console.log("Presave,", record)
    }

    function handleGraphInfoIsOpen(arr: boolean) {
        if (arr && isChildOpen) {
            return;
        }
       
        setChildOpen(arr);
        setBlur(arr);
        setGraphInfoisOpen(arr);
    }

    function handleMsgInfoIsOpen(arr: boolean) {
        if (arr && isChildOpen) {
            return;
        }
       
        setChildOpen(arr);
        setBlur(arr);
        setMsgInfoisOpen(arr);
    }


    const [msgtoolIds, setMsgtoolIds] = useState(() => {
        if (
          record &&
          record.alarm &&
          Array.isArray(record.alarm) &&
          record.alarm.length > alarmIndex &&
          record.alarm[alarmIndex].alarmLevels &&
          record.alarm[alarmIndex].alarmLevels.length >= level &&
          record.alarm[alarmIndex].alarmLevels[level - 1]
        ) {
          return (record.alarm[alarmIndex].alarmLevels[level - 1].msgToolId ?? []).map(Number);
        }
        return [];
      });


    const fields: fieldProp[] =
        [
        { title: msgtoolIds.length > 0 ? getMsgtool(msgtoolIds) : "Message Tool", set: handleMsgInfoIsOpen, textColor: msgtoolIds.length > 0 ? 'Black' : 'Grey' },
        ]

    function arraysAreEqual(arr1: number[], arr2: number[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }
    function handleCancel() {

       
        //////////////////////////////////////////////
        const recordMsgIds = record?.alarm?.[alarmIndex]?.alarmLevels[level - 1]?.msgToolId?.map(Number);
        if (!recordMsgIds || recordMsgIds?.length === 0) {
            setMsgtoolIds([]);
            return;
        }
        if (!arraysAreEqual(recordMsgIds, msgtoolIds)) {
            setMsgtoolIds(recordMsgIds)
        }


    }

    function setAllClear() {
        setMsgtoolIds([])
        console.log("msgTools", msgtoolIds)
    }

    useEffect(() => {
        if (isOpen || isChildOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [isOpen, isChildOpen]);

    if (isOpen === false) return null;


    return ReactDOM.createPortal(


        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} />
            <div style={CONTENT_STYLES as React.CSSProperties}>
                <div className="pageParentDiv flex flex-col gap-8" >
                    <span className="text-[20px] font-medium text-decoration-line: underline">
                        Audiences Table {">"} Edit Row
                    </span>

                    <div className="flex">

                        <div className="  p-2 flex w-[230px] h-[50px] border-2 border-[#D9D9D9] items-center" >
                            <span className="text-[#111111]">
                                Level {level}
                            </span>
                        </div>
                        {fields.map((field, index) =>
                            <AudienceField textColor={field.textColor} setisOpen={field.set} index={index} title={field.title} />


                        )}
                        <div className="border-t-2 cursor-pointer p-2 flex w-[230px] h-[50px] border-r-2 border-b-2 border-[#D9D9D9] items-center" onClick={setAllClear} >
                            <span className="text-[#CC311B]">
                                Clear
                            </span>
                        </div>
                    </div>

                    <CheckboxInfo multiSelection={msgtoolIds} setMultiSelection={setMsgtoolIds} options={messegeTools} title={'Message Tool'} isOpen={msgInfoisOpen} setisOpen={handleMsgInfoIsOpen} />

                    <div className="flex justify-center items-center  mt-2" style={{ height: "77px" }}>
                        <div className="flex gap-8" >
                            <IButton type="button" text="Pre-Save" onClick={() => {
                                if (!isChildOpen) { setisOpen(false) }
                                handlePreSave()
                            }} />

                            <IButton type='button' text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="[#15304B]"
                                onClick={() => {
                                    if (!isChildOpen) { setisOpen(false) }
                                    handleCancel()
                                }} />
                        </div>
                    </div>


                </div>
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement






    )
}
