import React from 'react'

import { observer } from "mobx-react-lite";
import { states } from "../../pages/SourceType/SourceTypeRecord";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/store";
import { useState } from "react";
import { Page } from '../../components/TitlePath/TitlePath';
import { CoustomTableHeaderProps } from '../../components/Table/CoustomTable';
import { SymbolTextBox } from '../../components/Input/SymbolTextbox';
import GeneralButton from '../../components/Button/GeneralButton';

interface Props {
    buttonChoice:string

    lastPages?:Page[]
    title:string
    
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];

}

export default observer(function DataTableHeader({buttonChoice, title,lastPages,setState, setButtonChoice, options, statesArray }: Props) {

    const navigate = useNavigate();

    const [filterPlaceholder,ChangePlaceHolder]=useState("Filter Name");

    const filterProps = {
        placeholder: filterPlaceholder,//
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",

    }



    return (
        <div className='  flex  pl-3 my-4 flex-col gap-4  '>

           
            <div className='flex justify-between'>
                <div className='flex' >
                    <SymbolTextBox
                buttonChoice={buttonChoice}

                    changePlaceholderValue={ChangePlaceHolder}
                        
                        options={options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={filterProps.placeholder}
                        onChange={(e) => setState(e.target.value)}
                        statesArray={statesArray}
                        setButtonChoice={setButtonChoice} />
                </div>
                
            </div>
        </div>
    )
}
)