import { ChangeEvent } from "react";
const errorMsg = {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '-2px'
}
interface DateInputProps<T> {
    width?: string
    name: string;
    title?: string;
    isRequired?: boolean
    setRecord: (input: T) => void;
    record: T & { [key: string]: any }; // Add index signature to the type of 'record'
    backgroundColor?: string
    type?: 'text' | 'date' | 'datetime-local';
    error?: string;
    error2?: string;
    textColor?: string;
    className?: string;
    value?: string | number | readonly string[] | undefined;
}

export const CustomCalendar = <T extends {}>({ error2, width, value, className, textColor, error, name, record, setRecord, title, isRequired, backgroundColor, type }: DateInputProps<T>) => {


    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        const newValue = event.target.value === '' ? undefined : event.target.value;
        setRecord({ ...record, [name]: newValue })
    }
    return (
        <div className={`${width ? width : "w-full"} h-full flex-col justify-between`}>

            <div className='mb-2'>

                <label className="w-full" >
                    <p className={` w-full flex justify-between whitespace-nowrap ${isRequired ? "text-[#CC311B]" : ""}`}>
                        {title && isRequired && "*"}

                        {title}
                        
                        {error2 && <span className="text-[#CC311B]">
                            **{error2}
                        </span>
                        }
                    </p>
                </label>
            </div>

            <div>
                <input
                    className={`w-full  border-2 rounded-lg border-[#ACACAC] px-4 py-1 ${backgroundColor ? "bg-" + backgroundColor : "bg-white"} ${textColor ? "text-" + textColor : "text-black"} ${className ?? ""}`}
                    type={type ?? "date"}
                    name={name}
                    value={record[name]}
                    onChange={handleChange}

                />
                {error && <p style={errorMsg}>{error}</p>}
            </div>
        </div>
    );
};