import React, { FormEvent, useEffect, useState } from 'react'
import CreateFormHeader from '../../../components/form/CreateFormHeader/CreateFormHeader'
import { TableHistory } from '../../../models/Inventory';
import { Page } from '../../../components/TitlePath/TitlePath';
import { AccountEditErrorValue, AccountEditForm, AccountEditValue, IAccountEditError } from '../../../models/Account';
import { useNavigate, useParams } from 'react-router-dom';
import AccountFormEdit from '../../../components/form/Account/EditForm/AccountFormEdit';
import { useStore } from '../../../stores/store';
import ProjectTypeHeader, { TypeHeaderItem } from '../../../components/ProjectRecord/ProjectTypeHeader/ProjectTypeHeader';
import AccountEditProject from '../../../components/form/Account/EditForm/AccountEditProject';
import * as yup from 'yup';



const validationSchema = yup.object({
    firstName: yup.string().trim().min(1, "First name can't be empty").matches(/^[A-Za-z ]+$/, "First name must contain only English letters"),
    lastName: yup.string().trim().min(1, "Last name can't be empty").matches(/^[A-Za-z ]+$/, "First name must contain only English letters"),
    role: yup.string().required('Role is required').trim().min(1, "Role can't be empty"),
    companyId: yup.number().required('Company is required'),
    phoneNumber: yup.string()
      .nullable() // empty or must contain 8
      .trim().matches(/^(|[0-9]{8})$/, "Phone Number must be empty or contain exactly 8 digits"),
      email: yup.string().required("Email can't be empty").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Fill in a correct email"),
  
  });

export default function AccountEditPage() {
    const { accountStore } = useStore()
    const { roles, EditAccount, LoadingRoles, LoadingAccountEditForm, selectedAccount, loadingInitial } = accountStore
    const navigate = useNavigate();


    const [errors, setErrors] = useState<Partial<IAccountEditError>>(new AccountEditErrorValue());
    const [history, setHistory] = useState<TableHistory | undefined>(undefined);
    const { id } = useParams();
    const [record, setRecord] = useState<AccountEditForm>(new AccountEditValue());
    const { getUserHistory, submitting } = accountStore;

    const handleValidationChange = (field: string, value: any) => {
        validationSchema.validateAt(field, {
          [field]: value
        })
          .then(() => {
            setErrors({ ...errors, [field]: '' });
          })
          .catch(err => {
            console.log(err)
            setErrors({ ...errors, [field]: err.message });
          });
      }

      
    useEffect(() => {
        const fetchHistory = async () => {
            const data = await getUserHistory(id);
            setHistory(data);
        }
        fetchHistory();
        // console.log(history)
    }, [])
    const lastPages: Page[] = [{ title: "Account Management", path: "/account" }]

    const [page, setPage] = useState(0);

    const handleHeaderStateChange = async(page: number) => {
        if (page === 1) {
            try {
                await validationSchema.validate(record, { abortEarly: false });
                setErrors({})
                console.log("submit form successfully")
              }
              catch (err) {
                if (err instanceof yup.ValidationError) {
                  const newErrors: Record<string, string> = {}; // Define the shape of the errors object
                  err.inner.forEach((error) => {
                    if (error.path) { // Check if path is defined
                      newErrors[error.path] = error.message;
                    }
                  });
                  setErrors(newErrors);
                  console.log(newErrors)
                }
                return false;
              }
        }
        setPage(page)
        return true
    }

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            await validationSchema.validate(record, { abortEarly: false });
            setErrors({})
            console.log("submit form successfully")
          }
          catch (err) {
            if (err instanceof yup.ValidationError) {
              const newErrors: Record<string, string> = {}; // Define the shape of the errors object
              err.inner.forEach((error) => {
                if (error.path) { // Check if path is defined
                  newErrors[error.path] = error.message;
                }
              });
              setErrors(newErrors);
              console.log(newErrors)
            }
            return ;
          }

        EditAccount(record).then(() => navigate("/account"))
            .catch(error => {
                console.log(error)
                let apiErrors = error.response.data.errors;
                if (apiErrors != undefined) {
                  var newError: Partial<IAccountEditError> = {}
                  for (let key in apiErrors) {
                    if (apiErrors.hasOwnProperty(key)) {
                      console.log(key, error.response.data.errors[key])
                      newError = { ...newError, [key]: error.response.data.errors[key] }
        
                    }
                  }
                  
                  const firstPageKeys = ['firstName', 'lastName','role', 'companyId', 'phoneNumber', 'email']
                  if(Object.keys(newError).some(x=>firstPageKeys.includes(x))){
                    setPage(0)
                  }
                  setErrors(newError)
                }
                return;
        
            })

    }
    const InventoryTypeHeaderAllItems:TypeHeaderItem[] = [
        { imgSrc: "/assets/ProjectTypeHeader/profile.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/profile.png", isSelected: false, title: "Account Profile"},
        { imgSrc: "/assets/ProjectTypeHeader/audiences.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/audiences.png", isSelected: false, title: "Assign Project"},
      ]
      
    return (
        <div className="pageParentDiv flex flex-col px-5">
            <CreateFormHeader
                registeredBy={history?.registeredBy}
                registeredOn={history?.registeredOn}
                updateBy={history?.updateBy}
                updateOn={history?.updateOn}
                titleText={"Edit Account Record"}
                lastPages={lastPages}
                formType={"account record"}
                page={page}
                changePage={handleHeaderStateChange}
                isCreate={false}
                InventoryTypeHeaderAllItems={InventoryTypeHeaderAllItems}
            />
            {page === 0 &&
                <AccountFormEdit handleValidationChange={handleValidationChange} errors={errors} handleNext={() => handleHeaderStateChange(1)} selectedId={id} record={record} setRecord={setRecord} />
            }
            {page === 1 &&
                <AccountEditProject handleSubmit={handleSubmit} record={record} setRecord={setRecord} />
            }
        </div>
    )
}
