import { useState } from "react";
import { IAlarmError, IAlarmLevelError } from "../../../../models/Alarm";
import { IProjectError, ProjectForm } from "../../../../models/Project";

export const ProjectFormValidation = (project: ProjectForm) => {
    var alarmError: (IAlarmError[] | undefined) = undefined;

    const errors: Partial<IProjectError> = {};
    if (project.name === "") {
        errors.name = "Input field is required!";
    }

    if (project.companyId === "") {
        errors.companyId = "Input field is required!";
    }

    if (project.startDate === "" || project.endDate === "") {
        errors.startDate = "Input field is required!";
    }
    var alarmVal = AlarmValidation(project);
    
    if (alarmVal!= undefined) {
        return { ...errors, alarm: alarmVal };
    }
    else {
        return errors
    }
}

const AlarmValidation = (record: ProjectForm) => {

    var alarmErrorList: IAlarmError[] | undefined = undefined

    const errorString = "Input Field is Required!"
    //first loop, to check ever error? else not assign

    if (record.alarm) { // and have error
        for (var recAlarm of record.alarm) {
            for (var alarmlvl of recAlarm.alarmLevels) {
                if (alarmlvl.conditionId === undefined || alarmlvl.conditionId === "") {
                    alarmErrorList = []

                }
                if (alarmlvl.value === undefined) {
                    alarmErrorList = []
                }
            }
        }

        if (alarmErrorList === undefined) {
            return alarmErrorList;
        }


        for (var recAlarm of record.alarm) {
            var alarmError: IAlarmError = {
                alarmLevels: []
            };



            for (var alarmlvl of recAlarm.alarmLevels) {
                var alarmLevelError: IAlarmLevelError = {}
                if (alarmlvl.conditionId === undefined || alarmlvl.conditionId === "") {
                    alarmLevelError.conditionId = errorString
                }
                if (alarmlvl.value === undefined ) {
                    alarmLevelError.value = errorString
                }
                alarmError.alarmLevels?.push(alarmLevelError)
            }
            alarmErrorList.push(alarmError)
        }
    }
    return alarmErrorList;
}