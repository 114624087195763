import TableCopyButton from "./TableCopyButton";
import TableDeleteButton from "./TableDeleteButton";

const exportprops = {
    exportcross: "assets/button/Vector.png",
    exporticon: "assets/button/excel.svg",
    deleteIcon: "assets/button/bin.png",
    copyIcon: "assets/button/copy.png"
}

interface ExportButtonprop {
    onClick: () => void;
}

interface ExportToExcelprop {
    handleDeleteClick?: () => void;
    handleCopyClick?: ()=>void
    onClick: () => void;
    items: number;
    handlecross: () => void;
}

interface ExportTextsprop {
    items: number;
}

const donothing = () => { }

const Exportbutton = ({ onClick }: ExportButtonprop) => {
    return <div onClick={onClick} className="cursor-pointer border-2 bg-[#D0D6DB] w-[120px] h-[32px] rounded-full flex justify-center items-center">
        <div className="m-[8px]"><img src={exportprops.exporticon} className="w-[21.875px] h-[20.313px]" /></div>
        <p className="w-[53px] h-[20px] text-base ">Export</p>
    </div>
}

const ExportText = ({ items }: ExportTextsprop) => {
    return <>
        <div className="w-[118px] h-[20px] text-white items-center">
            {items === 1 && <> {items} item selected</>}
            {items !== 1 && <> {items} items selected</>}
        </div>
    </>
}

export const ExportToExcelButton = ({handleCopyClick,  handleDeleteClick, onClick, items, handlecross }: ExportToExcelprop) => {



    return <>
        <div className="border-2 bg-[#15304B]  h-[45px] rounded-full px-3 py-1 flex   gap-1 items-center ">
            {/* Not sure what the cross button is supposed to do, not displayed in figma */}
            <div><img src={exportprops.exportcross} className="cursor-pointer w-[13.333px] h-[13.333px]" onClick={handlecross} /></div>

            <ExportText items={items} />
            <Exportbutton onClick={items === 0 ? donothing : onClick} />


            {handleDeleteClick && <TableDeleteButton onClick={items === 0 ? donothing : handleDeleteClick} iconSrc={exportprops.deleteIcon} />}


            {handleCopyClick && items <= 1 && 
                <TableCopyButton onClick={items === 0 ? donothing : handleCopyClick} iconSrc={exportprops.copyIcon} />
            }
        </div>
    </>

}

