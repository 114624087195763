import * as echarts from 'echarts';
import { useEffect, useRef, useState } from 'react';
import { GraphData, GraphForm } from '../../../models/Pharse2/Graph';
import { store } from '../../../stores/store';
import { boolean } from 'yup';

interface Prop {
  record: GraphData;
  customMaxY?: number;
  stateColor: string[];
  stateType: string[];
  stateGraphColor: string[];
  updateDummy: number;
  showDisplay: boolean;
  localForm: GraphForm;
  setDummy: (x: number)=> void
}

export const BarGraph = ({ record, customMaxY, stateColor, stateType, stateGraphColor,setDummy, updateDummy, showDisplay, localForm}: Prop) => {
  const maxYData = store.graphStore.calMax(record.yData.flat())
  const maxMarkLineValue =  record.alarmLevels? Math.max(maxYData, ...record.alarmLevels?.map(alarmlevel=>isNaN(alarmlevel.value)? 0: alarmlevel.value)): maxYData
  const yAxisMax = customMaxY === undefined || customMaxY === null || isNaN(customMaxY) ? Math.max(maxYData, maxMarkLineValue) : customMaxY;
  const customColor = stateColor
  const customGraphColor = stateGraphColor
  const customType = stateType
  const dummyCount = updateDummy
  const displayStatus = showDisplay
  var runtimecount = 0;
  const getOption = () => {

    return {
      
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: record.locationName,
      },
      grid: {
        left: '75px',
        right: '75px',
        bottom: '50px',
        containLabel: true
      },
      toolbox: {
        show: localForm.projectId ===70? false: true,
        feature: {
          saveAsImage: {},
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          iconStyle: {
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: record.xData[0],
        axisLabel: {
          rotate: 45,
          interval: 'auto'
        }
      },
      yAxis: {
        type: 'value',
        max: yAxisMax,
        name:  record.yUnit? record.yAxis +` (${record.yUnit})`:record.yAxis,
        nameLocation: 'middle',
        nameGap: 40
      },
      // Add 'inside' data zoom for mouse scroll and touch gestures
      dataZoom: [
        {
          type: 'inside', // Zoom in/out with mouse wheel, touch pinch
          start: 0, // Initial zoom start percentage
          end: 100 // Initial zoom end percentage
        },
        {
          type: 'slider', // Optional: if you want to keep the slider as well
          start: 0,
          end: 100,
          show: localForm.projectId ===70? false: true
        }
      ],
      color: customGraphColor,
      series: record.locationName.map((item, idx) => {
        return {
          onclick:{},
          label:{
            position: 'top',
            show: displayStatus,
            formatter:'{c}'
          },
          name: record.locationName[idx],
          type: 'bar',
          data: record.yData[idx],
          colorBy:'series',
          markLine: {
            
            label: {
              position: 'end', // Position the label at the end/start/middle of the line
              formatter: '{b}', // Format the label to show the name
              align: 'right',
              offset: [0, -10]
            },
            data: record.alarmLevels?.map((alarmlevel) => {
              return {
                yAxis: alarmlevel.value,
                name: record.alarmUnit? alarmlevel.name + ` (${record.alarmUnit})`: alarmlevel.name,
                lineStyle: {
                  color: customColor[runtimecount], // Set the color of the markLine here
                  type: customType[runtimecount++] // Optional: can be 'solid', 'dashed', or 'dotted'
                },
              }
            })
          }
        }
      })
      ,
    }
  }

  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.EChartsType | null>(null);


  useEffect(() => {
    // Initialize the chart instance on mount
    chartInstance.current = echarts.init(chartRef.current);

    // Set the option for the chart
    chartInstance.current.setOption(getOption());
    if (true || localForm.projectId === 70){                                                                  /*Project 70*/
      chartInstance.current.on('click', 'series.line', function (params) {
        if (params.seriesIndex != undefined && params.componentType === "series" ){
			  if(window.confirm("are you sure to hide this point?")){
				/*record.yData[params.seriesIndex].splice(params.dataIndex, 1)
				record.xData.splice(params.dataIndex, 1)*/
        record.yData[params.seriesIndex][params.dataIndex] = NaN
				runtimecount = 0
				chartInstance.current!.setOption(getOption());
				setDummy((updateDummy+1))
			}
        }
      })
    };
    return () => {
      // Dispose the chart instance on unmount
      chartInstance.current?.dispose();
    };
  }, [record, customMaxY, stateColor, stateType, stateGraphColor, updateDummy, showDisplay]);

  return (
    <>
      <div
        ref={chartRef}
        id="echarts-container"
        style={{
          width: '100%',
          height: '600px', // Set a height for the chart
        }}
      />
    </>
  );



}

