import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { InputElement } from "../../FormElement/InputElement";
import { SelectElement } from "../../FormElement/SelectElement";
import { TextAreaElement } from "../../FormElement/TextAreaElement";
import GeneralButton from "../../Button/GeneralButton";
import { LoggerInfoTable } from "./LoggerInfo/LoggerInfoTable";
import { RoundButton } from "../../Button/RoundButton";
import { useNavigate } from "react-router-dom";
import { ILoggerSetError, LoggerSetErrorValue, LoggerSetFormValue } from "../../../models/LoggerSet";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { Inventory, InventoryDto, InventoryForm, LoggerFormInventoryValue } from "../../../models/Inventory";
import LoggerSetFormValidation from "../validations/LoggerSetFormValidation";
import { runInAction } from "mobx";
import SummaryStore from './../../../stores/SummaryStore';
import { ComponentSet, ComponentSetProps } from "./ComponentSet";
import { Default } from "react-toastify/dist/utils";
import LoadingComponent from "../../LoadingComponent";
import { Location, LocationProject } from "../../../models/Location";
import LoggersetLocationPage from "./LoggersetLocation/LoggersetLocationPage";
import LoggersetCreateFormNew from './LoggersetCreateFormNew';


interface props {
    selectedId?: string;
}

export default observer(function LoggerSetCreateForm({ selectedId }: props) {


    const [loggersetLocation, setLoggersetLocation] = useState<LocationProject | undefined>();
    const navigate = useNavigate();
    const [record, setRecord] = useState<LoggerSetFormValue>(new LoggerSetFormValue());

    const { loggerSetStore } = useStore();
    const { LoadLoggerLocation, loadingInitial, submitting, editLoggerSet, CreateLoggerset, LoadSelectedLoggerset, selectedLoggerSet } = loggerSetStore

    const [loggerInfoIsOpen, setLoggerOpen] = useState(false);
    const [sensorInfoIsOpen, setSensorOpen] = useState(false);
    const [otherequipInfoIsOpen, setOtherequipOpen] = useState(false);

    const openLoggerInfo = () => {
        setLoggerOpen(true)
    }
    const openSensorInfo = () => {
        setSensorOpen(true)
    }
    const openOtherInfo = () => {
        setOtherequipOpen(true)
    }

    const getInventoryIds = (type: "Logger" | "Sensor" | "Other", loggerset?: LoggerSetFormValue) => {
        var ids: string[] = []
        if(loggerset){
            switch (type) {
                case "Logger":
                    for (var logger of loggerset.loggerList) {
                        if (logger.id) {
                            ids.push(String(logger.id))
                        }
                    }
                    break;
                case "Sensor":
                    for (var sensor of loggerset.sensorList) {
                        if (sensor.id) {
                            ids.push(String(sensor.id))
                        }
                    }
                    break;
                default:
                    for (var other of loggerset.otherEquipmentList) {
                        if (other.id) {
                            ids.push(String(other.id))
                        }
                    }
            }
        }
        return ids;
    }

    const [loggerIds, onLoggerIdsChange] = useState<string[]>([]);
    const [sensorIds, onSensorIdsChange] = useState<string[]>([]);
    const [otherIds, onOtherIdsChange] = useState<string[]>([]);

    const { inventoryStore } = useStore();

    const onChangeRecord = async (inventoryType: "Logger" | "Sensor" | "Other", ids: string[]) => {
        // Fetch the new Inventory objects
        switch (inventoryType) {
            case "Logger":
                const newLoggerList = await inventoryStore.fetchInventoryByIds(ids)
                setRecord({ ...record, loggerList: newLoggerList });
                break;
            case "Sensor":
                const newSensorList = await inventoryStore.fetchInventoryByIds(ids)
                setRecord({ ...record, sensorList: newSensorList });
                break;
            default:
                const newOtherList = await inventoryStore.fetchInventoryByIds(ids)
                setRecord({ ...record, otherEquipmentList: newOtherList });
        }
    }

    useEffect(() => { // fetch

        const fetchData = async (id: string) => {
            await LoadSelectedLoggerset(id).then(i => {
                setRecord(new LoggerSetFormValue(i))
                const loggerIds = getInventoryIds("Logger", i)
                const sensorIds = getInventoryIds("Sensor", i)
                const otherIds = getInventoryIds("Other", i)
                console.log(loggerIds, sensorIds, otherIds)
                onLoggerIdsChange(loggerIds);
                onSensorIdsChange(sensorIds);
                onOtherIdsChange(otherIds);
            }
            );
            await LoadLoggerLocation(id).then(i => {
                setLoggersetLocation(i)
                console.log(i)
                console.log(loggersetLocation)
            }
            )

        }
        if (selectedId) {
            fetchData(selectedId);
        }

    }, [selectedId, LoadSelectedLoggerset])

    const [Inventories, setInventories] = useState<InventoryDto[] | undefined>();

    const [errors, setErrors] = useState<Partial<ILoggerSetError>>(new LoggerSetErrorValue());

    const { summaryStore } = useStore();
    const { LoadingLogStatus, statuses } = summaryStore

    useEffect(() => {
        LoadingLogStatus(selectedId);
    }, [selectedId])

    const handleCancelPage = () => {
        navigate(-1)
    }

    const showIds = () => {
        console.log(record)
        console.log("logger", loggerIds)
        console.log("sensor", sensorIds)
        console.log("other", otherIds)

    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var error = LoggerSetFormValidation(record)
        if (Object.keys(error).length !== 0) {
            console.log("error")
            setErrors(error);
            return;
        }

        if (selectedId) {
            editLoggerSet(record).then(() => navigate('/LoggersetMaintenance'))
        } else {
            CreateLoggerset(record).then(() => navigate('/LoggersetMaintenance'))
        }
        ;
    }


    if (loadingInitial || submitting)
        return <LoadingComponent content="Loading activity..." />;
    return (
        <form onSubmit={handleSubmit} autoComplete="off" >

            <LoggerInfoTable Inventories={Inventories} selectedId={selectedId} record={record} onChangeRecord={(ids: string[]) => onChangeRecord("Logger", ids)} selectedIds={loggerIds} isOpen={loggerInfoIsOpen} setisOpen={setLoggerOpen} onSelectedIdsChange={onLoggerIdsChange} inventoryType="Logger" />
            <LoggerInfoTable Inventories={Inventories} selectedId={selectedId} record={record} onChangeRecord={(ids: string[]) => onChangeRecord("Sensor", ids)} selectedIds={sensorIds} isOpen={sensorInfoIsOpen} setisOpen={setSensorOpen} onSelectedIdsChange={onSensorIdsChange} inventoryType="Sensor" />
            <LoggerInfoTable Inventories={Inventories} selectedId={selectedId} record={record} onChangeRecord={(ids: string[]) => onChangeRecord("Other", ids)} selectedIds={otherIds} isOpen={otherequipInfoIsOpen} setisOpen={setOtherequipOpen} onSelectedIdsChange={onOtherIdsChange} inventoryType="Other" />

            <div className="mt-4 mb-[10px] flex flex-col gap-4">
                <span onClick={showIds} className="text-[20px] font-medium text-decoration-line: underline">General Information</span>
                <InputElement value={record.name} error={errors.name} title={"Logger Set Name "} name={"name"} isRequired={true} setRecord={setRecord} record={record} />
                <SelectElement width="[230px]" defValue={record.statusId} isWithApi={true} error={errors.status} isRequired={true} options={statuses} title={"Status"} name={"statusId"} setRecord={setRecord} record={record} />
                <InputElement value={record.remark} title={"Remark"} name={"remark"} isRequired={false} setRecord={setRecord} record={record} />
                <TextAreaElement height="h-[120px]" value={record.remarkDescription} title={"Remark Description"} name={"remarkDescription"} isRequired={false} setRecord={setRecord} record={record} />
            </div>

            <div className="flex flex-col gap-3">
                <div>
                    <span className="text-[20px] font-medium text-decoration-line: underline">Logger Set Component</span>
                    {record.loggerList.map((logger) => (
                        <>
                            <ComponentSet type="Logger" inventory={logger} />
                        </>
                    ))}
                    <GeneralButton title={"Edit Logger"} imgSrc={"/assets/button/circleAdd.png"} handleClick={openLoggerInfo} />
                </div>
                <div className="border-t-4 border-[D9D9D94D] border-dashed" />

                <div>
                    {record.sensorList.map((sensor) => (
                        <>
                            <ComponentSet type="Sensor" inventory={sensor} />
                        </>
                    ))}
                    <GeneralButton title={"Edit Sensor"} imgSrc={"/assets/button/circleAdd.png"} handleClick={openSensorInfo} />
                </div>
                <div className="border-t-4 border-[D9D9D94D] border-dashed" />


                <div>
                    {record.otherEquipmentList.map((equip) => (
                        <>
                            <ComponentSet type="Other Equipment" inventory={equip} />


                        </>
                    ))}
                    <GeneralButton title={"Edit Other Equipment"} imgSrc={"/assets/button/circleAdd.png"} handleClick={openOtherInfo} />
                </div>

            </div>

            {selectedId && loggersetLocation && <LoggersetLocationPage record={loggersetLocation} />}


            <div className="flex gap-16 items-center justify-center m-[40px]">
                <RoundButton type="submit" handleClick={() => { }} title={selectedId ? "Edit" : "Create"} backgroundColor={"#15304B"} border={"#15304B"} textColor={"#FFF"} />
                <RoundButton type={"button"} handleClick={() => handleCancelPage()} title={"Cancel"} backgroundColor={"#FFF"} border={"#15304B"} textColor={"#15304B"} />
            </div>







        </form>
    )
})
