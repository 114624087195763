import { useState } from "react"
import { GraphData, Graph_AlarmLevelDto } from "../../models/Pharse2/Graph"
import { useStore } from "../../stores/store"



interface Props {
    projectId?: number
    id: number
    record: GraphData
    dummyadd: () => void
}


export default function AlertLineConfig({projectId, id, record, dummyadd}: Props) {
    const [index, setIndex] = useState(Math.floor(id/3))
    const newValue = (Math.max(...record.alarmLevels!.map(alarmlevel => alarmlevel.level))) + 1 
    const tempElement = ({alarmId: newValue, conditionId: newValue, level: newValue, name:"New Alert Line", value: 0})
    const { userStore, commonStore } = useStore();
	const { user, CheckIfHkiaEmail } = userStore;
    
        /*const specialCondition=()=>{                                Any VIP user requirement
        if (user?.username) === 
    }
        */
    const handleAdd = () =>{
        record.alarmLevels?.push(tempElement) 
        return dummyadd()
    }
    const handleDelete =()=>{
        if (record.alarmLevels != undefined){
            if (record.alarmLevels[index] != undefined){
                record.alarmLevels.splice(index, 1)
                return dummyadd()
            }
        }
    }
    const handleValueChange = (x: number) =>{
        if (record.alarmLevels != undefined){
            if (record.alarmLevels[index] != undefined){
                if (Number.isNaN(x)){
                    record.alarmLevels[index].value = 0
                }
                else {
                    record.alarmLevels[index].value = x
                }
            }
        }
        return dummyadd()
    }

    const handleNameChange = (x: string) =>{
        if (record.alarmLevels != undefined){
            if (record.alarmLevels[index] != undefined){
                record.alarmLevels[index].name = x
            }
        }
        return dummyadd()
    }
    {
        return <div>
                    <input onChange={(e)=>{handleNameChange(e.target.value)}} className="w-[100%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block flex flex-row p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter alert name" />
                    <input onChange={(e)=>{handleValueChange(parseFloat(e.target.value))}} type="number" className="w-[100%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block flex flex-row p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter alert value" />
                    <div className="mt-[5px]">
                        <img src="/assets/button/orangeAdd.png" className="h-[30px] w-[30px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 float-left block flex flex-row dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onClick={handleAdd}/>
                        <img src="/assets/button/cross/cross.png" className="h-[30px] w-[30px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 float-right block flex flex-row dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onClick={handleDelete}/>
                    </div>
                </div>
    }
}