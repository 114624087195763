import { SelectListOption } from "./Graph";
import PerformancePage from './../../pages/PerformanceSummary/PerformancePage';
import { FieldProp } from './../../components/form/Project/NewAlarmControlTable/Field/AudienceField';

export class PerformanceError{
 projectId: boolean = false;
    locationId: boolean = false;
    sourceTypeId: boolean = false;
    intervalId : boolean = false;
    summaryId : boolean = false;
   
}

export interface IPerformanceForm {
    projectId: number;
    locationId: number;
    sourceTypeId: number;
    intervalId : number
    summaryId: number
    filterProp:FilterProp
    page:number
}
class FilterProp{
    dateTimeState:string = ""
    valueState:string = ""
}
export class PerformanceFormVal implements IPerformanceForm {
    projectId: number = -1;
    locationId: number = -1;
    sourceTypeId: number = -1;
    intervalId : number = -1;
    summaryId: number = -1;
    filterProp:FilterProp = new FilterProp();
    page = 0;

    constructor(init?: PerformanceFormVal) {
        Object.assign(this, init);
    }

}
export class DataOptions{
    projectList:SelectListOption[] = []
    locationList:SelectListOption[] = []
    sourceTypeList:SelectListOption[] = []
}

export interface PerformTableDto{
        search_ProjectName : string
        search_LocationName : string
        search_SourceTypeName : string
        search_Interval:string
        search_Summary:string
        datas:PerformTableRow[]
        count:number
}
export interface PerformTableRow{
    dateTime?:string
    locationName?:string

    value?:number
}

export interface SummaryType{
    name:string
    id:number
}
export interface IntervalType{
    name:string
    id:number
}