import path from 'path';
import React from 'react'
import { Link } from 'react-router-dom';

export interface MenuProps {
    imgSrc: string;
    handleClick: () => void;
    title: string;
    path: string;
}

export default function LoginMenuItem({ imgSrc, handleClick, title, path }: MenuProps) {
    return (
        <Link to={path}>
            <div className="block px-3 py-2 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={handleClick} >
                <img className="inline" src={imgSrc} />
                <span className="ml-2">
                    {title}
                </span>
            </div>
        </Link>
    )
}
