import React, { useState } from 'react'


interface Props {
    title: string;

}

export default function LoginCheckbox({ title, }: Props) {
    const [isChecked, setChecked] = useState(localStorage.getItem("rememberMe") === "true" ? true : false);
    const checkedSrc = "/assets/login/checkbox/blackChecked.png"
    const notCheckedSrc = "/assets/login/checkbox/blackCheckbox.png"
  
   
   
    return (
        <div className="flex items-center gap-1">
            <img src={isChecked?checkedSrc:notCheckedSrc} onClick={()=>{setChecked((prev)=>{if(!prev === true){localStorage.setItem("rememberMe","true")}else{localStorage.setItem("rememberMe","false")} return !prev})}}/>
            <span className="text-[13px] text-trans-gray ">
               {title}
            </span>
        </div>
    )
}
