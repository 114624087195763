import React from 'react'
interface Prop {
    imgSrc?: string;
}
export default function VerticalLine({ imgSrc }: Prop) {
    return (
        // <div>
        //     <img className="self-stretch object-cover" 
        //     src={imgSrc ? imgSrc : "/assets/form/line.png"} />
        // </div>
         <div className="hidden min-[1150px]:block "
         style={{ 
             backgroundImage: `url(/assets/form/line.png)`,
             backgroundRepeat: 'repeat-y',
             backgroundSize: 'cover',
             width: '10px'
         }}

     />
 
    )
}
