import React from 'react'

interface Prop {
    src?: string;
    showPhoto: boolean;
}

export default function PhotoElement({ src, showPhoto }: Prop) {

    if (!showPhoto)
        return (
            <div className="h-[250px] w-[90%] bg-[#D9D9D94D] "></div>
        )

    return (

        <div className="w-full flex-grow flex justify-center items-center mt-3">
            <div className="flex h-[240px] items-ceter justify-center">
                <img style={{ maxHeight: "100%" }} src={src} />
            </div>

        </div>

    )
}
