import React, { useEffect, useState } from 'react'
import { CustomCalendar } from '../../components/Input/CustomCalendar'
import { AlarmFormValue, IAlarmForm } from './../../models/Pharse2/Alarm';
import { SelectElement } from '../../components/FormElement/SelectElement';
import GeneralButton from '../../components/Button/GeneralButton';
import { useStore } from '../../stores/store';
import { DataOptions } from '../../models/Pharse2/Data';



export default function AlarmForm() {
    const [record, setRecord] = useState<IAlarmForm>(new AlarmFormValue());
    const { graphStore } = useStore();
    const [dataOptions, setDataOptions] = useState(new DataOptions())

    useEffect(() => { //fetch project options
        graphStore.getprojectList().then(i => {
            if (i) setDataOptions({ ...dataOptions, projectList: i })
        })
    }, [])

    useEffect(() => { // clear locationId, after the project Id is selected
        if (record.projectId > 0) {
            setRecord({ ...record, locationId: -1 })
        }
    }, [record.projectId])

    const handleProjectIdSelected = (newId: number | string | boolean) => {
        // after select the project item. fetch
        if (typeof newId === 'number') {

            graphStore.getlocationList(newId).then(i => { if (i) setDataOptions({ ...dataOptions, locationList: i }) })
        }

    }

    return (
        <div className="border-b-[4px] border-gray-400">

            <form className="w-full flex flex-col gap-3   h-full mt-2" >
                <div className="w-full flex flex-row gap-3 items-center ">
                    <div className="w-[45%]">
                        <CustomCalendar name={"fromDate"} setRecord={setRecord} record={record} title="From" />
                    </div>
                    <div className="w-[45%]">
                        <CustomCalendar name={"toDate"} setRecord={setRecord} record={record} title="To" />
                    </div>
                </div>
                <div className="w-full flex flex-row gap-3 items-center justify-between pb-2">
                    
                    <div style={{ flexGrow: 1 }}>
                        <SelectElement defValue={record.projectId} isWithApi={true} isRequired={false} options={dataOptions.projectList} title={"Project"} name={"projectId"} setRecord={setRecord} record={record} />

                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <SelectElement defValue={record.locationId} isWithApi={true} isRequired={false} options={dataOptions.locationList} title={"Location"} name={"locationId"} setRecord={setRecord} record={record} />

                    </div>
                    <div className=''>
                        <GeneralButton title={"Search"} handleClick={() => { }} />

                    </div>
                </div>
            </form>
        </div>
    )
}
