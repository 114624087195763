import React, { FormEvent, useState } from 'react'
import { InputElement } from '../../components/FormElement/InputElement'
import { LoginInput } from '../../components/LoginElement/LoginInput'
import { ChangePasswordFormVal, ChangePwErrorValue, IChangePwError } from '../../models/Account'
import { IButton } from '../../components/Button/Button'
import { useStore } from '../../stores/store'
import { useNavigate } from 'react-router-dom'

export default function ChangePwForm() {
  const navigate = useNavigate();
  const [record, setRecord] = useState<ChangePasswordFormVal>(new ChangePasswordFormVal())
  const [error, setError] =useState<Partial<IChangePwError>>(new ChangePwErrorValue);

  const clearRecord = () => {
    setRecord(new ChangePasswordFormVal())
  }

  const {accountStore} = useStore();

  const ChangePwFormValidation = (form :ChangePasswordFormVal )=>{
    var error: Partial<IChangePwError> = {}
    if(form.oldPassword === "" )
      error.oldPassword = "Input field is required!"
      
    if(form.newPassword===""){
      error.newPassword = "Input field is required!"
    }
    else if (form.newPassword !== form.confirmPassword){
      error.newPassword = "Different with new password!"
    }

    if(form.confirmPassword==="" ) 
      error.confirmPassword = "Input field is required!"

    return error
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var formError = ChangePwFormValidation(record);
    console.log(record)
    if (Object.keys(formError).length !== 0) {
      console.log(formError)
      setError(formError);
      return;
    }
    accountStore.ChangePassword(record).then(()=> navigate("/home"));
  }


  return (
    <form onSubmit={handleSubmit} autoComplete="off" className="mt-4 mb-[10px] flex flex-col gap-4">
      <span className="text-[20px] font-medium text-decoration-line: underline">General Information</span>

      <LoginInput title={"Old Password"} name={"oldPassword"} value={record.oldPassword} record={record} setRecord={setRecord} isPassword={true} isRequired={true} error={error.oldPassword} />
      <LoginInput title={"New Password"} name={"newPassword"} value={record.newPassword} record={record} setRecord={setRecord} isPassword={true} isRequired={true} error={error.newPassword} />
      <LoginInput title={"Confirm Password"} name={"confirmPassword"} value={record.confirmPassword} record={record} setRecord={setRecord} isPassword={true} isRequired={true} error={error.confirmPassword} />


      <div className="flex justify-center gap-5 mt-5 flex-wrap">
        <IButton type="submit" text={"Submit"} onClick={() => { }} className='w-full'/>
        <IButton type="button" text="Clear" onClick={clearRecord} backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" className='w-full'/>

      </div>

    </form>
  )
}
