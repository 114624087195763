import React, { useEffect, useState } from 'react'
import InventoryStatusButton from './InventoryStatusButton';

interface props {
    isLoggerset?: boolean;
    setStatusState: (x: string) => void;
    statusState?: string
}

export default function StatusSwitch({ isLoggerset, setStatusState, statusState }: props) {
    //'blue' | 'green' | 'red' | 'black' | 'all' |'testingBlue'
    const [color, setColor] = useState<string>(statusState ??'all')
    
    const invConvert = (c: string) => {
        switch (c) {
            case "all":
                return "blue";
            case "blue":
                return "green";
            case "green":
                return "red";
            case "red":
                return "black";
            case "black":
                return "all";
        }
        return "none"

    }

    const logConvert = (c: string) => {

        switch (c) {
            case "all":
                return "blue";
            case "blue":
                return "green";
            case "green":
                return "red";
            case "red":
                return "black";
            case "black":
                return "testingBlue";
            case "testingBlue":
                return "all";
        }
        return "none"

    }
const handleClick = ()=>{
    let newColor = isLoggerset?logConvert(color): invConvert(color)
    setColor(newColor) 
    setStatusState(newColor)
}

    return (
        <div className='flex  items-center'>
            <span>
                Status:
            </span>
            <div className="w-[176px] h-full flex items-center justify-center" onClick={handleClick}>
                <InventoryStatusButton type={color} />
            </div>
        </div>
    )
}

