import { observer } from "mobx-react-lite";
import GeneralButton from "../../components/Button/GeneralButton";
import { NewToggleSwitch } from "../../components/FormElement/ToggleButton";
import { SymbolTextBox } from "../../components/Input/SymbolTextbox";
import { compLabel, components } from "../../components/SourceTypeRecord/SourceTypeHeader";
import { CoustomTableHeaderProps } from "../../components/Table/CoustomTable";
import { useStore } from "../../stores/store";
import { states } from "../SourceType/SourceTypeRecord";
import { useNavigate } from 'react-router-dom';
import TitlePath, { Page } from "../../components/TitlePath/TitlePath";

interface props {
    buttonChoice: string
    state: string
    activeState: string


    lastPages?: Page[]
    title: string

    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    setactiveState: (x: string) => void;
}

export default observer(function LocationHeader(p: props) {

    const navigate = useNavigate();
    const { locationStore } = useStore();
    const { filterPlaceholder, ChangePlaceHolder } = locationStore;


    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",

    }

    //to-be-editted: when filter option is changed, placeholder string should change accordingly

    const createLocation = () => {
        navigate('/location/create');
    }


    const createButton = { title: "Create New Location", imgSrc: '/assets/button/recAdd.png', Click: createLocation }

    return (
        <div className='flex flex-col gap-4'>
            <TitlePath currentTitle={p.title} lastPages={p.lastPages} />

            <div className='flex flex-col  lg:flex-row justify-between gap-3'>
                <div className='flex' >
                    <SymbolTextBox
                        buttonChoice={p.buttonChoice}
                        defaultValue={p.state}
                        changePlaceholderValue={ChangePlaceHolder}
                        setactiveState={p.setactiveState}
                        options={p.options}
                        IconLeft={filterProps.IconLeft}
                        IconRight={filterProps.IconRight}
                        placeholder={filterProps.placeholder}
                        onChange={(e) => p.setState(e.target.value)}
                        statesArray={p.statesArray}
                        setButtonChoice={p.setButtonChoice} />
                </div>
                <div className='flex flex-col md:flex-row gap-3'>
                    <div className=' flex items-center ' >
                        <span >Status:</span>
                        <NewToggleSwitch value={p.activeState === "Active" ? true : undefined} setActiveState={p.setactiveState} name={"activeStatus"} />
                    </div>
                    <div className='w-full' >
                        <GeneralButton title={createButton.title} imgSrc={createButton.imgSrc} handleClick={createLocation} />
                    </div>
                </div>
            </div>
        </div>
    )
})