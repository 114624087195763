import { ThemeProvider } from "@mui/material/styles"
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../../Table/CoustomTable"
import { FormEvent, useEffect, useRef, useState } from "react";
import { LoggerInfoTableHeader } from "./LoggerInfoTableHeader";
import { states } from "../../../../pages/SourceType/SourceTypeRecord";
import ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../stores/store";
import InventoryStatusButton from "../../../Button/InventoryStatusButton";
import { statusColorToRecord } from "../../../../pages/Inventory/InventoryManagement";
import { LoggerSetFormValue } from "../../../../models/LoggerSet";
import { InventoryDto } from "../../../../models/Inventory";



interface loggerinfo {

  Loggername: string,
  id: string,
  LoggerserialNo: string,
  Loggerstatus: "red" | "black" | "green" | "blue" | "all" | undefined,
  Loggerlocation?: string,
  pathId: string,
  inventoryType: "Logger" | "Sensor" | "Other";
}

interface props {
  Inventories: InventoryDto[] | undefined

  onChangeRecord: (ids: string[]) => void
  selectedId?: string;
  record?: LoggerSetFormValue;
  isOpen: boolean;
  setisOpen: (x: boolean) => void;
  selectedIds: string[]
  onSelectedIdsChange: (arr: string[]) => void;
  inventoryType: "Logger" | "Sensor" | "Other";
}

export const MODAL_STYLES = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

}

export const OVERLAY_STYLES = {
  zIndex: 999,

  width: '100vw',
  height: '100vh',
  backgroundColor: ' rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(2px)',
};

export const CONTENT_STYLES = {

  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  zIndex: 1000,
  padding: '32px',
  boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  maxHeight: '100vh',
  maxWidth: '100vw',
  overflowY: 'auto',
};



export const LoggerInfoTable = observer(({ Inventories: inv, selectedId, onChangeRecord, selectedIds, record, onSelectedIdsChange, isOpen, setisOpen, inventoryType }: props) => {


  const [page, setPage] = useState(0);

  const [LoggernameState, setLoggernameState] = useState("");
  const [LoggerequipmentIdState, setLoggerequipmentIdState] = useState("");
  const [LoggerserialNoState, setLoggerserialNoState] = useState("");
  const [buttonChoice, setButtonChoice] = useState("Loggername");
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

  const { inventoryStore, loggerSetStore } = useStore();
  const { LoadingInventoryListByStatus } = inventoryStore
  const [Results, setResults] = useState<loggerinfo[]>([]);


  const [Inventories, setInventories] = useState<InventoryDto[]>(inv ? inv : []);

  useEffect(() => {
    if (inv) {
      setInventories(inv)

    }
  }, inv)



  useEffect(() => {

    const loadInventories = async () => {

      await loggerSetStore.getInfoTableInventories(selectedIds).then((i) => {
        setInventories(i);
        console.log("fetch", selectedIds)
      })

    };
    loadInventories();


  }, [inventoryStore]);

  const handleCancel = () => {
    const initialRows: Array<CoustomTableRowProps> =
      Results.filter((item) => {
        return LoggernameState.toLowerCase() == "" ? item
          : item.Loggername.toLowerCase().includes(LoggernameState.toLowerCase());
      }).filter((item2) => {
        return LoggerequipmentIdState.toLowerCase() == "" ? item2
          : item2.id.toLowerCase().includes(LoggerequipmentIdState.toLowerCase());
      }).filter((item2) => {
        return LoggerserialNoState.toLowerCase() == "" ? item2
          : item2.LoggerserialNo.toLowerCase().includes(LoggerserialNoState.toLowerCase());
      }).filter((item2) => {
        return item2.inventoryType === inventoryType  // filtering inventoryType
      }).map(
        (record: loggerinfo) => ({

          data: [
            { id: "Loggername", data: record.Loggername },
            { id: "id", data: record.id },
            { id: "LoggerserialNo", data: record.LoggerserialNo },
            { id: "Loggerstatus", JSXelement: <InventoryStatusButton type={record.Loggerstatus} handleClick={() => { }} />, data: statusColorToRecord(record.Loggerstatus), isJSXelement: true },
            { id: "Loggerlocation", data: record.Loggerlocation }
          ],


          Id: record.pathId,

          ischecked: selectedIds.includes(record.pathId)



        }))
    setrows(initialRows);
    setisOpen(false);
  }

  useEffect(() => {
    const initialRows: Array<CoustomTableRowProps> =
      Results.filter((item) => {
        return LoggernameState.toLowerCase() == "" ? item
          : item.Loggername.toLowerCase().includes(LoggernameState.toLowerCase());
      }).filter((item2) => {
        return LoggerequipmentIdState.toLowerCase() == "" ? item2
          : item2.id.toLowerCase().includes(LoggerequipmentIdState.toLowerCase());
      }).filter((item2) => {
        return LoggerserialNoState.toLowerCase() == "" ? item2
          : item2.LoggerserialNo.toLowerCase().includes(LoggerserialNoState.toLowerCase());
      }).filter((item2) => {
        return item2.inventoryType === inventoryType  // filtering inventoryType
      }).map(
        (record: loggerinfo) => ({

          data: [
            { id: "Loggername", data: record.Loggername },
            { id: "id", data: record.id },
            { id: "LoggerserialNo", data: record.LoggerserialNo },
            { id: "Loggerstatus", JSXelement: <InventoryStatusButton type={record.Loggerstatus} handleClick={() => { }} />, data: statusColorToRecord(record.Loggerstatus), isJSXelement: true },
            { id: "Loggerlocation", data: record.Loggerlocation }
          ],


          Id: record.pathId,

          ischecked: selectedIds.includes(record.pathId)



        }))
    setrows(initialRows);
  }, [Results, LoggernameState, LoggerequipmentIdState, LoggerserialNoState, selectedIds])

  useEffect(() => {

    const Results: loggerinfo[] = Inventories.map(
      (inventory) => ({
        Loggername: inventory.equipmentName,
        id: inventory.equipmentCode? inventory.equipmentCode : 'N/A',
        LoggerserialNo: inventory.serialNo,
        Loggerstatus: inventory.statusId === 1 ? 'red' : inventory.statusId === 2 ? 'green' : inventory.statusId === 3 ? 'black' : 'blue',
        Loggerlocation: "(123)",
        pathId: String(inventory.id),
        inventoryType: inventory.inventoryTypeId === 1 ? "Logger" : inventory.inventoryTypeId === 2 ? "Sensor" : "Other",

      })
    )
    setResults(Results)

  }, [Inventories, inventoryStore, inventoryType])


  const statesArray: states[] = [
    {
      currentstate: LoggernameState,
      statemethod: setLoggernameState
    },
    {
      currentstate: LoggerequipmentIdState,
      statemethod: setLoggerequipmentIdState
    },
    {
      currentstate: LoggerserialNoState,
      statemethod: setLoggerserialNoState
    }
  ]

  const headers: Array<CoustomTableHeaderProps> = [
    { id: "Loggername", data: inventoryType == "Logger" ? "Logger Name" : inventoryType == "Sensor" ? "Sensor Name" : "Other Equipment Name" },
    { id: "id", data: "Equipment ID" },
    { id: "LoggerserialNo", data: "Serial No." },
    { id: "Loggerstatus", data: "Status" },
    { id: "Loggerlocation", data: "(Location)" },

  ];

  const activeId = useRef<string | null>(null);


  const settingState = buttonChoice === "Loggername"
    ? setLoggernameState
    : buttonChoice === "id"
      ? setLoggerequipmentIdState
      : setLoggerserialNoState

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // Reset the filter state when the modal is closed.
      setLoggernameState("");
      setLoggerequipmentIdState("");
      setLoggerserialNoState("");
      document.body.style.removeProperty('overflow');
    }
  }, [isOpen]);

  if (isOpen == false) return null;

  return ReactDOM.createPortal(

    <div className="content" style={MODAL_STYLES as React.CSSProperties}>
      <div style={OVERLAY_STYLES as React.CSSProperties} />
      <div style={CONTENT_STYLES as React.CSSProperties}>
        <div className="pageParentDiv " style={{ borderRadius: '20px' }}>
          <LoggerInfoTableHeader buttonChoice={buttonChoice} title={inventoryType} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={settingState} setButtonChoice={setButtonChoice} options={headers.filter((item) => item.id == "Loggername" || item.id == "id" || item.id == "LoggerserialNo")} />
          <ThemeProvider theme={customTableTheme}>
            <CoustomTable page={page} setPage={setPage} onChangeIdsRecord={(ids: string[]) => onChangeRecord(ids)} record={record} onSelectedIdsChange={onSelectedIdsChange} cancelButton={handleCancel} assignButton={() => setisOpen(false)} headers={headers} rows={rows} setRows={setrows} activeId={activeId} tableMarginRight="10" />
          </ThemeProvider>


        </div>
      </div>
    </div>,
    document.getElementById('portal') as HTMLElement



  )
})