import { observer } from 'mobx-react-lite'
import React, { Dispatch, SetStateAction } from 'react'
import { Container } from "semantic-ui-react";
import { useEffect, useState } from "react";
import AMapLoader from '@amap/amap-jsapi-loader';
import { LocationMarker, LoggersetDetailsDto, ProjectDistinct } from "../../../models/Pharse2/LocationMap";

import { all } from "axios";
import { store, useStore } from '../../../stores/store';
import { Dictionary, colorAlgo } from './Algo/ColorAlgo';
import { GraphForm, GraphFormVal } from '../../../models/Pharse2/Graph';
import LoadingComponent from '../../../components/LoadingComponent';
import MapDetails from './Info/MapDetails';
import LoggersetMap from './Map/LoggersetMap';
import NewMap from './Map/NewMap';
import ProjectDisplayBoard from './ProjectDisplayBoard';
import { SelectElement } from '../../../components/FormElement/SelectElement';
import { AALocationDetailsDto } from '../../../models/Pharse2/Overview';
import OverviewStore from './../../../stores/OverviewStore';

interface Prop {
	form: GraphForm
	setForm: (x: GraphForm) => void
	setInfoOpen: (x: boolean) => void
	setDetailsInfo: Dispatch<SetStateAction<any>>;
	setprojectoptionsname: (data: string) => void;
	useOldMap: boolean
}

export default observer(function MapParentContainer({ setDetailsInfo, setInfoOpen, form, setForm, useOldMap, setprojectoptionsname }: Prop) {
	const { locationMapStore, graphStore } = useStore();

	const [projectList, setProjectList] = useState<ProjectDistinct[]>([])
	const [projectOptions, setProjectOptions] = useState<ProjectDistinct[]>([])

	const [locationMarkerList, setLocationMarkerList] = useState<LocationMarker[]>([])
	const [allLocationMarkerList, setAllLocationMarkerList] = useState<LocationMarker[]>([])

	const [colorDictionary, setDictionary] = useState<Dictionary>()

	const [record, setRecord] = useState({ selectedProjectId: 99999999999999 })
	useEffect(() => {

		locationMapStore.loadProjectDistinctList().then((i) => {
			if (i) {
				setProjectList(i);
				setProjectOptions([{ id: 99999999999999, name: "All" } as ProjectDistinct, ...i,])
				console.log(i)
				var tempDictionary = colorAlgo(i)
				setDictionary(tempDictionary)
			}

			/**
			 * {
					if(item.locationAlert){
						item.url = "red.png"
					}else{
						item.url = tempDictionary[item.projectId].markerUrl
					}
				}
			 */

			locationMapStore.loadLocationMarkerList().then((i) => {
				if (i == undefined) return
				i.map((item) => {
					if (item.locationAlert >= 1) {
						item.url = "red.png"

						//tempDictionary[item.projectId] = {markerUrl:'red.png',code:'#FF0000'}
					} else {
						item.url = tempDictionary[item.projectId].markerUrl
					}
				})
				setLocationMarkerList(i)
				setAllLocationMarkerList(i)
			}
			)

		})
	}, [locationMapStore])

	useEffect(() => {
		locationMarkerList.map(marker => {
			if (marker.locationAlert >= 1) {
				var proj = [...projectList];
				proj.map(x => {
					if (x.id == marker.projectId) x.locationAlert = true;
				})
				setProjectList([...proj]);
			}
		})
	}, [locationMarkerList])

	useEffect(() => {
		if (record.selectedProjectId === 99999999999999) {
			setProjectList(projectOptions.filter(x => x.id !== 99999999999999))
			setLocationMarkerList(allLocationMarkerList)
			return;
		}

		setProjectList(projectOptions.filter(x => x.id == record.selectedProjectId))
		setLocationMarkerList(allLocationMarkerList.filter(x => x.projectId == record.selectedProjectId))
		setprojectoptionsname(projectList[0].name)
	}, [record])

	const handleClick = (locationId: number, projectId: number) => {
		setForm({ ...form, projectId: projectId, locationIds: [locationId], intervalId: 5, summaryId: 1 })

		const Data = locationMapStore.getDetailsInfo(locationId).then((i) => {
			setInfoOpen(true)
			setDetailsInfo(i);
		})
	}

	useEffect(() => {
		if (projectList.length > 1 && projectList.length !== 0){
			setprojectoptionsname(projectOptions.filter(x => x.id == record.selectedProjectId)[0].name)
		}else if (projectList.length !== 0){
			setprojectoptionsname(projectList[0].name)
		}
		
	}, [projectList])

	return (
		(<>
			{(locationMapStore.loadingInitial || store.overviewStore.loadingInitial) && <LoadingComponent />}
			<div className={` ${locationMapStore.loadingInitial ? `hidden` : ``}`}>
					{useOldMap && <LoggersetMap locationMarkerList={locationMarkerList} handleMarkersClick={handleClick} />}
					{!useOldMap && <NewMap locationMarkerList={locationMarkerList} handleMarkersClick={handleClick} />}
				<div className="flex flex-col lg:flex-row items-center pt-2">
					<ProjectDisplayBoard projectList={projectList} colorDictionary={colorDictionary} />
					<SelectElement defValue={record.selectedProjectId} isWithApi={true} width="[100%]" isRequired={false} record={record} setRecord={setRecord} name={"selectedProjectId"} options={projectOptions}/>
				</div>


			</div>
		</>
		)
	)
})
