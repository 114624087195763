import React, { useEffect, useState } from 'react'
import { CustomCalendar } from '../../components/Input/CustomCalendar'
import { SelectElement } from '../../components/FormElement/SelectElement'
import { InputElement } from '../../components/FormElement/InputElement'
import CheckboxInfo from '../../components/form/Project/NewAlarmControlTable/InfoTable/Checkbox/CheckboxInfo'
import { DataFormVal, IDataForm, DataOptions } from '../../models/Pharse2/Data'
import { useStore } from '../../stores/store'
import GeneralButton from '../../components/Button/GeneralButton'
import { setOptions } from 'react-chartjs-2/dist/utils'
import { observer } from 'mobx-react-lite'
import { IPerformanceForm, PerformTableDto, PerformanceError } from '../../models/Pharse2/Performance'
import { SelectListOption } from '../../models/Pharse2/Graph'


interface Prop {
    record: IPerformanceForm
    setRecord: (arr: IPerformanceForm) => void
    setPerformData: (arr: PerformTableDto | undefined) => void
}

const PerformanceFormValidation = (form: IPerformanceForm) => {
    var error = new PerformanceError();
    if (form.projectId === undefined || form.projectId === -1) {
        error.projectId = true
    }
    if (form.locationId === undefined || form.locationId === -1) {
        error.locationId = true
    }
    if (form.sourceTypeId === undefined || form.sourceTypeId === -1) {
        error.sourceTypeId = true
    }
    if (form.intervalId === undefined || form.intervalId === -1) {
        error.intervalId = true
    }
    if (form.summaryId === undefined || form.summaryId === -1) {
        error.summaryId = true
    }
    return error;
}

export default observer(function PerformanceForm({ setPerformData, record, setRecord }: Prop) {

    const [errors, setErrors] = useState<PerformanceError>(new PerformanceError())
    const [locationTableOpen, setLocationTable] = useState(false);
    const [dataOptions, setDataOptions] = useState(new DataOptions())
    const { graphStore, dataStore, summaryStore, performanceStore } = useStore();
    const { LoadingSummarys, LoadingIntervals, summaryTypes, intervalTypes } = summaryStore;

    const [projectOptions, setProjectOptions] = useState<SelectListOption[]>([])
    const [locationOptions, setLocationOptions] = useState<SelectListOption[]>([])
    const [sourceTypeOptions, setSourceTypeOptions] = useState<SelectListOption[]>([])

    useEffect(() => {
        LoadingSummarys();
        LoadingIntervals([5]);
    }, [])

    useEffect(() => { //load projectListFirst
        graphStore.getprojectList().then(i => {
            if (i) setProjectOptions(i)
        })
    }, [graphStore])

    useEffect(() => { //load projectListFirst
        graphStore.getlocationList(record.projectId).then(i => {
            if (i) {
                setLocationOptions(i)
                setSourceTypeOptions([])
            }
            setRecord({ ...record, locationId: -1, sourceTypeId: -1 })
        })

    }, [record.projectId])

    useEffect(() => {
        if (record.locationId > 0) {
            dataStore.getsourceTypeList(record.locationId).then(
                (i) => {
                    setRecord({ ...record, sourceTypeId: -1 })
                    if (i) {
                        setSourceTypeOptions(i)
                    }
                }
            )
        }
    }, [record.locationId])

    const setLocationIds = (Id: number) => {
        setRecord({ ...record, locationId: Id })
    }

    const handleSearch = () => {
        var newError = PerformanceFormValidation(record)
        setErrors(newError)
        if (Object.values(newError).some(x => x === true)) {
            return;
        }

        setRecord({ ...record, page: 0 })
        performanceStore.getData(record, true).then(i => {
            setPerformData(i);
        });
    }
    return (

        <div className="border-b-[4px] border-gray-400">
            <form className="w-full flex flex-col gap-4   h-[full] mt-2" >



                <div className="  flex gap-4 justify-between">

                    <div style={{ width: '32%' }}>
                        <SelectElement defValue={record.projectId} isWithApi={true} isRequired={errors.projectId} options={projectOptions} title={"Project"} name={"projectId"} setRecord={setRecord} record={record} />
                        {/* <SelectElement isWithApi={true} isRequired={false} options={locationList} title={"Location"} name={"locationId"} setRecord={setlocalRecord} record={localrecord} /> */}
                    </div>
                    <div style={{ width: '32%' }}>
                        <SelectElement defValue={record.locationId} isWithApi={true} isRequired={errors.locationId} options={locationOptions} title={"Location"} name={"locationId"} setRecord={setRecord} record={record} />

                    </div>
                    <div style={{ width: '32%' }}>
                        <SelectElement defValue={record.sourceTypeId} isWithApi={true} isRequired={errors.sourceTypeId} options={sourceTypeOptions} title={"Source Type"} name={"sourceTypeId"} setRecord={setRecord} record={record} onlyRead={record.locationId <= 0} />
                    </div>

                </div>

                <div className="w-full flex flex-row gap-4 items-center justify-between">
                    <div style={{ flexGrow: 1 }}>
                        <SelectElement defValue={record.intervalId} isWithApi={true} isRequired={errors.intervalId} options={intervalTypes} title={"Interval"} name={"intervalId"} setRecord={setRecord} record={record} />

                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <SelectElement defValue={record.summaryId} isWithApi={true} isRequired={errors.summaryId} options={summaryTypes} title={"Summary"} name={"summaryId"} setRecord={setRecord} record={record} />

                    </div>
                    <div className=' '>
                        <GeneralButton title={"Search"} handleClick={handleSearch} />
                    </div>
                </div>
            </form>


        </div>

    )
})
