import { FormEvent, useEffect, useState } from 'react'
import { CustomCalendar } from '../../Input/CustomCalendar';
import { UploadPhotoElement } from '../../FormElement/UploadPhotoElement';

import { TextAreaElement } from '../../FormElement/TextAreaElement';
import { IInventoryError, InventoryErrorValue, InventoryForm, InventoryFormValue } from '../../../models/Inventory';
import { SelectElement } from '../../FormElement/SelectElement';
import { InputElement } from '../../FormElement/InputElement';
import VerticalLine from '../../FormElement/VerticalLine';
import { IButton } from '../../Button/Button';
import { useStore } from '../../../stores/store';
import { observer } from 'mobx-react-lite';
import EquipmentLoggerSet from '../../FormElement/InventoryFormElement/EquipmentLoggerSet';
import InventoryStore from '../../../stores/InventoryStore';
import axios, { AxiosResponse } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ProjectInfoTable } from '../Project/ProjectInfo/ProjectInfoTable';
import { TextAreaTable } from '../../FormElement/TextAreaTable/TextAreaTable';
import { InventoryInfoTable } from './InventoryInfoTable';
import LoadingComponent from '../../LoadingComponent';
import ServerErrorMessage from '../../Message/Error/ServerErrorMessage';
import { ApiError } from '../../../models/Error';


interface Prop {
  paraPage?:number
  copyId?: string
  selectedId?: string;
  record: InventoryForm
  setRecord: (arr: InventoryForm) => void
}

const InventoryFormValidation = (inventory: InventoryForm) => {
  const errors: Partial<IInventoryError> = {};
  if (inventory.inventoryTypeId === undefined) {
    errors.inventoryTypeId = "Input field is required!";
  }

  if (inventory.sourceTypeId && inventory.sourceTypeId.length === 0) {
    errors.sourceTypeId = "Input field is required!";
  }

  if (inventory.equipmentCode === "") {
    errors.equipmentCode = "Input field is required!";
  }

  if (inventory.equipmentName === "") {
    errors.equipmentName = "Input field is required!";
  }

  if (inventory.manufacturerName === "") {
    errors.manufacturerName = "Input field is required!";
  }

  if (inventory.serialNo === "") {
    errors.serialNo = "Input field is required!";
  }

  if (inventory.modelNo === "") {
    errors.modelNo = "Input field is required!";
  }

  if (inventory.purchaseDate === undefined) {
    errors.purchaseDate = "Input field is required!";
  }

  if (inventory.purchaseCost === "") {
    errors.purchaseCost = "Input field is required!";
  }

  if (inventory.statusId === undefined) {
    errors.statusId = "Input field is required!";
  }


  return errors;
}


export default observer(function InventoryCreateForm({ copyId, record, setRecord, selectedId }: Prop) {
  const [serverError, setServerError] = useState<ApiError | undefined>();

  const navigate = useNavigate();
  const { summaryStore, inventoryStore } = useStore();
  const { submitting, loadingInitial, InventoryTypes, LoadingInventoryType, CreateInventory, EditInventory } = inventoryStore

  useEffect(() => {
    console.log("serverError updated:", serverError);
  }, [serverError]);

  const testCase1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const error: ApiError = {
      messageHeader: 'Test error',
      messageList: ['Test message']
    };
    setServerError(error);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var error = InventoryFormValidation(record);
    console.log(record)
    if (Object.keys(error).length !== 0) {
      console.log(error)
      setErrors(error);
      return;
    }

    console.log(record);
    if (selectedId) {
      EditInventory(record).then(() => navigate('/Inventory')).catch((error) => {

        const { data, status, config } = error.response as AxiosResponse;

        const newError: ApiError = {
          messageHeader: data[0] as string,
          messageList: data.slice(1) as string[]
        }

        console.log("error is : ", newError)
        setServerError(newError);
      }
      )
    }
    else {
      CreateInventory(record).then(() => navigate('/Inventory'));
    }
  }


  //updated
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSelectedIdsChange = (newSelectedIds: string[]) => {
    setSelectedIds(newSelectedIds);
  };

  const [inventoryInfoisOpen, setInventoryInfoisOpen] = useState(false);

  const handleProjectInfo = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setInventoryInfoisOpen(true)
  }

  useEffect(() => {
    // console.log(selectedIds)
    setRecord({ ...record, sourceTypeId: selectedIds })
  }, [selectedIds])


  const [errors, setErrors] = useState<Partial<IInventoryError>>(new InventoryErrorValue());


  const { LoadingInvStatus, LoadingDepartment, statuses, departments } = summaryStore
  const { LoadingInventory, selectedInventory } = inventoryStore

  useEffect(() => {
    LoadingDepartment();
    LoadingInventoryType();
    LoadingInvStatus(selectedId);

  }, [])
  // useEffect(() => {
  //   console.log(record)
  // }, [record])

  useEffect(() => {
    console.log(copyId)
    if (selectedId) {
      LoadingInventory(selectedId).then(i => {
      console.log(i)
        setRecord(new InventoryFormValue(i))
      })
    }
    else if (copyId) {
      LoadingInventory(copyId).then(i => {
        const copiedInventory = { ...i, statusId: undefined };
        delete copiedInventory.id;
        setRecord(new InventoryFormValue(copiedInventory));
      }
      )
    }
  }, [selectedId, copyId])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  if (loadingInitial || submitting)
    return <LoadingComponent content="Loading activity..." />;


  return (
    <div className="w-full  flex flex-col my-scrollable-div h-[900px] mt-2">

      <form onKeyPress={handleKeyPress} onSubmit={handleSubmit} autoComplete="off" className='w-full '>
        <div className='flex flex-col md:flex-row md:justify-between md:flex-grow'>


          <div className='flex flex-col gap-3  md:w-[50%] md:pr-[60px]'>

            <SelectElement defValue={record.inventoryTypeId} isWithApi={true} width="[30%]" isRequired={true} record={record} setRecord={setRecord} name={"inventoryTypeId"} title={"Inventory Type"} options={InventoryTypes} error={errors.inventoryTypeId} />
            <SelectElement defValue={record.departmentId} isWithApi={true} width="[30%]" isRequired={false} record={record} setRecord={setRecord} name={"departmentId"} title={"Department"} options={departments} />
            <span className="text-[20px] font-medium text-decoration-line: underline">General Information</span>

            <div className="flex flex-col gap-3 sm:flex-row  justify-between">
              <div className="w-full sm:w-[30%]">
                {/* problem */}
                <InputElement value={record.equipmentCode} title={"Equipment Code"} name={"equipmentCode"} isRequired={true} record={record} setRecord={setRecord} error={errors.equipmentCode} />
              </div>
              <div className="w-full sm:w-[60%]">
                <InputElement value={record.surType} title={"Sur.Type"} name={"surType"} isRequired={false} record={record} setRecord={setRecord} />
              </div>
            </div>

            <InputElement value={record.equipmentName} title={"EquipmentName (Eng)"} name={"equipmentName"} isRequired={true} record={record} setRecord={setRecord} error={errors.equipmentName} />
            <InputElement value={record.label} title={"Label"} name={"label"} isRequired={false} record={record} setRecord={setRecord} />
            <InputElement value={record.manufacturerName} title={"Manufacturer Name"} name={"manufacturerName"} isRequired={true} record={record} setRecord={setRecord} error={errors.manufacturerName} />
            <InputElement value={record.supplierName} title={"Supplier Name"} name={"supplierName"} isRequired={false} record={record} setRecord={setRecord} />

            <div className="flex  flex-col sm:flex-row justify-between">
              <div className="w-full sm:w-[30%] ">
                <InputElement value={record.serialNo} title={"Serial No."} name={"serialNo"} isRequired={true} record={record} setRecord={setRecord} error={errors.serialNo} />
              </div>
              <div className="w-full sm:w-[60%]">
                <InputElement value={record.modelNo} title={"Model No."} name={"modelNo"} isRequired={true} record={record} setRecord={setRecord} error={errors.modelNo} />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full sm:w-[30%] ">
                <CustomCalendar value={record.warrantyStartDate} title="Warranty Period" name="warrantyStartDate" record={record} setRecord={setRecord} />
              </div>
              <div className="w-full sm:w-[60%]">
                <CustomCalendar value={record.warrantyEndDate} name="warrantyEndDate" record={record} setRecord={setRecord} />
              </div>
            </div>

            <InputElement value={record.simCardNo} isRequired={false} title={"Sim Card No"} name={"simCardNo"} record={record} setRecord={setRecord} />
            <UploadPhotoElement url={record.equipmentFileUrl} name="equipmentFile" title="Equipment Photo" record={record} setRecord={setRecord} />

            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full sm:w-[30%] ">
                <CustomCalendar value={record.purchaseDate} isRequired={true} title="Purchase Date" name="purchaseDate" record={record} setRecord={setRecord} error={errors.purchaseDate} />
              </div>
              <div className="w-full sm:w-[60%]">
                <InputElement value={record.purchaseCost} isRequired={true} title={"Purchase Cost"} name={"purchaseCost"} record={record} setRecord={setRecord} error={errors.purchaseCost} />
              </div>
            </div>

            <UploadPhotoElement url={record.reciptFileUrl} name="reciptFile" title="Receipt" record={record} setRecord={setRecord} />

            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full sm:w-[30%] ">
                <SelectElement defValue={record.statusId} isWithApi={true} isRequired={true} record={record} setRecord={setRecord} name={"statusId"} title={"Status"} options={statuses} error={errors.statusId} onlyRead={selectedId != undefined && record.statusId == 4} />
              </div>
              {record.statusId === 3 &&
                <div className="w-full sm:w-[60%]">
                  <CustomCalendar value={record.disposedDate} title="*Disposed Date" name="disposedDate" record={record} setRecord={setRecord} />
                </div>}
            </div>

            <div>
              <CustomCalendar value={record.withDrawlDate} title="Date of Withdrawal" name="withDrawlDate" record={record} setRecord={setRecord} />
            </div>
            <div className="flex flex-col sm:flex-row  justify-between">
              <div className="w-full sm:w-[30%] ">
                <CustomCalendar value={record.lastMaintenance} title="Last Maintenance" name="lastMaintenance" record={record} setRecord={setRecord} />
              </div>
              <div className="w-full sm:w-[60%]">
                <CustomCalendar value={record.nextMaintenance} title="Next Maintenance" name="nextMaintenance" record={record} setRecord={setRecord} />
              </div>
            </div>

            <InputElement value={record.remark} isRequired={false} title={"Remark"} name={"remark"} record={record} setRecord={setRecord} />
            <TextAreaElement value={record.remarkDescription} isRequired={false} title={"Remark Description"} name={"remarkDescription"} record={record} setRecord={setRecord} />

          </div>

          <div className="w-full  md:w-[50%]  flex mt-[50px] md:mt-[180px] ">
            <VerticalLine />
            <div className="flex-grow flex flex-col gap-3 md:mx-5">
              <span className="text-[20px] font-medium text-decoration-line: underline">Resources Type</span>

              <TextAreaTable selectedIds={record.sourceTypeId} handleClick={handleProjectInfo} value={record.sourceTypeId} isRequired={true} title={"Resources Type"} name={"sourceTypeId"} record={record} setRecord={setRecord} error={errors.sourceTypeId} />
              <InventoryInfoTable selectedIds={record.sourceTypeId} onSelectedIdsChange={setSelectedIds} isOpen={inventoryInfoisOpen} setisOpen={setInventoryInfoisOpen} />
              <EquipmentLoggerSet selectedId={selectedId} />
            </div>
          </div>




        </div>
        <div className="flex flex-col gap-1 min-[450px]:flex-row  justify-center min-[450px]:gap-5  mt-5">
          <IButton type="submit" text={selectedId ? "Edit" : "Create"} />

          <IButton type="button" text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" onClick={()=>{navigate(-1)}} />


        </div>
        {/* <button onClick={testCase1}>Test Error</button> */}
        <ServerErrorMessage messageHeader={serverError?.messageHeader} messageList={serverError?.messageList} />

      </form>




    </div>
  )
})
