import { makeAutoObservable } from "mobx";
import agent from "../services/Api/agent";
import DataForm from "../pages/Data/DataForm";
import { IPerformanceForm, PerformTableDto } from "../models/Pharse2/Performance";
import { Dictionary } from "../pages/home/LoggersetMap/Algo/ColorAlgo";
import { store } from "./store";
export default class LocationMapStore {

    loadingInitial: boolean = false
    //dictionary: Dictionary | undefined = undefined

    constructor() {
        makeAutoObservable(this)
    }

    loadProjectDistinctList = async () => {
        try {
            this.setLoadingInitial(true);
            var data = await agent.LocationMap.getProjectData();
            this.setLoadingInitial(false);
            return data;

        }
        catch (error) {
            this.setLoadingInitial(false);

            throw error;
        }
    }

    loadLocationMarkerList = async () => {
        try {
            this.setLoadingInitial(true);
            var data = await agent.LocationMap.getLocationData();
            this.setLoadingInitial(false);
            console.log(data);
            return data
        }
        catch (error) {
            this.setLoadingInitial(false);

            throw error;
        }
    }

    // getLoggersetDetails = async (locationId: number) => {
    //     try {
    //         this.loadingInitial = true
    //         var data = await agent.LocationMap.getLoggersetDetailsDto(locationId);
    //         this.loadingInitial = false;

    //         return data;
    //     }
    //     catch (error) {
    //         this.loadingInitial = false;

    //         throw error;
    //     }
    // }

    setLoadingInitial = (status: boolean) => {
        this.loadingInitial = status;
    }




    // getAALocationDetails = async (locationId: number) => {
    //     try {
    //         this.setLoadingInitial(true);
    //         const res = await agent.LocationMap.getAALocationDetails(locationId);
    //         this.setLoadingInitial(false);

    //         return res
    //     }
    //     catch (error) {
    //         this.setLoadingInitial(false);
    //     }
    // }

    getDetailsInfo = async (locationId: number) => {
        try {
            const result = agent.LocationMap.getLoggersetDetailsDto(locationId);

            console.log(result)
            return result

        }
        catch (error) {
            console.log(error)
        }
    }

}