

const HorizontalDashedLine = () => {
  const horizontalLineStyle = {
    marginTop:'20px',
    //marginBottom: '5px',
    border: 'none',
    borderTop: '2px dashed #000000',
    width: '100%',
  };

  return <hr style={horizontalLineStyle} />;
};

export default HorizontalDashedLine;