import React, { useState } from 'react'
import { IPerformanceForm, PerformTableDto, PerformanceFormVal } from '../../models/Pharse2/Performance';
import PerformanceForm from './PerformanceForm';
import PerformanceTable from './PerformanceTable';
import { useStore } from '../../stores/store';
import LoadingComponent from '../../components/LoadingComponent';
import { observer } from 'mobx-react-lite';

export default observer(function PerformancePage() {
  const [record, setRecord] = useState<IPerformanceForm>(new PerformanceFormVal());
  const {performanceStore} = useStore();
    const {loadingInitial} = performanceStore
    const [PerformData, setPerformData] = useState<PerformTableDto | undefined>();
   
    return (
        <div className="pageParentDiv ">
            <div className='flex h-[40px] items-center'>
                <span className="text-[20px] text-bold">Performance Summary</span>
            </div>
            <PerformanceForm record={record} setRecord={setRecord} setPerformData={setPerformData} />
            <PerformanceTable record={record} setRecord={setRecord} PerformData={PerformData} setPerformData={setPerformData} />
        </div>
    )
})
