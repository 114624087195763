import React, { useEffect, useRef, useState } from 'react'
import UserIcon from './UserIcon';
import LoginMenuItem, { MenuProps } from './LoginMenuItem';
import { useStore } from '../../stores/store';
import AccountItem from './AccountItem';


export default function LoginPoppedUp() {
  const { userStore } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const MenuItems: MenuProps[] = [
    { imgSrc: "/assets/userMenu/setting.png", title: "Change Password", handleClick: () => { }, path: "/changePassword" },
    { imgSrc: "/assets/userMenu/logout.png", title: "Logout", handleClick: () => { userStore.logout() }, path: "" },

  ]

  const ref = useRef<HTMLDivElement | null>(null); // Create a ref with type

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="px-5 flex" ref={ref}>
      <div>
        <div className='w-11 h-11' onClick={() => setIsOpen(!isOpen)}  >
          <UserIcon />
        </div>
      </div>
      {/* text-base */}
      
      {isOpen && (
        <div className="  origin-top-right absolute right-8 mt-5 min-w-64 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 ">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <AccountItem />

            {MenuItems.map((item, i) => (
              <LoginMenuItem key={i} imgSrc={item.imgSrc} handleClick={()=>{item.handleClick(); setIsOpen(false)}} title={item.title} path={item.path} />
            ))
            }
          </div>
        </div>
      )}
    </div>
  );
}
