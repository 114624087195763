import React, { useEffect, useRef, useState } from 'react'

interface Props {
    bgColor?: string,
    clickedColor?: string,
    imgSrc: string,
    clickedImgSrc: string,
    handleClick: (index: number) => void,
    isSelected: boolean,
    title: string
    number: number
    id: number,
    borderWidth?: number,
}

export default function InventoryTypeHeaderItems( { borderWidth, id, number, title, bgColor, clickedColor, imgSrc, clickedImgSrc, handleClick, isSelected }: Props) {
    const unSelectedColor = bgColor ? bgColor : "rgb(172, 172, 172)"
    const SelectedColor = clickedColor ? clickedColor : "rgb(204, 49, 27)"
    const finalColor = isSelected ? SelectedColor : unSelectedColor
    const finalImg = isSelected ? clickedImgSrc : imgSrc


    return (
        <div className='flex flex-col items-center  w-[22.222%] px-2'>
            <div className='flex cursor-pointer mx-auto gap-2 ' style={{ height: "30px" }} onClick={() => handleClick(id)}>
                <div className='' style={{ flexShrink: 0 }}>
                    <img src={finalImg} />
                </div>
                <span className=' hidden md:block font-semibold whitespace-nowrap' style={{ color: finalColor, flexShrink: 0 }}>
                    {title}
                </span>

                <div className='rounded-full flex items-center justify-center px-3  hidden lg:block' style={{ backgroundColor: finalColor, height: "19px", minWidth: "42px", flexShrink: 0 }}>
                    <span className="text-white whitespace-nowrap">
                        {number}
                    </span>
                </div>

            </div>
            <div className="h-5px rounded-full absolute bottom-0 z-1 w-[22.222%] " style={{ backgroundColor: isSelected ? finalColor : "transparent" }}>

            </div>


        </div>
    )
}
// backdrop-brightness-75
//sm:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/5