import React, { ChangeEvent } from 'react'
import TextAreaItems from './TextAreaItems';
const errorMsg = {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '-2px'
}
interface Props<T> {
    selectedIds?: string[];
    height?: string
    title: string;
    name: string;
    isRequired: boolean;
    setRecord: (input: T) => void;
    record: T;
    error?: string;
    value?: string | number | readonly string[] | undefined | number[]
    handleClick?: () => void
}

export const TextAreaTable = <T extends {}>({ selectedIds, handleClick, height, value, name, title, isRequired, setRecord, record, error }: Props<T>) => {
    const firstCol = selectedIds && selectedIds[0] && selectedIds.slice(0, 4).map(id => (
        <TextAreaItems id={Number(id)} />
    ))

    const secondCol = selectedIds && selectedIds[0] && selectedIds.slice(4,8).map(id => (
        <TextAreaItems id={Number(id)} />
    ))

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        const { name, value } = event.target;
        setRecord({ ...record, [name]: value })
    }




    return (
        <div className="flex flex-col w-full">
            <div className="mb-2">
                <label className="">
                    <p className={`${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>
                </label>
            </div>

            <div>
                {/* <textarea  onClick={handleClick} readOnly value={value} className={`w-full ${height ? height  : 
                    "h-[150px]"} border-2 rounded-lg border-[#ACACAC]`} name={name} onChange={handleChange} /> */}
                <div onClick={handleClick} className={`flex py-2 px-4 w-full ${height ? height : "h-[150px]"} border-2 rounded-lg border-[#ACACAC]`}>
                    <div className="flex flex-col gap-2 w-[50%]">
                        {firstCol}
                    </div>
                    <div className="flex flex-col gap-2 w-[50%]">
                        {secondCol}
                    </div>

                </div>
                {error && <p style={errorMsg}>{error}</p>}
            </div>

        </div>
    )
}