import React, { useState } from 'react'
import { SymbolTextBox } from '../../../../../Input/SymbolTextbox';
import { states } from '../../../../../../pages/SourceType/SourceTypeRecord';
import { CoustomTableHeaderProps } from '../../../../../Table/CoustomTable';
import GeneralButton from '../../../../../Button/GeneralButton';

interface Prop {
    buttonChoice:string
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];
    setisOpen: (bool: boolean) => void
    setactiveState: (x: string) => void;

}
export default function AssignTableHeader({ buttonChoice, setactiveState, setisOpen, setState, setButtonChoice, options, statesArray }: Prop) {
    const [filterPlaceholder, ChangePlaceHolder] = useState("Filter Project Name");

    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    }

    return (
        <div className='flex justify-between flex-wrap'>
            <div className='flex' >
                <SymbolTextBox
                buttonChoice={buttonChoice}
                    setactiveState={setactiveState}
                    changePlaceholderValue={ChangePlaceHolder}
                    options={options}
                    IconLeft={filterProps.IconLeft}
                    IconRight={filterProps.IconRight}
                    placeholder={filterProps.placeholder}
                    onChange={(e) => setState(e.target.value)}
                    statesArray={statesArray}
                    setButtonChoice={setButtonChoice} />
            </div>
            <div className='flex gap-4'>
                <GeneralButton title={"Assign Project"} handleClick={() => setisOpen(true)} />

            </div>
        </div>
    )
}
