import React from 'react'
import { useStore } from '../../stores/store';
import UserIcon from './UserIcon';


export default function AccountItem() {
    const { userStore } = useStore();
    const { user } = userStore
    return (
        <div className="flex  items-center block px-3 py-2 text-sm text-gray-700 " role="menuitem"  >
            <div className='w-10 h-10' >
                <UserIcon />
            </div>

            <div className="ml-3">
                <p className='m-0  font-bold'>{user?.username}</p>
                <p className='m-0'>{user?.email}</p>

            </div>
        </div>
    )
}
