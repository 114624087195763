import React from 'react'
import { LoggersetDetailsDto } from '../../../../../models/Pharse2/LocationMap'
import { TextAreaElement } from '../../../../../components/FormElement/TextAreaElement'

interface Prop {
    loggersetDetails?: LoggersetDetailsDto
}
export default function LoggersetDetails({ loggersetDetails }: Prop) {



    return (
        <div className="flex flex-col w-full gap-12">

            <div className="flex flex-col w-full gap-4">
                <span className="text-[20px] font-medium text-decoration-line: underline">
                    Location Details
                </span>
                <div>
                    <p>Location Name</p>
                    <input disabled value={loggersetDetails ? loggersetDetails.locationName : ""} className="pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                </div>
            </div>

            <div className="flex flex-col w-full gap-4">
                <span className="text-[20px] font-medium text-decoration-line: underline">
                    Loggerset Details
                </span>
                <div>
                    <p>Status</p>
                    <input disabled value={loggersetDetails ? loggersetDetails.status : ""} className="pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                </div>
                <div>
                    <p>Remark</p>
                    <input disabled value={loggersetDetails ? loggersetDetails.remark : ""} className="pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                </div>
                <TextAreaElement readonly={true} value={loggersetDetails ? loggersetDetails.remarkDescription : ""} title={'Remark Description'} name={''} isRequired={false} setRecord={() => { }} record={""} />
            </div>
            <div className="flex flex-col w-full gap-4">
                <span className="text-[20px] font-medium text-decoration-line: underline">
                    Loggerset Components
                </span>
                <div>
                    <p>Logger Set Name</p>
                    <input disabled value={loggersetDetails ? loggersetDetails.loggersetName : ""} className="pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                </div>

                <div className="flex flex-col gap-2">
                    <p>Existing Logger (Equipment ID)</p>
                    {loggersetDetails?.loggerList ? loggersetDetails.loggerList.map((logger) => (
                        <input disabled value={logger} className={` pl-3 bg-[#D9D9D9] w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-opacity-30`} />
                    )) :
                        <>
                            <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                        </>

                    }
                </div>
                <div className="flex flex-col gap-2" >
                    <p>"Existing Sensor (Equipment ID)</p>
                    {loggersetDetails?.sensorList ? loggersetDetails.sensorList.map((sensor) => (
                        <input disabled value={sensor ? sensor : ''} className={`pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30`} />
                    )) :
                        <>
                            <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />
                        </>

                    }
                </div>

                <div className="flex flex-col gap-2">
                    <p>Existing Other Equipment (Equipment ID)</p>
                    {(loggersetDetails !== undefined && loggersetDetails?.equipmentList.length > 0) ? loggersetDetails.equipmentList.map((otherEquipment) => (
                        <input disabled value={otherEquipment} className={`pl-3 w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30 `} />
                    )) :
                        <div className="flex flex-col gap-3">
                            <input disabled className="w-full h-[30px] border-2 rounded-lg border-[#ACACAC] bg-[#D9D9D9] bg-opacity-30" />

                        </div>

                    }
                </div>
            </div>

        </div>
    )
}
