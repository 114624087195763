import React from 'react'
import { ProjectDistinct } from '../../../models/Pharse2/LocationMap'
import { ColorOption, Dictionary } from './Algo/ColorAlgo'

interface ComponentProp {
    project: ProjectDistinct
    colorString: string
} //className={`${project.locationAlert==true? "border-red-500 border-2":""}`}

const ProjectComponent = ({ project, colorString }: ComponentProp) => {
    return (
        <div className='flex items-center gap-1'>
            <div className="h-[10px] w-[10px] rounded-full " style={{ backgroundColor: colorString }}></div>
            <span className={`${project.locationAlert==true? "border-b border-red-500 bg-red-100 cursor-pointer":""}`}>
                {project.name}
            </span>
            
        </div>
    )
}



interface BoardProp {
    projectList: ProjectDistinct[]
    colorDictionary?: Dictionary
}

export default function ProjectDisplayBoard({ projectList, colorDictionary }: BoardProp ) {


    return (
        <div className="flex gap-2 flex-grow flex-wrap">

            {projectList.map((item, idx) => (
                <ProjectComponent project={item} colorString={colorDictionary ? colorDictionary[item.id%100].code : ""} />
            ))}
        </div>
    )
}
