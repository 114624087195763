import { ThemeProvider } from "@mui/material/styles"
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../../Table/CoustomTable"
import { useEffect, useRef, useState } from "react";


import { states } from "../../../../pages/SourceType/SourceTypeRecord";
import ReactDOM from "react-dom";
import { LoggerInfoTableHeader } from "../../LoggerSet/LoggerInfo/LoggerInfoTableHeader";
import { InfoTableHeader } from "../../../InfoFIleHeader/InfoTableHeader";
import { useStore } from "../../../../stores/store";
import { observer } from "mobx-react-lite";
import SourceTypeStore from "../../../../stores/SourceTypeStore";


interface sourceTypeInfo {
  onSelectedIdsChange?: (arr: string[]) => void;
  id: string;
  sourceType: string;
  valueUnit: string;
  externalUserAcessible: string;
}


interface props {
  handlePresave:(newIds: string[])=>void
  id?: string;
  onSelectedIdsChange: (arr: string[]) => void;
  selectedIds: string[]
  isOpen: boolean;
  setisOpen: (x: boolean) => void;
}

const MODAL_STYLES = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,


}


const OVERLAY_STYLES = {
  width: '100vw',
  height: '100vh',
  backgroundColor: ' rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(2px)',
  zIndex: 1000,
};


const CONTENT_STYLES = {


  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  zIndex: 1001,
  padding: '32px',
  boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  maxHeight: '100vh',
  maxWidth: '100vw',
};




export const ProjectInfoTable = observer(({ handlePresave, id, selectedIds, onSelectedIdsChange, isOpen, setisOpen }: props) => {
  const [page, setPage] = useState(0);
  const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
  const [LoggernameState, setLoggernameState] = useState("");
  const [LoggerequipmentIdState, setLoggerequipmentIdState] = useState("");
  const [LoggerserialNoState, setLoggerserialNoState] = useState("");
  const [buttonChoice, setButtonChoice] = useState("Loggername");

  const { sourceTypeStore } = useStore();
  const { LoadingSourceType, SourceTypes } = sourceTypeStore
  const activeId = useRef<string | null>(null);
  const [results, setResult] = useState<sourceTypeInfo[]>([])
  const { projectStore } = useStore()
  const { getProjectHistory, LoadSrcTypeId } = projectStore;


  

  const handleCancel =()=>{
    const updatedRows = results
    .filter((item) => (
      LoggernameState.toLowerCase() === "" ||
      item.sourceType.toLowerCase().includes(LoggernameState.toLowerCase())
    ))
    .filter((item2) => (
      LoggerequipmentIdState.toLowerCase() === "" ||
      item2.valueUnit.toLowerCase().includes(LoggerequipmentIdState.toLowerCase())
    ))
    .filter((item3) => (
      LoggerserialNoState.toLowerCase() === "" ||
      item3.externalUserAcessible.toLowerCase().includes(LoggerserialNoState.toLowerCase())
    ))
    .map((record: sourceTypeInfo) => ({
      data: [
        { id: "SourceType", data: record.sourceType },
        { id: "ValueUnit", data: record.valueUnit },
        { id: "ExternalUserAcessible", data: record.externalUserAcessible },
      ],
      Id: record.id,
      ischecked: selectedIds.includes(record.id)
    }));


  setrows(updatedRows);
    setisOpen(false);
  }
  
  

  const handleAssign =(newIds: string[])=>{
    handlePresave(newIds)
    setisOpen(false);
  }
  
  
  useEffect(() => {
    const fetchData = async () => {
      console.log("SourceTypes appended")

      try {
        await LoadingSourceType(false);
        //setisLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (SourceTypeStore) {
      fetchData();
    }
  }, [SourceTypeStore]);


  useEffect(() => {
    // console.log(sourceTypeIds)
    const updatedRows = results
      .filter((item) => (
        LoggernameState.toLowerCase() === "" ||
        item.sourceType.toLowerCase().includes(LoggernameState.toLowerCase())
      ))
      .filter((item2) => (
        LoggerequipmentIdState.toLowerCase() === "" ||
        item2.valueUnit.toLowerCase().includes(LoggerequipmentIdState.toLowerCase())
      ))
      .filter((item3) => (
        LoggerserialNoState.toLowerCase() === "" ||
        item3.externalUserAcessible.toLowerCase().includes(LoggerserialNoState.toLowerCase())
      ))
      .map((record: sourceTypeInfo) => ({
        data: [
          { id: "SourceType", data: record.sourceType },
          { id: "ValueUnit", data: record.valueUnit },
          { id: "ExternalUserAcessible", data: record.externalUserAcessible },
        ],
        Id: record.id,
        ischecked: selectedIds.includes(record.id)
      }));


    setrows(updatedRows);
  }, [results, LoggernameState, LoggerequipmentIdState, LoggerserialNoState, selectedIds]);

  useEffect(() => {
    const temp: sourceTypeInfo[] = SourceTypes.map(
      (sourceType) => ({
        sourceType: String(sourceType.sourceType),
        id: String(sourceType.id),
        valueUnit: sourceType.unit ? sourceType.unit : 'N/A',
        externalUserAcessible: sourceType.access === true ? 'Yes' : 'No',
      })
    )
    setResult(temp)
  }, [SourceTypes, SourceTypeStore])


  const statesArray: states[] = [
    {
      currentstate: LoggernameState,
      statemethod: setLoggernameState
    },
    {
      currentstate: LoggerequipmentIdState,
      statemethod: setLoggerequipmentIdState
    },
    {
      currentstate: LoggerserialNoState,
      statemethod: setLoggerserialNoState
    }
  ]



  const headers: Array<CoustomTableHeaderProps> = [
    { id: "SourceType", data: "SourceType" },
    { id: "ValueUnit", data: "Value Unit" },
    { id: "ExternalUserAcessible", data: "External User Acessible" },
  ];


  const settingState = buttonChoice === "Loggername"
    ? setLoggernameState
    : buttonChoice === "id"
      ? setLoggerequipmentIdState
      : setLoggerserialNoState

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // Reset the filter state when the modal is closed.
      setLoggernameState("");
      setLoggerequipmentIdState("");
      setLoggerserialNoState("");
      document.body.style.removeProperty('overflow');
    }
  }, [isOpen]);
  if (isOpen == false) return null;








  return ReactDOM.createPortal(


    <div className="content" style={MODAL_STYLES as React.CSSProperties}>
      <div style={OVERLAY_STYLES as React.CSSProperties} />
      <div style={CONTENT_STYLES as React.CSSProperties}>
        <div className="pageParentDiv " style={{ borderRadius: '20px' }}>
          
          <InfoTableHeader buttonChoice={buttonChoice} title="Search Source Type" placeholder="Filter Source Type"
            statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))}
            setState={settingState}
            setButtonChoice={setButtonChoice}
          />

          <ThemeProvider theme={customTableTheme}>
            <CoustomTable  page={page} setPage={setPage} maxHeight={400} onSelectedIdsChange={onSelectedIdsChange} tableMarginRight="200" cancelButton={handleCancel}  assignButton={handleAssign} headers={headers} setRows={setrows} rows={rows} activeId={activeId} />
          </ThemeProvider>

        </div>
      </div>
    </div>,
    document.getElementById('portal') as HTMLElement






  )
})
