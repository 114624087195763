
import { action, makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/Api/agent';
import { store } from './store';
import { router } from '../router/Routes';
import { SourceType, SourceTypeForm } from './../models/SourceType';
import { useNavigate } from 'react-router-dom';

/*
export interface SourceType {

    id?:number;
    sourceType: string;
    unit: string;
    access: boolean;
    remark: string;
    status?: boolean;
}
*/


export default class SourceTypeStore {

    loadingInitial = false;
    submitting =false;

    SourceTypes: SourceType[] = [];
    selectedSourceType: SourceType |  undefined = undefined;
    filterPlaceholder = "Filter Source Types"

    constructor() {
        makeAutoObservable(this);
    }

    ChangePlaceHolder = (p:string) => {
        this.filterPlaceholder = p;
    }

    

    LoadingSourceType = async (isInfo:boolean) => {
       this.loadingInitial = true;
        try {
            const SourceTypes = await agent.SourceTypes.list(isInfo);
            runInAction(() => {
                    this.SourceTypes = SourceTypes? SourceTypes : []
                
            })
        }
        catch (error) {
            throw error
        }
        this.loadingInitial = false;
    }

    LoadSelectedSourceType = async (id: string) => {
        
        const Id: number = Number(id)
        let newSourcetype: SourceType | undefined  = this.SourceTypes.find((element) => element.id === Id);
        if (newSourcetype) {
            runInAction(() => this.selectedSourceType = newSourcetype);
        }
        else {
            this.loadingInitial = true;

            try {
                newSourcetype = await agent.SourceTypes.details(id);
                runInAction(() => this.selectedSourceType = newSourcetype)
                this.loadingInitial = false;

            } catch (error) {
                throw error;
            }
            this.loadingInitial = false;

        }
        console.log(" inside store: selected source: "+this.selectedSourceType?.id)
        return this.selectedSourceType;
    }

    CreateSourceType = async (record: SourceType) => {

        
        this.submitting = true
        try {

            //console.log(record)
            await agent.SourceTypes.create(record);
         


        }
        catch (error) {
          
            throw error

        }
        this.submitting = false

    }

    EditSourceType = async (record: SourceType) => {
        console.log(record);
        this.submitting = true

        try {

            //console.log(record)
            await agent.SourceTypes.update(record);
            // const newArr = [...this.SourceTypes.filter((x) => x.id !== record.id), record];
            // runInAction(() => {
            //     this.SourceTypes = newArr;
            // })

        }
        catch (error) {
        this.submitting = false

            throw error
        }
        this.submitting = false

    }

    getSourceTypeHistory = async (id?: string) => {
        try {
            if (id == null) return
            return await agent.SourceTypes.getHistory(id);
        } catch (error) {
            throw error;
        }
    }

    clearSelectedSourceType = () => {
        this.selectedSourceType = undefined;
    }

    getSourceNameWithId = (Id:number) => {
        if(this.SourceTypes === null){
            return ""
        }
        else{
           var sourceType =  this.SourceTypes.find((element) => element.id === Id)?.sourceType;
            if(sourceType){
                return sourceType
            }
            else{
                return ""
            }
        }
    }

    getUnitWithId = (Id:number) => {
        if(this.SourceTypes === null){
            return ""
        }
        else{
           var unit =  this.SourceTypes.find((element) => element.id === Id)?.unit;
            if(unit){
                return unit
            }
            else{
                return ""
            }
        }
    }

    DeleteSourceTypes = async (ids:string[]) => {
        try{

            await agent.SourceTypes.delete(ids);
            
        }
        catch(error){
            throw error;
        }
    }

    GetAllSourceType = async(ids:String[], locId?:Number) =>{
       
        try{
          var result =await agent.SourceTypes.GetAllSourceType(ids.map(Number), locId) 
          return result ;
        }
        catch(error){
            console.log(error)
            throw error;
        }
        
    }
}
