import { FormEvent, useEffect, useState } from "react";
import CreateFormHeader from "../../components/form/CreateFormHeader/CreateFormHeader";
import LoggerSetCreateForm from "../../components/form/LoggerSet/LoggerSetCreateForm";
import ProjectCreateForm from "../../components/form/Project/ProjectCreateForm";
import ProjectAudienceForm from "../../components/form/Project/ProjectAudienceForm";
import { IProjectError, ProjectErrorValue, ProjectForm, ProjectFormValue } from "../../models/Project";
import { useNavigate, useParams } from "react-router-dom";
import { TableHistory } from "../../models/Inventory";
import { useStore } from "../../stores/store";
import LoadingComponent from "../../components/LoadingComponent";
import { Page } from "../../components/TitlePath/TitlePath";
import { ProjectFormValidation } from "../../components/form/Project/Validation/ProjectValidation";
import { ApiError } from "../../models/Error";
import { AxiosResponse } from "axios";
import { TypeHeaderItem } from "../../components/ProjectRecord/ProjectTypeHeader/ProjectTypeHeader";

export default function ProjectCreatePage() {
  const [serverError, setServerError] = useState<ApiError | undefined>();
   
    const [errors, setErrors] = useState<Partial<IProjectError>>(new ProjectErrorValue());

    const [tableBlur, setTableBlur] = useState(false);

    const { id } = useParams();
    const [page, setPage] = useState(0);
    const [record, setRecord] = useState<ProjectForm>(new ProjectFormValue());
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const { projectStore } = useStore()
    const { submitting, CreateProject, EditProject, getProjectHistory, LoadSrcTypeId, loadingInitial } = projectStore;
    const [history, setHistory] = useState<TableHistory | undefined>(undefined);
    const [recordIsLoaded, setRecordLoaded] = useState<boolean>(false);
    const navigate = useNavigate();

    const [isUserUpdate, setIsUserUpdate] = useState(false);
   

    const [activateUseEffect, setActivate] = useState<boolean>(false);


    const handleHeaderStateChange = (page: number) => { // later add validation
       if(page === 1){
        setErrors({})
        var error = ProjectFormValidation(record);
        if (Object.keys(error).length !== 0) {
          console.log(error)
          setErrors(error);
          return false;
        }
       }
        setPage(page)
        return true
    }
    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setErrors({})
        var error = ProjectFormValidation(record);
        
        if (Object.keys(error).length !== 0) {
          console.log(error)
          setErrors(error);
          return;
        }


        console.log(record)
        if (id) {
            EditProject(record).then(() => navigate('/project')).catch((error) => {
                
                const { data, status, config } = error.response as AxiosResponse;
                var newError: ApiError = {
                      messageHeader: data[0],
                      messageList: data.slice(1) 
                    }

                setServerError(newError);
                window.scrollTo(0, document.body.scrollHeight);
            
            })
        }
        else {
            CreateProject(record).then(() => navigate('/project'))
        }



    }



    useEffect(() => {
        const fetchHistory = async () => {
            const data = await getProjectHistory(id);
            setHistory(data);
        }
        fetchHistory();
        // console.log(history)
    }, [])


    useEffect(() => {
        const fetchIds = async () => {
            if (id) {
                const ids = await LoadSrcTypeId(id)
                setSelectedIds(ids);
                console.log(selectedIds);
            }
        }
        fetchIds();

    }, [])
    const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }, { title: "Project Maintenance", path: "/project" }]
    
    const InventoryTypeHeaderAllItems:TypeHeaderItem[] = [
        { imgSrc: "/assets/ProjectTypeHeader/profile.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/profile.png", isSelected: false, title: "Project Profile"},
        { imgSrc: "/assets/ProjectTypeHeader/audiences.png", clickedImgSrc: "/assets/ProjectTypeHeader/orange/audiences.png", isSelected: false, title: "Default Audiences Table"},
      ]

    if (submitting || loadingInitial)
        return <LoadingComponent content="Loading activity..." />;

    return (
        <div className="pageParentDiv flex flex-col px-5">
            <div className={`${tableBlur ? "blur-[2px]" : ""}`}>
                <CreateFormHeader
                    registeredBy={history?.registeredBy}
                    registeredOn={history?.registeredOn}
                    updateBy={history?.updateBy}
                    updateOn={history?.updateOn}
                    page={page}
                    changePage={handleHeaderStateChange}
                    titleText={id ? "Edit Project" : "Create Project"}
                    lastPages={lastPages}
                    formType={"Logger set"}
                    isCreate={id === undefined} 
                    InventoryTypeHeaderAllItems={InventoryTypeHeaderAllItems}/>
            </div>

            {page === 0 &&
                <ProjectCreateForm
                errors={errors}
                setErrors={setErrors}

                    changePage={setPage}

                    recordIsLoaded={recordIsLoaded}
                    setRecordLoaded={setRecordLoaded}
                    selectedId={id}

                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}


                    record={record}
                    setRecord={setRecord} />}

            {page === 1 && <ProjectAudienceForm
                serverError={serverError}
                tableBlur={tableBlur} setTableBlur={setTableBlur}
                handleSubmit={handleSubmit}
                setRecord={setRecord}
                record={record}
            />}
        </div>
    )
}