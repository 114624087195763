import React, { useState } from 'react'
import DataForm from './DataForm'
import DataViewTable from './DataViewTable'
import DataPageHeader from './DataPageHeader'
import { DataFormVal, IDataForm } from '../../models/Pharse2/Data';
import { useStore } from '../../stores/store';

export default function DataPage() {
  const [record, setRecord] = useState<IDataForm>(new DataFormVal());
const {dataStore} = useStore();

  const handleExportCurrent = ()=>{
    if(record.locationId>0 ){
      dataStore.exportCurrentLocation(record);
    }
  }

  const handleExportAll = ()=>{
    if(record.projectId > 0){
      dataStore.exportAllLocations(record);

    }
  }

  return (
    <div className="pageParentDiv ">
     <DataPageHeader allBtnisDisabled={record.projectId <= 0} currentBtnisDisabled={record.locationId<=0} exportAll={handleExportAll} exportCurrent={handleExportCurrent} />
      <DataForm record={record} setRecord={setRecord} />
      <DataViewTable />
    </div>
  )
}
