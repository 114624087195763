import React, { useEffect, useState } from 'react'
import GeneralButton from '../../../Button/GeneralButton'
import { ProjectForm } from '../../../../models/Project';
import { useStore } from '../../../../stores/store';
import AudienceLevelsTable from './AudienceLevelsTable';
interface Prop {
    record: ProjectForm;
    title?: string;
    alarmIndex: number
    sourceTypeId: number
    setRecord: (arr: {}) => void;
    tableBlur: boolean
    setTableBlur: (arr: boolean) => void;


}

export default function AudienceAlarmTable({ tableBlur, setTableBlur, setRecord, alarmIndex, sourceTypeId, title, record }: Prop) {

    const { sourceTypeStore, summaryStore } = useStore();

    // const components = record.alarm && record.alarm[alarmIndex] ? record.alarm[alarmIndex].alarmLevels : [{}];
    // console.log("components ", components)
    const { getSourceNameWithId, getUnitWithId } = sourceTypeStore
    const [Title, setTitle] = useState<string>("");
    const [unit, setUnit] = useState<string>("");
    
    useEffect(() => {
        var sourceName = getSourceNameWithId(sourceTypeId)
        setTitle(sourceName)
        setUnit(getUnitWithId(sourceTypeId))
    }, [sourceTypeId])

    const onShow = () => {
        console.log(record)
    }


    const onshow=()=>{
        console.log(record)
      }
    return (


        <div className=" w-full flex flex-col gap-4 border-[8px] border-gray-400 rounded-xl">
            <div className="flex justify-between items-center ">
                <span className="text-[20px] font-medium text-decoration-line: underline" onClick={onShow}>
                    {Title}- ({unit})
                </span>
                <div>

                </div>
            </div>


            <div className="my-3">
                <AudienceLevelsTable tableBlur={tableBlur} setTableBlur={setTableBlur} alarmIndex={alarmIndex} record={record} setRecord={setRecord} />
            </div>
        </div>

    )
}
