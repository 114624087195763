import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../components/Table/CoustomTable";
import { LocationDto } from "../../models/Location";
import { useStore } from "../../stores/store";
import LocationStore from "../../stores/LocationStore";
import { states } from "../SourceType/SourceTypeRecord";
import LocationHeader from "./LocationHeader";
import { ThemeProvider } from "@mui/material";
import { Page } from "../../components/TitlePath/TitlePath";
import LoadingComponent from "../../components/LoadingComponent";
import { sortingAlgor } from "../../hook/useSorting";




export default observer(function LocationManagement() {

    const params = new URLSearchParams(window.location.search);
    const page_FromUrl = params.get('page');
    const loggerSNState_FromUrl = params.get('loggerSNState');
    const locationNameState_FromUrl = params.get('locationNameState');
    const loggerSetName_FromUrl = params.get('loggerSetNameState');
    const projectNameState_FromUrl = params.get('projectNameState');
    const activeState_FromUrl = params.get('activeState');
    const buttonChoice_FromUrl = params.get('buttonChoice');

    const [page, setPage] = useState(Number(page_FromUrl) ?? 0);

    const activeId = useRef<string | null>(null);
    const direction = useRef<'asc' | 'desc'>('asc');
    const [loggerSNState, setloggerSNState] = useState(locationNameState_FromUrl ?? "");
    const [locationNameState, setlocationNameState] = useState(locationNameState_FromUrl ?? "");
    const [loggerSetNameState, setloggerSetNameState] = useState(loggerSetName_FromUrl ?? "");
    const [projectNameState, setprojectNameState] = useState(projectNameState_FromUrl ?? "");
    const [activeState, setactiveState] = useState<string>(activeState_FromUrl ?? "");
    const [buttonChoice, setButtonChoice] = useState(buttonChoice_FromUrl ?? "loggerSN");
    //const [excelRecord,setexcelRecord] = useState(""); //Later: need to bring Location instead of LocationDto from backend
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
    const [Results, setResult] = useState<LocationDto[]>([]);
    
    const final_setButtonChoice = (state: string) => { //cant use useEffect
        setButtonChoice(state)
        setPage(0)
    }
    const final_setactiveState = (state: string) => { //cant use useEffect
        setactiveState(state)
        setPage(0)
    }

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('page', String(page));
        params.set('buttonChoice', buttonChoice);
        params.set('activeState', activeState);

        if (buttonChoice === 'locationName') {
            params.set('locationNameState', locationNameState);
        } else if (buttonChoice === 'loggerSetName') {
            params.set('loggerSetNameState', loggerSetNameState);
        } else if (buttonChoice === 'projectName') {
            params.set('projectNameState', projectNameState);
        }
         else if (buttonChoice === 'loggerSN') {
            params.set('loggerSNState', loggerSNState);
        }
        window.history.replaceState({}, '', `?${params.toString()}`);
    }, [page, buttonChoice,loggerSNState, locationNameState, loggerSetNameState, projectNameState, activeState]);

    const { locationStore, projectStore, loggerSetStore } = useStore();
    const { loading, LoadingLocationsList, locationsList } = locationStore;
    const { LoadingProject } = projectStore;
    const { LoadSelectedLoggerset } = loggerSetStore

    useEffect(() => {
        const fetchData = async () => {
            try {
                await LoadingLocationsList();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log(" Results changed...")
        console.log(Results)
        const updatedRows = Results.filter((item) => {
            return loggerSNState.toLowerCase() === "" ? item
                : item.loggerSN?.toLowerCase().includes(loggerSNState.toLowerCase());
        }).filter((item) => {
            return locationNameState.toLowerCase() === "" ? item
                : item.name?.toLowerCase().includes(locationNameState.toLowerCase());
        }).filter((item2) => {
            return loggerSetNameState.toLowerCase() === "" ? item2
                : item2.loggerSetName?.toLowerCase().includes(loggerSetNameState.toLowerCase());
        }).filter((item3) => {
            return projectNameState.toLowerCase() === "" ? item3
                : item3.projectName?.toLowerCase().includes(projectNameState.toLowerCase());
        }).filter((item3) => {
            return activeState.toLowerCase() === "" ? item3
                : item3.status?.toLowerCase() === activeState.toLowerCase();
        }).map((record: LocationDto) => ({
            data: [
                { id: "LocationCode", data: record.name },
                { id: "ProjectName", data: record.projectName },
                { id: "LoggerSetName", data: record.loggerSetName },
                { id: "LoggerSN", data: record.loggerSN },
                { id: "Address", data: record.address },
                { id: "Status", data: record.status }
            ],
            hiddenData: [],
            Id: String(record.id),
            ischecked: false
        }));
        setrows(updatedRows)
    }, [Results, locationNameState, loggerSetNameState, projectNameState, activeState, loggerSNState]);


    useEffect(() => {
        console.log(" locations list updated...");
        console.log(locationsList)
        const fetchData = async () => {
            setResult(locationsList);
        };

        fetchData();
    }, [locationsList, LocationStore]);

    if (loading) {
        return <div></div>; //improve later
    }

    const statesArray: states[] = [
        {
            currentstate: loggerSNState,
            statemethod: setloggerSNState
        },
        {
            currentstate: locationNameState,
            statemethod: setlocationNameState
        },
        {
            currentstate: projectNameState,
            statemethod: setprojectNameState
        },
        {
            currentstate: loggerSetNameState,
            statemethod: setloggerSetNameState
        }
    ]

    const headers: Array<CoustomTableHeaderProps> = [
        { id: "locationName", data: "Location Name" },
        { id: "projectName", data: "Project Name" },
        { id: "loggerSetName", data: "Logger Set Name" },
        { id: "loggerSN", data: "Logger SN" },
        { id: "address", data: "Address" },
        { id: "status", data: "Status" }
    ]

    const options: Array<CoustomTableHeaderProps> = [
        { id: "loggerSN", data: "Logger SN" },
        { id: "locationName", data: "Location Name" },
        { id: "projectName", data: "Project Name" },
        { id: "loggerSetName", data: "Logger Set Name" },
     
    ]

    const statesSelection = buttonChoice == "loggerSN"? setloggerSNState:
     buttonChoice == "locationName"
        ? setlocationNameState
        : buttonChoice == "projectName"
            ? setprojectNameState
            : setloggerSetNameState

    const state = buttonChoice == "loggerSN"? loggerSNState:
    buttonChoice == "locationName"
        ? locationNameState
        : buttonChoice == "projectName"
            ? projectNameState
            : loggerSetNameState

    //sorting



    const onSortClick = () => {
        setPage(0);
        sortingAlgor({ rows, setrows, direction, activeId });

    };

    //////////////////////
    const lastPages: Page[] = [{ title: "Project Management", path: "/projectManagementMenu" }]

    if (loading)
        return <LoadingComponent  />

    return (
        <div className="pageParentDiv">
            <LocationHeader state={state} buttonChoice={buttonChoice} activeState={activeState} title={"Search Location Record"} lastPages={lastPages} setactiveState={final_setactiveState} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={statesSelection} setButtonChoice={final_setButtonChoice} options={options} />
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable onSortClick={onSortClick} activeId={activeId} direction={direction} page={page} setPage={setPage} subPath="location" headers={headers} setRows={setrows} rows={rows} />
            </ThemeProvider>
        </div>
    )
})