export interface AccountDto {
    id: string;
    name: string;
    email: string;
    phoneNumber?: string
    companyName: string;
    role?: string;

}

export interface AccountEditForm {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string
    companyId?: string;
    role?: string;
    projectIds: string[]
}

export class AccountEditValue implements AccountEditForm {
    id: string = "";
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phoneNumber?: string = ""
    companyId: string = ""
    role?: string = ""
    projectIds: string[] = []

    constructor(init?: AccountEditForm) {
        Object.assign(this, init);
    }
}

export interface IAccountEditError {
    firstName: string;
    lastName: string;
    email: string;
    role: string
    companyId: string
phoneNumber:string
}

export class AccountEditErrorValue implements IAccountEditError {

    firstName: string = "";
    lastName: string = "";
    email: string = "";
    role: string = ""
    companyId: string = ""
    phoneNumber:string =""


}

export interface AccountCreateForm {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: number
    companyId?: string;
    role?: string;

    password: string
    confirmPassword: string
}

export class AccountCreateValue implements AccountCreateForm {
    id: string = "";
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phoneNumber?: number 
    companyId?: string = ""
    role?: string = ""
    password: string = ""
    confirmPassword: string = ""
    constructor(init?: AccountEditForm) {
        Object.assign(this, init);
    }
}

export interface IAccountCreateError {
    firstName: string;
    lastName: string;
    role: string;
    companyId: string
    phoneNumber:string

    email: string;
    password: string;
    confirmPassword: string;

}

export class AccountCreateErrorValue implements IAccountCreateError {
    firstName: string = "";
    lastName: string = "";
    role: string = ""
    companyId: string = ""
    phoneNumber:string=""

    email: string = "";
    password: string = "";
    confirmPassword: string = "";

}

export class ChangePasswordFormVal{

    oldPassword:string = ""
    newPassword:string = ""
    confirmPassword:string = ""
    
 constructor(init?: AccountEditForm) {
        Object.assign(this, init);
    }
}

export interface IChangePwError{
    oldPassword: string
    newPassword:string 
    confirmPassword:string 
}

export class ChangePwErrorValue implements IChangePwError{
    oldPassword:string = ""
    newPassword:string = ""
    confirmPassword:string = ""
} 