import { useEffect } from "react";
import AMapLoader from '@amap/amap-jsapi-loader';
import { LocationMarker } from "../../../../../models/Pharse2/LocationMap";
import { LocationProject } from "../../../../../models/Location";
import './LoggersetLocationMap.css';

interface Prop {
    southLatitude: number
    northLatitude: number

}

export default function LoggersetLocationMap({ southLatitude, northLatitude }: Prop) {

    useEffect(() => {
        if (southLatitude != undefined && northLatitude != undefined) {

            (window as any)._AMapSecurityConfig = {
                securityJsCode: '878aafec7318cd1bf101535c50a05380',
            };

            AMapLoader.load({
                key: '9bc7d341c9456f4cf26eb06555f1c427',
                version: '2.0',
                plugins: ['AMap.Driving', 'AMap.Marker'],
            }).then((AMap) => {

                const map = new AMap.Map('container', {
                    zoom: 14,
                    center: [southLatitude, northLatitude],
                    features: ['bg', 'road', 'building', 'point']
                });


                const marker = new AMap.Marker({
                    map: map,
                    position: [southLatitude, northLatitude],
                    icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                });

                const labelContent = `<div class="marker-label">[${southLatitude}, ${northLatitude}]</div>`;
                const label = new AMap.Marker({
                    content: labelContent,
                    offset: new AMap.Pixel(45, -10),
                    position: marker.getPosition(),
                    map: map,
                    bubble: true
                });
                // Initially, set the label to be invisible.
                label.hide();

                // Show the label on marker hover.
                marker.on('mouseover', () => {
                    label.show();
                });

                // Hide the label when no longer hovering over the marker.
                marker.on('mouseout', () => {
                    label.hide();
                });




            });
        }

    }, [southLatitude, northLatitude]);


    return (
        <div id="container" style={{ width: "100%", height: '800px' }}></div>
    )
}