
import { ThemeProvider } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../stores/store";
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from "../../../../Table/CoustomTable";
import { states } from "../../../../../pages/SourceType/SourceTypeRecord";
import { InfoTableHeader } from "../../../../InfoFIleHeader/InfoTableHeader";
import { ProjectForm } from "../../../../../models/Project";

interface userInfo {
    id: string;
    username?: string;
    telNo?: string;
    email?: string;

}

interface props {
    record: ProjectForm
    setRecord: (arr: {}) => void
    onSelectedIdsChange: (arr: string[]) => void;
    selectedIds?: string[]
    alarmIndex: number
    level: number
    isOpen: boolean;
    setisOpen: (x: boolean) => void;
}

const MODAL_STYLES = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

}

const OVERLAY_STYLES = {
    zIndex: 999,

    width: '100vw',
    height: '100vh',
    backgroundColor: ' rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
};

const CONTENT_STYLES = {

    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    zIndex: 1000,
    padding: '32px',
    boxShadow: '0 1px 12px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    maxHeight: '100vh',
    overflowY: 'auto',
};


export const AddUserInfoTable = observer(({ level, alarmIndex, record, setRecord, selectedIds, onSelectedIdsChange, isOpen, setisOpen }: props) => {
    const [page, setPage] = useState(0);

    const [userIds, setUserIds] = useState(() => {
        if (
          record &&
          record.alarm &&
          Array.isArray(record.alarm) &&
          record.alarm.length > alarmIndex &&
          record.alarm[alarmIndex].alarmLevels &&
          record.alarm[alarmIndex].alarmLevels.length >= level &&
          record.alarm[alarmIndex].alarmLevels[level - 1]
        ) {
          return (record.alarm[alarmIndex].alarmLevels[level - 1].usersId ?? []).map(Number);
        }
        return [];
      });

    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);

    const [usernameState, setUsernameState] = useState("");

    const [buttonChoice, setButtonChoice] = useState("Username");

    const { userStore } = useStore();

    const { LoadingUsers, usersDto } = userStore
    const activeId = useRef<string | null>(null);
    const [results, setResult] = useState<userInfo[]>([])




    function handlePreSave(finalIds:string[]) {

        setRecord((prev: ProjectForm) => ({
            ...prev,
            alarm: prev.alarm?.map((alarmItem, idx) =>
                idx === alarmIndex
                    ? {
                        ...alarmItem,
                        alarmLevels: alarmItem.alarmLevels.map((levelItem, levelIdx) =>
                            levelIdx === level - 1
                                ? { ...levelItem, usersId: finalIds.length === 0 ? undefined : finalIds }
                                : levelItem
                        )
                    }
                    : alarmItem
            )
        }))
        console.log("Presave,", record)

        setisOpen(false);
    }


    useEffect(() => {
        const fetchData = async () => {
            console.log("SourceTypes appended")

            try {
                await LoadingUsers();
                //setisLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        if (userStore) {
            fetchData();

        }
    }, []);


    useEffect(() => {
        const updatedRows = results
            .filter((item) => (
                item.username?.toLowerCase().includes(usernameState.toLowerCase())
            ))
            .map((item: userInfo) => ({
                data: [
                    { id: "username", data: item.username ? item.username : "--" },
                    { id: "telNo", data: item.telNo ? item.telNo : "--" },
                    { id: "email", data: item.email ? item.email : "--" },


                ],
                Id: item.id,

                ischecked:  (
                    record?.alarm?.[alarmIndex]?.alarmLevels?.[level - 1]?.usersId?.includes(item.id)
                  ) ?? false
            }));

        setrows(updatedRows);

    }, [results, usernameState, selectedIds]);


    useEffect(() => {

        const temp: userInfo[] = usersDto.map(
            (user) => ({
                id: String(user.id),
                username: user.username,
                telNo: user.telNo,
                email: user.email

            })
        )
        setResult(temp)
        console.log(results)
    }, [usersDto, userStore])


    const statesArray: states[] = [
        {
            currentstate: usernameState,
            statemethod: setUsernameState
        }
    ]
    const headers: Array<CoustomTableHeaderProps> = [

        { id: "username", data: "User Name" },
        { id: "telNo", data: "Telephone Number" },
        { id: "email", data: "Email Address" },


    ];



    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            // Reset the filter state when the modal is closed.
            setUsernameState("");

            document.body.style.removeProperty('overflow');
        }
    }, [isOpen]);
    if (isOpen == false) return null;




    return ReactDOM.createPortal(

        <div className="content" style={MODAL_STYLES as React.CSSProperties}>
            <div style={OVERLAY_STYLES as React.CSSProperties} />
            <div style={CONTENT_STYLES as React.CSSProperties}>
                <div className="pageParentDiv " style={{ borderRadius: '20px' }}>
                    <InfoTableHeader buttonChoice={buttonChoice} title="Search External User Record" placeholder="Filter Username"
                        statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))}
                        setState={setUsernameState}
                        setButtonChoice={setButtonChoice}
                    />

                    <ThemeProvider theme={customTableTheme}>
                        <CoustomTable  page={page} setPage={setPage} maxHeight={400} onSelectedIdsChange={onSelectedIdsChange}  cancelButton={() => setisOpen(false)} assignButton={handlePreSave} headers={headers} setRows={setrows} rows={rows} activeId={activeId} tableMarginRight="250" />
                    </ThemeProvider>


                </div>
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement



    )
})