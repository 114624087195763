import React, { FormEvent, useEffect, useState } from 'react'
import { AccountEditErrorValue, AccountEditForm, AccountEditValue, IAccountEditError } from '../../../../models/Account';
import { observer } from 'mobx-react-lite';
import { InputElement } from '../../../FormElement/InputElement';
import { Link, useNavigate } from 'react-router-dom';
import { IButton } from '../../../Button/Button';
import { useStore } from '../../../../stores/store';
import { SelectElement } from '../../../FormElement/SelectElement';

interface Prop {
  selectedId?: string;
  record: AccountEditForm;
  setRecord: (arr: AccountEditForm) => void
  handleNext: (arr: {}) => void
  errors: Partial<IAccountEditError>
  handleValidationChange:(field: string, value: any)=>void
}


export default observer(function AccountFormEdit({handleValidationChange,  errors, handleNext, selectedId, record, setRecord }: Prop) {
  const { accountStore } = useStore()
  const { roles, EditAccount, LoadingRoles, LoadingAccountEditForm, selectedAccount, loadingInitial } = accountStore
  const { summaryStore } = useStore();
  const { LoadingCompany, companys } = summaryStore

  const navigate = useNavigate();
  useEffect(() => {
    LoadingRoles()
    LoadingCompany();

  }, [])

  useEffect(() => {
    if (selectedId) {
      LoadingAccountEditForm(selectedId).then(res => {
        setRecord(new AccountEditValue(res))
        console.log(res)
      }
      );
    }
  }, [selectedId])




  const showRecord = () => {
    console.log(record)
  }

  return (

    <div className="mt-4 mb-[10px] flex flex-col gap-4">
      <span className="text-[20px] font-medium text-decoration-line: underline" onClick={showRecord}>General Information</span>
      <div className="flex flex-col gap-4  sm:flex-row sm:gap-5">
        <div className="w-full sm:w-[50%]">
          <InputElement handleValidation={handleValidationChange} title={"First Name"} name={"firstName"} isRequired setRecord={setRecord} record={record} value={record.firstName} error={errors.firstName}/>
        </div>
        <div className="w-full sm:Sw-[50%]">
          <InputElement handleValidation={handleValidationChange}  title={"Last Name"} name={"lastName"} isRequired setRecord={setRecord} record={record} value={record.lastName} error={errors.lastName}/>
        </div>

      </div>
      <SelectElement handleValidation={handleValidationChange}  title={"Role"} name={"role"} isRequired={true} record={record} setRecord={setRecord} defValue={record.role} options={roles} error={errors.role}/>
      <SelectElement handleValidation={handleValidationChange} defValue={record.companyId} isWithApi={true} isRequired={true} record={record} setRecord={setRecord} name={"companyId"} title={"Company"} options={companys} error={errors.companyId} />

      <InputElement handleValidation={handleValidationChange} title={"Mobile Number"} name={"phoneNumber"} isRequired={false} setRecord={setRecord} record={record} value={record.phoneNumber} error={errors.phoneNumber}/>


      <span className="text-[20px] font-medium text-decoration-line: underline mt-4">Login Information</span>
      <InputElement handleValidation={handleValidationChange} title={"Email Address"} name={"email"} isRequired={true} setRecord={setRecord} record={record} value={record.email} error={errors.email} />

      <div className="flex justify-center gap-5 mt-5 mb-4 flex-wrap">
        <IButton type="button" text={"Next"} onClick={handleNext} className="w-full"/>
          <IButton onClick={()=>{navigate(-1)}}type="button" text="Cancel" backgroundColor="[#FFFFFF]" textColor="[#15304B]" borderColor="nav-blue" className="w-full"/>

      </div>
    </div>
  )
})
