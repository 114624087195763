import React, { useState } from 'react'
import { AccountCreateErrorValue, AccountCreateForm, AccountCreateValue, AccountEditValue, IAccountCreateError } from '../../models/Account';
import CreateFormHeader from '../../components/form/CreateFormHeader/CreateFormHeader';
import { Page } from '../../components/TitlePath/TitlePath';
import AccountCreateGeneralInfo from '../../components/form/Account/CreateForm/AccountCreateGeneralInfo';
import AccountCreateLogin from '../../components/form/Account/CreateForm/AccountCreateLogin';
import { useStore } from '../../stores/store';
import { TableHistory } from '../../models/Inventory';

export default function AccountCreatePage() {
    const [record, setRecord] = useState<AccountCreateForm>(new AccountCreateValue());
    const lastPages: Page[] = [{ title: "Account Management", path: "/account" }]
    const [page, setPage] = useState<number>(0)
    const [errors, setErrors] = useState<Partial<IAccountCreateError>>(new AccountCreateErrorValue());
  const { accountStore } = useStore()
    const { getUserHistory, submitting } = accountStore;
    const [history, setHistory] = useState<TableHistory | undefined>(undefined);
    
    return (
   
 <div className="pageParentDiv flex flex-col px-5">
            <CreateFormHeader
                registeredBy={""}
                registeredOn={""}
                updateBy={""}
                updateOn={""}
                titleText={"Register Account Record"}
                lastPages={lastPages}
                formType={"account record"} />

            {page === 0 && <AccountCreateGeneralInfo errors={errors} setErrors={setErrors} record={record} setRecord={setRecord} setPage={()=>setPage(1)} /> }
            {page === 1 && <AccountCreateLogin errors={errors} setErrors={setErrors}  record={record} setRecord={setRecord}  setPage={()=>setPage(0)}/> }

        </div>
    
  )
}
