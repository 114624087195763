import { makeAutoObservable } from "mobx";
import { Alarm } from "../models/Alarm";
import agent from "../services/Api/agent";

export default class AlarmStore{

    alarm: Alarm | undefined = undefined;

    constructor(){
        makeAutoObservable(this);
    }

    getAlarm = async (SourceTypeId : number,ProjectId : number) => {
        return await agent.Alarms.details(SourceTypeId,ProjectId);
    }

    getAllAlarms = async () => {
        return await agent.Alarms.list();
    }
}