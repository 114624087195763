import React, { useState, ChangeEvent, useEffect } from "react";
import { read, utils, writeFile, WorkBook, AOA2SheetOpts, JSON2SheetOpts, WorkSheet } from 'xlsx';
import { Inventory } from "../../../models/Inventory";
import InventoryImportTable from "./InventoryImportTable";
import GeneralButton from "../../../components/Button/GeneralButton";



const InventoryImportForm = () => {
    const [fileName, setFileName] = useState<string | undefined>();

    const [inventories, setInventories] = useState<Inventory[]>([]);
   
   
    const handleImport = ($event: ChangeEvent<HTMLInputElement>) => {
        const files = $event.target.files;
        if (files && files.length) {
            const file = files[0];
            setFileName(file.name)
            const reader = new FileReader();
            
            reader.onload = (event) => {
                const wb: WorkBook = read(event.target?.result, { type: 'array' });
                const sheets = wb.SheetNames;
            
                if (sheets.length) {
                    let rows: any[] = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    
                    // Convert sourceTypeId from string to array and dates to string
                    rows = rows.map(row => {
                        if ('sourceTypeId' in row) {
                            row.sourceTypeId = row.sourceTypeId.split(',');
                        }
                        // if ('warrantyStartDate' in row) {
                        //     row.warrantyStartDate = convertSerialDate(row.warrantyStartDate);
                        // }
                        // if ('warrantyEndDate' in row) {
                        //     row.warrantyEndDate = convertSerialDate(row.warrantyEndDate);
                        // }
                        // add other date fields here ...
                        return row;
                    });
            
                    setInventories(rows as Inventory[]);
                }
            }



            reader.readAsArrayBuffer(file);
        }
    }

    useEffect(() => {
        console.log(inventories)
    }, [inventories])


    return (
        <div className="overflow-auto">
            <div className=" mt-4">

                <div className="flex justify-between items-center">
                    
                        <div>
                            <span>File name: </span>
                            {fileName &&
                            <span className=" whitespace-nowrap">
                                {fileName}
                            </span>}
                        </div>
                    
                    <div className='flex items-center'>




                        <label className=' px-[25px] h-10 cursor-pointer  rounded-lg bg-theme-orange flex items-center justify-center'>
                            <input type='file' style={{ display: 'none' }} onChange={handleImport} />
                            <span className='whitespace-nowrap text-white'>
                                {"Import Excel"}
                            </span>
                        </label>

                        <GeneralButton title="Register" />
                    </div>

                </div>
                <InventoryImportTable selectedInventories={inventories} />

            </div>


        </div>
    );
};

export default InventoryImportForm;