import React, { useState } from 'react'
import { ProjectForm } from '../../../../../models/Project'
import { AddUserInfoTable } from '../InfoTable/AddUserInfoTable'

interface Prop {
  handleClick: () => void
  setTableBlur: (arr: boolean) => void
  record: ProjectForm
  setRecord: (arr: {}) => void
  alarmIndex:number
  level: number
}

export default function AddUserButton(p: Prop) {
  const btnSrc = "/assets/button/orangeAdd.png"
  const [userInfoisOpen, setUserInfoisOpen] = useState(false);
  
  function handleUserInfo(arr:boolean){
    setUserInfoisOpen(arr);
    p.setTableBlur(arr);
  }

  return (
    <div>
      <div onClick={()=>{handleUserInfo(true)}}>
        <img src={btnSrc} />
      </div>

      <AddUserInfoTable level={p.level} alarmIndex={p.alarmIndex} record={p.record}  setRecord={p.setRecord} onSelectedIdsChange={() => {}} selectedIds={[]} isOpen={userInfoisOpen} setisOpen={handleUserInfo} />
    </div>

  )

}
