import React, { useEffect, useState } from 'react'
import ProjectTypeHeaderItems from './ProjectTypeHeaderItems';
export interface TypeHeaderItem{
  imgSrc?:string
  clickedImgSrc?:string
  isSelected:boolean
  title:string
}

interface prop{
    changePage: (arr: number) => boolean | Promise<boolean>;
    page:number;
    isCreate?:boolean
    InventoryTypeHeaderAllItems: TypeHeaderItem[]
    
}
export default function ProjectTypeHeader({ InventoryTypeHeaderAllItems, changePage, page}:prop) {
    const [clickedId, setClickedId] = useState<number>(page)

    useEffect(()=>{
      setClickedId(page);
    },[page])
    
    const handleClick = (index: number) => {
        setClickedId(index);
    }
   
  return (
    <div className='relative w-full' >
      <div className='flex' >
        {InventoryTypeHeaderAllItems.map((items, index) => (
          <ProjectTypeHeaderItems length={InventoryTypeHeaderAllItems.length} hidden={false} changePage={changePage} key={index} imgSrc={items.imgSrc}
            clickedImgSrc={ items.clickedImgSrc } handleClick={() => handleClick(index)}
            isSelected={ clickedId === index } title={items.title}  id={index} />
        ))}
      </div>
      <div className="w-full h-5px absolute  bottom-0 rounded-full" style={{ backgroundColor: "rgb(172, 172, 172)" }}></div>
    </div>
  )
}
