import { ChangeEvent, useState } from "react";
import { UserFormValue } from "../../models/User";
import { store } from "../../stores/store";
import { Message } from "semantic-ui-react";
import { Link } from "react-router-dom";


export default function RegisterForm() {
    const [registerObj, setRegisterObj] = useState<UserFormValue>({
        username: '',
        email: '',
        password: '',
    });
    const [confirmPw, setConfirmPw] = useState<string>("");
    const [FormError, setFormError] = useState<Partial<UserFormValue>>({});
    const [ServerError, setServerError] = useState<string>("");
    const [confirmPwError, setConfirmPwError] = useState<string>();
    function handleInput(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        setRegisterObj({ ...registerObj, [name]: value })  //name='title'  >>> change activity.title
    }
    function handleConfirmPw(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.target
        setConfirmPw(value);
    }

    function handelSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const error = handleRegisterValidation(registerObj);
        console.log(Object.keys(error).length + "  " + confirmPwError)
        if (Object.keys(error).length !== 0 || confirmPwError !== undefined ) {
            setFormError(error);
            //  console.log("return")
            return;
        }
        console.log("hand in register form")
        store.userStore.register(registerObj).catch(error => {
            setServerError(error)
        });
        
    }


    function handleRegisterValidation(reg: UserFormValue) {
        const ClientError: Partial<UserFormValue> = {}
        if (!reg.username) {
            ClientError.email = "Username is empty"
        }

        if (!reg.email) {
            ClientError.email = "Email is empty"
        }


        if (!reg.password) {
            ClientError.password = "Password is empty"
        }
        console.log(reg.password + "  " + confirmPw)
        if (reg.password !== confirmPw) {

            setConfirmPwError("Confirm Password is not match")
        } else {
            setConfirmPwError(undefined)
        }

        if (confirmPw === "") {
            setConfirmPwError("Confirm Password is empty")
        }
        return ClientError;
    }

    return (
        <div className='row ms-2' style={{ marginTop: '5em' }}>
            <div className='col-6 '>
                <form onSubmit={handelSubmit} autoComplete="off" >
                <div className='mb-3'>
                        <label className='form-label'>Username:</label>
                        <input className='form-control' placeholder='Username' value={registerObj?.username} onChange={handleInput} name='username'></input>
                        {FormError.username && <div>{FormError.username}</div>}
                    </div>

                    <div className='mb-3'>
                        <label className='form-label'>Email:</label>
                        <input className='form-control' placeholder='Email' value={registerObj?.email} onChange={handleInput} name='email'></input>
                        {FormError.email && <div>{FormError.email}</div>}
                    </div>
                    <div className='mb-3'>
                        <label className='form-label'>Password:</label>
                        <input className='form-control' type="password" placeholder='Password' value={registerObj?.password} onChange={handleInput} name='password'></input>
                        {FormError.password && <div>{FormError.password}</div>}
                    </div>
                    <div className='mb-3'>
                        <label className='form-label'>Confirm Password:</label>
                        <input className='form-control' type="password" placeholder='Confirm Password' onChange={handleConfirmPw}></input>
                        {FormError.password && <div>{FormError.password}</div>}
                        {confirmPwError && <div>{confirmPwError}</div>}
                    </div>
                    <div >
                        <button className='btn btn-primary mt-1' type='submit'>Register</button>
                        <Link to={"/"}>
                            <button className='btn btn-secondary mt-1 ms-2'>Back</button>
                        </Link>
                    </div>
                </form>
            </div>

            {ServerError &&
                <Message error>
                    {ServerError}
                </Message>}

        </div>
    )
}
