import { ChangeEvent } from 'react'

interface Props<T> {
    height?: string
    title: string;
    name: string;
    isRequired: boolean;
    setRecord: (input: T) => void;
    record: T;
    error?: string;
    value?: string | number | readonly string[] | undefined
    readonly?: boolean;
    overrideChange?:(event: ChangeEvent<HTMLTextAreaElement>)=>void
    unitsscript?: string;
    overrideChangeUnit?:(event: ChangeEvent<HTMLInputElement>)=>void
}

export const TextAreaElement = <T extends {}>({overrideChange, readonly, height, value, name, title, isRequired, setRecord, record, error, unitsscript, overrideChangeUnit }: Props<T>) => {
    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        const { name, value } = event.target;
        setRecord({ ...record, [name]: value })
    }
    function handleChangeunit(event: ChangeEvent<HTMLInputElement>) {
        setRecord({ ...record, Unit: event.target.value })
    }
    return (
        <div className="flex flex-col w-full">
            <div className="">
                <label className="flex justify-between">
                    <p className={`${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>
                    {title != 'Remark Description' && title != "Remark" &&
                    <span>
                        Script Unit: <input className={`border-2 rounded-lg border-[#ACACAC] w-20`}  defaultValue={unitsscript} onChange={(e)=>overrideChangeUnit!=undefined?overrideChangeUnit(e):handleChangeunit(e)} />
                    </span>}
                </label>
            </div>

            <div>
                <textarea value={value} readOnly={readonly} className={`w-full ${readonly ? "bg-[#D9D9D94D]" : ""} ${height ? height  : "h-[150px]"} border-2 rounded-lg border-[#ACACAC]`} name={name} onChange={(e)=>overrideChange!=undefined?overrideChange(e):handleChange(e)} />
                {error && <p>{error}</p>}
            </div>
            
        </div>
    )
}