import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const BpIcon = styled('span')(({ theme }) => ({

  width: "25px",
  height: "25px",
//   backgroundColor: 'transparent',  
//   borderWidth: '2.5px',  
//   borderStyle: 'solid',  
//   borderColor: '#CC311B',  
backgroundImage: 'url(/assets/table/checkbox.svg)',
backgroundRepeat: 'no-repeat',  // Prevent the image from repeating
  backgroundPosition: 'center',  // Position the image in the center of the element
  backgroundSize: 'contain',  // Scale the image to fit within the element
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundImage: 'url(/assets/table/checkboxTicked.svg)',  // Your image URL
    backgroundRepeat: 'no-repeat',  // Prevent the image from repeating
    backgroundPosition: 'center',  // Position the image in the center of the element
    backgroundSize: 'contain',  // Scale the image to fit within the element
    
  });

// Inspired by blueprintjs
export function BpCheckbox(props: CheckboxProps) {
  //console.log(props.checked);
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

