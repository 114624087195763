
import { action, makeAutoObservable, runInAction } from 'mobx';
import { InventoryDto, Inventory, InventoryForm, InventoryFormValue, InventoryType, InventoryCounts } from '../models/Inventory';
import agent from '../services/Api/agent';



export default class InventoryStore {

    loadingInitial = false;
    submitting = false;

    Inventories: InventoryDto[] = [];
    InventoryTypes: InventoryType[] = [];
    selectedInventory: Inventory | undefined = undefined
    InventoryCount: InventoryCounts = { all: 0, logger: 0, sensor: 0, other: 0 };

    filterPlaceholder = "Filter Equipment ID";

    constructor() {
        makeAutoObservable(this);
    }
 
    addToInvDtoList = (record: InventoryForm) => {
        console.log("hi")
        let { equipment, recipt, ...restRecord } = record;

        let inventoryDto: Partial<InventoryDto> = {
            ...restRecord,
            inventoryTypeName: "",

        };

        this.Inventories.push(inventoryDto as InventoryDto);
        console.log(this.Inventories)
    }

    ChangePlaceHolder = (p: string) => {
        this.filterPlaceholder = p;
    }

    LoadingInventoryType = async () => {
        try {
            const InventoryTypeList = await agent.Inventories.getType();
            runInAction(() => {
                this.InventoryTypes = InventoryTypeList ? InventoryTypeList : []
            })
        }
        catch (error) {
            throw error

        }
    }

    LoadingInventoryList = async () => {
        this.loadingInitial = true;
        try {
            const InventoryList = await agent.Inventories.list();
            runInAction(() => {
                this.Inventories = InventoryList ? InventoryList : []
            })
        }
        catch (error) {
            throw error
        }
        this.loadingInitial = false;

    }

    LoadingInventoryListByStatus = async (statusId: number) => {
        this.loadingInitial = true;
        try {
            var temp = await agent.Inventories.listByStatus(statusId)
            const InventoryList: InventoryDto[] = temp ? temp : [];

            return InventoryList;
        }
        catch (error) {
            throw error
        }
        this.loadingInitial = false;

    }

    LoadingInventory = async (id?: string) => {
        if (id === undefined) return

        var inventory: Inventory;

        this.loadingInitial = true;

        try {
            const selectedInventory = await agent.Inventories.details(id).then(
                response => {
                    inventory = { ...response };  // Create a copy of the response

                    Object.keys(inventory).forEach((key: string) => {
                        const value = (inventory as any)[key];
                        if (key === 'sourceTypeId' && typeof value === 'string') {
                            (inventory as any)[key] = JSON.parse(value);
                        } else {
                            (inventory as any)[key] = value !== null
                                ? (key.includes('Date') || key.includes('Maintenance' || key.includes('DisposedDate')) ? value.split('T')[0] : value)
                                : undefined;
                        }
                    });

                }
            );
            runInAction(() => {
                this.selectedInventory = inventory
            })
            this.loadingInitial = false;
            return this.selectedInventory
        }
        catch (error) {
            this.loadingInitial = false;

            throw error
        }
    }

    LoadingInventoryDto = async (id?: string) => {
        if (id === undefined) return

        var inventory: InventoryDto;

        this.loadingInitial = true;

        try {
            const inventory = await agent.Inventories.detailsDto(id);


            return inventory
        }
        catch (error) {
            this.loadingInitial = false;

            throw error
        }
    }

    CreateInventory = async (record: InventoryForm) => {
        this.submitting = true;
        try {
            console.log(record)
            const formData = new FormData();

            for (const key in record) {
                const value = (record as Record<string, any>)[key];

                if (value !== undefined) {
                    if (value instanceof File) {
                        formData.append(key, value);
                    } else if (value instanceof Array) { //handling sourceTypeId array
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            }
            // this.addToInvDtoList(record);

            await agent.Inventories.create(formData);
        }
        catch (error) {
            throw error
        }
        this.submitting = false;

    }

    EditInventory = async (record: InventoryForm) => {
        this.submitting = true;
        try {
            console.log(record)
            const formData = new FormData();

            for (const key in record) {
                const value = (record as Record<string, any>)[key];

                // Don't append if value is undefined
                if (value !== undefined) {
                    if (value instanceof File) {
                        formData.append(key, value);
                    } else if (value instanceof Array) { //handling sourceTypeId array
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            }

            await agent.Inventories.update(formData);
        }
        catch (error) {
            this.submitting = false;

            throw error
        }
        this.submitting = false;



    }

    getCount = async () => {
        try {

            const inventoryCount = await agent.Inventories.getCount();
       //     console.log(inventoryCount)
            runInAction(() => {
                this.InventoryCount = inventoryCount ? inventoryCount : { all: 0, logger: 0, sensor: 0, other: 0 }
            })
        }
        catch (error) {
            throw error
        }
    }

    getInventoryHistory = async (id?: string) => {
        try {
            if (id == null)
                return
            return await agent.Inventories.getHistory(id);
        }
        catch (error) {
            throw error
        }
    }

    getSourceTypeIds = async (inventoryId?: string) => {
        try {
            if (inventoryId == null) return;
            return await agent.Inventories.getSourceTypeIdsList(inventoryId);
        } catch (error) {
            console.log(" error inside inventory store : getSourceTypeIds ")
            throw error
        }
    }

    fetchInventoryByIds = async (ids: string[]) => {
        console.log(ids)
        var newInventoryList: InventoryDto[] = []
        for (var id of ids) {
            console.log(id); // This should now log the actual id values
            const InventoryDto = await this.LoadingInventoryDto(id)
            if (InventoryDto != undefined) {
                newInventoryList.push(InventoryDto)
            }
        }
        console.log(newInventoryList)
        return (newInventoryList)
    }

    DeleteInventories = async (ids: string[]) => {
        try {

            await agent.Inventories.delete(ids);

        }
        catch (error) {
            throw error;
        }
    }

    exportInventories = async (Ids: String[], event?: Event) => {
        if (event) {
            event.preventDefault(); // Prevent the default event action
        }


        try {

            const Number_Ids = Ids.map(x => Number(x));
            const blob = await agent.Inventories.exportInv(Number_Ids);

            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = blobUrl;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up by revoking the Blob URL after usage
            link.addEventListener('click', () => {
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            }, { once: true });

            return blob;
        }
        catch (error) {
            console.log(error);
        }
    }
}
