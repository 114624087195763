import { useState } from "react";

interface props {
    isChecked?: boolean,
    setisChecked?: (x: boolean) => void
}

export const DefaultTickButton = ({ isChecked, setisChecked }: props) => {

    const [color, setcolor] = useState("#ACACAC");

    const handleChange = () => {
        if (isChecked!==undefined && setisChecked) {
            setisChecked(!isChecked);
            if (!isChecked == true) {
                setcolor("#000000");
            } else {
                setcolor("#ACACAC");
            }
        }
    }

    return (
        <div className="w-[216px] h-[30px] bg-[blue-900] flex gap-[11.4px]">
            <label className={`w-[30px] h-[30px]`} style={{ border: `3px solid ${color}` }}>
                <input type="checkbox" checked={isChecked} onChange={handleChange} className="hidden" />
                {isChecked &&
                    <img src="\assets\button\plain_tick.png" />
                }
            </label>
            <div className={`w-[176px] h-full flex items-center  font-inter text-xl font-normal font-medium text-[${color}]`} style={{ fontWeight: "600" }}>Setting As Default</div>
        </div>
    )
}