import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { useStore } from '../../stores/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import LoadingComponent from '../../components/LoadingComponent';
import { states } from '../SourceType/SourceTypeRecord';
import PerformTableHeader from './PerformTableHeader';
import { IPerformanceForm, PerformTableDto } from '../../models/Pharse2/Performance';
import debounce from 'lodash.debounce';

interface PerformType {
    id: string,
    dateTime?: string
    locationName?: string,
    value?: number,

}


interface Prop {
    record: IPerformanceForm
    setRecord: (arr: IPerformanceForm) => void
    PerformData: PerformTableDto | undefined
    setPerformData:(arr:PerformTableDto|undefined ) =>void

}

export default observer(function PerformanceTable({PerformData,setPerformData, record, setRecord }: Prop) {



    
    const [page, setPage] = useState(0);
    const setFormPage = (p: number) => {
        setRecord({ ...record, page: p })
    }

    const [buttonChoice, setButtonChoice] = useState("dateTime");

    const [excelRecord, setexcelRecord] = useState<PerformType[]>([]);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
    const [Results, setResult] = useState<PerformType[]>([])

    const { performanceStore } = useStore();

    const {  loadingInitial, getData } = performanceStore
    const navigate = useNavigate();
    const location = useLocation();

    const setdateTimeState = (state: string) => {
        const newFilterProp = { ...record.filterProp, dateTimeState: state }
        setRecord({ ...record, filterProp: newFilterProp })
    }


    const setvalueState = (state: string) => {
        const newFilterProp = { ...record.filterProp, valueState: state }
        setRecord({ ...record, filterProp: newFilterProp })
    }
    const statesArray: states[] = [
        {
            currentstate: record.filterProp.dateTimeState,
            statemethod: setdateTimeState
        },

        {
            currentstate: record.filterProp.valueState,
            statemethod: setvalueState
        },
    ]

    useEffect(() => {
        setFormPage(0)

    }, [buttonChoice, record.filterProp.dateTimeState, record.filterProp.valueState])

    const fetchData =(record:IPerformanceForm)=>{
        getData(record, false).then( i => setPerformData(i))

    }
    const debouncedFetch = useCallback(
        debounce((record) => {
          fetchData(record);
        }, 1000),
        [] 
      );
    useEffect(() => {
        console.log(record.page)
        if (PerformData != undefined) {
            debouncedFetch(record)
        }
        //LoadingCount(nameState, companynameState, telnoState, mobilenoState, emailState);

    }, [record.filterProp.dateTimeState, record.filterProp.valueState, record.page])

   

    useEffect(() => {
        const initialRows: Array<CoustomTableRowProps> =
            Results.map(
                (record: PerformType) => ({
                    Id: record.id,
                    ischecked: false,

                    data: [
                        { id: "dateTime", data: record.dateTime, isJSXelement: false },
                        { id: "locationName", data: record.locationName },
                        { id: "value", data: record.value },

                    ]
                })
            )
        setrows(initialRows)
        console.log(initialRows)
    }, [Results])



    useEffect(() => {
        if (PerformData != undefined && PerformData) {
            const Results2: PerformType[] = PerformData.datas.map(
                (d, idx) => ({
                    dateTime: d.dateTime,
                    locationName: d.locationName,
                    id: idx.toString(),
                    value: d.value
                })
            );
            setResult(Results2)
        }

    }, [PerformData])

    const headers: Array<CoustomTableHeaderProps> = [
        { id: "dateTime", data: "DateTime" },
        { id: "locationName", data: "Location Name" },

        { id: "value", data: "Value" },

    ];





    const activeId = useRef<string | null>(null)


    const stateSelection = buttonChoice === "dateTime"
        ? setdateTimeState
        : setvalueState

const show =()=>{
    console.log(PerformData)
}





    if (PerformData === undefined)
        return (
            <div>
                {loadingInitial && (
                        <LoadingComponent content="Loading activity..." />
                )}
                <div className="h-[600px] flex items-center justify-center">

                    <span className="text-[#CC311B]">No record</span>
                </div>
            </div>
        )




    return (

        <div className=" pageParentDiv mt-4" >
            {loadingInitial && (
                    <LoadingComponent content="Loading activity..." />
            )}
            <PerformTableHeader buttonChoice={buttonChoice} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={setButtonChoice} options={headers.filter((item) => item.id == "dateTime" || item.id == "value")} title={PerformData.search_LocationName} />
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable page={page} setPage={setPage} apiCount={PerformData.count} apiPage={record.page} setApiPage={setFormPage} activeId={activeId} excelRecord={excelRecord} setexcelRecord={setexcelRecord} headers={headers} rows={rows} setRows={setrows} />
            </ThemeProvider>

        </div>


    )
})
