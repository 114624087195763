import React from 'react'

export interface FieldProp {
    title: string | string[];
    index: number;
    rowIndex?:number;
    setisOpen: (arr:boolean) => void
    textColor?: 'Black' | 'Grey' 
}
export default function AudienceField({ textColor, setisOpen, rowIndex,index, title }: FieldProp) {
    console.log(index)
    function click(){
      // e.stopPropagation();
        setisOpen(true);
    }
    return (
        <div onClick={click} className={` border-t-2 p-2 cursor-pointer flex w-[230px] h-[50px] border-r-2 border-b-2 border-[#D9D9D9] items-center`}>
            <span className={` ${(!textColor || textColor) === 'Grey' ? "text-[#ACACAC]" : "text-black" }  `} >
                {typeof title === "string" ? title : title.join(", ") }
            </span>
        </div>
    )
}
