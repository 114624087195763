import { ProjectDistinct } from "../../../../models/Pharse2/LocationMap"

export interface ColorOption {
    markerUrl: string,
    code: string,
}
export interface Dictionary {
    [key: number]: ColorOption
}

const colorArray:ColorOption[] = [
    {markerUrl:'green.png',code:'#3CB44B'},
    {markerUrl:'yellow.png',code:'#FFE119'},
    {markerUrl:'darkblue.png',code:'#232B83'},
    {markerUrl:'pink.png',code:'#FFB0B0'},
    {markerUrl:'lightgreen.png',code:'#80FF80'},
    //{markerUrl:'red.png',code:'#FF0000'},
    {markerUrl:'orange.png',code:'#FF8E1E'},
    {markerUrl:'darkgrey.png',code:'#747474'},
    {markerUrl:'black.png',code:'#000000'},
    {markerUrl:'purple.png',code:'#6F00DD'},
    //{markerUrl:'brown.png',code:'#9C6827'},
    {markerUrl:'lightblue.png',code:'#628FD5'},
    {markerUrl:'lightgrey.png',code:'#C8CEC6'},
    {markerUrl:'lightpink.png',code:'#FD97F5'},
    {markerUrl:'darkyellow.png',code:'#898301'},
    {markerUrl:'blue3.png',code:'#07F8F1'},
    {markerUrl:'lightbrown.png',code:'#FFD6AC'},
    {markerUrl:'lightpurple.png',code:'#CFCCDF'},
    //{markerUrl:'darkred.png',code:'#950000'},
    {markerUrl:'darkgreen.png',code:'#034901'},
];



export const colorAlgo = (projects:ProjectDistinct[]) => {
   
    const colorDictionary: Dictionary = projects.reduce((dict, item, idx) => {
        dict[item.id] = colorArray[idx % colorArray.length]; 
        return dict;
      }, {} as Dictionary);

    return colorDictionary;
}