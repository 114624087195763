import { FormEvent } from "react";

interface props {
    handleClick: () => void;
    title: string
    backgroundColor: string
    
}


export const RectangleButton = ({ handleClick, title, backgroundColor }: props) => {

    const style = {
        backgroundColor: backgroundColor,
        borderRadius: '10px',
        border: '2px solid #FFF',
        color: 'white',
        
    }

    const handleButtonClick =()=>{
       
        handleClick();
    }

    return (
        <button type="button" className="w-[156px] h-[40px] cursor-pointer text-base font-medium flex items-center justify-center" style={style}  onClick={()=>handleButtonClick()}>
            {title}
        </button>
    )
}