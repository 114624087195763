import React from 'react'

interface Props {
    length:number
    bgColor?: string,
    clickedColor?: string,
    imgSrc?: string,
    clickedImgSrc?: string,
    handleClick: (index: number) => void,
    isSelected: boolean,
    title: string,
    id: number,
    borderWidth?: number,
    changePage: (arr: number) => boolean | Promise<boolean>;
    hidden?: boolean
}

export default function ProjectTypeHeaderItems({ length, hidden, changePage, borderWidth, id, title, bgColor, clickedColor, imgSrc, clickedImgSrc, handleClick, isSelected }: Props) {

    const unSelectedColor = bgColor ? bgColor : "rgb(172, 172, 172)"
    const SelectedColor = clickedColor ? clickedColor : "rgb(204, 49, 27)"
    const finalColor = isSelected ? SelectedColor : unSelectedColor
    const finalImg = isSelected ? clickedImgSrc : imgSrc

    const finalClick =()=>{
      const res =   changePage(id)
      if(!res){return;}
      handleClick(id)
    }

    return (
        <>
        {!hidden &&
            <div className='flex flex-col items-center w-full px-2'>
                <div className='flex cursor-pointer mx-auto gap-2  items-center' style={{ height: "30px" }} onClick={finalClick}>
                    <div className="" style={{ flexShrink: 0 }}>
                        <img src={finalImg} className="w-[25px]"/>
                    </div>
                    <span className=' hidden md:block font-semibold whitespace-nowrap' style={{ color: finalColor, flexShrink: 0 }}>
                        {title}
                    </span>
                    

                </div>
                <div className='h-5px rounded-full absolute bottom-0 z-1'  style={{ width: `${100/length}%`, backgroundColor: isSelected ? finalColor : "transparent" }}>

                </div>


            </div>}
        </>
    )

}
