import { makeAutoObservable, runInAction } from "mobx";
import { CustomerDto, CustomerForm } from "../models/Customer";
import agent from "../services/Api/agent";

export default class CustomerStore {

    loadingInitial = false;
    submitting = false;


    Customers: CustomerDto[] = [];
    selectedCustomer: CustomerForm | undefined = undefined;
    count: number = 0;


    constructor() {
        makeAutoObservable(this);
    }

    LoadingCustomerList = async (name: string, companyName: string, telNo: string, mobileNo: string, email: string, page: number) => {
        
        try {
            const customerList = await agent.Customers.list(name, companyName, telNo, mobileNo, email, page);
            
            runInAction(() => {
                this.Customers = customerList?customerList:[]
            })
        
        }
        catch (error) {
            throw error
        }

    }

    LoadingCount = async (name: string, companyName: string, telNo: string, mobileNo: string, email: string) => {
        
        try {
            const cnt = await agent.Customers.cnt(name, companyName, telNo, mobileNo, email);
            
            runInAction(() => {
                if(cnt!=undefined)this.count = cnt;
            })
            
            return cnt;
        }
        catch (error) {
            
            throw error
        }

    }

    LoadingCustomer = async (id: string) => {
        if (id === undefined) return undefined;
        this.loadingInitial = true;
        var customer: CustomerForm;
        try {
            var temp = await agent.Customers.details(id)
            if(temp!=undefined)customer = temp;
            runInAction(() => {
            this.selectedCustomer = customer;
            })
        }
        catch (error) {
            throw error
        }
        this.loadingInitial = false;

    }

}