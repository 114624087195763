import { borderColor } from '@mui/system';
import { string } from 'prop-types';
import React, { MouseEventHandler } from 'react';

interface ButtonProps {
    px?: string;
    py?: string
    children?: React.ReactNode,
    className?: string,
    text?: string,
    textColor?: string
    backgroundColor?: string,
    focusColor?: string,
    hoverColor?: string,
    borderColor?: string
    type: 'submit' | 'reset' | 'button' | undefined
    onClick?: MouseEventHandler<HTMLButtonElement> 
}

export const IButton: React.FC<ButtonProps> = ({ px, py, borderColor, textColor, children, backgroundColor, hoverColor, focusColor, text, className, type, onClick }) => {
    return (
        <button
            className={` ${borderColor ? "border-2 border-" + borderColor : " "} ${focusColor ? "focus:ring-" + focusColor : " "} ${backgroundColor ? "bg-" + backgroundColor : "bg-[#15304B]"}  ${hoverColor ? "hover:bg-" + hoverColor : " "} focus:ring   focus:outline-none font-bold  delay-150    rounded-full  ${className ?? ""} `}
            type={type}
            onClick={onClick}
            style={{ padding: `${py ?? '12px'} ${px ?? '70px'}` }}
        >
            <p className={`${textColor ? "text-" + textColor + " " : "text-[#FFFFFF]"} `}>{text}</p>
            {children}
        </button >
    );
};