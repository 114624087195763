interface props {
    handleClick: () => void;
    title: string
    backgroundColor: string
    border: string
    textColor: string
    type?:"button" | "submit" | "reset" | undefined
    
}


export const RoundButton = ({ handleClick, title, backgroundColor,border,textColor,type }: props) => {

    const style = {
        backgroundColor: backgroundColor,
        borderRadius: '20px',
        border: '3px solid #15304B',
        color: textColor,
        
    }

    return (
        <button type={type} className="w-[156px] h-[40px] cursor-pointer text-base font-medium flex items-center justify-center" style={style} onClick={handleClick}>
            {title}
        </button>
    )
}