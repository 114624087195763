import { AlarmLevel } from "../Alarm";

export interface Dict{
    [key: number]: string;
}

export interface ResourceData {
    id: number;
    castcoId: number;
    resourceTypeId: number;
    dateTime: string;
    value: number;
}

export interface SelectListOption {
    id: number,
    name: string
}

export interface GraphData {
    sourceTypeId:number
    locationIds:number[]
    fromDate?: string;
    toDate?: string
    intervalId: number
    summaryId: number

    xAxis: string;
    yAxis: string;
    yUnit?:string;
	alarmUnit?:string;
    xData: string[][];
    yData: number[][];
    locationName: string[];
    alarmLevels?:Graph_AlarmLevelDto[];
}
export interface Graph_AlarmLevelDto{
    name:string
    level:number
    conditionId:number
    value:number
    alarmId:number
}

export class GraphDataVal implements GraphData {
    intervalId: number = -1
    summaryId: number = -1
    sourceTypeId:number =-1
    locationIds:number[] = []


    xAxis: string = "";
    yAxis: string = "";
    xData: string[][] = [];
    yData: number[][] = [];
    locationName: string[] = [];
    constructor(init?: GraphData) {
        Object.assign(this, init);
    }
}

export interface GraphForm {
    projectId: number;
    locationIds: number[];
    sourceTypeId: number;
    fromDate?: string;
    toDate?: string
    intervalId: number
    summaryId: number

    isScripted?:boolean

}


export class GraphFormVal implements GraphForm {
    projectId: number = -1;
    locationIds: number[] = [];
    sourceTypeId: number = -1;
    intervalId: number = -1;
    summaryId: number = -1;

    fromDate?: string;
    toDate?: string;

    constructor(init?: Partial<GraphForm>) {
        Object.assign(this, init);
    }

}
export class GraphError{
    projectId: boolean = false;
    locationIds:boolean = false;
    sourceTypeId:boolean = false;
    fromDate: boolean = false;
    toDate:boolean = false
    intervalId: boolean = false
    summaryId: boolean = false
}