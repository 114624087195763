import { useState } from "react";
import { states } from "../../../../pages/SourceType/SourceTypeRecord";
import { SymbolTextBox } from "../../../Input/SymbolTextbox";
import { CoustomTableHeaderProps } from "../../../Table/CoustomTable";

interface Props {
    buttonChoice:string
    title:string;
    setState: (str: string) => void;
    setButtonChoice: (str: string) => void;
    options: Array<CoustomTableHeaderProps>;
    statesArray: states[];

}



export const LoggerInfoTableHeader = ({buttonChoice, title, setState, setButtonChoice, options, statesArray }: Props) => {

    const [filterPlaceholder,setfilterPlaceholder] = useState("Filter Logger Name");

    const filterProps = {
        placeholder: filterPlaceholder,
        IconLeft: "/assets/button/filterProp/filterLeft.png",
        IconRight: "/assets/button/filterProp/filterRight.png",
    
    }
    
    return (
        <div className=' w-full mx-3 my-3 flex flex-col gap-2'>
            <p className=" font-md text-[20px]">Search Inventory {">"}  {title} </p>
            <div className='flex justify-between'>
                <div className='flex' >
                    <SymbolTextBox buttonChoice={buttonChoice} changePlaceholderValue={setfilterPlaceholder} options={options} IconLeft={filterProps.IconLeft} IconRight={filterProps.IconRight} placeholder={filterProps.placeholder} onChange={(e) => setState(e.target.value)} statesArray={statesArray} setButtonChoice={setButtonChoice} />
                </div>
                <div></div>

            </div>
        </div>
    )
}