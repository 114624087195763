import React from 'react'
import GeneralButton from '../../components/Button/GeneralButton'

interface Prop{
    exportAll:()=>void
    exportCurrent:()=>void
    allBtnisDisabled:boolean
    currentBtnisDisabled:boolean

}
export default function DataPageHeader(Prop:Prop) {
    return (
        <div className='flex flex-col gap-4' >
            <div className="flex flex-col md:flex-row md:h-[40px] md:justify-end md:items-center">
					<div className='flex flex-col md:flex-row gap-2'>
                    <GeneralButton disabled={Prop.allBtnisDisabled} imgSrc="/assets/button/excel.svg" title="export all locations" handleClick={Prop.exportAll} />
                    <GeneralButton disabled={Prop.currentBtnisDisabled} imgSrc="/assets/button/excel.svg" title="export current location"  handleClick={Prop.exportCurrent}/>
					</div>
            </div>
        </div>
    )
}
