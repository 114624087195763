import React from 'react'
import { NavItem } from '../NavBar';
import NavItems from '../NavItem';
import {useLocation} from 'react-router-dom';


interface Prop {
}
export default function Project70AdminNav() {
  const Project70AdminNavItems: NavItem[] = [
    { title: 'Project', src: 'project', path: '/projectManagementMenu' },
  ];
  
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="w-full flex flex-col items-center">
      <div className="my-5 ml-7 self-end md:self-start">
        <span className="text-white text-[12px] md:text-base " >
          Admin <br /> Management
        </span>
      </div>

      {Project70AdminNavItems.map((item, index) => (
        <NavItems key={index} title={item.title} imgSrc={item.src}
          path={item.path} isSelected={pathname === item.path} />
      ))}
    </div>
  )
}
